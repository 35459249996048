import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class DeleteTaxMessage extends ClientMessage {
    constructor(messageId, senderUserId, taxInstanceId) {
        super(NetworkProtocol.DeleteTax, messageId, senderUserId);
        this.taxInstanceId = taxInstanceId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { taxInstanceId: this.taxInstanceId });
    }
    static fromJSON(json) {
        return new DeleteTaxMessage(json.messageId, json.senderUserId, json.taxInstanceId);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('delete tax message validating: ', this.toJSON());
        return this.taxInstanceId !== undefined && super.validate();
    }
}
