import styled from 'styled-components';
import '../../../styles/constants.css';

export const BiomeTypeSelectorStyled = styled.div`
  margin: 5px;
  position: relative;
  display: inline-block;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  .visual {
    transition: 0.5s ease;
  }

  .selected {
    transform: scale(1.01);
    webkit-transform: scale(1.01);

    .tile-hexagon {
      stroke: var(--border-color-bright);
    }
  }

  .visual:hover {
    transform: scale(1.01);
    webkit-transform: scale(1.01);
  }

  .tile-hexagon {
    stroke: var(--border-color-dim);
    stroke-width: 2;
  }

  .tile-hexagon:hover {
    stroke: var(--border-color-bright);
  }
`;
