import { TaxType } from '../../Enums/Tax.js';
import ConsumptionSubsidy from './ConsumptionSubsidy.js';
import ConsumptionTax from './ConsumptionTax.js';
import ExportDuty from './ExportDuty.js';
import ExportTariff from './ExportTariff.js';
import ImportDuty from './ImportDuty.js';
import ImportTariff from './ImportTariff.js';
import ImprovementSubsidy from './ImprovementSubsidy.js';
import ProductionSubsidy from './ProductionSubsidy.js';
import ProductionTax from './ProductionTax.js';
import TransitDuty from './TransitDuty.js';
export class TaxFactory {
    static fromJSON(json) {
        if (json.taxType === TaxType.ConsumptionSubsidy) {
            return ConsumptionSubsidy.fromJSON(json);
        }
        else if (json.taxType === TaxType.ConsumptionTax) {
            return ConsumptionTax.fromJSON(json);
        }
        else if (json.taxType === TaxType.ExportDuty) {
            return ExportDuty.fromJSON(json);
        }
        else if (json.taxType === TaxType.ExportTariff) {
            return ExportTariff.fromJSON(json);
        }
        else if (json.taxType === TaxType.ImportDuty) {
            return ImportDuty.fromJSON(json);
        }
        else if (json.taxType === TaxType.ImportTariff) {
            return ImportTariff.fromJSON(json);
        }
        else if (json.taxType === TaxType.ImprovementSubsidy) {
            return ImprovementSubsidy.fromJSON(json);
        }
        else if (json.taxType === TaxType.ProductionSubsidy) {
            return ProductionSubsidy.fromJSON(json);
        }
        else if (json.taxType === TaxType.ProductionTax) {
            return ProductionTax.fromJSON(json);
        }
        else if (json.taxType === TaxType.TransitDuty) {
            return TransitDuty.fromJSON(json);
        }
    }
}
