// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import TopControls from './TopControls.tsx';
import NotificationFlags from './NotificationFlags/NotificationFlags.tsx';
import BottomInfoUnit from './BottomInfoUnit.tsx';
import DateBox from './DateBox.tsx';
import InfoSidebar from './InfoSidebar.tsx';
import UnitInfo from './UnitInfo/UnitInfo.tsx';

// internal context
import { PopupDataContext } from '../../../../contexts/PopupsAndNotifications/PopupDataContextProvider.tsx';
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';

// css
import { MapOverlayStyled } from './styles/MapOverlay.styled.js';

// gl

const MapOverlay = () => {
  const { bottomInfo, setBottomInfo } = useContext(PopupDataContext);
  const { selectedUnit } = useContext(SelectedEntityContext);

  return (
    <MapOverlayStyled>
      {!bottomInfo && (
        <div>
          <DateBox />
          <TopControls />
          <NotificationFlags />
          <InfoSidebar />
          <UnitInfo />
        </div>
      )}
      <BottomInfoUnit />
    </MapOverlayStyled>
  );
};

export default MapOverlay;
