import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import HexMap from './HexMap';
import { getTilePosition } from './Utils/GetTilePosition';
import { GameStateDataContext } from '../../../contexts/GameData/GameStateDataContextProvider.tsx';
import RuntimeLandTile from '../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import { MapViewContext } from '../../../contexts/ScreenAndMap/MapViewContextProvider.tsx';
interface HexMapContainerProps {
  tiles: RuntimeLandTile[];
  mapTileSize: number;
  onMouseEnterTile: (id: number) => void;
  onMouseLeaveTile: (id: number) => void;
  onRightClickTile: (id: number) => void;
  onMouseUpOnTile: (id: number) => void;
  width: number; // width of the viewport
  height: number; // height of the viewport
}

/**
 * This component:
 * - Provides a scrollable (zoomable) and pannable container for HexMap.
 * - Implements zoom with mouse wheel.
 * - Restricts panning so the map can't move beyond edges.
 * - Only renders tiles visible in the viewport.
 * - Centers on a given tile ID when requested.
 */
const HexMapContainer: React.FC<HexMapContainerProps> = ({
  tiles,
  mapTileSize,
  onMouseEnterTile,
  onMouseLeaveTile,
  onRightClickTile,
  onMouseUpOnTile,
  width,
  height,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { setCenterOnTileId, centerOnTileId } = useContext(MapViewContext);

  // States for transform
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [scale, setScale] = useState(1);

  // For dragging
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [initialTranslateX, setInitialTranslateX] = useState(0);
  const [initialTranslateY, setInitialTranslateY] = useState(0);

  // Drag detection
  const DRAG_THRESHOLD = 5;
  const isDraggingRef = useRef(false);

  // game state
  const { gameState } = useContext(GameStateDataContext)!;

  // determine map boundaries:
  // assuming the map extends in tile-space, find the min/max of tile positions.
  const [minX, maxX, minY, maxY] = useMemo(() => {
    if (tiles.length === 0) return [0, 0, 0, 0];
    let minX = Infinity,
      maxX = -Infinity,
      minY = Infinity,
      maxY = -Infinity;
    for (const tile of tiles) {
      const pos = getTilePosition(tile, mapTileSize);
      if (pos.x < minX) minX = pos.x;
      if (pos.x > maxX) maxX = pos.x;
      if (pos.y < minY) minY = pos.y;
      if (pos.y > maxY) maxY = pos.y;
    }
    // Add half a tile size margin so center of edge tiles is visible
    return [
      minX - mapTileSize / 2,
      maxX + mapTileSize / 2,
      minY - mapTileSize / 2,
      maxY + mapTileSize / 2,
    ];
  }, [tiles, mapTileSize]);

  // Clamp translation so the map doesn't go out of bounds.
  const clampTranslation = useCallback(
    (x: number, y: number) => {
      // after scaling, the map "virtual size" = scale * (maxX-minX), scale * (maxY-minY).
      // ensure that the viewport (width, height) never shows beyond map edges.

      // the map width/height in pixels after scale
      const mapWidth = (maxX - minX) * scale;
      const mapHeight = (maxY - minY) * scale;

      // if map is smaller than viewport in any dimension, center it.
      let clampedX = x;
      let clampedY = y;

      if (mapWidth < width) {
        // Center horizontally
        clampedX = (width - mapWidth) / 2 - minX * scale;
      } else {
        // clamp so map not moved out of viewport bounds
        const maxTranslateX = -minX * scale;
        const minTranslateX = width - (maxX - minX) * scale;
        if (clampedX > maxTranslateX) clampedX = maxTranslateX;
        if (clampedX < minTranslateX) clampedX = minTranslateX;
      }

      if (mapHeight < height) {
        // Center vertically
        clampedY = (height - mapHeight) / 2 - minY * scale;
      } else {
        // clamp vertically
        const maxTranslateY = -minY * scale;
        const minTranslateY = height - (maxY - minY) * scale;
        if (clampedY > maxTranslateY) clampedY = maxTranslateY;
        if (clampedY < minTranslateY) clampedY = minTranslateY;
      }

      return { x: clampedX, y: clampedY };
    },
    [scale, width, height, minX, maxX, minY, maxY]
  );

  // Handle mouse down to start dragging
  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true);
      // shouldn't need this here, but just in case
      isDraggingRef.current = false;

      setDragStartX(e.clientX);
      setDragStartY(e.clientY);
      setInitialTranslateX(translateX);
      setInitialTranslateY(translateY);
    },
    [translateX, translateY]
  );

  const handleMouseUpOnContainer = useCallback(() => {
    setIsDragging(false);
    isDraggingRef.current = false;
  }, []);

  const handleMouseUpOnTile = useCallback(
    (tileId: number) => {
      if (isDraggingRef.current) return;
      onMouseUpOnTile(tileId);
    },
    [isDraggingRef, onMouseUpOnTile]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;

      const dx = e.clientX - dragStartX;
      const dy = e.clientY - dragStartY;
      const newX = initialTranslateX + dx;
      const newY = initialTranslateY + dy;

      const clamped = clampTranslation(newX, newY);
      setTranslateX(clamped.x);
      setTranslateY(clamped.y);

      // for mouse up detection
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance > DRAG_THRESHOLD && !isDraggingRef.current) {
        isDraggingRef.current = true;
      }
    },
    [
      isDragging,
      isDraggingRef,
      dragStartX,
      dragStartY,
      initialTranslateX,
      initialTranslateY,
      clampTranslation,
    ]
  );

  // Zoom on wheel
  const handleWheel = useCallback(
    (e: React.WheelEvent) => {
      // e.preventDefault();

      const zoomFactor = 1.05;
      const direction = e.deltaY < 0 ? 1 : -1;
      const newScale = direction > 0 ? scale * zoomFactor : scale / zoomFactor;

      // Zoom centered on mouse
      const rect = containerRef.current?.getBoundingClientRect();
      if (!rect) return;

      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      // Convert mouse position to map coordinates
      const worldX = (mouseX - translateX) / scale;
      const worldY = (mouseY - translateY) / scale;

      // After scale
      const newTranslateX = mouseX - worldX * newScale;
      const newTranslateY = mouseY - worldY * newScale;

      const clamped = clampTranslation(newTranslateX, newTranslateY);
      setTranslateX(clamped.x);
      setTranslateY(clamped.y);
      setScale(newScale);
    },
    [scale, translateX, translateY, clampTranslation]
  );

  // Center on a given tile if requested
  useEffect(() => {
    if (centerOnTileId != null) {
      const tile = gameState.getLandTileByTileId(centerOnTileId);
      if (tile) {
        const pos = getTilePosition(tile, mapTileSize);

        // We want pos to be at the center of the viewport.
        const targetX = width / 2 - pos.x * scale;
        const targetY = height / 2 - pos.y * scale;

        const clamped = clampTranslation(targetX, targetY);
        setTranslateX(clamped.x);
        setTranslateY(clamped.y);

        setCenterOnTileId(null);
      }
    }
  }, [
    centerOnTileId,
    gameState,
    mapTileSize,
    width,
    height,
    scale,
    clampTranslation,
  ]);

  // Determine visible tiles
  const visibleTiles = useMemo(() => {
    // tile is visible if after transformation:
    // tileX * scale + translateX is within [0 - tileSize, width + tileSize]
    // tileY * scale + translateY is within [0 - tileSize, height + tileSize]
    // add some margin so partially visible tiles still render.

    const margin = mapTileSize;
    return tiles.filter((tile) => {
      const pos = getTilePosition(tile, mapTileSize);
      const screenX = pos.x * scale + translateX;
      const screenY = pos.y * scale + translateY;
      return (
        screenX > -margin &&
        screenX < width + margin &&
        screenY > -margin &&
        screenY < height + margin
      );
    });
  }, [tiles, translateX, translateY, scale, width, height, mapTileSize]);

  return (
    <div
      className='hex-map-container'
      ref={containerRef}
      style={{
        width,
        height,
        overflow: 'hidden',
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: 'black',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUpOnContainer}
      onMouseLeave={handleMouseUpOnContainer}
      onWheel={handleWheel}
    >
      <div
        style={{
          transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
          transformOrigin: '0 0',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        {/* Pass only visible tiles to HexMap */}
        <HexMap
          tiles={visibleTiles}
          totalSize={{
            width: maxX - minX + mapTileSize,
            height: maxY - minY + mapTileSize,
          }}
          mapTileSize={mapTileSize}
          onMouseEnterTile={onMouseEnterTile}
          onMouseLeaveTile={onMouseLeaveTile}
          onRightClickTile={onRightClickTile}
          onMouseUpOnTile={handleMouseUpOnTile}
        />
      </div>
    </div>
  );
};

export default HexMapContainer;
