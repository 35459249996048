// external
import React, { useMemo, useContext, useState } from 'react';

// internal
// components
import CenterTitle from '../../../UI/GenericComponents/CenterTitle.tsx';

// mapping function
import getInfoSidebarData from '../../../UI/MappingToData/MapOverlay/InfoSidebar.tsx';

// internal context
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// import { MapMovementDataContext } from '../../../../contexts/ScreenAndMap/MapMovementDataContextProvider.tsx';
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
import { MapViewContext } from '../../../../contexts/ScreenAndMap/MapViewContextProvider.tsx';
// css
import { InfoSidebarStyled } from './styles/InfoSidebar.styled.js';

// gl
import Nation from '../../../../../../../GildedLands/lib/Classes/Nation/Nation';
import Background from '../../../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../../../UI/Enums/UIEnums.ts';

const InfoSidebar = () => {
  // context
  const { setSelectedUnit } = useContext(SelectedEntityContext);
  const { openCityScreen } = useContext(InteractionContext);
  const { gameState, userId, playerInfo } = useContext(GameStateDataContext);
  const { setCenterOnTileId } = useContext(MapViewContext);

  // state

  // #region Game Objects
  const nation = useMemo(
    () => gameState?.getNationByControllerUserId(userId),
    [userId, gameState]
  );
  const admin = useMemo(() => nation?.administration, [nation]);

  // #endregion

  // #region score, rank, cash
  const score = useMemo(() => playerInfo?.score, [playerInfo]);
  const formattedScore = useMemo(() => {
    if (!score) return '0';
    if (score >= 1e8) return `${(score / 1e6).toFixed(1)}M`;
    if (score >= 1e7) return `${(score / 1e3).toLocaleString()}k`;
    if (score >= 1e5) return `${(score / 1e3).toFixed(1)}k`;
    return score.toFixed(0);
  }, [score]);

  const rank = useMemo(() => {
    return (
      gameState?.playerInfos
        .sort((a, b) => b.score - a.score)
        .findIndex((playerInfo) => playerInfo.userId === userId) + 1
    );
  }, [userId, gameState]);

  const formattedRank = useMemo(() => {
    if (rank % 10 === 1 && rank !== 11) return `${rank}st`;
    if (rank % 10 === 2 && rank !== 12) return `${rank}nd`;
    if (rank % 10 === 3 && rank !== 13) return `${rank}rd`;
    return `${rank}th`;
  }, [rank]);

  // cash
  const cash = useMemo(() => admin?.balance, [admin]);
  const cashChange = useMemo(() => admin?.lastCycleRevenueAmount, [admin]);

  const formattedCashChange = useMemo(() => {
    return cashChange >= 0
      ? `+${cashChange?.toFixed(0)}`
      : cashChange?.toFixed(0);
  }, [cashChange]);

  const formattedCash = useMemo(() => {
    if (!cash) return '0';
    if (cash >= 1e8) return `${(cash / 1e6).toFixed(1)}M`;
    if (cash >= 1e7) return `${(cash / 1e3).toLocaleString()}k`;
    if (cash >= 1e5) return `${(cash / 1e3).toFixed(1)}k`;
    return cash.toFixed(2);
  }, [cash]);
  // #endregion

  // #region remaining content

  // center by tile
  const centerByTile = (tileId: number) => {
    setCenterOnTileId(tileId);
  };

  const sidebarContent = useMemo(() => {
    if (!gameState) return;
    return getInfoSidebarData(
      gameState,
      centerByTile,
      openCityScreen,
      setSelectedUnit
    );
  }, [gameState]);

  // #endregion

  return (
    <InfoSidebarStyled className='info-sidebar ui-gold'>
      <div className='info-box'>
        <Background color={UIColor.Blue6} opacity={UIOpacity.List} />
        <div className='info-title'>{'Score: '}</div>
        <div className='info-data'>{formattedScore}</div>
        <div className='info-data align-right'>{`(${formattedRank})`}</div>
      </div>
      {/* <p className='info-sidebar-title'>Palace</p> */}
      <div className='info-box'>
        <Background color={UIColor.Blue6} opacity={UIOpacity.List} />
        <div className='info-title'>{'Cash: '}</div>
        <div className='info-data'>{formattedCash}</div>
        <div className='info-data align-right'>{`(${formattedCashChange})`}</div>
      </div>
      <div className='info-sidebar-content'>{sidebarContent}</div>
    </InfoSidebarStyled>
  );
};

export default InfoSidebar;
