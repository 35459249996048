import React, { useContext, memo, useState, useEffect, useMemo } from 'react';

// Contexts
import { ImageContext } from '../../../../contexts/Media/ImageContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';

// Styles
import {
  MapUnitStyled,
  MapUnitImageStyled,
  MapUnitShapeStyled,
  MapUnitPulseCircleStyled,
} from './styles/MapUnit.styled.js';

// Types
import Unit from '../../../../../../../GildedLands/lib/Classes/Unit/Unit';

interface MapUnitProps {
  unitInstanceId: number;
  hexagonSize: number;
  position: { x: number; y: number };
  onMouseEnterCallback: () => void;
  onMouseLeaveCallback: () => void;
}

const MapUnit: React.FC<MapUnitProps> = memo(
  ({
    unitInstanceId,
    hexagonSize,
    position,
    onMouseEnterCallback,
    onMouseLeaveCallback,
  }) => {
    // Context
    const { defaultCityIcon } = useContext(ImageContext);
    const { gameState } = useContext(GameStateDataContext);
    const { selectedUnit, setSelectedUnit } = useContext(SelectedEntityContext);

    const [isHovered, setIsHovered] = useState(false);

    // #region Values for Visuals
    const radius = useMemo(() => hexagonSize * 0.4, [hexagonSize]);
    const hexagonCenter = useMemo(
      () => ({
        x: (hexagonSize * Math.sqrt(3)) / 2,
        y: hexagonSize,
      }),
      [hexagonSize]
    );
    const circlePath = useMemo(
      () =>
        `M ${hexagonCenter.x},${
          hexagonCenter.y
        } m -${radius},0 a ${radius},${radius} 0 1,0 ${
          radius * 2
        },0 a ${radius},${radius} 0 1,0 -${radius * 2},0`,
      [hexagonCenter, radius]
    );

    // #endregion

    // #region Values for Logic
    const unit = useMemo(() => {
      if (!gameState) return null;
      return gameState.getUnitByInstanceId(unitInstanceId);
    }, [unitInstanceId, gameState]);

    // #endregion

    // #region Event Handlers
    const handleClick = () => {
      setSelectedUnit(unit);
    };

    const handleRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      console.log('Right-clicked unit:', unit);
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
      onMouseEnterCallback();
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
      onMouseLeaveCallback();
    };

    // #endregion

    return (
      <MapUnitStyled hexagonSize={hexagonSize} position={position}>
        <MapUnitImageStyled
          src={
            'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/settler.png'
          }
          hexagonSize={hexagonSize}
          points={circlePath}
          onClick={handleClick}
          onContextMenu={handleRightClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        {(selectedUnit?.instanceId === unit?.instanceId || isHovered) && (
          <MapUnitPulseCircleStyled hexagonSize={hexagonSize}>
            <defs>
              <radialGradient id='fade-gradient' cx='50%' cy='50%' r='50%'>
                <stop
                  offset='50%'
                  style={{ stopColor: 'white', stopOpacity: 1 }}
                />
                <stop
                  offset='100%'
                  style={{ stopColor: 'white', stopOpacity: 0 }}
                />
              </radialGradient>
            </defs>
            <circle
              cx={hexagonCenter.x}
              cy={hexagonCenter.y}
              r={hexagonSize * 0.6}
            />
          </MapUnitPulseCircleStyled>
        )}

        <MapUnitShapeStyled hexagonSize={hexagonSize}>
          <circle cx={hexagonCenter.x} cy={hexagonCenter.y} r={radius} />
        </MapUnitShapeStyled>
      </MapUnitStyled>
    );
  }
);

export default MapUnit;
