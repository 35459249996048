class QualityOfLifeItem {
    constructor(qol, categoryNeed, subCategoryNeed, goodNeed, goodQualityOfLife, affinity, quality, lackOfFoodDetractor, lackOfOtherGoodsDetractor) {
        this.qol = qol;
        this.categoryNeed = categoryNeed;
        this.subCategoryNeed = subCategoryNeed;
        this.goodNeed = goodNeed;
        this.goodQualityOfLife = goodQualityOfLife;
        this.affinity = affinity;
        this.quality = quality;
        this.lackOfFoodDetractor = lackOfFoodDetractor;
        this.lackOfOtherGoodsDetractor = lackOfOtherGoodsDetractor;
    }
    clone() {
        return new QualityOfLifeItem(this.qol, this.categoryNeed, this.subCategoryNeed, this.goodNeed, this.goodQualityOfLife, this.affinity, this.quality, this.lackOfFoodDetractor, this.lackOfOtherGoodsDetractor);
    }
    toJSON() {
        return {
            qol: this.qol,
            categoryNeed: this.categoryNeed,
            subCategoryNeed: this.subCategoryNeed,
            goodNeed: this.goodNeed,
            goodQualityOfLife: this.goodQualityOfLife,
            affinity: this.affinity,
            quality: this.quality,
            lackOfFoodDetractor: this.lackOfFoodDetractor,
            lackOfOtherGoodsDetractor: this.lackOfOtherGoodsDetractor,
        };
    }
    static fromJSON(json) {
        return new QualityOfLifeItem(json.qol, json.categoryNeed, json.subCategoryNeed, json.goodNeed, json.goodQualityOfLife, json.affinity, json.quality, json.lackOfFoodDetractor, json.lackOfOtherGoodsDetractor);
    }
}
export default QualityOfLifeItem;
