// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import NotificationItem from './NotificationFlag.tsx';

// internal context
import { NotificationFlagsDataContext } from '../../../../../contexts/PopupsAndNotifications/NotificationFlagsDataContextProvider.tsx';

// css
import { NotificationFlagsStyled } from './styles/NotificationFlags.styled.js';

// gl

const NotificationFlags = () => {
  const { notifications, removeNotification } = useContext(
    NotificationFlagsDataContext
  );

  if (notifications.length === 0) {
    return null;
  }

  return (
    <NotificationFlagsStyled className='notification-flags'>
      <div className='notification-flags-list-holder'>
        <div className='border-area'></div>
        <div className='notification-flags-list'>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              removeNotification={removeNotification}
            />
          ))}
        </div>
      </div>
    </NotificationFlagsStyled>
  );
};

export default NotificationFlags;
