import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import CityAdministration from '../../Administration/CityAdministration.js';
export default class SaveCityAdministrationMessage extends ClientMessage {
    constructor(messageId, senderUserId, admin, cityId) {
        super(NetworkProtocol.SaveCityAdministration, messageId, senderUserId);
        this.cityAdministration = admin;
        this.cityId = cityId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { cityAdministration: this.cityAdministration.toJSON(), cityId: this.cityId });
    }
    static fromJSON(json) {
        return new SaveCityAdministrationMessage(json.messageId, json.senderUserId, CityAdministration.fromJSON(json.cityAdministration), json.cityId);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.senderUserId !== undefined &&
            this.cityAdministration !== undefined &&
            this.cityId !== undefined &&
            super.validate());
    }
}
