import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AdminAndNetworking/AuthContextProvider.tsx';
import { RegisterComponentStyled } from './styles/RegisterComponent.styled.js'; // Reuse the styled component

function RegisterComponent() {
  const navigate = useNavigate();
  const { register } = useContext(AuthContext);

  const [name, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    register(name, email, password)
      .then(() => {
        navigate('/collections');
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <RegisterComponentStyled className='register-component'>
      <form onSubmit={handleSubmit}>
        <div className='register-wrapper'>
          <h1>Register</h1>
          <div className='register-form'>
            <input
              type='text'
              placeholder='username'
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type='text'
              placeholder='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type='password'
              placeholder='password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type='submit' value='Register' />
          </div>
          {error && <p className='input-error'>{error}</p>}
        </div>
      </form>
    </RegisterComponentStyled>
  );
}

export default RegisterComponent;
