// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// internal context
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import { TaxReportsStyled } from './styles/TaxReports.styled.js';

// lol

const TaxReports = () => {
  // #region context
  const { gameState } = useContext(GameStateDataContext);
  // #endregion

  // set goods data to the data on load - don't update it
  // const [goodsData, setGoodsData] = useState<GoodsData>();
  // useEffect(() => {
  //   if (masterPlayer && gameState)
  //     setGoodsData(gameState.goodsData);
  // }, []);

  return <TaxReportsStyled className='tax-reports'></TaxReportsStyled>;
};

export default TaxReports;
