import React, { useState, useContext } from 'react';
import { TaxItemStyled } from './styles/TaxItem.styled.js';
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import TransitDuty from '../../../../../../../../../GildedLands/lib/Classes/Tax/TransitDuty.js';
import NationList from '../Utilities/NationList.tsx';

interface TransitDutyItemProps {
  duty: TransitDuty;
}

const TransitDutyItem = ({ duty }: TransitDutyItemProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { editTax, deleteTax } = useContext(GameStateLogicContext);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const saveNewDuty = (newDuty: TransitDuty) => {
    editTax(newDuty);
  };

  return (
    <TaxItemStyled>
      <div className='tax-main-info' onClick={toggleDetails}>
        <span className='tax-title'>
          {showDetails ? '^ ' : '> '}
          Transit Duty
        </span>
        <span className='tax-info'>Duty Amount: {duty.dutyAmountPerGood}</span>
      </div>
      {showDetails && (
        <div className='tax-item-details'>
          <div className='tax-details-category-content'>
            <label>
              Duty Amount: $
              <input
                type='number'
                name='dutyAmountPerGood'
                defaultValue={duty.dutyAmountPerGood}
                step='0.1'
                onBlur={(e) => {
                  const newDuty = duty.clone();
                  newDuty.dutyAmountPerGood = parseFloat(e.target.value);
                  saveNewDuty(newDuty);
                }}
              />
            </label>
            <div className='tax-nations'>
              <label>
                Is On All Origin Nations:
                <input
                  type='checkbox'
                  name='isOnAllOriginNations'
                  defaultChecked={duty.isOnAllOriginNations}
                  onChange={(e) => {
                    const newDuty = duty.clone();
                    newDuty.isOnAllOriginNations = e.target.checked;
                    saveNewDuty(newDuty);
                  }}
                />
              </label>
              <NationList
                nationData={{
                  isOnAllNations: duty.isOnAllOriginNations,
                  excludedNationIds: duty.excludedOriginNationIds,
                  includedNationIds: duty.includedOriginNationIds,
                }}
                save={(nationData: {
                  isOnAllNations: boolean;
                  excludedNationIds: number[];
                  includedNationIds: number[];
                }) => {
                  const newDuty = duty.clone();
                  newDuty.isOnAllOriginNations = nationData.isOnAllNations;
                  newDuty.excludedOriginNationIds =
                    nationData.excludedNationIds;
                  newDuty.includedOriginNationIds =
                    nationData.includedNationIds;
                  saveNewDuty(newDuty);
                }}
              />
              <label>
                Is On All Destination Nations:
                <input
                  type='checkbox'
                  name='isOnAllDestinationNations'
                  defaultChecked={duty.isOnAllDestinationNations}
                  onChange={(e) => {
                    const newDuty = duty.clone();
                    newDuty.isOnAllDestinationNations = e.target.checked;
                    saveNewDuty(newDuty);
                  }}
                />
              </label>
              <NationList
                nationData={{
                  isOnAllNations: duty.isOnAllDestinationNations,
                  excludedNationIds: duty.excludedDestinationNationIds,
                  includedNationIds: duty.includedDestinationNationIds,
                }}
                save={(nationData: {
                  isOnAllNations: boolean;
                  excludedNationIds: number[];
                  includedNationIds: number[];
                }) => {
                  const newDuty = duty.clone();
                  newDuty.isOnAllDestinationNations = nationData.isOnAllNations;
                  newDuty.excludedDestinationNationIds =
                    nationData.excludedNationIds;
                  newDuty.includedDestinationNationIds =
                    nationData.includedNationIds;
                  saveNewDuty(newDuty);
                }}
              />
            </div>
          </div>
          <button
            className='delete-tax-button'
            onClick={() => deleteTax(duty.instanceId)}
          >
            Delete
          </button>
        </div>
      )}
    </TaxItemStyled>
  );
};

export default TransitDutyItem;
