// external
import React, { useState, useEffect, useContext } from 'react';
import GenericBackground, { IGenericBackground } from './Background.tsx';

// css
import { VerticalListStyled } from './styles/VerticalList.styled.js';

interface IVerticalList {
  data: React.ReactNode[] | React.ReactNode;
  backgroundData?: IGenericBackground;
}

const VerticalList: React.FC<IVerticalList> = ({ data, backgroundData }) => {
  return (
    <VerticalListStyled className='vertical-list'>
      {backgroundData && (
        <GenericBackground
          color={backgroundData.color}
          opacity={backgroundData.opacity}
        />
      )}
      {Array.isArray(data) ? (
        data.map((item, index) => (
          <div className='vertical-list-item' key={index}>
            {item}
          </div>
        ))
      ) : (
        <div className='vertical-list-item'>{data}</div>
      )}
    </VerticalListStyled>
  );
};

export default VerticalList;
export { IVerticalList };
