// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import CityAdministration from './CityAdministrationReport.tsx';
import NationalAdministration from './NationalAdministrationReport.tsx';

// context
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { AdministrationScreenStyled } from './styles/AdministrationScreen.styled.js';

// gl
import Department from '../../../../../../../GildedLands/lib/Classes/Administration/Department/Department/Department';
import TabSideContainer from '../../../UI/GenericComponents/TabSideContainer.tsx';

const AdministrationScreen = ({}) => {
  // contexts
  const { gameState, userId } = useContext(GameStateDataContext);

  // #region city

  const [cities, setCities] = useState<
    {
      instanceId: number;
      name: string;
    }[]
  >([]);

  useEffect(() => {
    if (!gameState) return;
    const playerNation = gameState.getNationByControllerUserId(userId);
    if (!playerNation) console.error('Player nation not found in city setter');

    // set cities
    setCities(
      gameState.cities
        .filter((city) => city.administeringNationId === playerNation.id)
        .map((city) => ({
          instanceId: city.instanceId,
          name: city.name,
        }))
    );
  }, [gameState, userId]);

  // #endregion city

  return (
    <AdministrationScreenStyled>
      <TabSideContainer
        data={[
          { title: 'National', content: <NationalAdministration /> },
          ...cities.map((city) => ({
            title: city.name,
            onTitleClick: () => {},
            content: (
              <CityAdministration
                cityInfo={{
                  instanceId: city.instanceId,
                  name: city.name,
                }}
              />
            ),
          })),
        ]}
      />
    </AdministrationScreenStyled>
  );
};

export default AdministrationScreen;
