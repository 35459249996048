// external
import React, { useState, useRef } from 'react';

// components
import InlineDataField from './InlineDataField.tsx';
import HoverBox, { IHoverBox } from './HoverBox.tsx';
import { IInlineDataField } from './InlineDataField.tsx';
import { UIColor } from '../Enums/UIEnums.ts';

// css
import { SelectionButtonStyled } from './styles/SelectionButton.styled.js';
import GenericBackground from './Background.tsx';

interface ISelectionButton {
  title?: string;
  titleIconUrl?: string;
  titleColor?: UIColor;
  otherContent?: React.ReactNode;
  onClick?: () => void;
  hoverData?: IHoverBox;
  alignment?: 'left' | 'center';
  amSelected: boolean;
  selectable: boolean;
}

const SelectionButton: React.FC<ISelectionButton> = ({
  title = null,
  titleIconUrl = null,
  titleColor = UIColor.White,
  otherContent = null,
  onClick = null,
  hoverData = null,
  alignment = 'center',
  amSelected = false,
  selectable = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const lineItemRefForFields = useRef<HTMLDivElement>(null);

  const handleMainClick = () => {
    if (!!onClick) onClick();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <SelectionButtonStyled
      className='selection-button'
      onClick={handleMainClick}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      titleColor={titleColor}
      selectable={selectable}
      inlineFieldsAlignment={alignment}
      ref={lineItemRefForFields}
    >
      {/* <div className='border'></div> */}
      {/* main */}
      <div className='selection-button-main-content'>
        {!!title && (
          <div className='selection-button-title-area'>
            {!!titleIconUrl && (
              <img
                className='selection-button-title-icon'
                src={titleIconUrl}
                alt='title icon'
              />
            )}
            <div className='selection-button-title-text'>{title}</div>
          </div>
        )}
      </div>

      {(isHovered || amSelected) && (
        <GenericBackground color={UIColor.White} opacity={0.2} />
      )}

      {/* hover */}
      {isHovered && hoverData && (
        <HoverBox
          content={hoverData.content}
          parentRef={lineItemRefForFields}
          width={hoverData.width}
        />
      )}
    </SelectionButtonStyled>
  );
};

export default SelectionButton;
export { ISelectionButton };
