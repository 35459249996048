// external
import React, { useEffect, useState, useContext } from 'react';

// internal
// context
import { AuthContext } from '../../contexts/AdminAndNetworking/AuthContextProvider.tsx';
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { StartingStatesContext } from '../../contexts/GameData/StartingStatesContextProvider.tsx';
import { GameStateDataContext } from '../../contexts/GameData/GameStateDataContextProvider.tsx';
import { ScreenSizeContext } from '../../contexts/ScreenAndMap/ScreenSizeContextProvider.tsx';

// hooks
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import useMouseDown from '../../hooks/useMouseDown.js';

// components
import Navbar from '../Navbar/Navbar.jsx';
import RealmList from './StartingStateList.tsx';
import BiomeList from './BiomeList.tsx';
import TitleComponent from './TitleComponent.tsx';
import BiomeTypeSelector from './BiomeTypeSelector.tsx';

//css
import { StartingStatePageStyled } from './styles/StartingStatePage.styled.js';
import { BiomeType } from '../../../../../GildedLands/lib/Enums/Biome';
import GenericBackground from '../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../UI/Enums/UIEnums.ts';
import HexMapContainer from '../Game/Map/HexMapContainer.tsx';
import StartingStateList from './StartingStateList.tsx';
import RiverTypeSelector from './RiverSelector.tsx';
import RoadTypeSelector from './RoadSelector.tsx';

function StartingStatePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context
  const { userId } = useContext(AuthContext);
  const {
    startingStates,
    selectedStartingState,
    setSelectedStartingState,
    createNewStartingState,
    saveStartingState,
    deleteStartingState,
  } = useContext(StartingStatesContext);

  const { displayWidth, displayHeight } = useContext(ScreenSizeContext);

  const { setGameState, gameState } = useContext(GameStateDataContext);

  useEffect(() => {
    if (selectedStartingState) {
      setGameState(selectedStartingState.gameState);
    }
  }, [selectedStartingState]);

  //#region Display State
  // changing 'select-realm' to 'select-starting-state'
  // changing 'edit-realm' to 'edit-starting-state'
  const [displayState, setDisplayState] = useState('select-starting-state');

  const handleBack = () => {
    if (displayState === 'edit-starting-state') {
      setDisplayState('select-starting-state');
    } else if (displayState === 'edit-biome') {
      setDisplayState('edit-starting-state');
      setSelectedBiome(null);
    }
  };
  //#endregion

  //#region Biomes
  const [selectedBiome, setSelectedBiome] = useState(null);

  useEffect(() => {
    if (!selectedStartingState) return;
    if (!selectedBiome) return;

    if (displayState === 'edit-starting-state') {
      setDisplayState('edit-biome');
    } else if (displayState === 'edit-biome') {
      // handle biome edit state if needed
    }
  }, [selectedBiome]);

  const [outlinedTileIndices, setOutlinedTileIndices] = useState([]);
  useEffect(() => {
    // tiles
    if (displayState === 'edit-biome') {
      const tempIndices = [];
      // Assuming you'd determine these indices from selectedBiome somehow
      // tempIndices.sort((a, b) => a.id - b.id);
      setOutlinedTileIndices(tempIndices);
    } else {
      setOutlinedTileIndices([]);
    }
  }, [displayState]);
  //#endregion

  //#region Hovering Biomes Functions
  const mouseOverBiome = (biome) => {
    if (!biome) {
      setOutlinedTileIndices([]);
      return;
    }
    if (displayState === 'select-starting-state') return;
    setOutlinedTileIndices(biome.landTiles.map((tile) => tile.id));
  };
  //#endregion

  //#region Interaction with Starting States
  const onStartingStateTileClick = (startingState) => {
    if (startingState !== selectedStartingState) {
      setSelectedStartingState(startingState);
    } else {
      setDisplayState('edit-starting-state');
    }
  };
  //#endregion

  //#region Land Type Editing
  const [editType, setEditType] = useState<
    'biome' | 'river' | 'road' | 'city' | 'starting-position'
  >('biome');
  const [biomeTypeSelected, setBiomeTypeSelected] = useState(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const landTileClicked = (tileId: number) => {
    if (displayState !== 'edit-starting-state') return;

    if (editType === 'biome') {
      if (biomeTypeSelected === null) return;
      changeMainTileType(tileId, biomeTypeSelected);
    } else if (editType === 'river') {
      toggleRiver(tileId);
    } else if (editType === 'road') {
      toggleRoad(tileId);
    }
  };

  const landTileEntered = (tileId: number) => {
    if (displayState !== 'edit-starting-state') return;
    if (biomeTypeSelected === null) return;

    if (isShiftPressed) {
      changeMainTileType(tileId, biomeTypeSelected);
    }
  };

  const toggleRiver = (tileId: number) => {
    console.log('toggleRiver: ', tileId);
    if (!selectedStartingState) return;
    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.toggleRiver(tileId);

    setSelectedStartingState(updatedStartingState);
  };

  const toggleRoad = (tileId: number) => {
    console.log('toggleRoad: ', tileId);
    if (!selectedStartingState) return;
    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.toggleRoad(tileId);

    const roadTile = updatedStartingState.gameState.getLandTileByTileId(tileId);
    console.log('roadTile: ', roadTile);
    setSelectedStartingState(updatedStartingState);
  };

  const changeMainTileType = (tileId: number, biomeType: BiomeType) => {
    if (!selectedStartingState) return;

    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.changeMainLandTileType(
      tileId,
      biomeType
    );

    setSelectedStartingState(updatedStartingState);
  };

  const addTileType = (tileId: number, biomeType: BiomeType) => {
    if (!selectedStartingState) return;

    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.addLandTileType(tileId, biomeType);

    setSelectedStartingState(updatedStartingState);
  };

  const removeTileType = (tileId: number, biomeType: BiomeType) => {
    if (!selectedStartingState) return;

    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.removeLandTileType(tileId, biomeType);

    setSelectedStartingState(updatedStartingState);
  };

  const toggleStartingPosition = (tileId: number) => {
    if (!selectedStartingState) return;

    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();

    const startingPositions =
      updatedStartingState.gameState.realm.getStartingLandTileIds() || [];
    if (startingPositions.includes(tileId)) {
      updatedStartingState.gameState.realm.removeStartingLandTile(tileId);
    } else {
      updatedStartingState.gameState.realm.addStartingLandTile(tileId);
    }

    setSelectedStartingState(updatedStartingState);
  };
  //#endregion

  //#region Name Editing
  const [editNameState, setEditNameState] = useState('display');
  const editName = (newName) => {
    if (!selectedStartingState) return;
    const trimmedName = newName.trim();
    const truncatedName = trimmedName.substring(0, 20);
    if (
      truncatedName &&
      truncatedName !== selectedStartingState.gameState.realm.name
    ) {
      const updatedStartingState = { ...selectedStartingState };
      updatedStartingState.gameState = selectedStartingState.gameState.clone();
      updatedStartingState.gameState.realm.name = truncatedName;
      setSelectedStartingState(updatedStartingState);
    }
  };
  //#endregion

  //#region Realm Layout
  const [mapWidth, setMapWidth] = useState(
    selectedStartingState
      ? selectedStartingState.gameState.realm.realmLayout.width
      : 0
  );
  const [mapHeight, setMapHeight] = useState(
    selectedStartingState
      ? selectedStartingState.gameState.realm.realmLayout.height
      : 0
  );

  useEffect(() => {
    if (!selectedStartingState) return;
    setMapWidth(selectedStartingState.gameState.realm.realmLayout.width);
    setMapHeight(selectedStartingState.gameState.realm.realmLayout.height);
  }, [selectedStartingState]);

  const handleLayoutChange = () => {
    if (!selectedStartingState) return;
    const updatedStartingState = { ...selectedStartingState };
    updatedStartingState.gameState = selectedStartingState.gameState.clone();
    updatedStartingState.gameState.realm.editLayout({
      width: mapWidth,
      height: mapHeight,
    });
    setSelectedStartingState(updatedStartingState);
  };
  //#endregion

  useEffect(() => {
    console.log('selectedStartingState: ', selectedStartingState);
  }, [selectedStartingState]);

  return (
    <StartingStatePageStyled className='starting-state-page'>
      <div className='main-content'>
        <div className='left-column ui-gold'>
          <GenericBackground color={UIColor.Blue4} opacity={UIOpacity.List} />
          {displayState === 'select-starting-state' && (
            <StartingStateList
              startingStates={startingStates}
              selectedStartingState={selectedStartingState}
              onStartingStateClicked={onStartingStateTileClick}
              deleteStartingState={deleteStartingState}
              createNewStartingState={createNewStartingState}
            />
          )}
          {displayState !== 'select-starting-state' && (
            <div className='back-button edit-button' onClick={handleBack}>
              {'<  Back'}
            </div>
          )}
        </div>
        <div className='map-container'>
          {!!gameState && (
            <HexMapContainer
              tiles={gameState.realm.getLandTiles()}
              mapTileSize={100}
              onMouseEnterTile={(tileId) => landTileEntered(tileId)}
              onRightClickTile={(tileId) => toggleStartingPosition(tileId)}
              onMouseUpOnTile={(tileId) => landTileClicked(tileId)}
              width={displayWidth}
              height={displayHeight}
            />
          )}
        </div>
        <div className='right-column ui-gold'>
          <GenericBackground color={UIColor.Blue4} opacity={0.9} />
          {displayState === 'edit-starting-state' && (
            <div className='edit-controls'>
              <div className='edit-button' onClick={saveStartingState}>
                Save Starting State
              </div>
              <div className='layout-widget'>
                <input
                  className='edit-input'
                  type='number'
                  value={mapWidth}
                  min={1}
                  max={300}
                  onChange={(e) => setMapWidth(Number(e.target.value))}
                  placeholder='Width'
                />
                <span>{'x'}</span>
                <input
                  className='edit-input'
                  type='number'
                  value={mapHeight}
                  min={1}
                  max={200}
                  onChange={(e) => setMapHeight(Number(e.target.value))}
                  placeholder='Height'
                />
                <div className='edit-button' onClick={handleLayoutChange}>
                  Apply
                </div>
              </div>
              <div className='biome-type-selectors'>
                <div className='biome-group'>
                  <h3>Desert</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertShallow]}
                    biomeType={BiomeType.DesertShallow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertShallow);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertMid]}
                    biomeType={BiomeType.DesertMid}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertMid);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertDeep]}
                    biomeType={BiomeType.DesertDeep}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertDeep);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertHills]}
                    biomeType={BiomeType.DesertHills}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertHills);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertCacti]}
                    biomeType={BiomeType.DesertCacti}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertCacti);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.DesertPillars]}
                    biomeType={BiomeType.DesertPillars}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.DesertPillars);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Farm</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Farm]}
                    biomeType={BiomeType.Farm}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Farm);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Forest</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.ForestBroadleaf]}
                    biomeType={BiomeType.ForestBroadleaf}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.ForestBroadleaf);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.ForestBroadleafThin]}
                    biomeType={BiomeType.ForestBroadleafThin}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.ForestBroadleafThin);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.ForestPine]}
                    biomeType={BiomeType.ForestPine}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.ForestPine);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.ForestPineFullSnow]}
                    biomeType={BiomeType.ForestPineFullSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.ForestPineFullSnow);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.ForestPinePartSnow]}
                    biomeType={BiomeType.ForestPinePartSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.ForestPinePartSnow);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Hills and Highlands</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Highlands]}
                    biomeType={BiomeType.Highlands}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Highlands);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Hills]}
                    biomeType={BiomeType.Hills}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Hills);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.HillsFullSnow]}
                    biomeType={BiomeType.HillsFullSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.HillsFullSnow);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.HillsPartSnow]}
                    biomeType={BiomeType.HillsPartSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.HillsPartSnow);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Marsh</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Marsh]}
                    biomeType={BiomeType.Marsh}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Marsh);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Mountains</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.MountainsFullSnow]}
                    biomeType={BiomeType.MountainsFullSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.MountainsFullSnow);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.MountainsPartSnow]}
                    biomeType={BiomeType.MountainsPartSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.MountainsPartSnow);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Ocean</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.OceanCalm]}
                    biomeType={BiomeType.OceanCalm}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.OceanCalm);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.OceanRough]}
                    biomeType={BiomeType.OceanRough}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.OceanRough);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.OceanIcebergs]}
                    biomeType={BiomeType.OceanIcebergs}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.OceanIcebergs);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Prairie</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Prairie]}
                    biomeType={BiomeType.Prairie}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Prairie);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.PrairieFullSnow]}
                    biomeType={BiomeType.PrairieFullSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.PrairieFullSnow);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.PrairiePartSnow]}
                    biomeType={BiomeType.PrairiePartSnow}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.PrairiePartSnow);
                    }}
                  />
                </div>
                <div className='biome-group'>
                  <h3>Tundra</h3>
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.Tundra]}
                    biomeType={BiomeType.Tundra}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.Tundra);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.TundraLargeDrifts]}
                    biomeType={BiomeType.TundraLargeDrifts}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.TundraLargeDrifts);
                    }}
                  />
                  <BiomeTypeSelector
                    key={BiomeType[BiomeType.TundraSmallDrifts]}
                    biomeType={BiomeType.TundraSmallDrifts}
                    biomeTypeSelected={biomeTypeSelected}
                    onClick={() => {
                      setEditType('biome');
                      setBiomeTypeSelected(BiomeType.TundraSmallDrifts);
                    }}
                  />
                </div>
                <div className='other-selectors'>
                  <h3>Other</h3>
                  <RiverTypeSelector
                    riverSelected={editType === 'river'}
                    onClick={() => {
                      setEditType('river');
                    }}
                  />
                  <RoadTypeSelector
                    roadSelected={editType === 'road'}
                    onClick={() => {
                      setEditType('road');
                    }}
                  />
                </div>
                {/* <div className='road-type-selectors'>
                  <h3>Roads</h3>
                  <RoadTypeSelector
                    roadSelected={roadSelected}
                    setRoadSelected={setRoadSelected}
                  />
                </div>
                <div className='city-type-selectors'>
                  <h3>City</h3>
                  <CityPlacementSelector
                    citySelected={citySelected}
                    setCitySelected={setCitySelected}
                  />
                </div>{' '} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </StartingStatePageStyled>
  );
}

export default StartingStatePage;
