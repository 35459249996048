// react
import React from 'react';

// enums
import { UIColor } from '../Enums/UIEnums.ts';

// css
import { BackgroundStyled } from './styles/Background.styled.js';

interface IGenericBackground {
  color: UIColor;
  opacity: number;
}

const GenericBackground: React.FC<IGenericBackground> = ({
  color,
  opacity,
}) => {
  return (
    <BackgroundStyled
      className='generic-background'
      color={color}
      opacity={opacity}
    >
      <div className='background-color'></div>
    </BackgroundStyled>
  );
};

export default GenericBackground;
export { IGenericBackground };
