class PlayerInfo {
    constructor(userId, id, name, score) {
        this.userId = userId;
        this.id = id;
        this.name = name;
        this.score = score;
    }
    clone() {
        const clone = new PlayerInfo(this.userId, this.id, this.name, this.score);
        return clone;
    }
    // #region JSON
    toJSON() {
        const json = {};
        json.name = this.name;
        json.userId = this.userId;
        json.id = this.id;
        json.score = this.score;
        return json;
    }
    static fromRuntimeJSON(json) {
        const playerInfo = new PlayerInfo(json.userId, json.id, json.name, json.score);
        return playerInfo;
    }
}
export default PlayerInfo;
