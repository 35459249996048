// external
import React, { useState, useEffect, useContext, useMemo } from 'react';

// internal
// components

// internal context
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { SocketContext } from '../../../../contexts/AdminAndNetworking/SocketContextProvider.tsx';

// css
import { DateBoxStyled } from './styles/DateBox.styled.js';

// gl
import { GamePlayState } from '../../../../../../../GildedLands/lib/Enums/GamePlayState';
import {
  TimeEnum,
  TimeMethods,
} from '../../../../../../../GildedLands/lib/Enums/Time';
import Background from '../../../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../../../UI/Enums/UIEnums.ts';

const TopControls = () => {
  const { gameState } = useContext(GameStateDataContext);
  const { socket } = useContext(SocketContext);

  const handleTogglePlayPause = () => {
    if (gameState?.gamePlayState === GamePlayState.Paused) {
      socket.emit('dev-player-start-game');
    } else {
      socket.emit('dev-player-pause-game');
    }
  };

  // #endregion

  return (
    <DateBoxStyled className='date-box'>
      <div className='date-box-content'>
        <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
        <div className='date-info'>
          {!!gameState?.days
            ? TimeMethods.getDateStringFromDays(gameState?.days)
            : '\u00A0'}
        </div>
        <div
          className='toggle-play-button align-right'
          onClick={handleTogglePlayPause}
        >
          {gameState?.gamePlayState === GamePlayState.Paused
            ? '\u25B6'
            : '\u23F8'}
        </div>
      </div>
    </DateBoxStyled>
  );
};

export default TopControls;
