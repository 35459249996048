// External dependencies
import React, { useContext, useMemo, useState } from 'react';

// Contexts
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { HighlightedTileContext } from '../../../../contexts/ScreenAndMap/HighlightedTileContextProvider.tsx';
// Components
import CenterTitle from '../../../UI/GenericComponents/CenterTitle';

// Styles
import { ExistingImprovementListStyled } from './styles/ExistingImprovementList.styled.js';

// Enums
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';

// Utilities
import getExistingImprovementInfo from '../../../UI/MappingToData/Improvement/ExistingImprovementInfo.tsx';
import getExistingBuildingsData from '../../../UI/MappingToData/CityScreen/ExistingBuildings.tsx';

// Types
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement';

const ExistingImprovementList = () => {
  // #region context
  const { gameState } = useContext(GameStateDataContext);
  const {
    setSelectedRuntimeImprovement,
    selectedCity,
    setSelectedImprovementTypeInfo,
  } = useContext(SelectedEntityContext);
  const { setHighlightedTiles } = useContext(HighlightedTileContext);
  // #endregion

  // #region  state
  const [filter, setFilter] = useState<string>('all');
  // #endregion

  // #region handlers

  const handleSelectExistingImprovement = (improvement: RuntimeImprovement) => {
    setSelectedRuntimeImprovement((currentSelectedRuntimeImprovement) => {
      if (
        improvement.instanceId === currentSelectedRuntimeImprovement?.instanceId
      ) {
        return null;
      } else {
        return improvement;
      }
    });

    setSelectedImprovementTypeInfo((currentSelectedImprovementTypeInfo) => {
      if (!!currentSelectedImprovementTypeInfo) {
        return null;
      } else {
        return currentSelectedImprovementTypeInfo;
      }
    });
  };

  const existingBuildingsData = useMemo(() => {
    if (!gameState) return;
    return getExistingBuildingsData(
      gameState,
      selectedCity,
      handleSelectExistingImprovement,
      setHighlightedTiles
    );
  }, [gameState, selectedCity]);
  // #endregion
  return (
    <ExistingImprovementListStyled className='existing-improvements-list'>
      <CenterTitle
        title='Buildings'
        padding={UIPadding.Tall}
        backgroundData={{
          color: UIColor.Gold,
          opacity: UIOpacity.Full,
        }}
      />
      <div className='buildings-list'>{existingBuildingsData}</div>
    </ExistingImprovementListStyled>
  );
};

export default ExistingImprovementList;
