import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const TaxesStyled = styled.div`
  position: absolute;
  top: calc(3% + 25px);
  left: 5%;
  width: 90%;
  height: calc(87% - 25px);
  border: 2px solid var(--lol-gold);

  //
  pointer-events: auto;

  .tax-buttons {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    transform: translate(0, -140%);
  }

  .tax-button {
    background-color: var(--lol-gold);
    border: 1px solid var(--lol-red);
    margin-right: 5px;
    padding: 2px 5px;
    cursor: pointer;
    color: var(--lol-red);
    font-size: 15px;
    font-family: var(--logo-font);

    &:hover {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
    &.active {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
  }
`;
