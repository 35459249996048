import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class ImprovementSubsidy extends Tax {
    constructor(instanceId, ownerNationId, subsidyAmountPerGood, improvementInstanceId) {
        super(instanceId, ownerNationId, TaxType.ImprovementSubsidy);
        this.subsidyAmountPerGood = subsidyAmountPerGood;
        this.improvementInstanceId = improvementInstanceId;
    }
    getCost(improvementInstanceId) {
        if (this.improvementInstanceId === improvementInstanceId) {
            return this.subsidyAmountPerGood;
        }
        return 0;
    }
    clone() {
        return new ImprovementSubsidy(this.instanceId, this.ownerNationId, this.subsidyAmountPerGood, this.improvementInstanceId);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            subsidyAmountPerGood: this.subsidyAmountPerGood,
            improvementInstanceId: this.improvementInstanceId,
        };
    }
    static fromJSON(json) {
        return new ImprovementSubsidy(json.instanceId, json.ownerNationId, json.subsidyAmountPerGood, json.improvementInstanceId);
    }
}
export default ImprovementSubsidy;
