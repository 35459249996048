import RealizedTaxItem from './TaxItem.js';
class AllShippingAndTaxesItem {
    constructor(totalShippingCost, totalTaxAmount, taxes) {
        this.totalShippingCost = totalShippingCost;
        this.totalTaxAmount = totalTaxAmount;
        this.taxes = taxes;
    }
    clone() {
        return new AllShippingAndTaxesItem(this.totalShippingCost, this.totalTaxAmount, this.taxes.map((tax) => tax.clone()));
    }
    toJSON() {
        return {
            totalShippingCost: this.totalShippingCost,
            totalTaxAmount: this.totalTaxAmount,
            taxes: this.taxes.map((tax) => tax.toJSON()),
        };
    }
    static fromJSON(json) {
        return new AllShippingAndTaxesItem(json.totalShippingCost, json.totalTaxAmount, json.taxes.map((tax) => RealizedTaxItem.fromJSON(tax)));
    }
}
export default AllShippingAndTaxesItem;
