// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import Popup from '../../../UI/GenericComponents/OptionsPopup.tsx';

// internal context
import { PopupDataContext } from '../../../../contexts/PopupsAndNotifications/PopupDataContextProvider.tsx';

// css
import { BottomInfoStyled } from './styles/BottomInfo.styled.js';

// gl

const BottomInfoUnit = () => {
  const { bottomInfo, setBottomInfo } = useContext(PopupDataContext);

  return (
    <BottomInfoStyled>
      {bottomInfo?.visible && (
        <div className='bottom-info-container'>
          <div className='bottom-info-title'>{bottomInfo.title}</div>
          <div className='bottom-info-description'>
            {bottomInfo.description}
          </div>
        </div>
      )}
    </BottomInfoStyled>
  );
};

export default BottomInfoUnit;
