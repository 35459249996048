// External dependencies
import React, { useContext, useMemo } from 'react';

// Contexts
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
import { ProductionPopupDataContext } from '../../../../contexts/PopupsAndNotifications/SelectProductionPopupContextProvider.tsx';
import { PopupDataContext } from '../../../../contexts/PopupsAndNotifications/PopupDataContextProvider.tsx';
// Components
import CenterTitle from '../../../UI/GenericComponents/CenterTitle';

// Styles
import { ExistingImprovementPanelStyled } from './styles/ExistingImprovementPanel.styled.js';

// Enums
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';

// Utilities
import getExistingImprovementInfo from '../../../UI/MappingToData/Improvement/ExistingImprovementInfo.tsx';
import CenterButton from '../../../UI/GenericComponents/CenterButton.tsx';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods';
import { ExperienceType } from '../../../../../../../GildedLands/lib/Enums/Experience';

const ExistingImprovementPanel = () => {
  // #region context
  const { changeImprovementProduction, changeKnowledge, removeImprovement } =
    useContext(GameStateLogicContext);
  const { gameState } = useContext(GameStateDataContext);
  const { selectedRuntimeImprovement, setSelectedRuntimeImprovement } =
    useContext(SelectedEntityContext);
  const { setProductionPopup } = useContext(ProductionPopupDataContext);
  const { addPopup } = useContext(PopupDataContext);
  // #endregion

  const handleChangeKnowledge = (
    knowledgeIndex: number,
    experienceType: ExperienceType
  ) => {
    changeKnowledge(
      selectedRuntimeImprovement.instanceId,
      knowledgeIndex,
      experienceType
    );
  };

  const handleChangeProduction = () => {
    setSelectedRuntimeImprovement(null);
    setProductionPopup({
      tileIndex: selectedRuntimeImprovement.landTileId,
      improvementType: selectedRuntimeImprovement.improvementType,
      onSelect: (goodType: GoodType) => {
        changeImprovementProduction(
          selectedRuntimeImprovement.instanceId,
          goodType
        );
      },
    });
  };

  const handleRemoveImprovement = () => {
    removeImprovement(selectedRuntimeImprovement.instanceId);
    setSelectedRuntimeImprovement(null);
  };

  return (
    <ExistingImprovementPanelStyled className='runtime-improvements-panel ui-gold clickable-area'>
      <div
        className='close-button'
        onClick={() => setSelectedRuntimeImprovement(null)}
      >
        X
      </div>
      <CenterTitle
        title={selectedRuntimeImprovement.name}
        padding={UIPadding.Tall}
        backgroundData={{
          color: UIColor.Gold,
          opacity: UIOpacity.Full,
        }}
      />
      <div className='runtime-improvements-panel-content'>
        {getExistingImprovementInfo(
          selectedRuntimeImprovement,
          gameState,
          handleChangeKnowledge,
          addPopup,
          handleChangeProduction,
          handleRemoveImprovement
        )}
      </div>
    </ExistingImprovementPanelStyled>
  );
};

export default ExistingImprovementPanel;
