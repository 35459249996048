import styled from 'styled-components';
import '../../../../styles/constants.css';

export const ListPopupStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .list-popup-closer {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  .list-popup-area {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
    padding: 10px;
    transform: translate(-50%, -50%);
    border: 2px solid var(--lol-gold);
    pointer-events: auto;
    overflow-y: auto;
  }

  .list-popup-item {
    margin-bottom: 3px;
    cursor: pointer;
    :hover {
      font-weight: bold;
    }
  }
`;
