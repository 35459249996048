import styled from 'styled-components';

export const HexMapStyled = styled.div`
  margin: 10px;
  background-color: black;

  .map-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .units-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  .paths-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;

    .unit-path {
      width: 100%;
      height: 100%;
    }
  }
`;
