import styled from 'styled-components';
import '../../../../styles/constants.css';

export const RangeSliderStyled = styled.div`
  position: relative;
  margin-top: 15px;

  .left-title {
    display: inline-block;
    margin-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    font-family: var(--header-font);
  }

  .above-title {
    align-self: center;
    margin-bottom: 10px;
    font-family: var(--header-font);
  }

  .slider-title-containter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .slider-container {
    position: relative;
    display: inline;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }

  input[type='range'] {
    width: 100%;
    height: 4px; // Reduced height of the slider
    -webkit-appearance: none; // Remove default styling
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px; // Reduced handle size
    width: 12px; // Reduced handle size
    background: #007bff; // Optional: change handle color
    border-radius: 50%; // Optional: make handle round
  }

  .current-value {
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    font-size: 12px;
  }

  .labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3px; // Reduced margin
  }

  .slider-label {
    cursor: pointer;
    font-size: 10px;
  }
`;
