import React, { useState, useContext } from 'react';
import { TaxItemStyled } from './styles/TaxItem.styled.js';
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import ProductionSubsidy from '../../../../../../../../../GildedLands/lib/Classes/Tax/ProductionSubsidy.js';
import {
  GoodCategory,
  GoodSubCategory,
  GoodType,
} from '../../../../../../../../../GildedLands/lib/Enums/Good.js';
import EnumList from '../Utilities/EnumList.tsx';
import CityList from '../Utilities/CityList.tsx';

interface ProductionSubsidyItemProps {
  subsidy: ProductionSubsidy;
}

const ProductionSubsidyItem = ({ subsidy }: ProductionSubsidyItemProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { editTax, deleteTax } = useContext(GameStateLogicContext);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const saveNewSubsidy = (newSubsidy: ProductionSubsidy) => {
    editTax(newSubsidy);
  };

  return (
    <TaxItemStyled>
      <div className='tax-main-info' onClick={toggleDetails}>
        <span className='tax-title'>
          {showDetails ? '^ ' : '> '}
          Production Subsidy
        </span>
        <span className='tax-info'>
          Subsidy Amount: {subsidy.subsidyAmountPerGood}
        </span>
      </div>
      {showDetails && (
        <div className='tax-item-details'>
          <div className='tax-details-category-content'>
            <label>
              Subsidy Amount: $
              <input
                type='number'
                name='subsidyAmountPerGood'
                defaultValue={subsidy.subsidyAmountPerGood}
                step='0.1'
                onBlur={(e) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.subsidyAmountPerGood = parseFloat(e.target.value);
                  saveNewSubsidy(newSubsidy);
                }}
              />
            </label>
            <div className='tax-goods'>
              <label>
                Is On All Goods:
                <input
                  type='checkbox'
                  name='isOnAllGoods'
                  defaultChecked={subsidy.isOnAllGoods}
                  onChange={(e) => {
                    const newSubsidy = subsidy.clone();
                    newSubsidy.isOnAllGoods = e.target.checked;
                    saveNewSubsidy(newSubsidy);
                  }}
                />
              </label>
              <EnumList
                title='Excluded Categories'
                enumObject={GoodCategory}
                values={subsidy.excludedCategories}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.excludedCategories = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
              <EnumList
                title='Excluded SubCategories'
                enumObject={GoodSubCategory}
                values={subsidy.excludedSubCategories}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.excludedSubCategories = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
              <EnumList
                title='Excluded Goods'
                enumObject={GoodType}
                values={subsidy.excludedGoods}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.excludedGoods = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
              <EnumList
                title='Included Categories'
                enumObject={GoodCategory}
                values={subsidy.includedCategories}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.includedCategories = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
              <EnumList
                title='Included SubCategories'
                enumObject={GoodSubCategory}
                values={subsidy.includedSubCategories}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.includedSubCategories = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
              <EnumList
                title='Included Goods'
                enumObject={GoodType}
                values={subsidy.includedGoods}
                save={(values) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.includedGoods = values;
                  saveNewSubsidy(newSubsidy);
                }}
              />
            </div>
            <div className='tax-cities'>
              <label>
                Is On All Cities:
                <input
                  type='checkbox'
                  name='isOnAllCities'
                  defaultChecked={subsidy.isOnAllCities}
                  onChange={(e) => {
                    const newSubsidy = subsidy.clone();
                    newSubsidy.isOnAllCities = e.target.checked;
                    saveNewSubsidy(newSubsidy);
                  }}
                />
              </label>
              <CityList
                cityData={{
                  isOnAllCities: subsidy.isOnAllCities,
                  excludedCityInstanceIds: subsidy.excludedCityInstanceIds,
                  includedCityInstanceIds: subsidy.includedCityInstanceIds,
                }}
                save={(cityData: {
                  isOnAllCities: boolean;
                  excludedCityInstanceIds: number[];
                  includedCityInstanceIds: number[];
                }) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.isOnAllCities = cityData.isOnAllCities;
                  newSubsidy.excludedCityInstanceIds =
                    cityData.excludedCityInstanceIds;
                  newSubsidy.includedCityInstanceIds =
                    cityData.includedCityInstanceIds;
                  saveNewSubsidy(newSubsidy);
                }}
              />
            </div>
          </div>
          <button
            className='delete-tax-button'
            onClick={() => deleteTax(subsidy.instanceId)}
          >
            Delete
          </button>
        </div>
      )}
    </TaxItemStyled>
  );
};

export default ProductionSubsidyItem;
