import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';

// GildedLands imports
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import Person from '../../../../../../../GildedLands/lib/Classes/Person/Person.js';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good.js';
import RealizedTaxItem from '../../../../../../../GildedLands/lib/Classes/EconomicData/TaxTracking/TaxItem.js';
import { TaxType } from '../../../../../../../GildedLands/lib/Enums/Tax.js';

// Other imports
import getPersonPersonalData from './PersonPersonal.tsx';
import getPersonProductionData from './PersonProduction.tsx';
import getPersonConsumptionData from './PersonConsumption.tsx';
import getExperienceData from '../Experience/Experience.tsx';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';

const getPersonData = (
  productionByPerson: {
    person: Person;
    improvementId: number;
    cityId: number;
    goodType: GoodType;
    quality: number;
    productionAmount: number;
    marketValue: number;
    inputCost: number;
    valueToProducer: number;
    taxes: RealizedTaxItem[];
  },
  gameState: GameState,
  showProduces = true,
  showTotalTax = true,
  showProdTax = true
): React.ReactNode => {
  const person = productionByPerson.person;
  const city = gameState.getCityByInstanceId(productionByPerson.cityId);

  const dropdownContent = renderdropdown(
    person,
    city,
    productionByPerson,
    gameState
  );

  const inlineDataFields = renderInlineFields(
    productionByPerson,
    showProduces,
    showTotalTax,
    showProdTax
  );

  return (
    <LineItemStandard
      title={person.name}
      titleColor={UIColor.White}
      inlineDataFields={inlineDataFields}
      dropdown={dropdownContent}
      inlineFieldsAlignment='table'
    />
  );
};

const renderdropdown = (
  person: Person,
  city: City,
  productionByPerson: any,
  gameState: GameState
): React.ReactNode => {
  return (
    <VerticalList
      data={[
        renderHeader('Personal Info'),
        getPersonPersonalData(person, city, gameState),
        renderHeader('Production Info'),
        getPersonProductionData(productionByPerson, gameState),
        renderHeader('Consumption Info'),
        getPersonConsumptionData(person),
        renderHeader('Experience Info'),
        getExperienceData(person.experiences, 1),
      ]}
    />
  );
};

const renderHeader = (title: string): React.ReactNode => (
  <CenterTitle
    title={title}
    padding={UIPadding.Short}
    backgroundData={{
      color: UIColor.DarkGrey,
      opacity: UIOpacity.Header,
    }}
  />
);

const renderInlineFields = (
  productionByPerson: any,
  showProduces: boolean,
  showTotalTax: boolean,
  showProdTax: boolean
): any[] => {
  const person = productionByPerson.person;
  const inlineDataFields = [];

  inlineDataFields.push({
    fieldData: {
      fieldName: 'QoL',
      textColor: UIColor.White,
      value: person.consumptionChart.totalQualityOfLife.toFixed(0),
    },
    dropdown: null,
  });

  if (showProduces) {
    inlineDataFields.push({
      fieldData: {
        fieldName: 'Produces',
        icon: 'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/wheat_icon.png',
        textColor: UIColor.White,
        value: productionByPerson.goodType.toString(),
      },
      dropdown: null,
    });
  }

  inlineDataFields.push({
    fieldData: {
      fieldName: 'Income',
      textColor: UIColor.White,
      value: productionByPerson.productionValue.toFixed(2),
    },
    dropdown: null,
  });

  if (showProdTax) {
    const productionTaxPaid = calculateTax(
      productionByPerson,
      TaxType.ProductionTax
    );
    inlineDataFields.push({
      fieldData: {
        fieldName: 'Prod Tax',
        textColor: UIColor.White,
        value: productionTaxPaid,
      },
      dropdown: null,
    });
  }

  if (showTotalTax) {
    const totalTaxPaid = calculateTotalTax(productionByPerson);
    inlineDataFields.push({
      fieldData: {
        fieldName: 'Total Tax',
        textColor: UIColor.White,
        value: totalTaxPaid,
      },
      dropdown: null,
    });
  }

  return inlineDataFields;
};

const calculateTax = (productionByPerson: any, taxType: TaxType): string => {
  return productionByPerson.taxes
    .filter((tax) => tax.taxType === taxType)
    .reduce((acc, curr) => acc + curr.incomeToGovernment, 0)
    .toFixed(2);
};

const calculateTotalTax = (productionByPerson: any): string => {
  return productionByPerson.taxes
    .reduce((acc, curr) => acc + curr.incomeToGovernment, 0)
    .toFixed(2);
};

export default getPersonData;
