import styled from 'styled-components';
import '../../../../styles/constants.css';

const StartingTileMarkerStyled = styled.div.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: none;
  z-index: 100;
  position: absolute;
`;

const StartingTileMarkerLabelStyled = styled.div.attrs(({ hexagonSize }) => ({
  style: {
    fontSize: `${hexagonSize * 0.3}px`,
  },
}))`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-green);
  color: white;
  font-family: var(--header-font);
  border: 1px solid var(--lol-gold);
  padding: 1px 3px;
  z-index: 11;
  white-space: nowrap;
  max-width: none;

  .improvement-name {
    font-size: 0.6em;
  }

  .improvement-description {
    font-size: 0.6em;
    margin-top: 2px;
  }
`;

const StartingTileMarkerShapeStyled = styled.svg.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  position: absolute;
  fill: none;
  stroke: var(--lol-gold);
  stroke-width: 2;
  z-index: 10;
  left: 0;
  top: 0;
`;

const StartingTileMarkerImageStyled = styled.img.attrs(
  ({ hexagonSize, points }) => ({
    style: {
      clipPath: `polygon(${points})`,
      width: `${hexagonSize * Math.sqrt(3)}px`,
      height: `${hexagonSize * 2}px`,
    },
  })
)`
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  object-fit: cover;
  object-position: center;
  z-index: 9;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export {
  StartingTileMarkerStyled,
  StartingTileMarkerShapeStyled,
  StartingTileMarkerImageStyled,
  StartingTileMarkerLabelStyled,
};
