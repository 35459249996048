// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// contexts
import { GameStateDataContext } from '../GameData/GameStateDataContextProvider.tsx';

// gl
import {
  BiomeType,
  BiomeTypeMethods,
} from '../../../../../GildedLands/lib/Enums/Biome';
import { ImprovementType } from '../../../../../GildedLands/lib/Enums/Improvement';
import RuntimeImprovement from '../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import RuntimeLandTile from '../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import RuntimeRealm from '../../../../../GildedLands/lib/Classes/Land/Realm/RuntimeRealm';
import LibraryLandTile from '../../../../../GildedLands/lib/Classes/Land/LandTile/LibraryLandTile';

const ImageContextProvider = ({ children }) => {
  // context
  const { gameState } = useContext(GameStateDataContext);

  // image kit
  const imageKit = 'https://ik.imagekit.io/hfywj4j0a/';
  const defaultRes = 2500;

  const resString = (res: number) => `tr:w-${res}/`;
  const getIKUrl = (url: string, res: number = defaultRes) => {
    if (url.includes(imageKit)) {
      return url.replace(imageKit, imageKit + resString(res));
    } else {
      return imageKit + resString(res) + url;
    }
  };

  // #region background

  const fog =
    'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/backgrounds/fog_v5.png';

  // #endregion

  // #region cards

  // #region icons

  const iconImageRes = 1000;
  const defaultCityIcon =
    'https://ik.imagekit.io/hfywj4j0a/GildedLands/default_city_icon.png';
  // #endregion

  // #region city view

  const cityViewRes = 10000;

  const cityViewDefault =
    // 'https://ik.imagekit.io/hfywj4j0a/GildedLands/medieval_town.png';
    'https://ik.imagekit.io/hfywj4j0a/GildedLands/town_v2.png';

  // #endregion

  // #region tiles

  const tileVersion = 'v2';

  const enumsToTile = (type: BiomeType) => {
    const typeString = BiomeType[type];
    const num = Math.floor(Math.random() * 4);
    return `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/${typeString}/${typeString}_${num}_${tileVersion}.png`;
  };

  const landTileToImage = (
    type: BiomeType,
    improvementInstanceIds: number[],
    imagePickerNumber: number
  ) => {
    if (type === null) {
      // return `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/undiscovered.png`;
      return `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/black.png`;
      // return `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/v2/cloud_v2.png`;
    }

    let currentType = type;

    const improvementTypes: ImprovementType[] = improvementInstanceIds.map(
      (id) => {
        const improvement = gameState.getImprovementByInstanceId(id);
        if (!improvement) {
          console.log(
            'improvement not found for id: ',
            id,
            ' on ',
            BiomeType[type]
          );
          return null;
        }
        return improvement.improvementType;
      }
    );

    for (let id of improvementInstanceIds) {
      const improvement = gameState.getImprovementByInstanceId(id);
      const info = improvementDisplayInfo(improvement, imagePickerNumber);
      if (info && info.tileImage) {
        return info.tileImage;
      }
    }

    const typeString = BiomeType[currentType];
    const num = imagePickerNumber % 4;
    return `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/${typeString}/${typeString}_${num}_${tileVersion}.png`;
  };

  const runtimeLandTileToRiverString = (
    tile: RuntimeLandTile,
    realm: RuntimeRealm
  ): string => {
    if (!tile.river) {
      return null;
    }

    let riverCode = '';

    // Iterate over positions 0 to 5
    for (let position = 0; position < 6; position++) {
      const neighbor = tile.getNeighborByPosition(position, realm);

      // Check if neighbor exists and has a river
      if (neighbor && neighbor.river) {
        riverCode += '1';
      } else {
        riverCode += '0';
      }
    }

    const imageName = `hexRiver${riverCode}-00`;
    return imageName;
  };

  const runtimeLandTileToRiver = (
    tile: RuntimeLandTile,
    realm: RuntimeRealm
  ): string => {
    const code = runtimeLandTileToRiverString(tile, realm);
    return getIKUrl(
      `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/Rivers/${code}.png`,
      defaultRes
    );
  };

  const runtimeLandTileToRoadString = (
    tile: RuntimeLandTile,
    realm: RuntimeRealm
  ): string => {
    if (!tile.road) {
      return null;
    }

    let roadCode = '';

    for (let position = 0; position < 6; position++) {
      const neighbor = tile.getNeighborByPosition(position, realm);
      if (neighbor && !!neighbor.road) {
        roadCode += '1';
      } else {
        roadCode += '0';
      }
    }

    const imageName = `hexRoad-${roadCode}-00`;
    return imageName;
  };

  const runtimeLandTileToRoad = (
    tile: RuntimeLandTile,
    realm: RuntimeRealm
  ): string => {
    const code = runtimeLandTileToRoadString(tile, realm);
    return getIKUrl(
      `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/Roads/${code}.png`,
      defaultRes
    );
  };

  const improvementDisplayInfo = (
    runtimeImprovement: RuntimeImprovement,
    imagePickerNumber: number
  ): {
    tileImage: string | null;
    tileIcon: boolean;
    iconInCity: boolean;
  } | null => {
    if (runtimeImprovement.improvementType === ImprovementType.SapTapping) {
      return {
        tileImage:
          'https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/v2/SapFarm/gilded_lands-ForestBroadleaf-copy-hexForestBroadleafFullColor00.png',
        tileIcon: false,
        iconInCity: false,
      };
    }

    if (runtimeImprovement.improvementType === ImprovementType.FlatlandFarm) {
      const num = imagePickerNumber % 4;
      const str = 'Farm';
      return {
        tileImage: `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/${str}/${str}_${num}_${tileVersion}.png`,
        tileIcon: false,
        iconInCity: false,
      };
    }

    if (runtimeImprovement.improvementType === ImprovementType.HillFarm) {
      const num = imagePickerNumber % 4;
      const str = 'HillFarm';
      return {
        tileImage: `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/${str}/${str}_${num}_${tileVersion}.png`,
        tileIcon: false,
        iconInCity: false,
      };
    }

    if (runtimeImprovement.improvementType === ImprovementType.MountainFarm) {
      const num = imagePickerNumber % 4;
      const str = 'MountainFarm';
      return {
        tileImage: `https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/${tileVersion}/${str}/${str}_${num}_${tileVersion}.png`,
        tileIcon: false,
        iconInCity: false,
      };
    }

    return {
      tileImage: null,
      tileIcon: true,
      iconInCity: false,
    };
  };

  // #endregion

  // #region popups

  const popupRes = 1000;

  const endGamePopupBackgroundDefeat =
    'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/popups/end_game/defeat_0.png';

  const endGamePopupBackgroundVictory =
    'https://ik.imagekit.io/hfywj4j0a/LoL/GameScene/popups/end_game/triumph_0.png';

  // #endregion

  const value = {
    enumsToTile,
    landTileToImage,
    runtimeLandTileToRiver,
    runtimeLandTileToRoad,
    improvementDisplayInfo,

    // #region cards

    defaultCityIcon: getIKUrl(defaultCityIcon, iconImageRes),

    // #region city view
    cityViewDefault: getIKUrl(cityViewDefault, cityViewRes),
    // #endregion

    // #endregion

    // #region background
    fog: getIKUrl(fog, defaultRes),
    // #endregion

    // #region popups
    endGamePopupBackgroundDefeat: getIKUrl(
      endGamePopupBackgroundDefeat,
      popupRes
    ),
    endGamePopupBackgroundVictory: getIKUrl(
      endGamePopupBackgroundVictory,
      popupRes
    ),
    // #endregion

    getIKUrl,
  };

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};

interface ImageContextInterface {
  enumsToTile: (type: BiomeType) => string;
  landTileToImage: (
    type: BiomeType,
    improvementInstanceIds: number[],
    imagePickerNumber: number
  ) => string;
  runtimeLandTileToRiver: (
    tile: RuntimeLandTile,
    realm: RuntimeRealm
  ) => string;
  runtimeLandTileToRoad: (tile: RuntimeLandTile, realm: RuntimeRealm) => string;
  improvementDisplayInfo: (
    runtimeImprovement: RuntimeImprovement,
    imagePickerNumber: number
  ) => {
    tileImage: string | null;
    tileIcon: boolean;
    iconInCity: boolean;
  } | null;
  defaultCityIcon: string;

  cityViewDefault: string;

  // #region background
  fog: string;
  // #endregion

  // #region popups
  endGamePopupBackgroundDefeat: string;
  endGamePopupBackgroundVictory: string;
  // #endregion

  getIKUrl: (url: string, res: number) => string;
}

const ImageContext = React.createContext<ImageContextInterface>(null);

export { ImageContextProvider, ImageContext };
