import ActiveArmy from './ActiveArmy.js';
import { BiomeType } from '../../Enums/Biome.js';
class Battle {
    constructor(sideA, sideB, terrain, turn, history) {
        this.sideA = sideA;
        this.sideB = sideB;
        this.terrain = terrain;
        this.turn = turn;
        this.history = history;
    }
    /**
     * Runs a single turn of the battle.
     */
    runTurn() {
        // Increment the battle turn
        this.turn += 1;
        // First, update stats for all armies on both sides
        this.sideA.forEach((army) => army.runStatsForBattle(this));
        this.sideB.forEach((army) => army.runStatsForBattle(this));
        // Calculate total offense and resilience for each side
        const totalOffenseA = this.getTotalOffense(this.sideA);
        const totalResilienceA = this.getTotalResilience(this.sideA);
        const totalOffenseB = this.getTotalOffense(this.sideB);
        const totalResilienceB = this.getTotalResilience(this.sideB);
        // Calculate damage dealt to each side based on offense and opponent's resilience
        // Ensure no division by zero
        const damageToB = totalOffenseA / Math.max(totalResilienceB, 1);
        const damageToA = totalOffenseB / Math.max(totalResilienceA, 1);
        // Apply damage to each side
        const casualtiesA = this.applyDamage(this.sideA, damageToA);
        const casualtiesB = this.applyDamage(this.sideB, damageToB);
        // Calculate morale and energy changes (placeholders for now)
        const moraleChangeA = -damageToA * 0.1; // Example: morale decreases by 10% of damage taken
        const moraleChangeB = -damageToB * 0.1;
        const energyChangeA = -1; // Placeholder: energy decreases by 1
        const energyChangeB = -1;
        // Update morale and energy for each side
        this.updateMoraleAndEnergy(this.sideA, moraleChangeA, energyChangeA);
        this.updateMoraleAndEnergy(this.sideB, moraleChangeB, energyChangeB);
        // Record the turn's results in history
        this.history.push({
            sideA: {
                army: this.sideA.map((army) => army.clone()),
                casualties: casualtiesA,
                moraleChange: moraleChangeA,
                energyChange: energyChangeA,
                resilience: totalResilienceA,
                offensiveOutput: totalOffenseA,
                shock: 0,
                formationModifier: this.getTerrainFormationModifier(),
            },
            sideB: {
                army: this.sideB.map((army) => army.clone()),
                casualties: casualtiesB,
                moraleChange: moraleChangeB,
                energyChange: energyChangeB,
                resilience: totalResilienceB,
                offensiveOutput: totalOffenseB,
                shock: 0,
                formationModifier: this.getTerrainFormationModifier(),
            },
        });
    }
    /**
     * Calculates the total offensive output for a side.
     */
    getTotalOffense(sideArmies) {
        let totalOffense = 0;
        for (const army of sideArmies) {
            for (const cohort of army.cohorts) {
                const offensiveOutput = cohort.lastFightStats.offensiveOutput.result;
                totalOffense += offensiveOutput;
            }
        }
        return totalOffense;
    }
    /**
     * Calculates the total resilience for a side.
     */
    getTotalResilience(sideArmies) {
        let totalResilience = 0;
        for (const army of sideArmies) {
            for (const cohort of army.cohorts) {
                const resilience = cohort.lastFightStats.resilience.result;
                totalResilience += resilience;
            }
        }
        return totalResilience;
    }
    /**
     * Applies damage to the given side's armies.
     * @returns The total number of soldiers lost (casualties) on that side.
     */
    applyDamage(sideArmies, damage) {
        // Calculate the total number of soldiers on the side
        let totalSoldiers = 0;
        for (const army of sideArmies) {
            for (const cohort of army.cohorts) {
                totalSoldiers += cohort.soldiers;
            }
        }
        // If there are no soldiers left, no damage can be applied
        if (totalSoldiers <= 0) {
            return 0;
        }
        let totalCasualties = 0;
        // Apply damage proportionally to each cohort based on their soldier count
        for (const army of sideArmies) {
            for (const cohort of army.cohorts) {
                const cohortSoldiers = cohort.soldiers;
                const damageRatio = cohortSoldiers / totalSoldiers;
                const soldiersLost = Math.min(cohortSoldiers, damage * damageRatio);
                cohort.soldiers -= soldiersLost;
                totalCasualties += soldiersLost;
                // Ensure soldiers don't go negative
                if (cohort.soldiers < 0) {
                    cohort.soldiers = 0;
                }
            }
        }
        return totalCasualties;
    }
    /**
     * Updates morale and energy for each cohort in the side's armies.
     */
    updateMoraleAndEnergy(sideArmies, moraleChange, energyChange) {
        for (const army of sideArmies) {
            for (const cohort of army.cohorts) {
                cohort.morale += moraleChange;
                cohort.energy += energyChange;
                // Clamp morale and energy between 0 and 100
                cohort.morale = Math.max(0, Math.min(100, cohort.morale));
                cohort.energy = Math.max(0, Math.min(100, cohort.energy));
            }
        }
    }
    /**
     * Gets the terrain formation modifier based on the main biome type.
     */
    getTerrainFormationModifier() {
        const { mainBiomeType } = this.terrain;
        switch (mainBiomeType) {
            case BiomeType.Forest:
                return 0;
            case BiomeType.Mountains:
                return 0.5;
            case BiomeType.Hills:
                return 0.75;
            default:
                return 1;
        }
    }
    // #region Clone and JSON
    clone() {
        return new Battle(this.sideA.map((army) => army.clone()), this.sideB.map((army) => army.clone()), {
            mainBiomeType: this.terrain.mainBiomeType,
            otherBiomeTypes: [...this.terrain.otherBiomeTypes],
        }, this.turn, this.history.map((turn) => ({
            sideA: {
                army: turn.sideA.army.map((army) => army.clone()),
                casualties: turn.sideA.casualties,
                moraleChange: turn.sideA.moraleChange,
                energyChange: turn.sideA.energyChange,
                resilience: turn.sideA.resilience,
                offensiveOutput: turn.sideA.offensiveOutput,
                shock: turn.sideA.shock,
                formationModifier: turn.sideA.formationModifier,
            },
            sideB: {
                army: turn.sideB.army.map((army) => army.clone()),
                casualties: turn.sideB.casualties,
                moraleChange: turn.sideB.moraleChange,
                energyChange: turn.sideB.energyChange,
                resilience: turn.sideB.resilience,
                offensiveOutput: turn.sideB.offensiveOutput,
                shock: turn.sideB.shock,
                formationModifier: turn.sideB.formationModifier,
            },
        })));
    }
    toJSON() {
        return {
            sideA: this.sideA.map((army) => army.toJSON()),
            sideB: this.sideB.map((army) => army.toJSON()),
            terrain: {
                mainBiomeType: this.terrain.mainBiomeType,
                otherBiomeTypes: this.terrain.otherBiomeTypes.map((type) => type),
            },
            turn: this.turn,
            history: this.history.map((turn) => ({
                sideA: {
                    army: turn.sideA.army.map((army) => army.toJSON()),
                    casualties: turn.sideA.casualties,
                    moraleChange: turn.sideA.moraleChange,
                    energyChange: turn.sideA.energyChange,
                    resilience: turn.sideA.resilience,
                    offensiveOutput: turn.sideA.offensiveOutput,
                    shock: turn.sideA.shock,
                    formationModifier: turn.sideA.formationModifier,
                },
                sideB: {
                    army: turn.sideB.army.map((army) => army.toJSON()),
                    casualties: turn.sideB.casualties,
                    moraleChange: turn.sideB.moraleChange,
                    energyChange: turn.sideB.energyChange,
                    resilience: turn.sideB.resilience,
                    offensiveOutput: turn.sideB.offensiveOutput,
                    shock: turn.sideB.shock,
                    formationModifier: turn.sideB.formationModifier,
                },
            })),
        };
    }
    static fromJSON(json) {
        return new Battle(json.sideA.map((army) => ActiveArmy.fromJSON(army)), json.sideB.map((army) => ActiveArmy.fromJSON(army)), {
            mainBiomeType: json.terrain.mainBiomeType,
            otherBiomeTypes: json.terrain.otherBiomeTypes.map((type) => type),
        }, json.turn, json.history.map((turn) => ({
            sideA: {
                army: turn.sideA.army.map((army) => ActiveArmy.fromJSON(army)),
                casualties: turn.sideA.casualties,
                moraleChange: turn.sideA.moraleChange,
                energyChange: turn.sideA.energyChange,
                resilience: turn.sideA.resilience,
                offensiveOutput: turn.sideA.offensiveOutput,
                shock: turn.sideA.shock,
                formationModifier: turn.sideA.formationModifier,
            },
            sideB: {
                army: turn.sideB.army.map((army) => ActiveArmy.fromJSON(army)),
                casualties: turn.sideB.casualties,
                moraleChange: turn.sideB.moraleChange,
                energyChange: turn.sideB.energyChange,
                resilience: turn.sideB.resilience,
                offensiveOutput: turn.sideB.offensiveOutput,
                shock: turn.sideB.shock,
                formationModifier: turn.sideB.formationModifier,
            },
        })));
    }
}
export default Battle;
