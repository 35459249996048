// Flat rate per good, targetable by shipping origin and destination
import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class TransitDuty extends Tax {
    constructor(instanceId, ownerNationId, dutyAmountPerGood, isOnAllOriginNations, excludedOriginNationIds, includedOriginNationIds, isOnAllDestinationNations, excludedDestinationNationIds, includedDestinationNationIds) {
        super(instanceId, ownerNationId, TaxType.TransitDuty);
        this.dutyAmountPerGood = dutyAmountPerGood;
        this.isOnAllOriginNations = isOnAllOriginNations;
        this.excludedOriginNationIds = excludedOriginNationIds;
        this.includedOriginNationIds = includedOriginNationIds;
        this.isOnAllDestinationNations = isOnAllDestinationNations;
        this.excludedDestinationNationIds = excludedDestinationNationIds;
        this.includedDestinationNationIds = includedDestinationNationIds;
    }
    getCost(sourceCityInstanceId, destinationCityInstanceId, gameState) {
        const sourceNationId = gameState.getCityByInstanceId(sourceCityInstanceId).administeringNationId;
        const destinationNationId = gameState.getCityByInstanceId(destinationCityInstanceId).administeringNationId;
        if (this.isOnAllOriginNations) {
            if (this.excludedOriginNationIds.includes(sourceNationId)) {
                return 0;
            }
        }
        else {
            if (!this.includedOriginNationIds.includes(sourceNationId)) {
                return 0;
            }
        }
        if (this.isOnAllDestinationNations) {
            if (this.excludedDestinationNationIds.includes(destinationNationId)) {
                return 0;
            }
        }
        else {
            if (!this.includedDestinationNationIds.includes(destinationNationId)) {
                return 0;
            }
        }
        return this.dutyAmountPerGood;
    }
    clone() {
        return new TransitDuty(this.instanceId, this.ownerNationId, this.dutyAmountPerGood, this.isOnAllOriginNations, [...this.excludedOriginNationIds], [...this.includedOriginNationIds], this.isOnAllDestinationNations, [...this.excludedDestinationNationIds], [...this.includedDestinationNationIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            dutyAmountPerGood: this.dutyAmountPerGood,
            isOnAllOriginNations: this.isOnAllOriginNations,
            excludedOriginNationIds: [...this.excludedOriginNationIds],
            includedOriginNationIds: [...this.includedOriginNationIds],
            isOnAllDestinationNations: this.isOnAllDestinationNations,
            excludedDestinationNationIds: [...this.excludedDestinationNationIds],
            includedDestinationNationIds: [...this.includedDestinationNationIds],
        };
    }
    static fromJSON(json) {
        return new TransitDuty(json.instanceId, json.ownerNationId, json.dutyAmountPerGood, json.isOnAllOriginNations, [...json.excludedOriginNationIds], [...json.includedOriginNationIds], json.isOnAllDestinationNations, [...json.excludedDestinationNationIds], [...json.includedDestinationNationIds]);
    }
}
export default TransitDuty;
