import React from 'react';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { MissionMethods } from '../../../../../../../GildedLands/lib/Enums/Mission.js';
import {
  UIPadding,
  UIColor,
  UIOpacity,
  UITextSize,
} from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import TextBox from '../../GenericComponents/TextBox.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import RequirementReturnComponent from '../Requirements/RequirementReturn.tsx';

const getCreateMissionData = (
  city: City,
  gameState: GameState,
  isFounder: boolean,
  setIsFounder: (isFounder: boolean) => void,
  amMountainTraining: boolean,
  setAmMountainTraining: (amMountainTraining: boolean) => void,
  giveShips: boolean,
  setGiveShips: (giveShips: boolean) => void
): React.ReactNode => {
  const data: React.ReactNode[] = [];

  data.push(
    getCreateMissionCostData(
      city,
      gameState,
      isFounder,
      setIsFounder,
      amMountainTraining,
      setAmMountainTraining,
      giveShips,
      setGiveShips
    )
  );

  data.push(getMissionLengthData(city, gameState));

  return <VerticalList data={data} />;
};

const getCreateMissionCostData = (
  city: City,
  gameState: GameState,
  isFounder: boolean,
  setIsFounder: (isFounder: boolean) => void,
  amMountainTraining: boolean,
  setAmMountainTraining: (amMountainTraining: boolean) => void,
  giveShips: boolean,
  setGiveShips: (giveShips: boolean) => void
): React.ReactNode => {
  if (!city || !gameState) return null;

  const founderCost = MissionMethods.getFounderCost(city, gameState);
  const mountainTrainingCost = MissionMethods.getMountainTrainingCost(
    city,
    gameState
  );
  const shipsCost = MissionMethods.getShipsCost(city, gameState);
  const baseCost = MissionMethods.getBaseCost(city, gameState);

  const data: React.ReactNode[] = [
    <CenterTitle
      title='Create Mission'
      padding={UIPadding.Medium}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.Header,
      }}
    />,
    getFounderLineItem(founderCost, isFounder, setIsFounder),
    getMountainTrainingLineItem(
      mountainTrainingCost,
      city,
      gameState,
      amMountainTraining,
      setAmMountainTraining
    ),
    getGiveShipsLineItem(shipsCost, city, gameState, giveShips, setGiveShips),
    getFinalCostLineItem(
      baseCost,
      founderCost,
      mountainTrainingCost,
      shipsCost,
      isFounder,
      amMountainTraining,
      giveShips
    ),
  ];

  return <VerticalList data={data} />;
};

const getFounderLineItem = (
  founderCost: number,
  isFounder: boolean,
  setIsFounder: (isFounder: boolean) => void
): React.ReactNode => {
  const costField = {
    fieldName: 'Cost',
    textColor: UIColor.White,
    value: founderCost.toFixed(0),
  };

  const dropdownContent = (
    <VerticalList
      data={[
        <TextBox
          key='founder-cost-textbox'
          title='Founder Cost'
          content='Cheapest Food Price * 3 Years'
          padding={UIPadding.Medium}
          textSize={UITextSize.Medium}
        />,
      ]}
    />
  );

  return (
    <LineItemStandard
      title='Supply To Found New City'
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: costField,
          dropdown: dropdownContent,
        },
      ]}
      onMainClick={() => {
        setIsFounder(!isFounder);
      }}
      inlineFieldsAlignment='even'
      selectorData={{
        amSelected: isFounder,
      }}
    />
  );
};

const getMountainTrainingLineItem = (
  mountainTrainingCost: number,
  city: City,
  gameState: GameState,
  amMountainTraining: boolean,
  setAmMountainTraining: (amMountainTraining: boolean) => void
): React.ReactNode => {
  const experienceReturn = MissionMethods.getMountainTrainingReturn(
    city,
    gameState
  );

  const experienceField = {
    fieldName: 'Gain Mountain Exp.',
    textColor: UIColor.White,
    value: experienceReturn.amount.toFixed(0),
  };

  const costField = {
    fieldName: 'Cost',
    textColor: UIColor.White,
    value: mountainTrainingCost.toFixed(0),
  };

  const costDropdownContent = (
    <VerticalList
      data={[
        <TextBox
          key='mountain-training-cost-textbox'
          title='Mountain Training Cost'
          content='City Median Income * 2 Months'
          padding={UIPadding.Medium}
          textSize={UITextSize.Medium}
        />,
      ]}
    />
  );

  return (
    <LineItemStandard
      title='Do Mountain Training?'
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: experienceField,
          dropdown: null,
        },
        {
          fieldData: costField,
          dropdown: costDropdownContent,
        },
      ]}
      onMainClick={() => {
        setAmMountainTraining(!amMountainTraining);
      }}
      inlineFieldsAlignment='even'
      selectorData={{
        amSelected: amMountainTraining,
      }}
    />
  );
};

const getGiveShipsLineItem = (
  shipsCost: number,
  city: City,
  gameState: GameState,
  giveShips: boolean,
  setGiveShips: (giveShips: boolean) => void
): React.ReactNode => {
  const requirementReturn = MissionMethods.canBuildShips(city, gameState);

  const costField = {
    fieldName: 'Cost',
    textColor: UIColor.White,
    value: shipsCost.toFixed(0),
  };

  const titleColor = requirementReturn.isMet ? UIColor.White : UIColor.Red;

  let dropdown = null;
  if (!requirementReturn.isMet) {
    dropdown = (
      <VerticalList
        data={[<RequirementReturnComponent reqReturn={requirementReturn} />]}
      />
    );
  }

  const costDropdownContent = (
    <VerticalList
      data={[
        <TextBox
          key='ships-cost-textbox'
          title='Ships Cost'
          content='City Median Income * 2 Years'
          padding={UIPadding.Medium}
          textSize={UITextSize.Medium}
        />,
      ]}
    />
  );

  return (
    <LineItemStandard
      title='Give Ships?'
      titleColor={titleColor}
      inlineDataFields={[
        {
          fieldData: costField,
          dropdown: costDropdownContent,
        },
      ]}
      dropdown={dropdown}
      onMainClick={
        !dropdown
          ? () => {
              setGiveShips(!giveShips);
            }
          : null
      }
      inlineFieldsAlignment='even'
      selectorData={
        !dropdown
          ? {
              amSelected: giveShips,
            }
          : null
      }
    />
  );
};

const getFinalCostLineItem = (
  baseCost: number,
  founderCost: number,
  mountainTrainingCost: number,
  shipsCost: number,
  isFounder: boolean,
  amMountainTraining: boolean,
  giveShips: boolean
): React.ReactNode => {
  let finalCost = baseCost;
  const data: React.ReactNode[] = [];

  data.push(
    <LineItemStandard
      key='base-cost'
      title='Base Cost'
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: {
            fieldName: 'Cost',
            textColor: UIColor.White,
            value: baseCost.toFixed(0),
          },
          dropdown: null,
        },
      ]}
      inlineFieldsAlignment='even'
    />
  );

  if (isFounder) {
    finalCost += founderCost;
    data.push(
      <LineItemStandard
        key='founder-cost'
        title='Founder Cost'
        titleColor={UIColor.White}
        inlineDataFields={[
          {
            fieldData: {
              fieldName: 'Cost',
              textColor: UIColor.White,
              value: founderCost.toFixed(0),
            },
            dropdown: null,
          },
        ]}
        inlineFieldsAlignment='even'
      />
    );
  }

  if (amMountainTraining) {
    finalCost += mountainTrainingCost;
    data.push(
      <LineItemStandard
        key='mountain-training-cost'
        title='Mountain Training Cost'
        titleColor={UIColor.White}
        inlineDataFields={[
          {
            fieldData: {
              fieldName: 'Cost',
              textColor: UIColor.White,
              value: mountainTrainingCost.toFixed(0),
            },
            dropdown: null,
          },
        ]}
        inlineFieldsAlignment='even'
      />
    );
  }

  if (giveShips) {
    finalCost += shipsCost;
    data.push(
      <LineItemStandard
        key='ships-cost'
        title='Ships Cost'
        titleColor={UIColor.White}
        inlineDataFields={[
          {
            fieldData: {
              fieldName: 'Cost',
              textColor: UIColor.White,
              value: shipsCost.toFixed(0),
            },
            dropdown: null,
          },
        ]}
        inlineFieldsAlignment='even'
      />
    );
  }

  const costField = {
    fieldName: 'Cost',
    textColor: UIColor.White,
    value: finalCost.toFixed(0),
  };

  const dropdownContent = (
    <VerticalList
      data={data}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.Header,
      }}
    />
  );

  return (
    <LineItemStandard
      title='Total'
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: costField,
          dropdown: dropdownContent,
        },
      ]}
      inlineFieldsAlignment='even'
    />
  );
};

const getMissionLengthData = (
  city: City,
  gameState: GameState
): React.ReactNode => {
  const missionLengthData = MissionMethods.getMissionLength(city, gameState);
  const factorsData = missionLengthData.factors.map((factor, index) => (
    <LineItemStandard
      key={index}
      title={factor.title}
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: {
            fieldName: 'Days Added',
            textColor: UIColor.White,
            value: factor.daysAdded.toFixed(0),
          },
          dropdown: (
            <VerticalList
              data={[
                <TextBox
                  key='description-textbox'
                  title='Description'
                  content={factor.description}
                  padding={UIPadding.Medium}
                  textSize={UITextSize.Medium}
                />,
              ]}
            />
          ),
        },
      ]}
      inlineFieldsAlignment='even'
    />
  ));

  const missionLengthField = {
    fieldName: 'Days',
    textColor: UIColor.White,
    value: missionLengthData.lengthInDays.toFixed(0),
  };

  const dropdownContent = <VerticalList data={factorsData} />;

  return (
    <LineItemStandard
      title='Mission Length'
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: missionLengthField,
          dropdown: dropdownContent,
        },
      ]}
      inlineFieldsAlignment='even'
    />
  );
};

export default getCreateMissionData;
