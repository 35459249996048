class Administration {
    //
    constructor(balance, lastCycleRevenueAmount, lastCycleTaxes, lastCycleSpending) {
        this.balance = balance;
        this.lastCycleRevenueAmount = lastCycleRevenueAmount;
        this.lastCycleTaxes = lastCycleTaxes;
        this.lastCycleSpending = lastCycleSpending;
    }
}
export default Administration;
