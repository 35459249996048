class RuntimeBiome {
    constructor() {
        this.name = 'New Biome';
        this.landTileIds = [];
        // #endregion
    }
    //#region Landtiles
    getLandTiles() {
        return this.landTileIds;
    }
    // #region JSON and CLONE
    toJSON() {
        const json = {};
        json.biomeType = this.biomeType;
        json.landTileIds = this.landTileIds;
        return json;
    }
    static fromRuntimeJSON(json) {
        const biome = new RuntimeBiome();
        biome.biomeType = json.biomeType;
        if (!!json.landTileIds) {
            biome.landTileIds = json.landTileIds;
        }
        return biome;
    }
    static fromLibraryJSON(json) {
        const biome = new RuntimeBiome();
        biome.name = json.name;
        biome.biomeType = json.biomeType;
        biome.landTileIds = json.landTileIds;
        return biome;
    }
    clone() {
        const tempBiome = new RuntimeBiome();
        tempBiome.name = this.name;
        tempBiome.biomeType = this.biomeType;
        tempBiome.landTileIds = [];
        for (const tile of this.landTileIds) {
            tempBiome.landTileIds.push(tile);
        }
        return tempBiome;
    }
}
// export
export default RuntimeBiome;
