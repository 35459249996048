import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import TextBox from '../../GenericComponents/TextBox.tsx';

// GildedLands imports
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import { RequirementTypeEnum } from '../../../../../../../GildedLands/lib/Enums/Requirement.js';
import RequirementReturnComponent from '../Requirements/RequirementReturn.tsx';

const getRequirementInfo = (
  improvementType: ImprovementType,
  city: City,
  gameState: GameState
): React.ReactNode => {
  const data: React.ReactNode[] = [];
  const improvementInfo =
    ImprovementTypeMethods.getImprovementInfo(improvementType);
  if (
    improvementInfo.requirements.hasRequirementType(
      RequirementTypeEnum.TileIsCity
    )
  ) {
    const requirementReturn = ImprovementTypeMethods.canBuildImprovementOnTile(
      improvementType,
      gameState.getLandTileByTileId(city.centerTileLandtileId),
      gameState
    );
    data.push(renderRequirementReturnComponent(requirementReturn));
  } else {
    data.push(
      renderCityBuildInfo(improvementType, city, gameState, improvementInfo)
    );

    data.push(renderTileRequirementInfo(improvementType, city, gameState));
  }

  return <VerticalList data={data} />;
};

const renderRequirementReturnComponent = (
  requirementReturn: any
): React.ReactNode => {
  return <RequirementReturnComponent reqReturn={requirementReturn} />;
};

const renderCityBuildInfo = (
  improvementType: ImprovementType,
  city: City,
  gameState: GameState,
  improvementInfo: any
): React.ReactNode => {
  const canBuild = ImprovementTypeMethods.canBuildImprovementInCity(
    improvementType,
    city,
    gameState
  );

  if (canBuild.canBuild) {
    return (
      <TextBox
        key='available-tiles'
        title={null}
        content={`There are available tiles to build the '${improvementInfo.name}'.`}
      />
    );
  } else {
    return (
      <TextBox
        key='no-available-tiles'
        title={null}
        content={`There are no available tiles to build the '${improvementInfo.name}'.`}
      />
    );
  }
};

const renderTileRequirementInfo = (
  improvementType: ImprovementType,
  city: City,
  gameState: GameState
): React.ReactNode => {
  const tileItems = city.administeredTileLandtileIds.map((tileId) => {
    const landTile = gameState.getLandTileByTileId(tileId);
    const requirementReturn = ImprovementTypeMethods.canBuildImprovementOnTile(
      improvementType,
      landTile,
      gameState
    );

    return renderTileLineItem(
      tileId,
      landTile.mainBiomeType,
      requirementReturn
    );
  });

  return (
    <CenterTitle
      key='tile-requirement-info'
      title='Tile Requirement Info'
      padding={UIPadding.Short}
      backgroundData={{
        color: UIColor.HeaderBlue,
        opacity: UIOpacity.Header,
      }}
      startOpen={false}
      dropdown={
        <VerticalList
          data={tileItems}
          backgroundData={{
            color: UIColor.ListBlue,
            opacity: UIOpacity.List,
          }}
        />
      }
    />
  );
};

const renderTileLineItem = (
  tileId: number,
  biomeType: string,
  requirementReturn: any
): React.ReactNode => {
  return (
    <LineItemStandard
      key={tileId}
      title={biomeType}
      titleColor={UIColor.White}
      inlineDataFields={[]}
      dropdown={
        <VerticalList
          data={[<RequirementReturnComponent reqReturn={requirementReturn} />]}
        />
      }
      inlineFieldsAlignment='left'
    />
  );
};

export default getRequirementInfo;
