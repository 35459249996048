// external
import React, { useMemo, useContext } from 'react';

// internal
import { SimTileStyled } from './styles/SimTile.styled.js';
import { BiomeType } from '../../../../../../GildedLands/lib/Enums/Biome.js';
import TileImage from '../../Game/Map/TileImage.tsx';
import TileHexagon from '../../Game/Map/TileHexagon.tsx';

// context
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

function SimTile({ mainBiomeType, otherBiomeTypes, onTileClick }) {
  const hexagonSize = 100;
  const position = { x: 0, y: 0 };

  const { landTileToImage } = useContext(ImageContext);

  const tileImageUrl = useMemo(
    () => landTileToImage(mainBiomeType, [], 0),
    [mainBiomeType, landTileToImage]
  );

  return (
    <SimTileStyled
      className='sim-tile'
      onClick={onTileClick}
      position={position}
      hexagonSize={hexagonSize}
    >
      <TileImage
        hexagonSize={hexagonSize}
        url={tileImageUrl}
        amDimmed={false}
      />
      <TileHexagon hexagonSize={hexagonSize} tileId={0} />
    </SimTileStyled>
  );
}

export default SimTile;
