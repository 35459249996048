// Flat rate per good, targetable by shipping origin
import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class ImportDuty extends Tax {
    constructor(instanceId, ownerNationId, dutyAmountPerGood, isOnAllNations, excludedNationIds, includedNationIds) {
        super(instanceId, ownerNationId, TaxType.ImportDuty);
        this.dutyAmountPerGood = dutyAmountPerGood;
        this.isOnAllNations = isOnAllNations;
        this.excludedNationIds = excludedNationIds;
        this.includedNationIds = includedNationIds;
    }
    getCost(sourceCityInstanceId, gameState) {
        const sourceNationId = gameState.getCityByInstanceId(sourceCityInstanceId).administeringNationId;
        if (this.isOnAllNations) {
            if (this.excludedNationIds.includes(sourceNationId)) {
                return 0;
            }
        }
        else {
            if (!this.includedNationIds.includes(sourceNationId)) {
                return 0;
            }
        }
        return this.dutyAmountPerGood;
    }
    clone() {
        return new ImportDuty(this.instanceId, this.ownerNationId, this.dutyAmountPerGood, this.isOnAllNations, [...this.excludedNationIds], [...this.includedNationIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            dutyAmountPerGood: this.dutyAmountPerGood,
            isOnAllNations: this.isOnAllNations,
            excludedNationIds: [...this.excludedNationIds],
            includedNationIds: [...this.includedNationIds],
        };
    }
    static fromJSON(json) {
        return new ImportDuty(json.instanceId, json.ownerNationId, json.dutyAmountPerGood, json.isOnAllNations, [...json.excludedNationIds], [...json.includedNationIds]);
    }
}
export default ImportDuty;
