import ExperienceCurveInfo from './ExperienceCurveInfo.js';
import { ImprovementCalculation } from './ImprovementCalculation.js';
import RuntimeProduction from './RuntimeProduction.js';
class RuntimeCapacity {
    constructor(currentNumberOfWorkers, maxCapacity, production, appliedUpgradeIndices, productionAmountExperienceCurves, productionAmountCalculation, baseProductionQuality, productionQualityExperienceCurves, productionQualityCalculation, modifyProducer, modifyCity) {
        this.currentNumberOfWorkers = currentNumberOfWorkers;
        this.maxCapacity = maxCapacity;
        this.production = production;
        this.appliedUpgradeIndices = appliedUpgradeIndices;
        this.productionAmountExperienceCurves = productionAmountExperienceCurves;
        this.productionAmountCalculation = productionAmountCalculation;
        this.baseProductionQuality = baseProductionQuality;
        this.productionQualityExperienceCurves = productionQualityExperienceCurves;
        this.productionQualityCalculation = productionQualityCalculation;
        this.modifyProducer = modifyProducer;
        this.modifyCity = modifyCity;
    }
    clone() {
        return new RuntimeCapacity(this.currentNumberOfWorkers, this.maxCapacity, this.production.clone(), [...this.appliedUpgradeIndices], this.productionAmountExperienceCurves.map((curve) => curve.clone()), this.productionAmountCalculation.clone(), this.baseProductionQuality, this.productionQualityExperienceCurves.map((curve) => curve.clone()), this.productionQualityCalculation.clone(), this.modifyProducer.map((mod) => (Object.assign({}, mod))), this.modifyCity.map((mod) => (Object.assign({}, mod))));
    }
    toJSON() {
        return {
            currentNumberOfWorkers: this.currentNumberOfWorkers,
            maxCapacity: this.maxCapacity,
            production: this.production.toJSON(),
            appliedUpgradeIndices: [...this.appliedUpgradeIndices],
            productionAmountExperienceCurves: this.productionAmountExperienceCurves.map((curve) => curve.toJSON()),
            productionAmountCalculation: this.productionAmountCalculation.toJSON(),
            baseProductionQuality: this.baseProductionQuality,
            productionQualityExperienceCurves: this.productionQualityExperienceCurves.map((curve) => curve.toJSON()),
            productionQualityCalculation: this.productionQualityCalculation.toJSON(),
            modifyProducer: this.modifyProducer.map((mod) => (Object.assign({}, mod))),
            modifyCity: this.modifyCity.map((mod) => (Object.assign({}, mod))),
        };
    }
    static fromJSON(json) {
        return new RuntimeCapacity(json.currentNumberOfWorkers, json.maxCapacity, RuntimeProduction.fromJSON(json.production), json.appliedUpgradeIndices.map((index) => index), json.productionAmountExperienceCurves.map((curve) => ExperienceCurveInfo.fromJSON(curve)), ImprovementCalculation.fromJSON(json.productionAmountCalculation), json.baseProductionQuality, json.productionQualityExperienceCurves.map((curve) => ExperienceCurveInfo.fromJSON(curve)), ImprovementCalculation.fromJSON(json.productionQualityCalculation), json.modifyProducer.map((mod) => ({
            experience: mod.experience,
            amount: mod.amount,
        })), json.modifyCity.map((mod) => ({
            experience: mod.experience,
            amount: mod.amount,
        })));
    }
}
export default RuntimeCapacity;
