// HexMap.tsx
import React, {
  useMemo,
  useState,
  useCallback,
  forwardRef,
  useContext,
} from 'react';
import HexTile from './HexTile.tsx';
import Path from './Paths/Path.tsx';
import MapUnit from './Unit/MapUnit.tsx';
import River from './River.tsx';
import { getTilePosition } from './Utils/GetTilePosition.ts';
import { HexMapStyled } from './styles/HexMap.styled.js';

import { SelectedEntityContext } from '../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { InteractionContext } from '../../../contexts/GameData/InteractionContextProvider.tsx';
import { GameStateDataContext } from '../../../contexts/GameData/GameStateDataContextProvider.tsx';
import RuntimeLandTile from '../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import Road from './Road.tsx';

interface HexMapProps {
  tiles: RuntimeLandTile[];
  totalSize: {
    width: number;
    height: number;
  };
  mapTileSize: number;
  onMouseEnterTile: (id: number) => void;
  onMouseLeaveTile: (id: number) => void;
  onRightClickTile: (id: number) => void;
  onMouseUpOnTile: (id: number) => void;
}

const HexMapComponent: React.FC<HexMapProps> = ({
  tiles,
  totalSize,
  mapTileSize,
  onMouseEnterTile,
  onMouseLeaveTile,
  onRightClickTile,
  onMouseUpOnTile,
}) => {
  const { gameState } = useContext(GameStateDataContext)!;
  const { selectedUnit } = useContext(SelectedEntityContext)!;
  const { previewPath } = useContext(InteractionContext)!;

  const [hoveredUnit, setHoveredUnit] = useState<any>(null);

  const selectedUnitPath = useMemo(() => {
    if (!selectedUnit) return null;
    const unit = gameState.getUnitByInstanceId(selectedUnit.instanceId);
    if (!unit) return null;
    const pathData = [
      gameState.getLandTileByTileId(unit.tileId),
      ...unit.path.map((id: number) => gameState.getLandTileByTileId(id)),
    ];
    return (
      <Path
        tiles={pathData}
        adjustStartForUnit={true}
        mapTileSize={mapTileSize}
      />
    );
  }, [selectedUnit, gameState, mapTileSize]);

  const hoveredUnitPath = useMemo(() => {
    if (!hoveredUnit) return null;
    const unit = gameState.getUnitByInstanceId(hoveredUnit.instanceId);
    if (!unit) return null;
    const pathData = [
      gameState.getLandTileByTileId(unit.tileId),
      ...unit.path.map((id: number) => gameState.getLandTileByTileId(id)),
    ];
    return (
      <Path
        tiles={pathData}
        adjustStartForUnit={true}
        mapTileSize={mapTileSize}
      />
    );
  }, [hoveredUnit, gameState, mapTileSize]);

  const pathPreview = useMemo(() => {
    if (!previewPath) return null;
    const pathData = previewPath.map((tileId: number) =>
      gameState.getLandTileByTileId(tileId)
    );
    return (
      <Path
        tiles={pathData}
        adjustStartForUnit={false}
        mapTileSize={mapTileSize}
      />
    );
  }, [previewPath, gameState, mapTileSize]);

  const handleMouseEnterTile = useCallback(
    (id: number) => {
      onMouseEnterTile(id);
    },
    [onMouseEnterTile]
  );

  const handleMouseLeaveTile = useCallback(
    (id: number) => {
      onMouseLeaveTile(id);
    },
    [onMouseLeaveTile]
  );

  const handleRightClickTile = useCallback(
    (id: number) => {
      onRightClickTile(id);
    },
    [onRightClickTile]
  );

  const handleMouseUpOnTile = useCallback(
    (id: number) => {
      onMouseUpOnTile(id);
    },
    [onMouseUpOnTile]
  );

  return (
    <HexMapStyled className='hex-map'>
      <div className='map-area'>
        {tiles.map((tile) => {
          const tilePosition = getTilePosition(tile, mapTileSize);
          return (
            <HexTile
              key={tile.id}
              tile={tile}
              x={tilePosition.x}
              y={tilePosition.y}
              hexagonSize={mapTileSize}
              onMouseEnterCallback={handleMouseEnterTile}
              onMouseLeaveCallback={handleMouseLeaveTile}
              onMouseUpOnTile={handleMouseUpOnTile}
              onRightClickTile={handleRightClickTile}
            />
          );
        })}
      </div>
      <div className='river-area'>
        {tiles.map((tile) => {
          if (!tile.river) return null;
          const tilePosition = getTilePosition(tile, mapTileSize);
          return (
            <River
              key={tile.id}
              tile={tile}
              x={tilePosition.x}
              y={tilePosition.y}
              hexagonSize={mapTileSize}
            />
          );
        })}
      </div>
      <div className='road-area'>
        {tiles.map((tile) => {
          if (!tile.road) return null;
          const tilePosition = getTilePosition(tile, mapTileSize);
          return (
            <Road
              key={tile.id}
              tile={tile}
              x={tilePosition.x}
              y={tilePosition.y}
              hexagonSize={mapTileSize}
            />
          );
        })}
      </div>
      <div
        className='paths-area'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: totalSize.width,
          height: totalSize.height,
          pointerEvents: 'none',
          zIndex: 10,
        }}
      >
        {hoveredUnit && (
          <svg className='unit-path hovered-unit-path'>{hoveredUnitPath}</svg>
        )}
        {selectedUnit &&
          selectedUnit.instanceId !== hoveredUnit?.instanceId && (
            <svg className='unit-path selected-unit-path'>
              {selectedUnitPath}
            </svg>
          )}
        {pathPreview && (
          <svg className='unit-path path-preview'>{pathPreview}</svg>
        )}
      </div>
      <div className='units-area'>
        {gameState?.civilianUnits.map((unit: any) => {
          let tile = gameState.getLandTileByTileId(unit.tileId);
          if (!tile) return null;
          const tilePosition = getTilePosition(tile, mapTileSize);

          if (tile.amCityCenter) {
            tilePosition.y -= mapTileSize * 0.5;
            tilePosition.x += mapTileSize * 0.5;
          }

          if (unit.isMoving) {
            const nextTile = gameState.getLandTileByTileId(
              unit.currentDestinationTileId
            );
            if (nextTile) {
              const nextTilePosition = getTilePosition(nextTile, mapTileSize);
              tilePosition.x += (nextTilePosition.x - tilePosition.x) * 0.3;
              tilePosition.y += (nextTilePosition.y - tilePosition.y) * 0.3;
            }
          }

          return (
            <MapUnit
              key={unit.instanceId}
              unitInstanceId={unit.instanceId}
              hexagonSize={mapTileSize}
              position={tilePosition}
              onMouseEnterCallback={() => setHoveredUnit(unit)}
              onMouseLeaveCallback={() => setHoveredUnit(null)}
            />
          );
        })}
      </div>
    </HexMapStyled>
  );
};
export default React.memo(HexMapComponent);
