// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { OptionsPopupStyled } from './styles/OptionsPopup.styled.js';
import CenterTitle from './CenterTitle.tsx';
import { UIPadding } from '../Enums/UIEnums.ts';

const OptionsPopup = ({
  title,
  description,
  index,
  options,
  close,
}: {
  title: string | null;
  description: string | null;
  index: number;
  options: {
    name: string;
    onSelect: () => void;
  }[];
  close: () => void;
}) => {
  const onSelect = (option: { name: string; onSelect: () => void }) => {
    option.onSelect();
    close();
  };

  const specialOption = (text: string) => {
    switch (text) {
      case 'Cancel':
        return 'red';
      default:
        return 'blue';
    }
  };

  return (
    <OptionsPopupStyled
      className='options-popup'
      style={{ top: `${index * 10}px`, left: `${index * 10}px` }}
    >
      <div className='options-popup-closer'></div>
      <div className='options-popup-area'>
        {title && <CenterTitle title={title} padding={UIPadding.Tall} />}
        <div className='options-popup-content'>
          {description && (
            <div className='options-popup-description'>{description}</div>
          )}
          {options.map((option, index) => (
            <div
              className={`whole-button ${specialOption(option.name)}`}
              key={index}
              onClick={() => onSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </OptionsPopupStyled>
  );
};

export default OptionsPopup;
