class RealizedTaxItem {
    constructor(taxType, goodType, policyInstanceId, nationId, cityId, incomeToGovernment) {
        this.taxType = taxType;
        this.goodType = goodType;
        this.policyInstanceId = policyInstanceId;
        this.nationId = nationId;
        this.cityId = cityId;
        this.incomeToGovernment = incomeToGovernment;
    }
    clone() {
        return new RealizedTaxItem(this.taxType, this.goodType, this.policyInstanceId, this.nationId, this.cityId, this.incomeToGovernment);
    }
    toJSON() {
        return {
            taxType: this.taxType,
            goodType: this.goodType,
            policyInstanceId: this.policyInstanceId,
            nationId: this.nationId,
            cityId: this.cityId,
            incomeToGovernment: this.incomeToGovernment,
        };
    }
    static fromJSON(json) {
        return new RealizedTaxItem(json.taxType, json.goodType, json.policyInstanceId, json.nationId, json.cityId ? json.cityId : null, json.incomeToGovernment);
    }
}
export default RealizedTaxItem;
