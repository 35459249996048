import styled from 'styled-components';
import '../../../../styles/constants.css';

export const CenterButtonStyled = styled.div`
  position: relative;
  min-height: 30px;
  width: 100%;
  display: flex;
  padding: 10px;

  /* cursor */
  cursor: pointer;

  /* background */
  background-color: ${(props) => props.color};

  /* text */
  color: white;
  text-align: center;
  font-family: var(--header-font);
  font-weight: bold;
  font-size: 12px;

  /* text-align */
  align-items: center;
  justify-content: center;

  /* border */
  border-top: 1px solid var(--lol-gold);
  border-bottom: 1px solid var(--lol-gold);

  :hover {
    font-weight: bold;
    box-shadow: 0 0 10px white;
  }
`;
