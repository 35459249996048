import styled from 'styled-components';
import '../../../../styles/constants.css';

export const CitySidebarStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 5px;
  box-sizing: border-box;
  border-right: 1px solid white;

  .city-sidebar-button {
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid white;
    cursor: pointer;

    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;

    &:hover {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
    &.selected {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
  }
`;
