// external
import React, { useEffect } from 'react';

// components
import WaitlistComponent from './WaitlistComponent';

// css
import { WaitlistPageStyled } from './styles/WaitlistPage.styled.js';
import QuickPage from '../../UtilityComponents/QuickPage.tsx';

function WaitlistPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WaitlistPageStyled className='waitlist-page'>
      <QuickPage />
      <div className='main-content'>
        <WaitlistComponent />
      </div>
    </WaitlistPageStyled>
  );
}

export default WaitlistPage;
