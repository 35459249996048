// CONFIRM THAT THIS MATCHES THE SERVER SIDE

export const NEW_ROOM_REQUEST = 'new-room-request'; // Client requests a new room to be created
export const ROOMS_REQUEST = 'rooms-request'; // Client requests the current list of open rooms
export const JOIN_ROOM_REQUEST = 'join-room-request'; // Client requests to join a specific room
export const LEAVE_ROOM_REQUEST = 'leave-room-request'; // Client requests to leave the current room
export const TOGGLE_READY_REQUEST = 'toggle-ready-request'; // Client requests to toggle their ready state
export const FULL_ROOM_REPORT_REQUEST = 'full-room-report-request'; // Client requests a full room report (admin feature)
export const REALM_CHANGE_REQUEST = 'realm-change-request'; // Client requests to change the realm
export const SELECT_GAME_TYPE_REQUEST = 'select-game-type-request'; // Client requests to select the game type
export const LOAD_SAVED_GAME_REQUEST = 'load-saved-game-request'; // Client requests to load a saved game

export const GAME_STARTED_EVENT = 'game-started'; // Notify client of game start
export const REJOINED_GAME_EVENT = 'rejoined-game'; // Notify client they have rejoined a game
export const NOT_IN_GAME_EVENT = 'not-in-game'; // Notify client they are not in any game
export const ROOMS_UPDATE_EVENT = 'rooms-update'; // Update client with the latest rooms information
export const YOU_JOINED_ROOM_EVENT = 'you-joined-room'; // Notify client they have successfully joined a room
export const YOU_LEFT_ROOM_EVENT = 'you-left-room'; // Notify client they have left a room
export const SET_READY_EVENT = 'set-ready'; // Notify client of their ready state change
export const GAME_ENDED_EVENT = 'game-ended'; // Notify all clients that the game has ended
export const REALM_CHANGE_EVENT = 'realm-change'; // Notify all clients that the realm has changed
export const GAME_TYPE_SELECTED_EVENT = 'on-game-type-selected'; // Notify all clients that the game type has been selected
export const LOAD_SAVED_GAME_EVENT = 'load-saved-game'; // Notify all clients that a saved game has been loaded
