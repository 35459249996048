import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';

// GildedLands imports
import Experience from '../../../../../../../GildedLands/lib/Classes/Experience/Experience.js';

const getExperienceData = (
  experiences: Experience[],
  columns: number
): React.ReactNode => {
  const experienceData = experiences.map((experience, index) => (
    <LineItemStandard
      key={index}
      title={experience.experienceType}
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: {
            fieldName: '',
            textColor: UIColor.White,
            iconUrl: '',
            value: experience.amount.toFixed(0),
          },
          dropdown: null,
        },
      ]}
    />
  ));

  const columnData = Array.from({ length: columns }, () => []);
  experienceData.forEach((item, index) => {
    columnData[index % columns].push(item);
  });

  return (
    <div style={{ display: 'flex' }}>
      {columnData.map((columnItems, colIndex) => (
        <div
          key={colIndex}
          style={{
            flex: 1,
            marginRight: colIndex < columns - 1 ? '50px' : '0',
          }}
        >
          <VerticalList data={columnItems} />
        </div>
      ))}
    </div>
  );
};

export default getExperienceData;
