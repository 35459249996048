import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';

// GildedLands imports
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { ImprovementCategory } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement.js';

const getExistingBuildingsData = (
  gameState: GameState,
  selectedCity: City,
  selectImprovement: (improvement: RuntimeImprovement) => void,
  setHighlightedTiles: (tileIds: number[]) => void
): React.ReactNode => {
  const data: React.ReactNode[] = [];

  Object.values(ImprovementCategory).forEach((category) => {
    let buildings = getBuildingsByCategory(category, selectedCity, gameState);

    if (buildings.length > 0) {
      data.push(
        renderCategorySection(
          category,
          buildings,
          selectImprovement,
          setHighlightedTiles
        )
      );
    }
  });

  return <VerticalList data={data} />;
};

const getBuildingsByCategory = (
  category: ImprovementCategory,
  selectedCity: City,
  gameState: GameState
): RuntimeImprovement[] => {
  return (
    selectedCity
      ?.getImprovements(gameState)
      .filter(
        (improvement) =>
          ImprovementTypeMethods.getImprovementInfo(improvement.improvementType)
            .category === category
      ) || []
  );
};

const renderCategorySection = (
  category: ImprovementCategory,
  buildings: RuntimeImprovement[],
  selectImprovement: (improvement: RuntimeImprovement) => void,
  setHighlightedTiles: (tileIds: number[]) => void
): React.ReactNode => {
  const lineItems = buildings.map((building) =>
    getExistingBuildingLineItem(
      building,
      selectImprovement,
      setHighlightedTiles
    )
  );

  return (
    <CenterTitle
      key={category}
      title={category.toString()}
      padding={UIPadding.Short}
      backgroundData={{
        color: UIColor.HeaderBlue,
        opacity: UIOpacity.Header,
      }}
      dropdown={
        <VerticalList
          key={`${category}-list`}
          data={lineItems}
          backgroundData={{
            color: UIColor.ListBlue,
            opacity: UIOpacity.List,
          }}
        />
      }
    />
  );
};

const getExistingBuildingLineItem = (
  building: RuntimeImprovement,
  selectImprovement: (improvement: RuntimeImprovement) => void,
  setHighlightedTiles: (tileIds: number[]) => void
): React.ReactNode => {
  const inlineDataFields = generateBuildingInlineFields(building);

  return (
    <LineItemStandard
      key={building.instanceId}
      title={building.name}
      titleColor={UIColor.White}
      inlineDataFields={inlineDataFields}
      onMainClick={() => selectImprovement(building)}
      onMouseEnter={() => setHighlightedTiles([building.landTileId])}
      onMouseLeave={() => setHighlightedTiles([])}
    />
  );
};

const generateBuildingInlineFields = (building: RuntimeImprovement): any[] => {
  const inlineDataFields = [];

  if (building.capacity) {
    const capacityValue = `${building.capacity.currentNumberOfWorkers} / ${building.capacity.maxCapacity}`;

    inlineDataFields.push({
      fieldData: {
        fieldName: 'Cap.',
        textColor: UIColor.White,
        iconUrl: null,
        value: capacityValue,
      },
      dropdown: null,
    });
  }

  return inlineDataFields;
};

export default getExistingBuildingsData;
