import React from 'react';

// Components
import { UIColor } from '../../Enums/UIEnums.ts';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';

// GildedLands imports
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import Person from '../../../../../../../GildedLands/lib/Classes/Person/Person.js';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import { TimeMethods } from '../../../../../../../GildedLands/lib/Enums/Time.js';

const getPersonPersonalData = (
  person: Person,
  city: City,
  gameState: GameState
): React.ReactNode => {
  const inlineDataFields = generateInlineDataFields(person, city, gameState);

  return (
    <LineItemStandard
      title='Personal Info'
      titleColor={UIColor.White}
      inlineDataFields={inlineDataFields}
      inlineFieldsAlignment='even'
    />
  );
};

const generateInlineDataFields = (
  person: Person,
  city: City,
  gameState: GameState
): any[] => {
  const ageField = generateAgeField(person, gameState);
  const lifeExpectancyField = generateLifeExpectancyField(person);
  const nutritionField = generateNutritionField(person);

  return [
    { fieldData: ageField, dropdown: null },
    { fieldData: lifeExpectancyField, dropdown: null },
    { fieldData: nutritionField, dropdown: null },
  ];
};

const generateAgeField = (person: Person, gameState: GameState): any => {
  const ageValue = TimeMethods.getAgeInYearsFromBirthday(
    person.dayBorn,
    gameState.days
  );
  return {
    fieldName: 'Age',
    textColor: UIColor.White,
    iconUrl: '',
    value: ageValue.toFixed(0),
  };
};

const generateLifeExpectancyField = (person: Person): any => {
  return {
    fieldName: 'Life Expectancy',
    textColor: UIColor.White,
    iconUrl: '',
    value: `${person.lifeExpectancy.toFixed(0)} Years`,
  };
};

const generateNutritionField = (person: Person): any => {
  return {
    fieldName: 'Nutrition',
    textColor: UIColor.White,
    iconUrl: '',
    value: `${person.nutrition.toFixed(0)}%`,
  };
};

export default getPersonPersonalData;
