import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class ChangeImprovementProductionMessage extends ClientMessage {
    constructor(messageId, senderUserId, improvementId, goodType) {
        super(NetworkProtocol.ChangeImprovementProduction, messageId, senderUserId);
        this.improvementId = improvementId;
        this.goodType = goodType;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { improvementId: this.improvementId, goodType: this.goodType });
    }
    static fromJSON(json) {
        return new ChangeImprovementProductionMessage(json.messageId, json.senderUserId, json.improvementId, json.goodType);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('create improvement message validating: ', this.toJSON());
        return (this.improvementId !== undefined &&
            this.goodType !== undefined &&
            super.validate());
    }
}
