class Responsibility {
    constructor(name) {
        this.name = name;
    }
    doesExperienceMatch(str) {
        return this.name.includes(str);
    }
    doesResponsibilityMatch(responsibility) {
        for (let i = 0; i < this.name.length; i++) {
            if (this.name[i] === 'All')
                return true;
            if (this.name[i] !== responsibility.name[i])
                return false;
        }
        return true;
    }
    ///
    clone() {
        return new Responsibility([...this.name]);
    }
    toJSON() {
        return {
            name: [...this.name],
        };
    }
    static fromJSON(json) {
        return new Responsibility([...json.name]);
    }
}
export default Responsibility;
