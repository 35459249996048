import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const TopPanelStyled = styled.div`
  width: 100%;
  height: 100%;

  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;

  .city-title {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--header-font);
    font-size: 1rem;
    color: white;
  }
`;
