import React from 'react';

// Components
import {
  UIPadding,
  UIColor,
  UIOpacity,
  UITextSize,
} from '../../Enums/UIEnums.ts';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import TextBox from '../../GenericComponents/TextBox.tsx';

// GildedLands imports
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import {
  GoodCategory,
  GoodType,
  GoodTypeMethods,
} from '../../../../../../../GildedLands/lib/Enums/Good.js';
import RealizedTaxItem from '../../../../../../../GildedLands/lib/Classes/EconomicData/TaxTracking/TaxItem.js';
import { TaxType } from '../../../../../../../GildedLands/lib/Enums/Tax.js';

// Other imports
import Person from '../../../../../../../GildedLands/lib/Classes/Person/Person.js';

const getPersonProductionData = (
  productionByPerson: {
    person: Person;
    improvementId: number;
    cityId: number;
    goodType: GoodType;
    quality: number;
    productionAmount: number;
    productionValue: number;
    valueToProducer: number;
    taxes: RealizedTaxItem[];
  },
  gameState: GameState
): React.ReactNode => {
  const improvement = gameState.getImprovementByInstanceId(
    productionByPerson.improvementId
  );

  // Gather all inline data fields
  const inlineDataFields = generateInlineDataFields(
    productionByPerson,
    improvement
  );

  // Conditionally add the Good Quality Field
  if (
    GoodTypeMethods.getGoodInfo(productionByPerson.goodType).category !==
    GoodCategory.Food
  ) {
    inlineDataFields.push({
      fieldData: generateGoodQualityField(productionByPerson, improvement),
      dropdown: null,
    });
  }

  // Return the LineItemStandard component
  return (
    <LineItemStandard
      title={null}
      titleColor={UIColor.White}
      inlineDataFields={inlineDataFields}
      inlineFieldsAlignment='even'
    />
  );
};

const generateInlineDataFields = (
  productionByPerson: any,
  improvement: any
): any[] => {
  const producesField = {
    fieldName: 'Produces',
    textColor: UIColor.White,
    iconUrl: '',
    value: productionByPerson.goodType.toString(),
  };

  const incomeField = {
    fieldName: 'Income',
    textColor: UIColor.White,
    iconUrl: '',
    value: productionByPerson.productionValue.toFixed(2),
  };

  const productionTaxPaidField = {
    fieldName: 'Prod. Tax',
    textColor: UIColor.White,
    iconUrl: '',
    value: calculateProductionTax(productionByPerson),
  };

  const amountProducedField = generateAmountProducedField(
    productionByPerson,
    improvement
  );

  return [
    { fieldData: producesField, dropdown: null },
    { fieldData: incomeField, dropdown: null },
    { fieldData: productionTaxPaidField, dropdown: null },
    { fieldData: amountProducedField, dropdown: null },
  ];
};

const calculateProductionTax = (productionByPerson: any): string => {
  return productionByPerson.taxes
    .filter((tax: RealizedTaxItem) => tax.taxType === TaxType.ProductionTax)
    .reduce(
      (acc: number, curr: RealizedTaxItem) => acc + curr.incomeToGovernment,
      0
    )
    .toFixed(2);
};

const generateAmountProducedField = (
  productionByPerson: any,
  improvement: any
) => {
  const unitsHoverContent = (
    <TextBox
      content={`${improvement.capacity.baseProductionAmount} base amount from ${
        improvement.improvementType
      } and ${(
        productionByPerson.productionAmount -
        improvement.capacity.baseProductionAmount
      ).toFixed(1)} from ${productionByPerson.person.name}'s experience`}
      padding={UIPadding.Medium}
      textSize={UITextSize.Medium}
    />
  );

  return {
    fieldName: 'Units',
    textColor: UIColor.White,
    iconUrl: '',
    value: productionByPerson.productionAmount.toFixed(0),
    hoverData: {
      content: unitsHoverContent,
      width: 200,
      height: null,
    },
  };
};

const generateGoodQualityField = (
  productionByPerson: any,
  improvement: any
) => {
  const goodHoverContent = (
    <TextBox
      content={`${improvement.quality} base quality from ${
        improvement.improvementType
      } and ${productionByPerson.quality - improvement.quality} quality from ${
        productionByPerson.person.name
      }`}
      padding={UIPadding.Medium}
      textSize={UITextSize.Medium}
    />
  );

  return {
    fieldName: 'Quality',
    textColor: UIColor.White,
    iconUrl: '',
    value: productionByPerson.quality.toFixed(0),
    hoverData: {
      content: goodHoverContent,
      width: 200,
      height: null,
    },
  };
};

export default getPersonProductionData;
