import styled from 'styled-components';

export const HexTileStyled = styled.div.attrs(({ position, hexagonSize }) => ({
  style: {
    top: `${position.y}px`,
    left: `${position.x}px`,
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: none;
  position: absolute;
`;
