import React from 'react';
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import getExperienceData from '../Experience/Experience.tsx';
import CivilianUnit from '../../../../../../../GildedLands/lib/Classes/Unit/CivilianUnit.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import { TimeMethods } from '../../../../../../../GildedLands/lib/Enums/Time';
import ImageBox from '../../GenericComponents/ImageBox.tsx';

const getCivilianUnitInfo = (
  unit: CivilianUnit,
  gameState: GameState
): React.ReactNode => {
  const data: React.ReactNode[] = [];

  data.push(
    <CenterTitle
      title={unit.name}
      padding={UIPadding.Tall}
      backgroundData={{
        color: UIColor.Gold,
        opacity: UIOpacity.Full,
      }}
    />
  );

  data.push(
    <ImageBox
      url={'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/settler.png'}
    />
  );

  const abilitiesSection = renderAbilities(unit);
  if (abilitiesSection) {
    data.push(renderHeader('Abilities'));
    data.push(abilitiesSection);
  }

  if (unit.isMoving) {
    const movementSection = renderMovement(unit);
    if (movementSection) {
      data.push(renderHeader('Movement'));
      data.push(movementSection);
    }
  }

  if (unit.experience.length > 0) {
    data.push(renderHeader('Experience'));
    data.push(getExperienceData(unit.experience, 1)); // Use getExperienceData
  }

  return <VerticalList data={data} />;
};

// Function to render a section header
const renderHeader = (title: string): React.ReactNode => (
  <CenterTitle
    title={title}
    padding={UIPadding.Short}
    backgroundData={{
      color: UIColor.HeaderBlue,
      opacity: UIOpacity.Header,
    }}
  />
);

// Function to render unit attributes
const renderUnitAttributes = (unit: CivilianUnit): React.ReactNode => {
  const unitAttributes = [
    { fieldName: 'Day Created', value: unit.dayCreated.toString() },
  ];

  return (
    <VerticalList
      data={unitAttributes.map((attr, index) => (
        <LineItemStandard
          key={`unit-attr-${index}`}
          title={attr.fieldName}
          titleColor={UIColor.White}
          inlineDataFields={[
            {
              fieldData: {
                fieldName: attr.fieldName,
                textColor: UIColor.White,
                value: attr.value,
              },
              dropdown: null,
            },
          ]}
        />
      ))}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.List,
      }}
    />
  );
};

// Function to render abilities section
const renderAbilities = (unit: CivilianUnit): React.ReactNode | null => {
  const abilities = [];

  if (unit.isNationFounder)
    abilities.push({ fieldName: 'Nation Founder', value: 'Yes' });
  if (unit.isCityFounder)
    abilities.push({ fieldName: 'City Founder', value: 'Yes' });
  if (unit.isBuilder) abilities.push({ fieldName: 'Builder', value: 'Yes' });
  if (unit.hasShips) abilities.push({ fieldName: 'Has Ships', value: 'Yes' });

  if (abilities.length === 0) return null;

  return (
    <VerticalList
      data={abilities.map((ability, index) => (
        <LineItemStandard
          key={`ability-${index}`}
          title={ability.fieldName}
          inlineDataFields={null}
        />
      ))}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.List,
      }}
    />
  );
};

const renderMovement = (unit: CivilianUnit): React.ReactNode => {
  if (!unit.isMoving || unit.travelTimes.length === 0) return null;

  const timeToNextTile = unit.timeToNextTile();
  const timeToFinalDestination = unit.timeToFinalDestination();

  const timeToNextTileString = Math.floor(timeToNextTile) + ' days';
  const timeToFinalDestinationString =
    Math.floor(timeToFinalDestination) + ' days';

  const movementAttributes = [
    {
      fieldName: 'Next Tile',
      value: timeToNextTileString,
    },
    {
      fieldName: 'Final Destination',
      value: timeToFinalDestinationString,
    },
  ];

  return (
    <VerticalList
      data={movementAttributes.map((attr, index) => (
        <LineItemStandard
          key={`movement-attr-${index}`}
          title={null}
          titleColor={UIColor.White}
          inlineDataFields={[
            {
              fieldData: {
                fieldName: attr.fieldName,
                textColor: UIColor.White,
                value: attr.value,
              },
              dropdown: null,
            },
          ]}
        />
      ))}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.List,
      }}
    />
  );
};

export default getCivilianUnitInfo;
