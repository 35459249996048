import styled from 'styled-components';
import '../../../../styles/constants.css';

export const CenterTitleStyled = styled.div`
  position: relative;
  width: 100%;

  .center-title-main-content {
    position: relative;
    font-family: var(--header-font);
    font-size: 12px;
    font-weight: bold;
    padding: ${(props) => props.padding}px;
    overflow: hidden;
    border-radius: ${(props) =>
      props.roundedCorners ? 'var(--ui-border-radius)' : '0px'};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.align};
  }

  .center-title-content-arrow {
    display: inline;
    font-size: 8px;
    text-align: left;
  }

  .center-title-title-text {
    display: inline;
  }

  .dropdown-content {
    position: relative;
  }
`;
