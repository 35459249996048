import styled from 'styled-components';

export const NationListStyled = styled.div`
  border: 1px solid white;
  padding: 5px;
  position: relative;

  .add-nation-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .nation-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .nation-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .nation-list-title {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    margin: 10px 0;
  }
`;
