// Graph.tsx

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// CSS
import { GraphStyled } from './styles/Graph.styled.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IDataPoint {
  x: number;
  y: number;
}

interface IGraphDataset {
  label: string;
  data: (number | IDataPoint)[];
  borderColor?: string;
  backgroundColor?: string;
  pointRadius?: number;
}

interface IGraphProps {
  labels: string[]; // X-axis labels (not strictly needed for linear scales, but can still provide)
  datasets: IGraphDataset[];
  title?: string;
  options?: object;
}

const Graph: React.FC<IGraphProps> = ({ labels, datasets, title, options }) => {
  const data = {
    labels, // Not strictly required if using linear scale, but harmless
    datasets: datasets.map((ds) => ({
      label: ds.label,
      data: ds.data,
      borderColor: ds.borderColor || 'white',
      backgroundColor: ds.backgroundColor || 'black',
      fill: false,
      tension: 0.1,
      pointRadius: ds.pointRadius !== undefined ? ds.pointRadius : 0,
      hitRadius: 10,
    })),
  };

  const defaultOptions = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: 'white',
        },
      },
      title: {
        display: !!title,
        text: title || '',
        color: 'white',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        title: {
          display: true,
          text: '',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        min: 0,
      },
    },
  };

  return (
    <GraphStyled>
      <Line data={data} options={options || defaultOptions} />
    </GraphStyled>
  );
};

export default Graph;
