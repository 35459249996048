import styled from 'styled-components';
import '../../../../styles/constants.css';

export const ImprovementBuilderPageStyled = styled.div`
  .improvement-builder-content {
    position: absolute;
    top: var(--game-ui-margin);
    left: var(--game-ui-margin);
    width: calc(100% - var(--game-ui-margin) * 2);
    height: calc(100% - var(--game-ui-margin) * 2);
    pointer-events: auto;
    overflow-y: hidden;
  }

  .improvement-info-list {
    max-height: 100%;
    overflow-y: auto;
  }

  .improvement-info-line-items {
  }
`;
