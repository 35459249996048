// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// internal context

// css
import { TopPanelStyled } from './styles/TopPanel.styled.js';
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

// lol

const TopPanel = () => {
  const { cityViewDefault } = useContext(ImageContext);

  return (
    <TopPanelStyled className='city-view' background={cityViewDefault}>
      <div className='city-title'>
        <h1>Battle Simulation</h1>
      </div>
    </TopPanelStyled>
  );
};

export default TopPanel;
