import styled from 'styled-components';

export const EnumListStyled = styled.div`
  padding: 5px;
  position: relative;

  .enum-list-title {
    display: inline-block;
  }

  .add-enum-button {
    position: absolute;
    // top: 5px;
    right: 5px;
  }

  .enum-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .enum-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
`;
