import React, { useState, useContext, useEffect, useCallback } from 'react';

// context
import { GameStateDataContext } from './GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from './GameStateLogicContextProvider.tsx';
import { SelectedEntityContext } from './SelectedEntityContextProvider.tsx';
import { SelectedLandtileSetContext } from './SelectedLandtileContextProvider.tsx';
import { ScreenModeContext } from '../ScreenAndMap/ScreenModeContextProvider.tsx';
import { HighlightedTileContext } from '../ScreenAndMap/HighlightedTileContextProvider.tsx';
import { PopupDataContext } from '../PopupsAndNotifications/PopupDataContextProvider.tsx';
import { ProductionPopupDataContext } from '../PopupsAndNotifications/SelectProductionPopupContextProvider.tsx';
// not using this now but will use it to center things in the future probably

// gl
import City from '../../../../../GildedLands/lib/Classes/City/City.js';
import { ImprovementType } from '../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import { UIColor } from '../../components/UI/Enums/UIEnums.ts';
import { RequirementTypeMethods } from '../../../../../GildedLands/lib/Enums/Requirement';
import { GoodType } from '../../../../../GildedLands/lib/Enums/Good';
import RuntimeImprovement from '../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement';

const InteractionContextProvider = ({ children }) => {
  // context
  const { gameState, userId } = useContext(GameStateDataContext);
  const {
    foundCity,
    buildImprovement,
    moveUnit: moveUnitLogic,
  } = useContext(GameStateLogicContext);
  const {
    selectedCity,
    setSelectedCity,
    selectedUnit,
    setSelectedUnit,
    selectedRuntimeImprovement,
    setSelectedRuntimeImprovement,
    selectedImprovementTypeInfo,
    setSelectedImprovementTypeInfo,
  } = useContext(SelectedEntityContext);

  const setSelectedTileIndex = useContext(SelectedLandtileSetContext);

  // const { setCenteredTilePosition } = useContext(MapMovementDataContext);
  const { setBorderedTiles, setHighlightedTiles } = useContext(
    HighlightedTileContext
  );

  const {
    screenMode,
    setScreenMode,
    actionMode,
    setActionMode,
    ScreenMode,
    ActionMode,
  } = useContext(ScreenModeContext);

  const { addPopup, setBottomInfo } = useContext(PopupDataContext);
  const { setProductionPopup } = useContext(ProductionPopupDataContext);
  const [previewPath, setPreviewPath] = useState<number[] | null>(null);

  // #region Actions

  const handleFoundCity = useCallback(
    (tileIndex: number, founderInstanceId: number) => {
      if (
        !City.canFoundCityOnTile(
          gameState.getLandTileByTileId(tileIndex),
          gameState.getNationByControllerUserId(userId),
          gameState
        )
      ) {
        addPopup({
          title: 'Cannot found city here',
          description: 'This tile is not suitable for founding a city.',
          options: [
            {
              name: 'Cancel',
              color: UIColor.EarthRed,
              onSelect: () => {
                setActionMode(ActionMode.Default);
              },
            },
          ],
        });
        return;
      }
      addPopup({
        title: 'Found City',
        description: 'Do you want to build a city here?',
        options: [
          {
            name: 'Build City',
            color: UIColor.Blue,
            onSelect: () => {
              setSelectedUnit(null);
              foundCity(tileIndex, founderInstanceId);
              setActionMode(ActionMode.Default);
            },
          },
          {
            name: 'Cancel',
            color: UIColor.EarthRed,
            onSelect: () => {
              setActionMode(ActionMode.Default);
            },
          },
        ],
      });
    },
    [gameState, userId, foundCity, addPopup, setActionMode]
  );

  const determineIfNeedToChooseProduction = useCallback(
    (tileId: number) => {
      const info = ImprovementTypeMethods.getImprovementInfo(
        selectedImprovementTypeInfo
      );
      if (!info.capacity) {
        buildImprovement(tileId, selectedImprovementTypeInfo, null);
        setScreenMode(ScreenMode.CityScreen);
        setActionMode(ActionMode.Default);
        setSelectedImprovementTypeInfo(null);
        // setCenteredTilePosition(
        //   gameState.getLandTileByTileId(selectedCity.centerTileLandtileId)
        // );
        return;
      }
      const possibleProduction = info.capacity.possibleProduction.filter(
        (production) => {
          const requirementReturn = RequirementTypeMethods.checkRequirement(
            production.requirements,
            selectedImprovementTypeInfo,
            gameState.getLandTileByTileId(tileId),
            gameState
          );
          return requirementReturn.isMet;
        }
      );
      if (possibleProduction.length === 0) {
        setScreenMode(ScreenMode.CityScreen);
        // setCenteredTilePosition(
        //   gameState.getLandTileByTileId(selectedCity.centerTileLandtileId)
        // );
        setActionMode(ActionMode.Default);
        addPopup({
          title: 'No possible production',
          description: 'There are no possible production for this improvement.',
          options: [
            {
              name: 'Okay',
              color: UIColor.EarthRed,
              onSelect: () => {},
            },
          ],
        });
        console.error('no possible production');

        return;
      }
      if (possibleProduction.length === 1) {
        buildImprovement(
          tileId,
          selectedImprovementTypeInfo,
          possibleProduction[0].goodType
        );
        setScreenMode(ScreenMode.CityScreen);
        setActionMode(ActionMode.Default);
        setSelectedImprovementTypeInfo(null);
        // setCenteredTilePosition(
        //   gameState.getLandTileByTileId(selectedCity.centerTileLandtileId)
        // );
      } else {
        setProductionPopup({
          tileIndex: tileId,
          improvementType: selectedImprovementTypeInfo,
          onSelect: (goodType: GoodType) => {
            buildImprovement(tileId, selectedImprovementTypeInfo, goodType);
            setSelectedImprovementTypeInfo(null);
          },
        });
      }
    },
    [
      gameState,
      userId,
      buildImprovement,
      setScreenMode,
      // setCenteredTilePosition,
      setActionMode,
      setProductionPopup,
      selectedImprovementTypeInfo,
    ]
  );

  const handleBuildImprovement = useCallback(() => {
    console.log('handleBuildImprovement');
    if (!selectedCity || !gameState || !selectedImprovementTypeInfo) {
      console.error('No city, game state, or improvement type info selected');
      return;
    }

    const canBuild = ImprovementTypeMethods.canBuildImprovementInCity(
      selectedImprovementTypeInfo,
      selectedCity,
      gameState
    );

    // shoulnd't happen - shouldn't have been able to click the button
    if (!canBuild.canBuild) console.error('this shouldnt have been clickable');

    if (canBuild.tileIds.length === 1) {
      determineIfNeedToChooseProduction(canBuild.tileIds[0]);
    } else if (canBuild.tileIds.length > 1) {
      setScreenMode(ScreenMode.Map);
      setActionMode(ActionMode.PickingTileForImprovement);
      setHighlightedTiles(canBuild.tileIds);
      setBottomInfo({
        visible: true,
        title: 'Build Improvement',
        description: `Select a highlighted tile to build a ${selectedImprovementTypeInfo}.`,
      });
    }
  }, [
    selectedCity,
    gameState,
    userId,
    selectedImprovementTypeInfo,
    buildImprovement,
    setScreenMode,
    setActionMode,
    setHighlightedTiles,
    setSelectedImprovementTypeInfo,
    determineIfNeedToChooseProduction,
  ]);

  const openCityScreen = useCallback(
    (tileIndex: number) => {
      setScreenMode(ScreenMode.CityScreen);
      setSelectedTileIndex(tileIndex);
      // setCenteredTilePosition({
      //   x: gameState.getLandTileByTileId(tileIndex).x,
      //   y: gameState.getLandTileByTileId(tileIndex).y,
      // });

      const city = gameState.getCityByInstanceId(
        gameState.getLandTileByTileId(tileIndex).cityInstanceId
      );
      setSelectedCity(city);
      setHighlightedTiles(city.administeredTileLandtileIds);
    },
    [
      gameState,
      userId,
      setScreenMode,
      setSelectedTileIndex,
      // setCenteredTilePosition,
      setSelectedCity,
    ]
  );

  const closeCityScreen = useCallback(() => {
    setScreenMode(ScreenMode.Map);
    setSelectedTileIndex(null);
    // setCenteredTilePosition(null);
    setSelectedCity(null);
    setSelectedRuntimeImprovement(null);
    setSelectedImprovementTypeInfo(null);
    setHighlightedTiles(null);
  }, [
    setScreenMode,
    setSelectedTileIndex,
    // setCenteredTilePosition,
    setSelectedCity,
    setSelectedRuntimeImprovement,
    setSelectedImprovementTypeInfo,
  ]);

  const moveUnit = useCallback(
    (tileIndex: number) => {
      if (!gameState) {
        console.error('No game state defined.');
        return;
      }
      if (selectedUnit) {
        moveUnitLogic(selectedUnit.instanceId, tileIndex);
      }
    },
    [gameState, selectedUnit, moveUnitLogic]
  );

  const setMoveMode = useCallback(() => {
    if (!selectedUnit) {
      console.error('No unit selected');
      return;
    }
    setActionMode(ActionMode.PickingTileForUnitMovement);
    setBottomInfo({
      visible: true,
      title: 'Move Unit',
      description: `Select a tile to move the unit to.`,
    });
  }, [setActionMode, selectedUnit, setBottomInfo]);

  // #endregion

  // #region Triggers

  useEffect(() => {
    if (!selectedImprovementTypeInfo) {
      setHighlightedTiles(null);
      return;
    }

    if (!selectedCity && !gameState) return;

    const canBuild = ImprovementTypeMethods.canBuildImprovementInCity(
      selectedImprovementTypeInfo,
      selectedCity,
      gameState
    );

    setHighlightedTiles(canBuild.tileIds);
    console.log(canBuild.tileIds);
  }, [selectedImprovementTypeInfo]);

  // #endregion

  // #region key press

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (screenMode === ScreenMode.Map) {
        switch (event.key) {
          case 'c':
            setActionMode(ActionMode.BuildCity);
            break;
          case 'm':
            if (!!selectedUnit) {
              setMoveMode();
            }
            break;
          case 'Escape':
            setActionMode(ActionMode.Default);
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [screenMode, setActionMode, selectedUnit]);

  // #endregion

  // #region tile click

  const handleTileClick = useCallback(
    (tileIndex: number) => {
      if (!gameState) {
        console.error('No game state defined.');
        return;
      }
      switch (screenMode) {
        case ScreenMode.Map:
          switch (actionMode) {
            case ActionMode.BuildCity:
              handleFoundCity(tileIndex, selectedUnit.instanceId);
              break;
            case ActionMode.PickingTileForUnitMovement:
              moveUnit(tileIndex);
              setActionMode(ActionMode.Default);
              setBottomInfo(null);
              break;
            case ActionMode.PickingTileForImprovement:
              addPopup({
                title: 'Build Improvement',
                description: `Do you want to build a ${selectedImprovementTypeInfo} here?`,
                options: [
                  {
                    name: 'Build Improvement',
                    color: UIColor.Blue,
                    onSelect: () => {
                      determineIfNeedToChooseProduction(tileIndex);
                    },
                  },
                  {
                    name: 'Cancel',
                    color: UIColor.EarthRed,
                    onSelect: () => {
                      setActionMode(ActionMode.Default);
                    },
                  },
                ],
              });
              setHighlightedTiles(null);
              setBottomInfo(null);
              break;
            case ActionMode.Default:
              if (selectedUnit) {
                setSelectedUnit(null);
              }
              const runtimeLandTile = gameState.getLandTileByTileId(tileIndex);
              if (
                runtimeLandTile.cityInstanceId !== null &&
                runtimeLandTile.amCityCenter
              ) {
                openCityScreen(tileIndex);
              }
              break;
            default:
              console.log(
                'No action mode set or action not recognized: ',
                ActionMode[actionMode]
              );
          }
          break;
        case ScreenMode.CityScreen:
          switch (actionMode) {
            case ActionMode.Default:
              if (
                !!selectedRuntimeImprovement ||
                !!selectedImprovementTypeInfo
              ) {
                setSelectedRuntimeImprovement(null);
                setSelectedImprovementTypeInfo(null);
              } else {
                closeCityScreen();
              }
              break;
            default:
              console.log(
                'No action mode set or action not recognized: ',
                ActionMode[actionMode]
              );
          }
          break;
        case ScreenMode.EconomyScreen:
          break;
        default:
          console.log('No screen mode set or screen mode not recognized');
      }
    },
    [
      gameState,
      userId,
      foundCity,
      actionMode,
      screenMode,
      selectedRuntimeImprovement,
      selectedImprovementTypeInfo,
      selectedCity,
    ]
  );

  const handleTileRightClick = useCallback(
    (tileIndex: number) => {
      console.log('right clicked tile');
      if (!gameState) {
        console.error('No game state defined.');
        return;
      }
      switch (screenMode) {
        case ScreenMode.Map:
          moveUnit(tileIndex);
          break;
        default:
          break;
      }
    },
    [gameState, screenMode, selectedUnit]
  );

  const handleTileEnter = useCallback(
    (tileIndex: number) => {
      if (!gameState) {
        console.error('No game state defined.');
        return;
      }
      switch (screenMode) {
        case ScreenMode.Map:
          switch (actionMode) {
            case ActionMode.PickingTileForUnitMovement:
              if (!!selectedUnit) {
                console.log('setting preview path');
                setPreviewPath((prev) => {
                  const path = selectedUnit.findPath(
                    gameState.getLandTileByTileId(selectedUnit.tileId),
                    gameState.getLandTileByTileId(tileIndex),
                    gameState
                  );
                  console.log(path);
                  return path;
                });
              }
              break;
            case ActionMode.PickingGoodTypeForImprovement:
              break;
            default:
            // setSelectedTileIndex((prev) => {
            //   return tileIndex;
            // });
            // break;
          }
          break;
        default:
          break;
      }
    },
    [gameState, actionMode, screenMode]
  );

  const handleTileExit = useCallback(
    (tileIndex: number) => {
      if (!gameState) {
        console.error('No game state defined.');
        return;
      }
      switch (screenMode) {
        case ScreenMode.Map:
          // setSelectedTileIndex((prev) => {
          //   if (prev === tileIndex) {
          //     return null;
          //   }
          //   return tileIndex;
          // });
          break;
        default:
          break;
      }
    },
    [gameState, screenMode]
  );

  // #endregion

  return (
    <InteractionContext.Provider
      value={{
        onClickTile: handleTileClick,
        onRightClickTile: handleTileRightClick,
        onMouseTileEnter: handleTileEnter,
        onMouseTileExit: handleTileExit,
        handleFoundCity,
        handleBuildImprovement,
        determineIfNeedToChooseProduction,
        openCityScreen,
        setMoveMode,
        previewPath,
      }}
    >
      {children}
    </InteractionContext.Provider>
  );
};

interface InteractionContextType {
  onClickTile: (tileIndex: number) => void;
  onRightClickTile: (tileIndex: number) => void;
  onMouseTileEnter: (tileIndex: number) => void;
  onMouseTileExit: (tileIndex: number) => void;
  handleFoundCity: (tileIndex: number, founderInstanceId: number) => void;
  handleBuildImprovement: () => void;
  determineIfNeedToChooseProduction: (tileIndex: number) => void;
  openCityScreen: (tileIndex: number) => void;
  setMoveMode: () => void;
  previewPath: number[] | null;
}

const InteractionContext = React.createContext<InteractionContextType | null>(
  null
);

export { InteractionContextProvider, InteractionContext };
