import styled from 'styled-components';
import '../../../../styles/constants.css';

export const FlagshipSelectionBoxStyled = styled.div`
  position: relative;
  width: 300px;
  height: 500px;
  position: relative;
  transition: transform 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    transform: scale(1.02);

    .generic-background {
      .background-color {
        background-color: var(--ui-blue-4);
      }
    }
  }

  .selection-box-text-container {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 10;
  }

  .selection-box-title {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: var(--header-font);
    text-align: center;
  }

  .selection-box-text {
    font-size: 1rem;
    font-family: var(--body-font);
    text-align: center;
  }

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    -webkit-mask: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .interior-image {
    width: 100%;
    height: 100%;
    background-image: url(${({ background }) => background});
    background-size: cover;
    background-position: center;
  }
`;
