// external
import React, { useState, useEffect } from 'react';
import { ImprovementType } from '../../../../../GildedLands/lib/Enums/Improvement';
import { GoodType } from '../../../../../GildedLands/lib/Enums/Good';

const PropductionPopupDataContextProvider = ({ children }) => {
  // Normal Middle Popups
  const [productionPopup, setProductionPopup] = useState<{
    tileIndex: number;
    improvementType: ImprovementType;
    onSelect: (goodType: GoodType) => void;
  }>(null);

  const value: ProductionPopupDataContextType = {
    productionPopup,
    setProductionPopup,
  };

  return (
    <ProductionPopupDataContext.Provider value={value}>
      {children}
    </ProductionPopupDataContext.Provider>
  );
};

interface ProductionPopupDataContextType {
  productionPopup: {
    tileIndex: number;
    improvementType: ImprovementType;
    onSelect: (goodType: GoodType) => void;
  };
  setProductionPopup: (productionPopup: {
    tileIndex: number;
    improvementType: ImprovementType;
    onSelect: (goodType: GoodType) => void;
  }) => void;
}

const ProductionPopupDataContext =
  React.createContext<ProductionPopupDataContextType | null>(null);

export { PropductionPopupDataContextProvider, ProductionPopupDataContext };
