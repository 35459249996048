import { ExperienceType } from './Experience.js';
import { GoodCategory, GoodSubCategory, GoodType, GoodTypeMethods, } from './Good.js';
var ResponsibilityType;
(function (ResponsibilityType) {
    ResponsibilityType["Admin"] = "Admin";
})(ResponsibilityType || (ResponsibilityType = {}));
var AdminResponsibilityType;
(function (AdminResponsibilityType) {
    AdminResponsibilityType["Tax"] = "Tax";
    AdminResponsibilityType["Education"] = "Education";
})(AdminResponsibilityType || (AdminResponsibilityType = {}));
var TaxResponsibilityType;
(function (TaxResponsibilityType) {
    TaxResponsibilityType["Production"] = "Production";
    TaxResponsibilityType["Consumption"] = "Consumption";
    TaxResponsibilityType["Shipping"] = "Shipping";
})(TaxResponsibilityType || (TaxResponsibilityType = {}));
class ResponsibilityMethods {
    static subcategoriesForResponsibility(str) {
        // #region Goods
        // good strings
        if (Object.values(GoodCategory).includes(str)) {
            return [
                'All',
                ...GoodTypeMethods.getCategoryInfo(str).subCategories,
            ];
        }
        if (Object.values(GoodSubCategory).includes(str)) {
            return [
                'All',
                ...GoodTypeMethods.getSubCategoryInfo(str)
                    .subCategories,
            ];
        }
        if (Object.values(GoodType).includes(str)) {
            return [];
        }
        // #endregion
        // #region Tax
        // tax strings
        if (Object.values(TaxResponsibilityType).includes(str)) {
            return ['All', ...Object.values(GoodCategory)];
        }
        // #endregion
        // #region Education
        // education strings
        if (Object.values(ExperienceType).includes(str)) {
            return [];
        }
        // #endregion
        switch (str) {
            // top level
            case 'Admin':
                return ['All', ...Object.values(AdminResponsibilityType)];
            case 'All':
                return [];
            case AdminResponsibilityType.Tax:
                return ['All', ...Object.values(TaxResponsibilityType)];
            case AdminResponsibilityType.Education:
                return ['All', ...Object.values(ExperienceType)];
            default:
                return null;
        }
    }
    static getTaxLoadBetweenCities(nation, taxCity, gameState) {
        const capital = gameState.getCityByInstanceId(nation.capitalCityId);
        let shipping = taxCity.instanceId === capital.instanceId
            ? 0
            : gameState.getShippingForGood(taxCity, capital);
        return this.taxLoadConstant + shipping;
    }
}
ResponsibilityMethods.taxLoadConstant = 0.5;
export { ResponsibilityType, AdminResponsibilityType, TaxResponsibilityType, ResponsibilityMethods, };
