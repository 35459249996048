import RuntimeCapacity from '../Classes/Improvement/RuntimeCapacity.js';
import { ImprovementCalculation } from '../Classes/Improvement/ImprovementCalculation.js';
import RuntimeProduction from '../Classes/Improvement/RuntimeProduction.js';
import RuntimeImprovement from '../Classes/Improvement/RuntimeImprovement.js';
import { GoodTypeMethods } from './Good.js';
import { ImprovementType } from './Improvement.js';
import getImprovementInfo from './ImprovementInfo.js';
import { RequirementTypeMethods, } from './Requirement.js';
import RuntimeKnowledge from '../Classes/Improvement/RuntimeKnowledge.js';
class ImprovementTypeMethods {
    static getImprovementInfo(improvementType) {
        return getImprovementInfo(improvementType);
    }
    static getNewRuntimeImprovement(improvementType, instanceId, ownerLandtileId, goodType, gameState) {
        const improvementInfo = getImprovementInfo(improvementType);
        const capacityInfo = improvementInfo.capacity;
        const knowledgeInfo = improvementInfo.knowledge;
        let finalCapacity = null;
        if (!!capacityInfo) {
            const production = capacityInfo.possibleProduction.find((p) => p.goodType === goodType);
            if (!production) {
                throw new Error(`ImprovementTypeMethods.getNewRuntimeImprovement: Production not found for ${goodType}`);
            }
            const runtimeProduction = RuntimeProduction.fromJSON(production);
            if (!runtimeProduction) {
                throw new Error(`ImprovementTypeMethods.getNewRuntimeImprovement: RuntimeProduction not found for ${goodType}`);
            }
            finalCapacity = new RuntimeCapacity(0, capacityInfo.maxCapacity, runtimeProduction, [], // appliedUpgradeIndices
            capacityInfo.productionAmountExperienceCurves.map((curve) => curve), ImprovementCalculation.fromJSON(capacityInfo.productionAmountCalculation), capacityInfo.baseProductionQuality, capacityInfo.productionQualityExperienceCurves.map((curve) => curve), ImprovementCalculation.fromJSON(capacityInfo.productionQualityCalculation), capacityInfo.modifyProducer, capacityInfo.modifyCity);
        }
        let finalKnowledge = [];
        if (!!knowledgeInfo) {
            finalKnowledge = knowledgeInfo.map((knowledge) => {
                const landTile = gameState.getLandTileByTileId(ownerLandtileId);
                const availableExperienceTypes = knowledge.availableExperienceTypes
                    .map((experienceType) => {
                    return {
                        experienceType: experienceType.experienceType,
                        requirements: experienceType.requirements,
                    };
                })
                    .filter((experienceType) => RequirementTypeMethods.checkRequirement(experienceType.requirements, improvementType, landTile, gameState).isMet);
                return new RuntimeKnowledge(knowledge.type, availableExperienceTypes[0].experienceType, knowledge.amount);
            });
        }
        return new RuntimeImprovement(instanceId, improvementType, improvementInfo.name, ownerLandtileId, finalCapacity, finalKnowledge);
    }
    static getCompletedProject(instanceId, landTileId, improvementType, gameState, goodType) {
        switch (improvementType) {
            case ImprovementType.CathedralProject:
                return this.getNewRuntimeImprovement(ImprovementType.Cathedral, instanceId, landTileId, goodType, gameState);
            default:
                throw new Error(`ImprovementTypeMethods.getCompletedProject: Improvement type not found for ${improvementType}`);
        }
    }
    static canBuildImprovementOnTile(improvementType, landTile, gameState) {
        let improvementInfo = ImprovementTypeMethods.getImprovementInfo(improvementType);
        let requirement = improvementInfo.requirements;
        let requirementReturn = RequirementTypeMethods.checkRequirement(requirement, improvementType, landTile, gameState);
        return requirementReturn;
    }
    static canBuildImprovementInCity(improvementType, city, gameState) {
        let buildableTileIds = [];
        for (let adminTileID of city.administeredTileLandtileIds) {
            const landTile = gameState.getLandTileByTileId(adminTileID);
            const canBuild = this.canBuildImprovementOnTile(improvementType, landTile, gameState);
            if (canBuild.isMet) {
                buildableTileIds.push(adminTileID);
            }
        }
        return {
            canBuild: buildableTileIds.length > 0,
            tileIds: buildableTileIds,
        };
    }
    static calculateProductionAmount(improvement, experience) {
        const goodInfo = GoodTypeMethods.getGoodInfo(improvement.capacity.production.goodType);
        const curves = improvement.capacity.productionAmountExperienceCurves.map((curve) => curve.clone());
        curves.push(...goodInfo.productionAmountExperienceCurves);
        return improvement.capacity.productionAmountCalculation.evaluate(improvement.capacity.production.goodType, curves, experience, improvement.capacity.production.improvementAffinity);
    }
    static calculateProductionQuality(improvement, experience) {
        const goodInfo = GoodTypeMethods.getGoodInfo(improvement.capacity.production.goodType);
        const curves = improvement.capacity.productionQualityExperienceCurves.map((curve) => curve.clone());
        curves.push(...goodInfo.productionQualityExperienceCurves);
        return improvement.capacity.productionQualityCalculation.evaluate(improvement.capacity.production.goodType, curves, experience, improvement.capacity.production.improvementAffinity);
    }
    static modifyProducer(person, city, improvement, gameState) {
        if (!improvement.capacity) {
            return;
        }
        improvement.capacity.modifyProducer.forEach((modifyProducer) => {
            person.addExperience(modifyProducer.experienceType, modifyProducer.amount);
        });
        improvement.capacity.modifyCity.forEach((modifyCity) => {
            city.addExperience(modifyCity.experienceType, modifyCity.amount);
        });
    }
    static endTurnEffect(improvement, gameState) {
        const improvementInfo = getImprovementInfo(improvement.improvementType);
        improvementInfo.endTurnEffect(improvement, gameState);
    }
}
export { ImprovementTypeMethods };
