import React from 'react';
import { AuthContextProvider } from './AdminAndNetworking/AuthContextProvider.tsx';
// import { ImprovementLibraryContextProvider } from './ImprovementLibraryContextProvider.tsx';
import { SocketContextProvider } from './AdminAndNetworking/SocketContextProvider.tsx';
import { SoundContextProvider } from './Media/SoundContextProvider.tsx';
import { ImageContextProvider } from './Media/ImageContextProvider.tsx';
import { AnimationContextProvider } from './Media/AnimationContextProvider.tsx';
import { EntityRefContextProvider } from './ScreenAndMap/EntityRefContextProvider.tsx';
import { GameStatusContextProvider } from './GameData/GameStatusContextProvider.tsx';
import { RoomsContextProvider } from './AdminAndNetworking/RoomsContextProvider.tsx';
import { ScreenSizeContextProvider } from './ScreenAndMap/ScreenSizeContextProvider.tsx';
import { GameElementSizeContextProvider } from './ScreenAndMap/GameElementSizeContextProvider.tsx';
import { StartingStatesContextProvider } from './GameData/StartingStatesContextProvider.tsx';
import { InteractionContextProvider } from './GameData/InteractionContextProvider.tsx';
import { HighlightedTileContextProvider } from './ScreenAndMap/HighlightedTileContextProvider.tsx';
import { SelectedEntityContextProvider } from './GameData/SelectedEntityContextProvider.tsx';
import { ScreenModeContextProvider } from './ScreenAndMap/ScreenModeContextProvider.tsx';
import { NotificationFlagsDataContextProvider } from './PopupsAndNotifications/NotificationFlagsDataContextProvider.tsx';
import { NotificationFlagsTriggerContextProvider } from './PopupsAndNotifications/NotificationFlagsTriggerContextProvider.tsx';
import { PopupDataContextProvider } from './PopupsAndNotifications/PopupDataContextProvider.tsx';
import { PropductionPopupDataContextProvider } from './PopupsAndNotifications/SelectProductionPopupContextProvider.tsx';
import { GameStateDataContextProvider } from './GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContextProvider } from './GameData/GameStateLogicContextProvider.tsx';
import { SelectedLandtileContextProvider } from './GameData/SelectedLandtileContextProvider.tsx';
import { MapViewContextProvider } from './ScreenAndMap/MapViewContextProvider.tsx';

const composeProviders = (providers, children) => {
  return providers.reduceRight(
    (kids, Parent) => <Parent>{kids}</Parent>,
    children
  );
};

const providers = [
  // need nothing - data holders, etc.
  SoundContextProvider,
  SelectedEntityContextProvider, // needs nothing
  EntityRefContextProvider, // needs nothing
  GameStatusContextProvider, // needs nothing
  ScreenModeContextProvider, // needs nothing
  HighlightedTileContextProvider, // needs nothing
  GameStateDataContextProvider, // needs nothing
  // ImprovementLibraryContextProvider, // needs nothing
  ScreenSizeContextProvider, // needs nothing
  NotificationFlagsDataContextProvider, // needs nothing
  PopupDataContextProvider, // needs nothing
  PropductionPopupDataContextProvider, // needs nothing
  SelectedLandtileContextProvider, // needs nothing
  MapViewContextProvider, // needs nothing
  // socket
  SocketContextProvider, // needs nothing
  // just need socket
  AuthContextProvider, // needs socket
  RoomsContextProvider, // needs socket

  // player
  GameStateLogicContextProvider, // needs socket, game status, popup, map movement data
  // popups

  // dependant
  ImageContextProvider, // needs player
  AnimationContextProvider, // needs entity ref
  GameElementSizeContextProvider, // needs screen size
  InteractionContextProvider, // needs player, MapMovementData, MapMovementLogic, Screen Mode, popups
  StartingStatesContextProvider, // needs CardLibrary and Auth
  NotificationFlagsTriggerContextProvider, // needs NotificationFlagsData, Player, ScreenMode
];

const MasterContextProvider = ({ children }) => {
  return composeProviders(providers, children);
};

export default MasterContextProvider;
