// external
import React, { useState, useRef } from 'react';

// components
import InlineDataField from './InlineDataField.tsx';
import HoverBox, { IHoverBox } from './HoverBox.tsx';
import { IInlineDataField } from './InlineDataField.tsx';
import { UIColor } from '../Enums/UIEnums.ts';

// css
import { LineItemStandardStyled } from './styles/LineItemStandard.styled.js';
import GenericBackground from './Background.tsx';

interface ILineItemStandard {
  title?: string;
  titleColor?: UIColor;
  inlineDataFields?: {
    fieldData: IInlineDataField;
    dropdown: React.ReactNode;
  }[];
  dropdown?: React.ReactNode;
  onMainClick?: () => void;
  onDoubleClick?: () => void;
  hoverData?: IHoverBox;
  inlineFieldsAlignment?: 'left' | 'even' | 'table';
  selectorData?: {
    amSelected: boolean;
  };
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const LineItemStandard: React.FC<ILineItemStandard> = ({
  title = null,
  titleColor = UIColor.White,
  inlineDataFields = [],
  dropdown = null,
  onMainClick = null,
  onDoubleClick = null,
  hoverData = null,
  inlineFieldsAlignment = 'even',
  selectorData = null,
  onMouseEnter = null,
  onMouseLeave = null,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [activedropdown, setActivedropdown] = useState<boolean>(false);
  const [activeChildDropdown, setActiveChildDropdown] = useState<number | null>(
    null
  );
  const lineItemRefForFields = useRef<HTMLDivElement>(null);

  const hasInlineDropdown = !!inlineDataFields
    ? inlineDataFields.some((field) => !!field.dropdown)
    : false;
  const hasInlineHover = !!inlineDataFields
    ? inlineDataFields.some((field) => !!field.fieldData.hoverData)
    : false;

  const handleMainClick = () => {
    if (!!dropdown) {
      setActivedropdown((prev) => !prev);
      if (activeChildDropdown !== null) setActiveChildDropdown(null);
      if (!!onMainClick) console.error('onMainClick blocked by dropdownData');
    } else {
      if (!!onMainClick) onMainClick();
    }
  };

  const handleDoubleClick = () => {
    if (!!onDoubleClick) onDoubleClick();
  };

  const handleChildDropdownClick = (index: number) => {
    setActiveChildDropdown((prev) => (prev === index ? null : index));
    setActivedropdown(false);
  };

  const handleMouseEnter = (isTitle: boolean) => {
    if (onMouseEnter) onMouseEnter();
    if (hasInlineHover) {
      if (isTitle) {
        setIsHovered(true);
      }
    } else {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = (isTitle: boolean) => {
    if (onMouseLeave) onMouseLeave();
    if (hasInlineHover) {
      if (isTitle) {
        setIsHovered(false);
      }
    } else {
      setIsHovered(false);
    }
  };

  return (
    <LineItemStandardStyled
      className='line-item-standard'
      onMouseEnter={() => handleMouseEnter(false)}
      onMouseLeave={() => handleMouseLeave(false)}
      titleColor={titleColor}
      clickable={!!onMainClick || !!dropdown}
      inlineFieldsAlignment={inlineFieldsAlignment}
      hasTitle={!!title}
    >
      {/* main */}
      <div
        className='line-item-main-content'
        onClick={!hasInlineDropdown ? handleMainClick : undefined}
        onDoubleClick={handleDoubleClick}
        ref={lineItemRefForFields}
      >
        {!!title && (
          <div
            className='line-item-title-area'
            onClick={!!hasInlineDropdown ? handleMainClick : undefined}
            onMouseEnter={() => handleMouseEnter(true)}
            onMouseLeave={() => handleMouseLeave(true)}
          >
            {!!dropdown && (
              <div className='line-item-title-dropdown-arrow dropdown-arrow'>
                {activedropdown ? '\u25BC' : '\u25B6'}
                &nbsp;&nbsp;&nbsp;
              </div>
            )}
            {!!selectorData && (
              <div className='line-item-title-selector'>
                {selectorData.amSelected ? '☑' : '☐'}&nbsp;&nbsp;&nbsp;
              </div>
            )}
            <div className='line-item-title-text'>{title}</div>
          </div>
        )}
        {inlineDataFields &&
          inlineDataFields.map((field, index) => (
            <InlineDataField
              key={index}
              fieldName={field.fieldData.fieldName}
              textColor={field.fieldData.textColor}
              iconUrl={field.fieldData.iconUrl}
              value={field.fieldData.value}
              hoverData={field.fieldData.hoverData}
              onClick={() => {
                if (field.dropdown) {
                  handleChildDropdownClick(index);
                }
                if (field.fieldData.onClick) field.fieldData.onClick();
              }}
              isDropdown={!!field.dropdown}
              dropdownActive={activeChildDropdown === index}
            />
          ))}
      </div>

      {/* hover */}
      {isHovered && hoverData && (
        <HoverBox
          content={hoverData.content}
          parentRef={lineItemRefForFields}
          width={hoverData.width}
        />
      )}

      {/* dropdown */}
      {activedropdown && (
        <div className='line-item-dropdown-content'>
          <GenericBackground color={UIColor.Blue2} opacity={0.3} />
          {dropdown}
        </div>
      )}
      {activeChildDropdown !== null && (
        <div className='line-item-dropdown-content'>
          <GenericBackground color={UIColor.Blue2} opacity={0.3} />
          {inlineDataFields[activeChildDropdown].dropdown}
        </div>
      )}
    </LineItemStandardStyled>
  );
};

export default LineItemStandard;
export { ILineItemStandard };
