// external
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

// internal
// components
import DepartmentItem from './DepartmentItem.tsx';

// context
import { GameStateLogicContext } from '../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { CityAdministrationReportStyled } from './styles/CityAdministrationReport.styled.js';

// gl
import Department from '../../../../../../../GildedLands/lib/Classes/Administration/Department/Department/Department';
import CityAdministration from '../../../../../../../GildedLands/lib/Classes/Administration/CityAdministration';
import Eunuch from '../../../../../../../GildedLands/lib/Classes/Administration/Eunuch';

interface CityAdministrationProps {
  cityInfo: {
    instanceId: number;
    name: string;
  };
}

const CityAdministrationReport = ({ cityInfo }: CityAdministrationProps) => {
  // contexts
  const { saveCityAdministration } = useContext(GameStateLogicContext);
  const { gameState, userId } = useContext(GameStateDataContext);

  // states

  // #region administration and departments
  const [cityAdministration, setCityAdministration] =
    useState<CityAdministration | null>(null);

  useEffect(() => {
    if (!gameState) return;
    const playerNation = gameState.getNationByControllerUserId(userId);
    if (!playerNation)
      console.error('Player nation not found in department setter');

    const city = gameState.getCityByInstanceId(cityInfo.instanceId);
    if (!cityInfo) {
      console.error('Selected city not found in department setter');
    }

    // set departments
    setCityAdministration(city.governor);
    //
  }, [cityInfo]);
  // #endregion department

  // #region handlers
  const handleSave = () => {
    if (!cityAdministration) return;
    saveCityAdministration(cityAdministration, cityInfo.instanceId);
  };

  const handleSaveGovernor = (governor: Department) => {
    const temp = cityAdministration.clone();
    temp.governorsOffice = governor;
    setCityAdministration(temp);
  };

  const addDepartment = useCallback(
    (parentId: string) => {
      if (!cityAdministration) return;
      const tempAdmin = cityAdministration.clone();
      // parent
      let parent = null;
      if (!!parentId) {
        parent = tempAdmin.getDepartmentById(parentId);
      }
      // nation
      const playerNation = gameState.getNationByControllerUserId(userId);
      const dept = new Department(
        uuidv4(),
        'New Department',
        playerNation.id,
        cityInfo.instanceId,
        !!parent ? parent.instanceIdentifier : '',
        [],
        [],
        [new Eunuch([])],
        [],
        [],
        [],
        0
      );
      if (!!parent) {
        parent.subDepartments.push(dept);
      } else {
        throw new Error('Parent department not found');
      }
      setCityAdministration(tempAdmin);
    },
    [gameState, userId]
  );

  const deleteDepartment = (id: string) => {
    if (!cityAdministration) return;
    const tempAdmin = cityAdministration.clone();
    tempAdmin.governorsOffice.subDepartments =
      tempAdmin.governorsOffice.subDepartments.filter(
        (dept) => dept.instanceIdentifier !== id
      );
    setCityAdministration(tempAdmin);
  };

  // #endregion handlers

  if (!cityAdministration) return null;

  return (
    <CityAdministrationReportStyled>
      <div className='main-content-header'>
        <div className='main-content-header-title'>{cityInfo?.name}</div>
        <button className='save-button' onClick={handleSave}>
          Save
        </button>
      </div>
      <div className='departments'>
        <div className='departments-header'>
          <div className='departments-header-title'>Governors Office</div>
        </div>
        <div className='governors-office'>
          <DepartmentItem
            key={cityAdministration?.governorsOffice.instanceIdentifier}
            department={cityAdministration?.governorsOffice}
            parent={null}
            saveDepartment={handleSaveGovernor}
            addDepartment={addDepartment}
            deleteDepartment={deleteDepartment}
          />
        </div>
      </div>
    </CityAdministrationReportStyled>
  );
};

export default CityAdministrationReport;
