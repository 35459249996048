import styled from 'styled-components';
import '../../../../styles/constants.css';

export const WaitlistComponentStyled = styled.div`
  h1 {
    text-align: center;
    font-family: var(--header-font);
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: white;
  }

  .waitlist-wrapper {
    margin: 0 auto;
    padding: 20px;
    display: block;
    width: 300px;
  }

  .waitlist-wrapper input {
    width: 100%;
    display: block;
    margin: 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 40px; // Increased height
    text-align: center; // Center text
    font-family: var(--body-font);
    font-size: 16px;
  }

  .input-error {
    font-size: 14px;
    color: red;
  }
`;
