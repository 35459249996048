// SoldierExperiencePage.tsx

import React, { useEffect } from 'react';
import { SoldierExperiencePageStyled } from './styles/SoldierExperiencePage.styled.js';
import QuickPage from '../../UtilityComponents/QuickPage.tsx';
import { ScreenModeContext } from '../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';
import ExperienceCurveItem from './ExperienceCurveItem.tsx';
import { ExperienceCurveType } from '../../../../../../GildedLands/lib/Enums/Experience';
import { ExperienceMethods } from '../../../../../../GildedLands/lib/Enums/Experience';

const SoldierExperiencePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setScreenMode, ScreenMode } = React.useContext(ScreenModeContext);

  useEffect(() => {
    setScreenMode(ScreenMode.NotInGame);
  }, []);

  const formationExperienceCurve = ExperienceMethods.getExperienceCurve(
    ExperienceCurveType.Formation
  );
  const attackExperienceCurve = ExperienceMethods.getExperienceCurve(
    ExperienceCurveType.Attack
  );
  const defenseExperienceCurve = ExperienceMethods.getExperienceCurve(
    ExperienceCurveType.Defense
  );
  const shockExperienceCurve = ExperienceMethods.getExperienceCurve(
    ExperienceCurveType.Shock
  );

  return (
    <SoldierExperiencePageStyled>
      <QuickPage />
      <div className='soldier-experience-content'>
        <div className='soldier-experience-info-list ui-gold'>
          <div className='soldier-experience-line-items'>
            <ExperienceCurveItem experienceCurve={formationExperienceCurve} />
            <ExperienceCurveItem experienceCurve={attackExperienceCurve} />
            <ExperienceCurveItem experienceCurve={defenseExperienceCurve} />
            <ExperienceCurveItem experienceCurve={shockExperienceCurve} />
          </div>
        </div>
      </div>
    </SoldierExperiencePageStyled>
  );
};

export default SoldierExperiencePage;
