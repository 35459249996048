// external
import React, { useState, useEffect, useContext } from 'react';

// comps
import Background, { IGenericBackground } from './Background.tsx';
import { UIPadding, UITextSize } from '../Enums/UIEnums.ts';

// css
import { TextBoxStyled } from './styles/TextBox.styled.js';

interface ITextBox {
  title?: string;
  content?: string;
  padding?: number;
  textSize?: UITextSize;
  roundedCorners?: boolean;
  background?: IGenericBackground;
}

const TextBox: React.FC<ITextBox> = ({
  title = null,
  content = null,
  padding = UIPadding.Short,
  textSize = UITextSize.Medium,
  roundedCorners = false,
  background = null,
}) => {
  let titleTextSize = `${parseInt(textSize) - 2}px`;
  let contentTextSize = `${parseInt(textSize)}px`;

  return (
    <TextBoxStyled
      className='text-box'
      padding={padding}
      roundedCorners={roundedCorners}
      titleTextSize={titleTextSize}
      contentTextSize={contentTextSize}
    >
      {!!background && (
        <Background color={background.color} opacity={background.opacity} />
      )}
      {!!title && (
        <div className='text-box-title'>{title}&nbsp;&nbsp;&nbsp;</div>
      )}
      {!!content && <div className='text-box-content'>{content}</div>}
    </TextBoxStyled>
  );
};

export default TextBox;
export { ITextBox };
