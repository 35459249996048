// external
import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
// lol

// internal
// components
import TileHexagon from '../Game/Map/TileHexagon.tsx';
import TileImage from '../Game/Map/TileImage.tsx';
// context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { GameElementSizeContext } from '../../contexts/ScreenAndMap/GameElementSizeContextProvider.tsx';

//css
import { BiomeTypeSelectorStyled } from './styles/BiomeTypeSelector.styled.js';
import { BiomeType } from '../../../../../GildedLands/lib/Enums/Biome';

function BiomeTypeSelector({ biomeType, biomeTypeSelected, onClick }) {
  const { enumsToTile } = useContext(ImageContext);
  const { biomeTypeSelectorSize } = useContext(GameElementSizeContext);
  const url = useMemo(() => enumsToTile(biomeType), [biomeType]);

  //#endregion

  return (
    <BiomeTypeSelectorStyled
      className='land-type-selector'
      onClick={onClick}
      height={biomeTypeSelectorSize * 2}
      width={biomeTypeSelectorSize * Math.sqrt(3)}
      title={BiomeType[biomeType]}
    >
      <div
        className={
          'visual' + (biomeTypeSelected === biomeType ? ' selected' : '')
        }
      >
        <TileImage
          hexagonSize={biomeTypeSelectorSize}
          url={url}
          amDimmed={false}
        />
        <TileHexagon tileId={null} hexagonSize={biomeTypeSelectorSize} />
      </div>
    </BiomeTypeSelectorStyled>
  );
}

export default BiomeTypeSelector;
