class CharacterTrait {
    constructor(characterType, amount) {
        this.characterType = characterType;
        this.amount = amount;
    }
    clone() {
        return new CharacterTrait(this.characterType, this.amount);
    }
    toJSON() {
        return {
            characterType: this.characterType,
            amount: this.amount,
        };
    }
    static fromJSON(json) {
        return new CharacterTrait(json.characterType, json.amount);
    }
}
export default CharacterTrait;
