import { ServerMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import GameState from '../../Game/GameState.js';
export default class ServerSendingGamestateForRejoin extends ServerMessage {
    constructor(recipientUserId, gameState) {
        super(NetworkProtocol.ServerSendingGamestateForRejoin, recipientUserId);
        this.gameState = gameState;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { gameState: this.gameState.toJSONForPlayer(this.recipientUserId) });
    }
    static fromJSON(json) {
        return new ServerSendingGamestateForRejoin(json.recipientUserId, GameState.fromJSON(json.gameState));
    }
    // check that all fields in the message are valid
    validate() {
        return this.recipientUserId != null && this.gameState != null;
    }
}
