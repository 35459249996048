import { BiomeTypeMethods } from '../../../Enums/Biome.js';
import TileResource from './TileResource.js';
class RuntimeLandTile {
    constructor(id, x, y, mainBiomeType, allBiomeTypes, river, road, imagePickerNumber, resources, improvementInstanceIds, cityInstanceId, amCityCenter, visible, discovered) {
        // resources
        this.resources = [];
        // city
        this.improvementInstanceIds = [];
        // trade routes
        this.tradeRouteInstanceIds = [];
        this.id = id;
        this.x = x;
        this.y = y;
        this.mainBiomeType = mainBiomeType;
        this.allBiomeTypes = [...allBiomeTypes];
        this.river = river;
        this.road = road;
        this.imagePickerNumber = imagePickerNumber;
        this.resources = resources;
        this.improvementInstanceIds = [...improvementInstanceIds];
        this.cityInstanceId = cityInstanceId;
        this.amCityCenter = amCityCenter;
        this.visible = visible;
        this.discovered = discovered;
    }
    // #region Utility Methods
    // neighbors
    // neighbors
    findNeighbors(realm) {
        const mapWidth = realm.realmLayout.width;
        const mapHeight = realm.realmLayout.height;
        const isEvenRow = this.y % 2 === 0;
        let upperLeft = { x: this.x + (isEvenRow ? -1 : 0), y: this.y - 1 };
        let upperRight = { x: this.x + (isEvenRow ? 0 : 1), y: this.y - 1 };
        let left = { x: this.x - 1, y: this.y };
        let right = { x: this.x + 1, y: this.y };
        let lowerLeft = { x: this.x + (isEvenRow ? -1 : 0), y: this.y + 1 };
        let lowerRight = { x: this.x + (isEvenRow ? 0 : 1), y: this.y + 1 };
        // delete vars
        // top row
        if (this.y === 0) {
            upperLeft = null;
            upperRight = null;
        }
        // bottom row
        if (this.y === mapHeight - 1) {
            lowerLeft = null;
            lowerRight = null;
        }
        // left side
        if (this.x === 0) {
            left = null;
            if (isEvenRow) {
                upperLeft = null;
                lowerLeft = null;
            }
        }
        // right side
        if (this.x === mapWidth - 1) {
            right = null;
            if (!isEvenRow) {
                upperRight = null;
                lowerRight = null;
            }
        }
        const neighbors = [];
        if (!!upperLeft)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(upperLeft.x, upperLeft.y)]);
        if (!!upperRight)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(upperRight.x, upperRight.y)]);
        if (!!left)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(left.x, left.y)]);
        if (!!right)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(right.x, right.y)]);
        if (!!lowerLeft)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(lowerLeft.x, lowerLeft.y)]);
        if (!!lowerRight)
            neighbors.push(realm.landTiles[realm.getTileIdFromXY(lowerRight.x, lowerRight.y)]);
        return neighbors;
    }
    getNeighborByPosition(position, realm) {
        const mapWidth = realm.realmLayout.width;
        const mapHeight = realm.realmLayout.height;
        const isEvenRow = this.y % 2 === 0;
        let neighborX;
        let neighborY;
        switch (position) {
            case 0: // Top-Left
                neighborX = this.x + (isEvenRow ? -1 : 0);
                neighborY = this.y - 1;
                break;
            case 1: // Top-Right
                neighborX = this.x + (isEvenRow ? 0 : 1);
                neighborY = this.y - 1;
                break;
            case 2: // Right
                neighborX = this.x + 1;
                neighborY = this.y;
                break;
            case 3: // Bottom-Right
                neighborX = this.x + (isEvenRow ? 0 : 1);
                neighborY = this.y + 1;
                break;
            case 4: // Bottom-Left
                neighborX = this.x + (isEvenRow ? -1 : 0);
                neighborY = this.y + 1;
                break;
            case 5: // Left
                neighborX = this.x - 1;
                neighborY = this.y;
                break;
            default:
                return null;
        }
        // Check if neighbor coordinates are within bounds
        if (neighborX < 0 ||
            neighborX >= mapWidth ||
            neighborY < 0 ||
            neighborY >= mapHeight) {
            return null;
        }
        const neighborId = realm.getTileIdFromXY(neighborX, neighborY);
        return realm.landTiles[neighborId] || null;
    }
    // static
    static assignCoords(landTiles, realmLayout) {
        if (landTiles.length === 0) {
            console.log('No land tiles to assign coordinates');
            return;
        }
        const { width, height } = realmLayout;
        let index = 0;
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                if (index < landTiles.length) {
                    landTiles[index].x = x;
                    landTiles[index].y = y;
                    index++;
                }
                else {
                    return;
                }
            }
        }
    }
    assignResources() {
        const biomeType = this.mainBiomeType;
        const allBiomeTypes = this.allBiomeTypes;
        const resources = BiomeTypeMethods.getResourcesForBiomeType(biomeType, allBiomeTypes);
        this.resources = resources;
    }
    // #endregion Utility Methods
    // #region JSON and CLONE
    clone() {
        return new RuntimeLandTile(this.id, this.x, this.y, this.mainBiomeType, this.allBiomeTypes, this.river, this.road ? Object.assign({}, this.road) : null, this.imagePickerNumber, this.resources.map((resource) => resource.clone()), [...this.improvementInstanceIds], this.cityInstanceId, this.amCityCenter, this.visible, this.discovered);
    }
    toJSONForPlayer(discovered, visible) {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            // as string of enum
            mainBiomeType: discovered ? this.mainBiomeType : null,
            allBiomeTypes: discovered
                ? this.allBiomeTypes.map((biomeType) => biomeType)
                : [],
            river: this.river,
            road: this.road ? Object.assign({}, this.road) : null,
            imagePickerNumber: this.imagePickerNumber,
            resources: discovered
                ? this.resources.map((resource) => resource.toJSON())
                : [],
            improvementInstanceIds: discovered
                ? [...this.improvementInstanceIds]
                : [],
            cityInstanceId: discovered ? this.cityInstanceId : null,
            amCityCenter: discovered ? this.amCityCenter : false,
            visible: visible,
            discovered: discovered,
        };
    }
    toPerfectJSON() {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            // as string of enum
            mainBiomeType: this.mainBiomeType,
            allBiomeTypes: this.allBiomeTypes.map((biomeType) => biomeType),
            river: this.river,
            road: this.road ? Object.assign({}, this.road) : null,
            imagePickerNumber: this.imagePickerNumber,
            resources: this.resources.map((resource) => resource.toJSON()),
            improvementInstanceIds: [...this.improvementInstanceIds],
            cityInstanceId: this.cityInstanceId,
            amCityCenter: this.amCityCenter,
            visible: true,
            discovered: true,
        };
    }
    static fromRuntimeJSON(json) {
        const tile = new RuntimeLandTile(json.id, json.x, json.y, !!json.mainBiomeType ? json.mainBiomeType : null, json.allBiomeTypes.map((biomeType) => biomeType), json.river, json.road ? Object.assign({}, json.road) : null, json.imagePickerNumber, json.resources.map((resource) => TileResource.fromJSON(resource)), [...json.improvementInstanceIds], json.cityInstanceId, json.amCityCenter, json.visible, json.discovered);
        return tile;
    }
}
export default RuntimeLandTile;
