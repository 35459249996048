// external
import React from 'react';

// comps
import Background, { IGenericBackground } from './Background.tsx';

// contexts
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

// css
import { FlagshipSelectionBoxStyled } from './styles/FlagshipSelectionBox.styled.js';
import GenericBackground from './Background.tsx';
import { UIColor } from '../Enums/UIEnums.ts';

interface IFlagshipSelectionBox {
  title: string;
  text: string;
  image: string;
  onClick: () => void;
}

const FlagshipSelectionBox: React.FC<IFlagshipSelectionBox> = ({
  title,
  text,
  image,
  onClick,
}) => {
  const { getIKUrl } = React.useContext(ImageContext);

  return (
    <FlagshipSelectionBoxStyled
      className='flagship-selection-box ui-gold'
      background={image}
      onClick={onClick}
    >
      <GenericBackground color={UIColor.None} opacity={0.5} />
      <div className='image-container'>
        <div className='interior-image'></div>
        <div className='exterior-image'></div>
      </div>
      <div className='selection-box-text-container'>
        <div className='selection-box-title'>{title}</div>
        <p className='selection-box-text'>{text}</p>
      </div>
    </FlagshipSelectionBoxStyled>
  );
};

export default FlagshipSelectionBox;
export { IFlagshipSelectionBox };
