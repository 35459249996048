import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import NationalAdministration from '../../Administration/NationalAdministration.js';
export default class SaveNationalAdministrationMessage extends ClientMessage {
    constructor(messageId, senderUserId, admin) {
        super(NetworkProtocol.SaveNationalAdministration, messageId, senderUserId);
        this.nationalAdministration = admin;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { nationalAdministration: this.nationalAdministration.toJSON() });
    }
    static fromJSON(json) {
        return new SaveNationalAdministrationMessage(json.messageId, json.senderUserId, NationalAdministration.fromJSON(json.nationalAdministration));
    }
    // check that all fields in the message are valid
    validate() {
        return (this.senderUserId !== undefined &&
            this.nationalAdministration !== undefined &&
            super.validate());
    }
}
