import styled from 'styled-components';

export const ArmyPanelStyled = styled.div`
  position: relative;
  width: 500px;
  max-height: 100%;
  overflow-y: auto;

  .dug-in {
    padding: 0 10px;
  }
`;
