import FunctionInfo from './FunctionInfo.js';
export default class ExperienceCurveInfo {
    constructor(name, experienceInfluence, functionInfo) {
        this.name = name;
        this.experienceInfluence = experienceInfluence;
        this.functionInfo = functionInfo;
    }
    evaluate(experience) {
        const xValue = this.getMatchingExperienceAmount(experience);
        return this.functionInfo.evaluate(xValue);
    }
    getMatchingExperienceAmount(experience) {
        return this.experienceInfluence.reduce((acc, influence) => {
            const matchingExperience = experience.find((exp) => exp.experienceType === influence.experienceType);
            return (acc +
                (matchingExperience ? matchingExperience.amount * influence.amount : 0));
        }, 0);
    }
    // #region Clone and JSON
    clone() {
        return new ExperienceCurveInfo(this.name, this.experienceInfluence.map((influence) => (Object.assign({}, influence))), this.functionInfo.clone());
    }
    toJSON() {
        return {
            name: this.name,
            experienceInfluence: this.experienceInfluence.map((influence) => (Object.assign({}, influence))),
            functionInfo: this.functionInfo.toJSON(),
        };
    }
    static fromJSON(json) {
        return new ExperienceCurveInfo(json.name, json.experienceInfluence.map((influence) => (Object.assign({}, influence))), FunctionInfo.fromJSON(json.functionInfo));
    }
}
