import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const TileInfoStyled = styled.div`
  position: absolute;
  bottom: var(--game-ui-margin);
  right: var(--game-ui-margin);
  width: 200px;
  z-index: var(--overlay-z-index);

  border: 2px solid var(--lol-gold);
  border-radius: var(--ui-border-radius);
  overflow: hidden;

  .biome-type-title {
    position: relative;
    font-family: var(--body-font);
    font-size: 16px;
    padding: 10px;
  }

  .tile-info-title {
    font-family: var(--header-font);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
  }

  .tile-info-list {
    position: relative;
    padding: 10px;
  }

  .tile-info-list-item-title {
    display: inline-block;
    font-family: var(--body-font);
    font-size: 16px;
  }

  .tile-info-list-item-value {
    display: inline-block;
    font-size: 16px;
  }
`;
