// external
import React, { useEffect, useContext } from 'react';
import axios from 'axios';

// internal
// css
import { LobbyPageStyled } from './styles/LobbyPage.styled.js';
// components
import Navbar from '../Navbar/Navbar.jsx';
import RoomSelection from './Setup/Rooms/RoomSelection.tsx';
import ReadyButton from './ReadyButton.tsx';

// context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { SoundContext } from '../../contexts/Media/SoundContextProvider.tsx';
import { AuthContext } from '../../contexts/AdminAndNetworking/AuthContextProvider.tsx';
import { RoomsContext } from '../../contexts/AdminAndNetworking/RoomsContextProvider.tsx';
import { SocketContext } from '../../contexts/AdminAndNetworking/SocketContextProvider.tsx';
import { StartingStatesContext } from '../../contexts/GameData/StartingStatesContextProvider.tsx';

// UI components
import QuickPage from '../UtilityComponents/QuickPage.tsx';
import GenericBackground from '../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity, UIPadding } from '../UI/Enums/UIEnums.ts';
import CenterTitle from '../UI/GenericComponents/CenterTitle.tsx';
import CenterButton from '../UI/GenericComponents/CenterButton.tsx';
import LineItemStandard from '../UI/GenericComponents/LineItemStandard.tsx';

function LobbyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cityViewDefault } = useContext(ImageContext);
  const soundContext = useContext(SoundContext);
  const { myRoomID, leaveRoom, rooms } = useContext(RoomsContext);
  const { socket } = useContext(SocketContext);

  // States from context
  const { startingStates, selectedStartingState, setSelectedStartingState } =
    useContext(StartingStatesContext);

  // #region SAVED GAMES
  const [savedGames, setSavedGames] = React.useState([]);

  useEffect(() => {
    axios
      .get(`/savedgames/all/`)
      .then((res) => {
        console.log('Fetched saved games:', res.data);
        setSavedGames(res.data);
      })
      .catch((err) => {
        console.log('Error fetching saved games:', err);
      });
  }, []);
  // #endregion

  const handleSavedGameClick = (savedGameId) => {
    soundContext.playClick();
    // Send a request to the server to create a new room with the saved game
    socket.emit('LOAD_SAVED_GAME_REQUEST', { savedGameId });
  };

  return (
    <LobbyPageStyled background={cityViewDefault}>
      <QuickPage />
      <div className='lobby-main-content'>
        <div className='lobby-top-holder'>
          <div className='lobby-title'>Find a Game</div>
        </div>
        <div className='lobby-left-column ui-gold'>
          <GenericBackground color={UIColor.White} opacity={0.3} />
          <RoomSelection />
        </div>

        {myRoomID && (
          <div className='center-panel ui-gold'>
            <GenericBackground color={UIColor.White} opacity={0.3} />
            <div className='center-panel-content'>
              <CenterTitle
                title='Your Room'
                padding={UIPadding.Medium}
                backgroundData={{
                  color: UIColor.Gold,
                  opacity: 1,
                }}
              />
              <div className='players'>
                <CenterTitle
                  title='Players'
                  backgroundData={{
                    color: UIColor.HeaderBlue,
                    opacity: 0.3,
                  }}
                />
                {rooms
                  ?.find((room) => room.id === myRoomID)
                  ?.players.map((player) => (
                    <LineItemStandard
                      key={player.name}
                      title={player.name}
                      titleColor={UIColor.HeaderBlue}
                    />
                  ))}
              </div>
              <div className='states'>
                <CenterTitle
                  title='Your Starting States'
                  backgroundData={{
                    color: UIColor.HeaderBlue,
                    opacity: 0.3,
                  }}
                />
                {startingStates.length > 0 ? (
                  startingStates.map((stateObj) => (
                    <LineItemStandard
                      key={stateObj._id}
                      title={`State ID: ${stateObj._id}`}
                      selectorData={{
                        amSelected: selectedStartingState?._id === stateObj._id,
                      }}
                      onMainClick={() => {
                        soundContext.playClick();
                        setSelectedStartingState(stateObj);
                      }}
                    />
                  ))
                ) : (
                  <div>No starting states found.</div>
                )}
              </div>
              <div className='center-panel-buttons'>
                <ReadyButton
                  selectedRealmID={
                    selectedStartingState?._id || null
                  } /* If you still need this prop, rename accordingly */
                />
                <CenterButton
                  label='Leave Room'
                  onClick={leaveRoom}
                  color={UIColor.EarthRed}
                />
              </div>
            </div>
          </div>
        )}

        <div className='saved-games ui-gold'>
          <GenericBackground color={UIColor.White} opacity={0.3} />
          <CenterTitle
            title='Your Saved Games'
            padding={UIPadding.Medium}
            backgroundData={{
              color: UIColor.Gold,
              opacity: 1,
            }}
          />
          {savedGames.length > 0 ? (
            savedGames.map((game) => (
              <LineItemStandard
                key={game._id}
                title={`Saved on ${new Date(game.dateSaved).toLocaleString()}`}
                onMainClick={() => handleSavedGameClick(game._id)}
              />
            ))
          ) : (
            <div>No saved games found.</div>
          )}
        </div>
      </div>
    </LobbyPageStyled>
  );
}

export default LobbyPage;
