import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const ExistingImprovementPanelStyled = styled.div`
  position: absolute;
  top: calc(5% + 1.5 * var(--game-ui-margin));
  left: 320px;
  width: 800px;

  z-index: 100;

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: var(--dark-grey);
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    z-index: 101;
  }

  .runtime-improvements-panel-content {
    position: relative;
    max-height: calc(80vh - 2 * var(--game-ui-margin));
    overflow-y: auto;
  }
`;
