import React, { useMemo } from 'react';
const hoverSfx = require('../../sounds/light_click.mp3');
const clickSfx = require('../../sounds/heavy_click.mp3');

const SoundContextProvider = ({ children }) => {
  const hoverSound = useMemo(() => new Audio(hoverSfx), [hoverSfx]);
  const clickSound = useMemo(() => new Audio(clickSfx), [clickSfx]);

  const playHover = function () {
    hoverSound.play();
  };

  const playClick = function () {
    clickSound.play();
  };

  const test = 1;

  const value = {
    playHover,
    playClick,
    test,
  };

  return (
    <SoundContext.Provider value={value}>{children}</SoundContext.Provider>
  );
};

const SoundContext = React.createContext(null);

export { SoundContextProvider, SoundContext };
