import React from 'react';
import Graph from '../../GenericComponents/Graph';
import ExperienceCurveInfo from '../../../../../../../GildedLands/lib/Classes/Improvement/ExperienceCurveInfo';

interface ExperienceCurveGraphProps {
  name: string;
  curve: ExperienceCurveInfo;
  xValue: number;
  result: number;
}

const ExperienceCurveGraphComponent: React.FC<ExperienceCurveGraphProps> = ({
  name,
  curve,
  xValue,
  result,
}) => {
  const maxX = Math.max(xValue * 2, 300);
  const maxY = Math.max(result * 1.3, curve.functionInfo.evaluate(maxX));

  const sampleCount = 100;
  const xStep = maxX / (sampleCount - 1);

  // We'll store points as { x, y } objects for better control
  const curvePoints = [];
  for (let i = 0; i < sampleCount; i++) {
    const x = i * xStep;
    const y = curve.functionInfo.evaluate(x);
    curvePoints.push({ x, y });
  }

  // The labels can still be the x values formatted as strings, but with a linear scale,
  // labels are mostly ignored. However, we can just provide them for display.
  const labels = curvePoints.map((p) => p.x.toFixed(2));

  // Create a vertical line dataset
  // Two points at the same xValue: from (xValue, 0) to (xValue, maxY)
  const verticalLinePoints = [
    { x: xValue, y: 0 },
    { x: xValue, y: maxY },
  ];

  const datasets = [
    {
      label: 'Curve',
      // For Graph.tsx, since we now have {x,y} we need to ensure it passes these points directly.
      // We'll modify Graph to accept object data points.
      data: curvePoints,
      borderColor: 'white',
      backgroundColor: 'white',
      pointRadius: 0,
    },
    {
      label: 'Current Input Line',
      data: verticalLinePoints,
      borderColor: 'red',
      backgroundColor: 'red',
      pointRadius: 0,
    },
  ];

  const options = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        text: `Experience Curve: ${name}`,
        color: 'white',
      },
    },
    scales: {
      x: {
        type: 'linear' as const,
        title: {
          display: true,
          text: 'Experience',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        min: 0,
        max: maxX,
      },
      y: {
        title: {
          display: true,
          text: 'Output',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        min: 0,
        max: maxY,
      },
    },
  };

  return <Graph labels={labels} datasets={datasets} options={options} />;
};

export default ExperienceCurveGraphComponent;
