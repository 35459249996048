import React from 'react';

import { ExperienceItemStyled } from './styles/ExperienceItem.styled.js';
import RangeSlider from '../../UI/GenericComponents/RangeSlider.tsx';
import { UIColor, UIOpacity } from '../../UI/Enums/UIEnums.ts';
import SelectionButton from '../../UI/GenericComponents/SelectionButton.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';
import { ExperienceType } from '../../../../../../GildedLands/lib/Enums/Experience';
import Experience from '../../../../../../GildedLands/lib/Classes/Experience/Experience';

interface ExperienceItemProps {
  experience: Experience;
  handleExperienceChange: (index: number, field: string, value: any) => void;
  removeExperience: (index: number) => void;
  index: number;
}

function ExperienceItem({
  experience,
  handleExperienceChange,
  removeExperience,
  index,
}: ExperienceItemProps) {
  return (
    <ExperienceItemStyled className='experience-item rounded'>
      <GenericBackground color={UIColor.ListBlue} opacity={UIOpacity.Header} />
      <div className='experience-item-content'>
        <div className='top-row'>
          <select
            value={experience.experienceType}
            onChange={(e) =>
              handleExperienceChange(index, 'experienceType', e.target.value)
            }
          >
            {Object.values(ExperienceType).map((expType) => (
              <option key={expType} value={expType}>
                {expType}
              </option>
            ))}
          </select>
          <SelectionButton
            title='Remove Experience'
            amSelected={false}
            selectable={true}
            onClick={() => removeExperience(index)}
          />
        </div>

        <RangeSlider
          title='Amount'
          min={0}
          max={100}
          step={1}
          startingValue={experience.amount}
          onValueChange={(value) =>
            handleExperienceChange(index, 'amount', value)
          }
        />
      </div>
    </ExperienceItemStyled>
  );
}

export default ExperienceItem;
