import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class IncreasePopulationMessage extends ClientMessage {
    constructor(messageId, senderUserId, cityInstanceId) {
        super(NetworkProtocol.IncreasePopulation, messageId, senderUserId);
        this.cityInstanceId = cityInstanceId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { cityInstanceId: this.cityInstanceId });
    }
    static fromJSON(json) {
        return new IncreasePopulationMessage(json.messageId, json.senderUserId, json.cityInstanceId);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('increase population message validating: ', this.toJSON());
        return super.validate();
    }
}
