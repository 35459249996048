// external
import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// internal
// components
import DepartmentItem from './DepartmentItem.tsx';

// context
import { GameStateLogicContext } from '../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { NationalAdministrationReportStyled } from './styles/NationalAdministrationReport.styled.js';

// gl
import Department from '../../../../../../../GildedLands/lib/Classes/Administration/Department/Department/Department';
import NationalAdministration from '../../../../../../../GildedLands/lib/Classes/Administration/NationalAdministration';
import Eunuch from '../../../../../../../GildedLands/lib/Classes/Administration/Eunuch';

const NationalAdministrationReport = ({}) => {
  // contexts
  const { saveNationalAdministration } = useContext(GameStateLogicContext);
  const { gameState, userId } = useContext(GameStateDataContext);
  // states

  // #region administration and departments
  const [admin, setAdmin] = useState<NationalAdministration>(null);

  useEffect(() => {
    if (!userId || !gameState) return;
    const playerNation = gameState.getNationByControllerUserId(userId);
    if (!playerNation)
      console.error('Player nation not found in department setter');

    setAdmin(playerNation.administration);
  }, []);

  useEffect(() => {
    if (!userId || !gameState) return;
    if (!admin) return;

    const playerNation = gameState.getNationByControllerUserId(userId);
    const clone = admin.clone();
    clone.updateStatsFromServer(playerNation.administration);
    setAdmin(clone);
  }, [userId, gameState]);

  const savePalace = (palace: Department) => {
    const temp = admin.clone();
    temp.palace = palace;
    setAdmin(temp);
  };

  const addDepartment = (parentId: string) => {
    if (!admin) return;
    const tempAdmin = admin.clone();
    // parent
    let parent = null;
    if (!!parentId) {
      parent = tempAdmin.getDepartmentById(parentId);
    }
    // nation
    const playerNation = gameState.getNationByControllerUserId(userId);

    const dept = new Department(
      uuidv4(),
      'New Department',
      playerNation.id,
      null,
      !!parent ? parent.instanceIdentifier : null,
      [],
      [],
      [new Eunuch([])],
      [],
      [],
      [],
      0
    );
    if (!!parent) {
      parent.subDepartments.push(dept);
    } else {
      throw new Error('Parent not found');
    }
    setAdmin(tempAdmin);
  };

  const deleteDepartment = (id: string) => {
    if (!admin) return;
    const tempAdmin = admin.clone();
    tempAdmin.palace.subDepartments = tempAdmin.palace.subDepartments.filter(
      (dept) => dept.instanceIdentifier !== id
    );
    setAdmin(tempAdmin);
  };

  // #endregion department

  // #region handlers
  const handleSave = () => {
    if (!admin) return;
    saveNationalAdministration(admin);
  };
  // #endregion handlers

  return (
    <NationalAdministrationReportStyled>
      <div className='main-admin-content'>
        <div className='main-content-header'>
          <div className='main-content-header-title'>
            National Administration
          </div>
          <button className='save-button' onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
      <div className='departments'>
        <div className='departments-header'>
          <div className='departments-header-title'>Palace</div>
        </div>
        <div className='palace'>
          <DepartmentItem
            key={admin?.palace.instanceIdentifier}
            department={admin?.palace}
            parent={null}
            saveDepartment={savePalace}
            addDepartment={addDepartment}
            deleteDepartment={deleteDepartment}
          />
        </div>
      </div>
    </NationalAdministrationReportStyled>
  );
};

export default NationalAdministrationReport;
