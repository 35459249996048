import styled from 'styled-components';
import '../../../../styles/constants.css';

export const WaitlistPageStyled = styled.div`
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow: hidden;

  .main-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    margin: 0 var(--screen-margin);
    border: 1px solid var(--lol-gold);
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .waitlist-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Adjust to center the component properly
    z-index: 3;
  }
`;
