import Unit from './Unit.js';
class MilitaryUnit extends Unit {
    //
    constructor(instanceId, name, nationId, tileId, isMoving, finalDestinationTileId, currentDestinationTileId, path, travelTimes, percentageMoved) {
        super(instanceId, name, nationId, tileId, isMoving, finalDestinationTileId, currentDestinationTileId, path, travelTimes, percentageMoved);
    }
    // #region Clone, toJSON, and fromJSON
    clone() {
        return new MilitaryUnit(this.instanceId, this.name, this.nationId, this.tileId, this.isMoving, this.finalDestinationTileId, this.currentDestinationTileId, this.path, this.travelTimes, this.percentageMoved);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            name: this.name,
            nationId: this.nationId,
            tileId: this.tileId,
            isMoving: this.isMoving,
            finalDestinationTileId: this.finalDestinationTileId,
            currentDestinationTileId: this.currentDestinationTileId,
            path: this.path,
            travelTimes: this.travelTimes,
            percentageMoved: this.percentageMoved,
        };
    }
    static fromJSON(json) {
        return new MilitaryUnit(json.instanceId, json.name, json.nationId, json.tileId, json.isMoving, json.finalDestinationTileId, json.currentDestinationTileId, json.path, json.travelTimes, json.percentageMoved);
    }
}
export default MilitaryUnit;
