import React from 'react';
import { CohortComponentStyled } from './styles/CohortComponent.styled.js';
import RangeSlider from '../../UI/GenericComponents/RangeSlider.tsx';
import LineItemStandard from '../../UI/GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../UI/GenericComponents/VerticalList.tsx';
import { GoodType } from '../../../../../../GildedLands/lib/Enums/Good.js';
import { UIColor, UIOpacity } from '../../UI/Enums/UIEnums.ts';
import Experience from '../../../../../../GildedLands/lib/Classes/Experience/Experience';
import CenterTitle from '../../UI/GenericComponents/CenterTitle.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';
import WeaponItem from './WeaponItem.tsx';
import ExperienceItem from './ExperienceItem.tsx';
import SelectionButton from '../../UI/GenericComponents/SelectionButton.tsx';
import { ExperienceType } from '../../../../../../GildedLands/lib/Enums/Experience';
import Cohort from '../../../../../../GildedLands/lib/Classes/Army/Cohort';
import EvaluationResultComponent from '../../UI/MappingToData/Experience/EvaluationResult.tsx';

function CohortComponent({
  cohort,
  onCohortChange,
  onRemove,
  onDuplicate,
}: {
  cohort: Cohort;
  onCohortChange: (cohort: Cohort) => void;
  onRemove: () => void;
  onDuplicate: () => void;
}) {
  const readOnly = !!cohort.lastFightStats; // If lastFightStats exist, consider it read-only.

  // Handlers
  const handleValueChange = (field, value) => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort[field] = value;
    onCohortChange(newCohort);
  };

  const handleWeaponChange = (index, field, value) => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.weapons[index][field] = value;
    onCohortChange(newCohort);
  };

  const addWeapon = () => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.weapons.push({
      goodType: GoodType.Gladius, // Default weapon
      primary: false,
      quality: 50,
    });
    onCohortChange(newCohort);
  };

  const removeWeapon = (index) => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.weapons.splice(index, 1);
    onCohortChange(newCohort);
  };

  const handleExperienceChange = (index, field, value) => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.experience[index][field] = value;
    onCohortChange(newCohort);
  };

  const addExperience = () => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.experience.push(new Experience(ExperienceType.Combat, 0));
    onCohortChange(newCohort);
  };

  const removeExperience = (index) => {
    if (readOnly) return;
    const newCohort = cohort.clone();
    newCohort.experience.splice(index, 1);
    onCohortChange(newCohort);
  };

  // Weapons List Component
  const weaponsListComponent = (
    <VerticalList
      data={cohort.weapons.map((weapon, index) => (
        <WeaponItem
          key={index}
          weapon={weapon}
          handleWeaponChange={handleWeaponChange}
          removeWeapon={removeWeapon}
          index={index}
        />
      ))}
    />
  );

  const weaponsComponent = (
    <LineItemStandard
      title='Weapons'
      inlineDataFields={[
        !readOnly
          ? {
              fieldData: {
                fieldName: 'Add Weapon',
                value: '',
                onClick: addWeapon,
              },
              dropdown: null,
            }
          : null,
      ].filter(Boolean)}
      dropdown={<VerticalList data={[weaponsListComponent]} />}
    />
  );

  // Experience List Component
  const experienceListComponent = (
    <VerticalList
      data={cohort.experience.map((experience, index) => (
        <ExperienceItem
          key={index}
          experience={experience}
          handleExperienceChange={handleExperienceChange}
          removeExperience={removeExperience}
          index={index}
        />
      ))}
    />
  );

  const experienceComponent = (
    <LineItemStandard
      title='Experience'
      inlineDataFields={[
        !readOnly
          ? {
              fieldData: {
                fieldName: 'Add Experience',
                value: '',
                onClick: addExperience,
              },
              dropdown: null,
            }
          : null,
      ].filter(Boolean)}
      dropdown={<VerticalList data={[experienceListComponent]} />}
    />
  );

  // Last Fight Stats Component
  const lastFightStatsComponent = cohort.lastFightStats ? (
    <VerticalList
      data={[
        <LineItemStandard
          key='morale-stats'
          title='Morale Stats'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Morale Start',
                value: cohort.lastFightStats.morale.start.toFixed(0),
              },
              dropdown: null,
            },
            {
              fieldData: {
                fieldName: 'Morale End',
                value: cohort.lastFightStats.morale.end.toFixed(0),
              },
              dropdown: null,
            },
          ]}
        />,
        <LineItemStandard
          key='energy-stats'
          title='Energy Stats'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Energy Start',
                value: cohort.lastFightStats.energy.start.toFixed(0),
              },
              dropdown: null,
            },
            {
              fieldData: {
                fieldName: 'Energy End',
                value: cohort.lastFightStats.energy.end.toFixed(0),
              },
              dropdown: null,
            },
          ]}
        />,
        <LineItemStandard
          key='shock'
          title='Shock'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Shock',
                value: cohort.lastFightStats.shock.result.toFixed(0),
              },
              dropdown: (
                <EvaluationResultComponent
                  evaluationResult={cohort.lastFightStats.shock}
                />
              ),
            },
          ]}
        />,
        <LineItemStandard
          key='formation'
          title='Formation'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Formation',
                value: cohort.lastFightStats.formation.result.toFixed(0),
              },
              dropdown: (
                <EvaluationResultComponent
                  evaluationResult={cohort.lastFightStats.formation}
                />
              ),
            },
          ]}
        />,
        <LineItemStandard
          key='discipline'
          title='Discipline'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Discipline',
                value: cohort.lastFightStats.discipline.result.toFixed(0),
              },
              dropdown: (
                <EvaluationResultComponent
                  evaluationResult={cohort.lastFightStats.discipline}
                />
              ),
            },
          ]}
        />,
        <LineItemStandard
          key='resilience'
          title='Resilience'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Resilience',
                value: cohort.lastFightStats.resilience.result.toFixed(0),
              },
              dropdown: (
                <EvaluationResultComponent
                  evaluationResult={cohort.lastFightStats.resilience}
                />
              ),
            },
          ]}
        />,
        <LineItemStandard
          key='offensiveOutput'
          title='Offensive Output'
          inlineDataFields={[
            {
              fieldData: {
                fieldName: 'Offensive Output',
                value: cohort.lastFightStats.offensiveOutput.result.toFixed(0),
              },
              dropdown: (
                <EvaluationResultComponent
                  evaluationResult={cohort.lastFightStats.offensiveOutput}
                />
              ),
            },
          ]}
        />,
      ]}
    />
  ) : null;

  return (
    <LineItemStandard
      title='Cohort'
      inlineDataFields={[
        {
          fieldData: {
            fieldName: 'Soldiers',
            value: cohort.soldiers.toFixed(0),
          },
          dropdown: !readOnly ? (
            <RangeSlider
              title='Soldiers'
              min={0}
              max={1000}
              step={1}
              startingValue={cohort.soldiers}
              onValueChange={(value) => handleValueChange('soldiers', value)}
            />
          ) : null,
        },
        {
          fieldData: {
            fieldName: 'Morale',
            value: cohort.morale.toFixed(0),
          },
          dropdown: !readOnly ? (
            <RangeSlider
              title='Morale'
              min={0}
              max={100}
              step={1}
              startingValue={cohort.morale}
              onValueChange={(value) => handleValueChange('morale', value)}
            />
          ) : null,
        },
        {
          fieldData: {
            fieldName: 'Energy',
            value: cohort.energy.toFixed(0),
          },
          dropdown: !readOnly ? (
            <RangeSlider
              title='Energy'
              min={0}
              max={100}
              step={1}
              startingValue={cohort.energy}
              onValueChange={(value) => handleValueChange('energy', value)}
            />
          ) : null,
        },
      ]}
      dropdown={
        <VerticalList
          data={[
            <div key='weapons-component'>
              {/* Weapons */}
              {weaponsComponent}
            </div>,
            <div key='experience-component'>
              {/* Experience */}
              {experienceComponent}
            </div>,
            <div key='last-fight-stats-component'>
              {/* Last Fight Stats */}
              {lastFightStatsComponent}
            </div>,
            !readOnly && (
              <div key='cohort-buttons' className='filter-buttons'>
                <SelectionButton
                  title='Duplicate'
                  amSelected={false}
                  selectable={true}
                  onClick={onDuplicate}
                />
                <SelectionButton
                  title='Delete'
                  amSelected={false}
                  selectable={true}
                  onClick={onRemove}
                />
              </div>
            ),
          ].filter(Boolean)}
        />
      }
      inlineFieldsAlignment='even'
    />
  );
}

export default CohortComponent;
