import React, { useState, useContext } from 'react';
import { TaxItemStyled } from './styles/TaxItem.styled.js';
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import ImprovementSubsidy from '../../../../../../../../../GildedLands/lib/Classes/Tax/ImprovementSubsidy.js';

interface ImprovementSubsidyItemProps {
  subsidy: ImprovementSubsidy;
}

const ImprovementSubsidyItem = ({ subsidy }: ImprovementSubsidyItemProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { editTax, deleteTax } = useContext(GameStateLogicContext);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const saveNewSubsidy = (newSubsidy: ImprovementSubsidy) => {
    editTax(newSubsidy);
  };

  return (
    <TaxItemStyled>
      <div className='tax-main-info' onClick={toggleDetails}>
        <span className='tax-title'>
          {showDetails ? '^ ' : '> '}
          Improvement Subsidy
        </span>
        <span className='tax-info'>
          Subsidy Amount: {subsidy.subsidyAmountPerGood}
        </span>
      </div>
      {showDetails && (
        <div className='tax-item-details'>
          <div className='tax-details-category-content'>
            <label>
              Subsidy Amount: $
              <input
                type='number'
                name='subsidyAmountPerGood'
                defaultValue={subsidy.subsidyAmountPerGood}
                step='0.1'
                onBlur={(e) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.subsidyAmountPerGood = parseFloat(e.target.value);
                  saveNewSubsidy(newSubsidy);
                }}
              />
            </label>
            <label>
              Improvement Instance ID:
              <input
                type='number'
                name='improvementInstanceId'
                defaultValue={subsidy.improvementInstanceId}
                onBlur={(e) => {
                  const newSubsidy = subsidy.clone();
                  newSubsidy.improvementInstanceId = parseInt(
                    e.target.value,
                    10
                  );
                  saveNewSubsidy(newSubsidy);
                }}
              />
            </label>
          </div>
          <button
            className='delete-tax-button'
            onClick={() => deleteTax(subsidy.instanceId)}
          >
            Delete
          </button>
        </div>
      )}
    </TaxItemStyled>
  );
};

export default ImprovementSubsidyItem;
