// External dependencies
import React, { useContext, useMemo, useState } from 'react';

// Internal dependencies
// Contexts
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';

// Components
import TopPanel from './CityView.tsx';
import CenterTitle from '../../../UI/GenericComponents/CenterTitle.tsx';
import Background from '../../../UI/GenericComponents/Background.tsx';
import CreateMissionPopup from './CreateMissionPopup.tsx';
import ExistingImprovementList from './ExistingImprovementList.tsx';
import BuildImprovementList from './BuildImprovementList.tsx';
import ExistingImprovementPanel from './ExistingImprovementPanel.tsx';
import BuildImprovementPanel from './BuildImprovementPanel.tsx';

// Enums and Types
import { UIPadding, UIColor, UIOpacity } from '../../../UI/Enums/UIEnums.ts';

// Utility functions
import getExperienceData from '../../../UI/MappingToData/Experience/Experience.tsx';

// Styles
import { CityScreenStyled } from './styles/CityScreen.styled.js';

const CityScreen = () => {
  // #region context
  const {
    selectedCity,
    selectedRuntimeImprovement,
    setSelectedRuntimeImprovement,
    selectedImprovementTypeInfo,
    setSelectedImprovementTypeInfo,
  } = useContext(SelectedEntityContext);

  // #endregion

  // #region simple state

  const [showCreateMissionPopup, setShowCreateMissionPopup] = useState(false);

  // #endregion

  // #region game state

  const experienceData = useMemo(() => {
    const columns = Math.max(1, Math.floor((window.innerWidth - 800) / 250));
    return getExperienceData(selectedCity.experiences, columns);
  }, [selectedCity]);

  // #endregion

  return (
    <CityScreenStyled className='city-screen'>
      <div className='city-view-holder ui-gold clickable-area'>
        <TopPanel />
      </div>

      <div className='center-buttons'>
        <button className='gold-button' onClick={() => {}}>
          Economy
        </button>
        <button className='gold-button' onClick={() => {}}>
          Admin
        </button>
        <button className='gold-button' onClick={() => {}}>
          Military
        </button>
      </div>

      <div className='right-buttons'>
        <button
          className='gold-button'
          onClick={() => {
            setShowCreateMissionPopup(true);
          }}
        >
          Create Mission
        </button>
      </div>

      {showCreateMissionPopup && (
        <CreateMissionPopup close={() => setShowCreateMissionPopup(false)} />
      )}
      <div className='city-side-bar left-bar ui-gold clickable-area'>
        <ExistingImprovementList />
      </div>

      <div className='city-side-bar right-bar ui-gold clickable-area'>
        <BuildImprovementList />
      </div>
      {selectedRuntimeImprovement && <ExistingImprovementPanel />}
      {selectedImprovementTypeInfo && <BuildImprovementPanel />}
      <div className='bottom-panel clickable-area'>
        <CenterTitle
          title='City Experience'
          padding={UIPadding.Medium}
          backgroundData={{
            color: UIColor.HeaderBlue,
            opacity: UIOpacity.List,
          }}
        />
        <div className='bottom-panel-content'>
          <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
          <div className='experience-list-container'>
            <div className='experience-list'>{experienceData}</div>
          </div>
        </div>
      </div>
    </CityScreenStyled>
  );
};

export default CityScreen;
