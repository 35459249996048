import React, { useState } from 'react';
import { BattleSimulationPageStyled } from './styles/BattleSimulationPage.styled.js';
import SimTile from './SimTile.tsx';
import BiomePopup from './BiomePopup.tsx';
import { BiomeType } from '../../../../../../GildedLands/lib/Enums/Biome.js';
import ActiveArmy from '../../../../../../GildedLands/lib/Classes/Army/ActiveArmy.js';
import Battle from '../../../../../../GildedLands/lib/Classes/Army/Battle.js';
import Cohort from '../../../../../../GildedLands/lib/Classes/Army/Cohort.js';
import { GoodType } from '../../../../../../GildedLands/lib/Enums/Good.js';
import Experience from '../../../../../../GildedLands/lib/Classes/Experience/Experience.js';
import { ExperienceType } from '../../../../../../GildedLands/lib/Enums/Experience';
import ArmyPanel from './ArmyPanel.tsx';
import CenterButton from '../../UI/GenericComponents/CenterButton.tsx';
import { UIColor, UIOpacity, UIPadding } from '../../UI/Enums/UIEnums.ts';
import TopPanel from './TopPanel.tsx';
import CenterTitle from '../../UI/GenericComponents/CenterTitle.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';
import SelectionButton from '../../UI/GenericComponents/SelectionButton.tsx';
import LineItemStandard from '../../UI/GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../UI/GenericComponents/VerticalList.tsx';

function BattleSimulationPage() {
  // State for the biome types
  const [mainBiomeType, setMainBiomeType] = useState(BiomeType.Prairie);
  const [otherBiomeTypes, setOtherBiomeTypes] = useState([]);

  // State to control the visibility of the popup/modal
  const [showBiomePopup, setShowBiomePopup] = useState(false);

  const getDefaultCohort = () => {
    return new Cohort(
      1000, // soldiers
      100, // morale
      100, // energy
      [
        {
          goodType: GoodType.Gladius,
          primary: true,
          quality: 80,
        },
      ],
      {
        goodType: GoodType.LeatherJerkin,
        quality: 70,
      },
      [],
      null, // No horse
      [new Experience(ExperienceType.Combat, 50)],
      null // last fight stats (none at start)
    );
  };

  // State for initial armies (the armies before battle starts)
  const [sideAArmy, setSideAArmy] = useState<ActiveArmy[]>(() => {
    const defaultCohort = getDefaultCohort();
    return [new ActiveArmy([defaultCohort], [], 0)];
  });

  const [sideBArmy, setSideBArmy] = useState<ActiveArmy[]>(() => {
    const defaultCohort = getDefaultCohort();
    return [new ActiveArmy([defaultCohort], [], 0)];
  });

  // State for the battle and turn navigation
  const [battle, setBattle] = useState<Battle | null>(null);
  const [currentTurn, setCurrentTurn] = useState(0);
  const [maxTurns, setMaxTurns] = useState(10); // You can adjust the maximum number of turns

  // The displayed armies represent the state of the armies at the selected turn
  const [displaySideAArmy, setDisplaySideAArmy] = useState<ActiveArmy[]>(() =>
    sideAArmy.map((army) => army.clone())
  );
  const [displaySideBArmy, setDisplaySideBArmy] = useState<ActiveArmy[]>(() =>
    sideBArmy.map((army) => army.clone())
  );

  // Handler for tile click
  const handleTileClick = () => {
    setShowBiomePopup(true);
  };

  // Handler for updating biome types
  const handleBiomeChange = (newMainBiomeType, newOtherBiomeTypes) => {
    setMainBiomeType(newMainBiomeType);
    setOtherBiomeTypes(newOtherBiomeTypes);
    setShowBiomePopup(false);
  };

  const loadTurn = (turnIndex) => {
    if (battle && turnIndex >= 0 && turnIndex < battle.history.length) {
      const turnData = battle.history[turnIndex];

      // Clone armies from the selected turn's history
      const sideAState = turnData.sideA.army.map((a) => a.clone());
      const sideBState = turnData.sideB.army.map((a) => a.clone());

      setDisplaySideAArmy(sideAState);
      setDisplaySideBArmy(sideBState);

      setCurrentTurn(turnIndex);
    }
  };

  // Handler to start the battle
  const startBattle = () => {
    // Create a new Battle from the given armies
    const newBattle = new Battle(
      sideAArmy.map((army) => army.clone()),
      sideBArmy.map((army) => army.clone()),
      {
        mainBiomeType,
        otherBiomeTypes: [...otherBiomeTypes],
      },
      0,
      []
    );

    // Run the battle for maxTurns or until one side is destroyed
    for (let i = 0; i < maxTurns; i++) {
      newBattle.runTurn();

      // Check if one side is destroyed or morale is 0 etc. (You can add your own end conditions)
      const sideAAlive = newBattle.sideA.some((army) =>
        army.cohorts.some((cohort) => cohort.soldiers > 0)
      );
      const sideBAlive = newBattle.sideB.some((army) =>
        army.cohorts.some((cohort) => cohort.soldiers > 0)
      );
      if (!sideAAlive || !sideBAlive) {
        break;
      }
    }

    // Set the battle state
    setBattle(newBattle);

    // Load the final turn state
    const lastTurnIndex = newBattle.history.length - 1;
    loadTurn(lastTurnIndex);
  };

  // Handlers to navigate turns
  const goToNextTurn = () => {
    if (battle && currentTurn < battle.history.length - 1) {
      loadTurn(currentTurn + 1);
    }
  };

  const goToPreviousTurn = () => {
    if (battle && currentTurn > 0) {
      loadTurn(currentTurn - 1);
    }
  };

  return (
    <BattleSimulationPageStyled>
      <div className='content-container'>
        <div className='top-panel-holder ui-gold'>
          <TopPanel />
        </div>
        {/* Army Panels for the displayed turn state */}
        <div className='army-panel side-a'>
          {!!displaySideAArmy && (
            <ArmyPanel
              side='Side A'
              army={displaySideAArmy[0]}
              onArmyChange={(army) => {
                const newSideAArmy = [...sideAArmy];
                newSideAArmy[0] = army;
                setSideAArmy(newSideAArmy);
                setDisplaySideAArmy(newSideAArmy.map((a) => a.clone()));
              }}
              getDefaultCohort={getDefaultCohort}
              // Note: onArmyChange here changes the initial army,
              // but after battle starts you might want to prevent that or handle differently.
            />
          )}
        </div>
        <div className='tile-container'>
          <SimTile
            mainBiomeType={mainBiomeType}
            otherBiomeTypes={otherBiomeTypes}
            onTileClick={handleTileClick}
          />
          {/* Render the biome popup if showBiomePopup is true */}
          {showBiomePopup && (
            <BiomePopup
              mainBiomeType={mainBiomeType}
              otherBiomeTypes={otherBiomeTypes}
              onBiomeChange={handleBiomeChange}
              onClose={() => setShowBiomePopup(false)}
            />
          )}
        </div>
        {/* Control Panel */}
        <div className='control-panel ui-gold'>
          <CenterTitle title='Battle Control' padding={UIPadding.Tall} />
          <div className='control-panel-content'>
            <GenericBackground
              color={UIColor.ListBlue}
              opacity={UIOpacity.List}
            />
            <div className='turn-controls'>
              {!battle ? (
                <SelectionButton
                  title='Start Battle'
                  onClick={startBattle}
                  amSelected={false}
                  selectable={true}
                />
              ) : (
                <>
                  <SelectionButton
                    title='Previous Turn'
                    onClick={goToPreviousTurn}
                    amSelected={false}
                    selectable={true}
                  />
                  <span className='turn-label'>Turn {currentTurn + 1}</span>
                  <SelectionButton
                    title='Next Turn'
                    onClick={goToNextTurn}
                    amSelected={false}
                    selectable={true}
                  />
                </>
              )}
            </div>

            <div className='history-panel'>
              <CenterTitle title='Battle History' padding={UIPadding.Medium} />
              <div className='history-list'>
                <VerticalList
                  data={
                    battle
                      ? battle.history.map((historyItem, index) => (
                          <LineItemStandard
                            key={index}
                            title={`Turn ${index + 1}`}
                            inlineDataFields={[
                              {
                                fieldData: {
                                  fieldName: 'Casualties A',
                                  value:
                                    historyItem.sideA.casualties.toFixed(0),
                                },
                                dropdown: null,
                              },
                              {
                                fieldData: {
                                  fieldName: 'Casualties B',
                                  value:
                                    historyItem.sideB.casualties.toFixed(0),
                                },
                                dropdown: null,
                              },
                            ]}
                            dropdown={
                              <div className='battle-stats-dropdown'>
                                <LineItemStandard
                                  title='Side A Stats'
                                  inlineDataFields={[
                                    {
                                      fieldData: {
                                        fieldName: 'Offensive Output',
                                        value:
                                          historyItem.sideA.offensiveOutput.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Resilience',
                                        value:
                                          historyItem.sideA.resilience.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Shock',
                                        value:
                                          historyItem.sideA.shock.toFixed(2),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Formation Modifier',
                                        value:
                                          historyItem.sideA.formationModifier.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Morale Change',
                                        value:
                                          historyItem.sideA.moraleChange.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Energy Change',
                                        value:
                                          historyItem.sideA.energyChange.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                  ]}
                                />
                                <LineItemStandard
                                  title='Side B Stats'
                                  inlineDataFields={[
                                    {
                                      fieldData: {
                                        fieldName: 'Offensive Output',
                                        value:
                                          historyItem.sideB.offensiveOutput.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Resilience',
                                        value:
                                          historyItem.sideB.resilience.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Shock',
                                        value:
                                          historyItem.sideB.shock.toFixed(2),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Formation Modifier',
                                        value:
                                          historyItem.sideB.formationModifier.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Morale Change',
                                        value:
                                          historyItem.sideB.moraleChange.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                    {
                                      fieldData: {
                                        fieldName: 'Energy Change',
                                        value:
                                          historyItem.sideB.energyChange.toFixed(
                                            2
                                          ),
                                      },
                                      dropdown: null,
                                    },
                                  ]}
                                />
                              </div>
                            }
                            onMainClick={() => loadTurn(index)}
                            inlineFieldsAlignment='even'
                          />
                        ))
                      : []
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='army-panel side-b'>
          {!!displaySideBArmy && (
            <ArmyPanel
              side='Side B'
              army={displaySideBArmy[0]}
              onArmyChange={(army) => {
                console.log('onArmyChange', army);
                const newSideBArmy = [...sideBArmy];
                newSideBArmy[0] = army;
                setSideBArmy(newSideBArmy);
                setDisplaySideBArmy(newSideBArmy.map((a) => a.clone()));
              }}
              getDefaultCohort={getDefaultCohort}
            />
          )}
        </div>
      </div>
    </BattleSimulationPageStyled>
  );
}

export default BattleSimulationPage;
