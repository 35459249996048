// external
import React, { useEffect, useState } from 'react';

// this was used in lol to keep a reference to the position of the cards - we may not need this now,
// though it may be nice for cities or armies
const EntityRefContextProvider = ({ children }) => {
  // #region card ref data

  const [entityRefData, setEntityRefData] = useState([]);
  const addEntityRef = (entityInstanceId, ref) => {
    setEntityRefData((oldData) => [
      ...oldData.filter((data) => data.entityInstanceId !== entityInstanceId),
      {
        entityInstanceId,
        ref,
      },
    ]);
  };

  // #endregion

  const value: EntityRefContextType = {
    entityRefData,
    addEntityRef,
  };

  return (
    <EntityRefContext.Provider value={value}>
      {children}
    </EntityRefContext.Provider>
  );
};

interface EntityRefContextType {
  entityRefData: {
    entityInstanceId: number;
    ref: React.RefObject<HTMLDivElement>;
  }[];
  addEntityRef: (
    entityInstanceId: number,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
}

const EntityRefContext = React.createContext<EntityRefContextType | null>(null);

export { EntityRefContextProvider, EntityRefContext };
