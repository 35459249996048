// external
import React, { useState, useEffect, useContext, useMemo } from 'react';

// internal
// components

// internal context
import { SelectedEntityContext } from '../../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { InteractionContext } from '../../../../../contexts/GameData/InteractionContextProvider.tsx';
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
// css
import { CivilianUnitInfoStyled } from './styles/CivilianUnitInfo.styled.js';

// gl
import CivilianUnit from '../../../../../../../../GildedLands/lib/Classes/Unit/CivilianUnit';
import City from '../../../../../../../../GildedLands/lib/Classes/City/City';
import CenterButton from '../../../../UI/GenericComponents/CenterButton.tsx';
import { UIColor, UIOpacity } from '../../../../UI/Enums/UIEnums.ts';
import getCivilianUnitInfo from '../../../../UI/MappingToData/MapOverlay/UnitInfo.tsx';
import GenericBackground from '../../../../UI/GenericComponents/Background.tsx';

const CivilianUnitInfo = ({ selectedUnitId }: { selectedUnitId: number }) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { handleFoundCity: handleFoundCityInteraction, setMoveMode } =
    useContext(InteractionContext);

  // #region Game Objects
  if (selectedUnitId == null || selectedUnitId === undefined) {
    return null;
  }

  const unit = useMemo(() => {
    return gameState?.getUnitByInstanceId(selectedUnitId) as CivilianUnit;
  }, [selectedUnitId, gameState]);

  const nation = useMemo(
    () => gameState?.getNationByControllerUserId(userId),
    [gameState, userId]
  );

  const tile = useMemo(
    () => gameState?.getLandTileByTileId(unit.tileId),
    [gameState, unit]
  );

  const canFoundCity = useMemo(() => {
    if (!tile || !nation || !gameState) {
      return false;
    }
    return City.canFoundCityOnTile(tile, nation, gameState);
  }, [tile, nation, gameState]);

  // #endregion

  // #region Handlers

  const handleFoundCity = () => {
    handleFoundCityInteraction(tile.id, unit.instanceId);
  };

  // #endregion

  const civilianUnitInfo = useMemo(() => {
    if (!unit || !gameState) {
      return null;
    }
    return getCivilianUnitInfo(unit, gameState);
  }, [unit, gameState]);

  if (!civilianUnitInfo) {
    return null;
  }

  return (
    <CivilianUnitInfoStyled className='unit-info'>
      <div className='unit-info-content'>
        {civilianUnitInfo}
        <div className='unit-info-buttons'>
          <GenericBackground
            color={UIColor.ListBlue}
            opacity={UIOpacity.List}
          />
          <CenterButton
            label='Move'
            onClick={setMoveMode}
            color={UIColor.Blue}
          />
          {(unit.isCityFounder || unit.isNationFounder) && canFoundCity && (
            <CenterButton
              label='Found City'
              onClick={handleFoundCity}
              color={UIColor.Blue}
            />
          )}
        </div>
      </div>
    </CivilianUnitInfoStyled>
  );
};

export default CivilianUnitInfo;
