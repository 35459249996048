var NetworkProtocol;
(function (NetworkProtocol) {
    NetworkProtocol["StartGame"] = "StartGame";
    NetworkProtocol["EndGame"] = "EndGame";
    NetworkProtocol["PlayerState"] = "PlayerState";
    NetworkProtocol["RejoinedGame"] = "RejoinedGame";
    NetworkProtocol["ServerSendingGamestateForRejoin"] = "ServerSendingGamestateForRejoin";
    NetworkProtocol["FoundCity"] = "FoundCity";
    NetworkProtocol["CityFounded"] = "CityFounded";
    NetworkProtocol["CreateImprovement"] = "CreateImprovement";
    NetworkProtocol["ImprovementCreated"] = "ImprovementCreated";
    NetworkProtocol["RemoveImprovement"] = "RemoveImprovement";
    NetworkProtocol["ImprovementRemoved"] = "ImprovementRemoved";
    NetworkProtocol["EconomicCycle"] = "EconomicCycle";
    NetworkProtocol["GameStateUpdate"] = "GameStateUpdate";
    NetworkProtocol["IncreasePopulation"] = "IncreasePopulation";
    NetworkProtocol["DecreasePopulation"] = "DecreasePopulation";
    NetworkProtocol["AddTax"] = "AddTax";
    NetworkProtocol["DeleteTax"] = "DeleteTax";
    NetworkProtocol["EditTax"] = "EditTax";
    NetworkProtocol["GameCycleStarted"] = "GameCycleStarted";
    NetworkProtocol["GameCyclePaused"] = "GameCyclePaused";
    NetworkProtocol["SaveNationalAdministration"] = "SaveNationalAdministration";
    NetworkProtocol["SaveCityAdministration"] = "SaveCityAdministration";
    NetworkProtocol["CreateCivilianUnit"] = "CreateCivilianUnit";
    NetworkProtocol["MoveUnit"] = "MoveUnit";
    NetworkProtocol["ChangeImprovementProduction"] = "ChangeImprovementProduction";
    NetworkProtocol["ChangeKnowledge"] = "ChangeKnowledge";
})(NetworkProtocol || (NetworkProtocol = {}));
export { NetworkProtocol };
