import styled from 'styled-components';
import '../../../styles/constants.css';

export const TidingsPageStyled = styled.div`
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow: hidden;

  .screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .tidings-content {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;

    z-index: 10;
  }

  .tidings-title {
    width: 100%;
    text-align: center;
    font-size: 100px;
    font-family: var(--header-font);
    color: var(--lol-gold);
    z-index: 10;
    margin-bottom: 20px;
  }

  .gold-button {
    text-decoration: none;
    margin: 0;
  }

  .tidings-button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 40px;
    justify-content: center;
  }
`;
