import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const TaxReportsStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
