import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import { TaxFactory } from '../../Tax/TaxFactory.js';
export default class EditTaxMessage extends ClientMessage {
    constructor(messageId, senderUserId, tax) {
        super(NetworkProtocol.EditTax, messageId, senderUserId);
        this.tax = tax;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { tax: this.tax.toJSON() });
    }
    static fromJSON(json) {
        return new EditTaxMessage(json.messageId, json.senderUserId, TaxFactory.fromJSON(json.tax));
    }
    // check that all fields in the message are valid
    validate() {
        console.log('edit tax message validating: ', this.toJSON());
        return this.tax !== undefined && super.validate();
    }
}
