import RuntimeEntity from '../Entity/RuntimeEntity.js';
import RuntimeCapacity from './RuntimeCapacity.js';
import RuntimeKnowledge from './RuntimeKnowledge.js';
class RuntimeImprovement extends RuntimeEntity {
    constructor(instanceId, improvementType, name, landTileId, 
    // capacity
    capacity, knowledge) {
        super(instanceId);
        this.improvementType = improvementType;
        this.name = name;
        this.landTileId = landTileId;
        this.capacity = capacity;
        this.knowledge = knowledge;
    }
    // #region Clone and JSON
    clone() {
        return new RuntimeImprovement(this.instanceId, this.improvementType, this.name, this.landTileId, this.capacity ? this.capacity.clone() : null, this.knowledge.map((knowledge) => knowledge.clone()));
    }
    toJSON() {
        var _a;
        return {
            instanceId: this.instanceId,
            improvementType: this.improvementType,
            name: this.name,
            landTileId: this.landTileId,
            capacity: (_a = this.capacity) === null || _a === void 0 ? void 0 : _a.toJSON(),
            knowledge: this.knowledge.map((knowledge) => knowledge.toJSON()),
        };
    }
    static fromRuntimeJSON(json) {
        return new RuntimeImprovement(json.instanceId, json.improvementType, json.name, json.landTileId, json.capacity ? RuntimeCapacity.fromJSON(json.capacity) : null, json.knowledge.map((knowledge) => RuntimeKnowledge.fromJSON(knowledge)));
    }
}
export default RuntimeImprovement;
