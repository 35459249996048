import React, { useState } from 'react';

const SelectedLandtileContext = React.createContext<number | null>(null);
const SelectedLandtileSetContext = React.createContext<React.Dispatch<
  React.SetStateAction<number | null>
> | null>(null);

const SelectedLandtileContextProvider = ({ children }) => {
  const [selectedTileIndex, setSelectedTileIndex] = useState<number | null>(
    null
  );

  return (
    <SelectedLandtileContext.Provider value={selectedTileIndex}>
      <SelectedLandtileSetContext.Provider value={setSelectedTileIndex}>
        {children}
      </SelectedLandtileSetContext.Provider>
    </SelectedLandtileContext.Provider>
  );
};

export {
  SelectedLandtileContextProvider,
  SelectedLandtileContext,
  SelectedLandtileSetContext,
};
