import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class FoundCityMessage extends ClientMessage {
    constructor(messageId, senderUserId, landTileId, founderInstanceId) {
        super(NetworkProtocol.FoundCity, messageId, senderUserId);
        this.landTileId = landTileId;
        this.founderInstanceId = founderInstanceId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { landTileId: this.landTileId, founderInstanceId: this.founderInstanceId });
    }
    static fromJSON(json) {
        return new FoundCityMessage(json.messageId, json.senderUserId, json.landTileId, json.founderInstanceId);
    }
    // check that all fields in the message are valid
    validate() {
        return (this.senderUserId !== undefined &&
            this.landTileId !== undefined &&
            this.founderInstanceId !== undefined &&
            super.validate());
    }
}
