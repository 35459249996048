// dependancies
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// imports
// context
import { AuthContext } from '../../../contexts/AdminAndNetworking/AuthContextProvider.tsx';
// css
import { AllowlistComponentStyled } from './styles/AllowlistComponent.styled.js';

function AllowlistComponent() {
  const navigate = useNavigate();
  const { allowlist } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    allowlist(email)
      .then((data) => {
        console.log('Allowlisted:', data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <AllowlistComponentStyled className='allowlist-component'>
      <form onSubmit={handleSubmit}>
        <div className='allowlist-wrapper'>
          <h1>Allowlist</h1>
          <div className='allowlist-form'>
            <input
              type='text'
              placeholder='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type='submit' value='Submit' />
          </div>
          {error && <p className='input-error'>{error}</p>}
        </div>
      </form>
    </AllowlistComponentStyled>
  );
}

export default AllowlistComponent;
