// external
import React, { useState, useEffect, useContext, useMemo } from 'react';

// context
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
// css
import { CreateMissionPopupStyled } from './styles/CreateMission.styled.js';
import CenterTitle from '../../../UI/GenericComponents/CenterTitle.tsx';
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';
import Background from '../../../UI/GenericComponents/Background.tsx';
import getCreateMissionData from '../../../UI/MappingToData/CityScreen/CreateMission.tsx';
import CenterButton from '../../../UI/GenericComponents/CenterButton.tsx';
import { MissionMethods } from '../../../../../../../GildedLands/lib/Enums/Mission';

const CreateMissionPopup = ({ close }: { close: () => void }) => {
  const { createCivilianUnit } = useContext(GameStateLogicContext);
  const { gameState, userId } = useContext(GameStateDataContext);
  const { selectedCity } = useContext(SelectedEntityContext);

  // state
  const [isFounderSelected, setIsFounderSelected] = useState(false);
  const [amMountainTraining, setAmMountainTraining] = useState(false);
  const [giveShips, setGiveShips] = useState(false);

  // memo
  const buildCost = useMemo(() => {
    let totalCost = MissionMethods.getBaseCost(selectedCity, gameState);
    if (isFounderSelected)
      totalCost += MissionMethods.getFounderCost(selectedCity, gameState);
    if (amMountainTraining)
      totalCost += MissionMethods.getMountainTrainingCost(
        selectedCity,
        gameState
      );
    if (giveShips)
      totalCost += MissionMethods.getShipsCost(selectedCity, gameState);

    return totalCost;
  }, [
    selectedCity,
    gameState,
    isFounderSelected,
    amMountainTraining,
    giveShips,
  ]);

  const nationalBalance = useMemo(() => {
    return gameState.getNationByControllerUserId(userId)?.administration
      ?.balance;
  }, [gameState, userId]);

  // main data
  const createMissionData = useMemo(() => {
    return getCreateMissionData(
      selectedCity,
      gameState,
      isFounderSelected,
      setIsFounderSelected,
      amMountainTraining,
      setAmMountainTraining,
      giveShips,
      setGiveShips
    );
  }, [
    selectedCity,
    gameState,
    isFounderSelected,
    amMountainTraining,
    giveShips,
  ]);

  if (!createMissionData) return null;

  return (
    <CreateMissionPopupStyled className='create-mission-popup'>
      <div className='create-mission-popup-closer' onClick={close}></div>
      <div className='create-mission-popup-area'>
        <Background color={UIColor.Blue6} opacity={UIOpacity.List} />
        {createMissionData}
        <div className='create-mission-popup-button-wrapper'>
          <CenterButton
            label={
              buildCost > nationalBalance
                ? 'Not Enough Money'
                : `Create Mission`
            }
            color={
              buildCost > nationalBalance ? UIColor.EarthRed : UIColor.Blue
            }
            onClick={() => {
              if (buildCost > nationalBalance) return;
              createCivilianUnit(
                selectedCity.instanceId,
                isFounderSelected,
                false,
                amMountainTraining,
                giveShips
              );
              close();
            }}
          />
        </div>
      </div>
    </CreateMissionPopupStyled>
  );
};

export default CreateMissionPopup;
