import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

// internal
import { AuthContext } from '../AdminAndNetworking/AuthContextProvider.tsx';
import GameState from '../../../../../GildedLands/lib/Classes/Game/GameState.js';

// Types
interface StartingState {
  _id: string | null;
  userId?: string;
  gameState: GameState;
  dateSaved?: string;
}

// The context interface
interface StartingStatesContextType {
  startingStates: StartingState[];
  selectedStartingState: StartingState | null;
  setSelectedStartingState: (ss: StartingState) => void;
  createNewStartingState: () => void;
  saveStartingState: () => void;
  deleteStartingState: (startingStateId: string) => void;
}

// Create the context
const StartingStatesContext =
  React.createContext<StartingStatesContextType>(null);

// Helper functions
const createStartingStateFromJSON = (data: any): StartingState => {
  return {
    _id: data._id || null,
    userId: data.userId,
    gameState: GameState.fromJSON(data.gameState),
    dateSaved: data.dateSaved,
  };
};

const createStartingStatesFromJSON = (jsonArray: any[]): StartingState[] => {
  return jsonArray.map((data) => createStartingStateFromJSON(data));
};

const StartingStatesContextProvider = ({ children }) => {
  const { userId } = useContext(AuthContext);

  const [startingStates, setStartingStates] = useState<StartingState[]>([]);
  const [selectedStartingState, setSelectedStartingState] =
    useState<StartingState | null>(null);

  // #region Get Starting States
  useEffect(() => {
    if (!userId) return;

    axios
      .get(`/starting-states/${userId}`)
      .then((res) => {
        console.log('get starting-states: ', res.data);
        const fetchedStates = createStartingStatesFromJSON(res.data);
        setStartingStates(fetchedStates);
        if (fetchedStates.length > 0) {
          setSelectedStartingState(fetchedStates[0]);
        }
      })
      .catch((err) => {
        console.log('Error fetching starting states: ', err);
      });
  }, [userId]);
  // #endregion

  // #region CRUD Operations
  const createNewStartingState = () => {
    axios
      .post(`/starting-states/${userId}`)
      .then((res) => {
        console.log('Created new starting state: ', res.data);
        _addStartingStateAndRefresh(res.data);
      })
      .catch((err) => {
        console.log('Error creating new starting state: ', err);
      });
  };

  const saveStartingState = () => {
    if (!selectedStartingState) return;
    console.log('save starting state: ', selectedStartingState);
    console.log(
      'selectedStartingState.gameState: ',
      selectedStartingState.gameState
    );

    const json = selectedStartingState.gameState.toPerfectJSON();
    if (selectedStartingState._id) {
      // Update existing starting state
      axios
        .put(`/starting-states/${userId}/${selectedStartingState._id}`, json)
        .then((res) => {
          console.log('Updated starting state: ', res.data);
          _addStartingStateAndRefresh(res.data);
        })
        .catch((err) => {
          console.log('Error updating starting state: ', err);
        });
    } else {
      // Create a new starting state from scratch
      axios
        .post(`/starting-states/${userId}`, json)
        .then((res) => {
          console.log('Created starting state: ', res.data);
          _addStartingStateAndRefresh(res.data);
        })
        .catch((err) => {
          console.log('Error creating starting state: ', err);
        });
    }
  };

  const deleteStartingState = (startingStateId: string) => {
    if (!startingStateId) {
      console.log('Delete Starting State - No ID provided');
      return;
    }

    // Optimistically remove from state
    const filteredStates = startingStates.filter(
      (ss) => ss._id !== startingStateId
    );
    setStartingStates(filteredStates);
    if (
      selectedStartingState &&
      selectedStartingState._id === startingStateId
    ) {
      setSelectedStartingState(
        filteredStates.length > 0 ? filteredStates[0] : null
      );
    }

    axios
      .delete(`/starting-states/${userId}/${startingStateId}`)
      .catch((err) => {
        console.log('Error deleting starting state: ', err);
      });
  };
  // #endregion

  // #region Helper Functions
  const _addStartingStateAndRefresh = (data: any) => {
    const newSS = createStartingStateFromJSON(data);
    setStartingStates((prevStates) => {
      const exists = prevStates.some((ss) => ss._id === newSS._id);
      if (!exists) {
        return [...prevStates, newSS];
      } else {
        return prevStates.map((ss) => (ss._id === newSS._id ? newSS : ss));
      }
    });
    setSelectedStartingState(newSS);
  };
  // #endregion

  const value: StartingStatesContextType = {
    startingStates,
    selectedStartingState,
    setSelectedStartingState,
    createNewStartingState,
    saveStartingState,
    deleteStartingState,
  };

  return (
    <StartingStatesContext.Provider value={value}>
      {children}
    </StartingStatesContext.Provider>
  );
};

export { StartingStatesContextProvider, StartingStatesContext, StartingState };
