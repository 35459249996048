import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AdminAndNetworking/AuthContextProvider.tsx';

function AuthorizedPage({ children }) {
  const { isAdmin } = React.useContext(AuthContext);

  if (!isAdmin) {
    return <Navigate to='/sign-in' replace />;
  }
  return children;
}

export default AuthorizedPage;
