import Unit from './Unit.js';
import Experience from '../Experience/Experience.js';
class CivilianUnit extends Unit {
    constructor(instanceId, name, nationId, tileId, isMoving, finalDestinationTileId, currentDestinationTileId, path, travelTimes, percentageMoved, isNationFounder, isCityFounder, isBuilder, hasShips, dayCreated, experience) {
        super(instanceId, name, nationId, tileId, isMoving, finalDestinationTileId, currentDestinationTileId, path, travelTimes, percentageMoved);
        this.hasShips = hasShips;
        this.isBuilder = isBuilder;
        this.isNationFounder = isNationFounder;
        this.isCityFounder = isCityFounder;
        this.dayCreated = dayCreated;
        this.experience = experience;
    }
    // #region Clone and JSON
    clone() {
        return new CivilianUnit(this.instanceId, this.name, this.nationId, this.tileId, this.isMoving, this.finalDestinationTileId, this.currentDestinationTileId, this.path, this.travelTimes, this.percentageMoved, this.isNationFounder, this.isCityFounder, this.isBuilder, this.hasShips, this.dayCreated, this.experience.map((e) => e.clone()));
    }
    toJSON() {
        const json = {
            instanceId: this.instanceId,
            name: this.name,
            nationId: this.nationId,
            tileId: this.tileId,
            isMoving: this.isMoving,
            finalDestinationTileId: this.finalDestinationTileId,
            currentDestinationTileId: this.currentDestinationTileId,
            path: this.path,
            travelTimes: this.travelTimes,
            percentageMoved: this.percentageMoved,
            isNationFounder: this.isNationFounder,
            isCityFounder: this.isCityFounder,
            isBuilder: this.isBuilder,
            hasShips: this.hasShips,
            dayCreated: this.dayCreated,
            experience: this.experience.map((e) => e.toJSON()),
        };
        return json;
    }
    static fromJSON(json) {
        return new CivilianUnit(json.instanceId, json.name, json.nationId, json.tileId, json.isMoving, json.finalDestinationTileId, json.currentDestinationTileId, json.path, json.travelTimes, json.percentageMoved, json.isNationFounder, json.isCityFounder, json.isBuilder, json.hasShips, json.dayCreated, json.experience.map((e) => Experience.fromJSON(e)));
    }
}
export default CivilianUnit;
