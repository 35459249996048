import styled from 'styled-components';
import '../../../styles/constants.css';

export const StartingStatePageStyled = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  .main-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  .map-container {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 10;
  }

  .left-column {
    position: absolute;
    top: var(--game-ui-margin);
    left: var(--game-ui-margin);
    border-radius: var(--ui-border-radius);
    overflow: hidden;
    width: 200px;
    height: calc(100vh - 2 * var(--game-ui-margin));
    z-index: 11;
  }

  .right-column {
    position: absolute;
    top: var(--game-ui-margin);
    right: var(--game-ui-margin);
    border-radius: var(--ui-border-radius);
    overflow: hidden;
    width: 200px;
    height: calc(100vh - 2 * var(--game-ui-margin));
    z-index: 11;
  }

  .realm-title {
    font-size: 1.5rem;
    font-family: var(--bold-medieval-font);
    font-weight: 900;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    line-height: 50px;
    text-align: center;
    min-width: 200px;
  }

  .biome-group {
    h3 {
      font-size: 0.8rem;
      margin: 8px 0 3px 10px;
      color: var(--lol-gold);
      text-align: left;
      font-family: var(--logo-font);
    }
  }

  .layout-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lol-gold);
  }

  .edit-input {
    flex: 1;
    margin: 0 5px;
  }

  // Adjust the span to not grow and stay centered
  .layout-widget span {
    flex: 0 0 auto; // Does not grow or shrink and keeps its auto width
  }

  .edit-button {
    font-size: 0.8rem;
    padding: 8px;
    margin: 8px 8px 0 8px;
    color: var(--border-color-bright);
    border: 1px solid var(--border-color-bright);
    text-align: center;
    font-family: var(--logo-font);
    transition: 0.5s ease;
  }
  .edit-button:hover {
    border: 1px solid var(--lol-red);
    color: var(--lol-red);
  }
  .back-button {
    font-size: 1rem;
    text-align: left;
  }
  .edit-input {
    font-size: 0.8rem;
    padding: 8px;
    margin: 8px 8px 0 8px;
    color: var(--border-color-bright);
    border: 1px solid var(--border-color-bright);
    text-align: left;
    font-family: var(--logo-font);
  }

  .edit-input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Chrome, Safari, Edge, Opera */
  }

  .edit-input[type='number']::-webkit-inner-spin-button,
  .edit-input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Chrome, Safari */
    margin: 0; /* Removes default margin provided by Chrome */
  }
`;
