import React from 'react';

// Components
import {
  UIPadding,
  UIColor,
  UIOpacity,
  UITextSize,
} from '../../Enums/UIEnums.ts';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import TextBox from '../../GenericComponents/TextBox.tsx';

// GildedLands imports
import Person from '../../../../../../../GildedLands/lib/Classes/Person/Person.js';
import {
  GoodCategory,
  GoodTypeMethods,
} from '../../../../../../../GildedLands/lib/Enums/Good.js';
import QualityOfLifeItem from '../../../../../../../GildedLands/lib/Classes/EconomicData/Consumption/QualityOfLifeItem.js';
import { IInlineDataField } from '../../GenericComponents/InlineDataField.tsx';

const getPersonConsumptionData = (person: Person): React.ReactNode => {
  const consumptionChart = person.consumptionChart;
  const categories = Object.values(GoodCategory);

  // Render category-level data
  const categoryData = categories
    .map((category) => renderCategoryData(category, consumptionChart))
    .filter((item) => item !== null);

  return <VerticalList data={categoryData} />;
};

const renderCategoryData = (
  category: GoodCategory,
  consumptionChart: any
): React.ReactNode => {
  const categoryItems = consumptionChart.goods.filter(
    (item) => item.category === category
  );
  if (categoryItems.length === 0) return null;

  const subCategories = GoodTypeMethods.getCategoryInfo(category).subCategories;
  const unitsConsumed = categoryItems.length;
  const totalCost = categoryItems.reduce((sum, item) => sum + item.cost, 0);
  const totalQualityOfLife = categoryItems.reduce(
    (sum, item) => sum + item.qualityOfLife.qol,
    0
  );

  const subCategoryData = subCategories
    .map((subCategory) => renderSubCategoryData(subCategory, categoryItems))
    .filter((item) => item !== null);

  return (
    <LineItemStandard
      key={`category-${category}`}
      title={category.toString()}
      titleColor={UIColor.White}
      inlineDataFields={renderInlineDataFields(
        unitsConsumed,
        totalCost,
        totalQualityOfLife
      )}
      dropdown={<VerticalList data={subCategoryData} />}
      inlineFieldsAlignment='table'
    />
  );
};

const renderSubCategoryData = (
  subCategory: any,
  categoryItems: any[]
): React.ReactNode => {
  const subCategoryItems = categoryItems.filter(
    (item) => item.subCategory === subCategory
  );
  if (subCategoryItems.length === 0) return null;

  const goodTypes =
    GoodTypeMethods.getSubCategoryInfo(subCategory).subCategories;
  const subUnitsConsumed = subCategoryItems.length;
  const subTotalCost = subCategoryItems.reduce(
    (sum, item) => sum + item.cost,
    0
  );
  const subTotalQualityOfLife = subCategoryItems.reduce(
    (sum, item) => sum + item.qualityOfLife.qol,
    0
  );

  const goodTypeData = goodTypes
    .map((goodType) => renderGoodTypeData(goodType, subCategoryItems))
    .filter((item) => item !== null);

  return (
    <LineItemStandard
      key={`subCategory-${subCategory}`}
      title={subCategory.toString()}
      titleColor={UIColor.White}
      inlineDataFields={renderInlineDataFields(
        subUnitsConsumed,
        subTotalCost,
        subTotalQualityOfLife
      )}
      dropdown={<VerticalList data={goodTypeData} />}
      inlineFieldsAlignment='table'
    />
  );
};

const renderGoodTypeData = (
  goodType: any,
  subCategoryItems: any[]
): React.ReactNode => {
  const goodTypeItems = subCategoryItems.filter(
    (item) => item.goodType === goodType
  );
  if (goodTypeItems.length === 0) return null;

  const unitsConsumed = goodTypeItems.length;
  const totalCost = goodTypeItems.reduce((sum, item) => sum + item.cost, 0);
  const totalQualityOfLife = goodTypeItems.reduce(
    (sum, item) => sum + item.qualityOfLife.qol,
    0
  );

  const itemLevelData = goodTypeItems.map((item, index) =>
    renderItemLevelData(item, index)
  );

  return (
    <LineItemStandard
      key={`goodType-${goodType}`}
      title={goodType.toString()}
      titleColor={UIColor.White}
      inlineDataFields={renderInlineDataFields(
        unitsConsumed,
        totalCost,
        totalQualityOfLife
      )}
      dropdown={<VerticalList data={itemLevelData} />}
      inlineFieldsAlignment='table'
    />
  );
};

const renderItemLevelData = (item: any, index: number): React.ReactNode => {
  const inlineDataFields: {
    fieldData: IInlineDataField;
    dropdown: React.ReactNode;
  }[] = [
    {
      fieldData: {
        fieldName: 'Origin',
        value: item.cityInfo.name,
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'Quality',
        value: item.quality.toFixed(0),
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'Cost',
        value: item.cost.toFixed(2),
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'QoL',
        value: item.qualityOfLife.qol.toFixed(2),
        hoverData: {
          content: getQoLHoverBox(item.qualityOfLife),
          width: 400,
          parentRef: null,
        },
      },
      dropdown: null,
    },
  ];

  return (
    <LineItemStandard
      key={`item-${item.goodType}-${index}`}
      title={item.goodType.toString()}
      titleColor={UIColor.White}
      inlineDataFields={inlineDataFields}
      inlineFieldsAlignment='table'
    />
  );
};

const renderInlineDataFields = (units: number, cost: number, qol: number) => [
  {
    fieldData: {
      fieldName: 'Units',
      textColor: UIColor.White,
      iconUrl: '',
      value: units.toString(),
    },
    dropdown: null,
  },
  {
    fieldData: {
      fieldName: 'Cost',
      textColor: UIColor.White,
      iconUrl: '',
      value: cost.toFixed(2),
    },
    dropdown: null,
  },
  {
    fieldData: {
      fieldName: 'QoL',
      textColor: UIColor.White,
      iconUrl: '',
      value: qol.toFixed(2),
    },
    dropdown: null,
  },
];

const getQoLHoverBox = (q: QualityOfLifeItem): React.ReactNode => {
  const ex = `
    Category Need: ${q.categoryNeed}
    SubCategory Need: ${q.subCategoryNeed}
    Good Need: ${q.goodNeed}
    Good QoL: ${q.goodQualityOfLife}
    Affinity: ${q.affinity}
    Quality: ${q.quality}
    Lack of Food Detractor: ${q.lackOfFoodDetractor}
    Lack of Other Goods Detractor: ${q.lackOfOtherGoodsDetractor}

    qol = categoryNeed * subCategoryNeed * goodNeed * (goodInfo.qualityOfLife + affinity + quality / 10);
  `;

  return (
    <TextBox
      content={ex}
      padding={UIPadding.Medium}
      textSize={UITextSize.Medium}
      background={{
        color: UIColor.DarkGrey,
        opacity: UIOpacity.Full,
      }}
    />
  );
};

export default getPersonConsumptionData;
