import GameManager from '../Game/GameManager.js';
import { NetworkProtocol } from '../../Enums/NetworkProtocol.js';
class MessageBase {
    constructor(messageEnum) {
        this.messageEnum = messageEnum;
    }
}
class ServerMessage extends MessageBase {
    constructor(messageEnum, recipientUserId) {
        super(messageEnum);
        this.recipientUserId = recipientUserId;
    }
    toJSON() {
        return {
            messageEnum: this.messageEnum,
            recipientUserId: this.recipientUserId,
        };
    }
    validate() {
        return this.recipientUserId != null && this.messageEnum != null;
    }
}
class ClientMessage extends MessageBase {
    constructor(messageEnum, messageId, senderUserId) {
        super(messageEnum);
        this.messageId = messageId;
        this.senderUserId = senderUserId;
    }
    validate() {
        return (this.senderUserId != null &&
            this.messageId != null &&
            this.messageEnum != null);
    }
    static generateUniqueId() {
        return GameManager.generateUniqueId();
    }
    clone() {
        return Object.assign(Object.create(this), this);
    }
    toJSON() {
        return {
            messageEnum: this.messageEnum,
            messageId: this.messageId,
            senderUserId: this.senderUserId,
        };
    }
    static registerFromJSON(type, method) {
        this.fromJSONMethods[type] = method;
    }
    static fromJSON(json) {
        const messageType = json.messageEnum;
        const method = this.fromJSONMethods[messageType];
        if (!method) {
            throw new Error('Message type not registered for: ' + NetworkProtocol[messageType]);
        }
        return method(json);
    }
}
ClientMessage.fromJSONMethods = {};
export { ServerMessage, ClientMessage, MessageBase };
