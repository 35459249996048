import styled from 'styled-components';
import '../../../../styles/constants.css';

export const OptionsPopupStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .options-popup-closer {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  .options-popup-area {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow-y: auto;
    background-color: rgba(106, 108, 109, 0.9);
    pointer-events: auto;
    box-shadow: 0 0 20px white;
  }

  .options-popup-title {
    font-size: 16px;
    padding: 25px; // Add padding
  }

  .options-popup-content {
    padding: 30px; // Add padding
  }

  .options-popup-description {
    font-size: 16px;
    font-weight: normal;
    text-align: center; // Center the description
    margin-bottom: 30px;
    font-family: var(--body-font);
    font-weight: 100;
  }
`;
