import { GoodTypeMethods } from '../../Enums/Good.js';
import { ImprovementTypeMethods } from '../../Enums/ImprovementTypeMethods.js';
import { TaxType } from '../../Enums/Tax.js';
import City from '../City/City.js';
import RuntimeImprovement from '../Improvement/RuntimeImprovement.js';
import RuntimeRealm from '../Land/Realm/RuntimeRealm.js';
import Nation from '../Nation/Nation.js';
import Person from '../Person/Person.js';
import PlayerInfo from '../Player/PlayerInfo.js';
import GoodsData from '../EconomicData/PriceAndProduction/GoodsData.js';
import TaxData from '../EconomicData/TaxTracking/TaxData.js';
import RealizedTaxItem from '../EconomicData/TaxTracking/TaxItem.js';
import AllShippingAndTaxesItem from '../EconomicData/TaxTracking/AllShippingAndTaxesItem.js';
import RouteTracker from '../TradeRoute/RouteTracker.js';
import { TaxFactory } from '../Tax/TaxFactory.js';
import { TimeEnum } from '../../Enums/Time.js';
import PersonalConsumptionChart from '../Person/PersonalConsumptionChart.js';
import { ResponsibilityMethods, AdminResponsibilityType, TaxResponsibilityType, ResponsibilityType, } from '../../Enums/Responsibility.js';
import MilitaryUnit from '../Unit/MilitaryUnit.js';
import CivilianUnit from '../Unit/CivilianUnit.js';
// #endregion
class GameState {
    // policies
    constructor(playerInfos, realm, cities, improvements, taxes, nations, days, rngSeed, gameType, economicCycleInterval, currentEntityInstanceId, gamePlayState, routeTracker, goodsData, taxData, militaryUnits, civilianUnits) {
        this.playerInfos = playerInfos;
        this.realm = realm;
        this.cities = cities;
        this.improvements = improvements;
        this.nations = nations;
        this.days = days;
        this.rngSeed = rngSeed;
        this.gameType = gameType;
        this.economicCycleInterval = economicCycleInterval;
        this.currentEntityInstanceId = currentEntityInstanceId;
        this.gamePlayState = gamePlayState;
        this.routeTracker = routeTracker;
        this.goodsData = goodsData;
        this.taxData = taxData;
        this.taxes = taxes;
        this.militaryUnits = militaryUnits;
        this.civilianUnits = civilianUnits;
    }
    // #region Fetching Entities
    getEntityById(instanceId) {
        let entity = this.improvements.find((improvement) => improvement.instanceId === instanceId);
        if (!entity) {
            entity = this.cities.find((city) => city.instanceId === instanceId);
        }
        return entity;
    }
    getPlayerInfoByUserId(userId) {
        return this.playerInfos.find((playerInfo) => playerInfo.userId === userId);
    }
    getCityByInstanceId(instanceId) {
        return this.cities.find((city) => city.instanceId === instanceId);
    }
    getImprovementByInstanceId(instanceId) {
        return this.improvements.find((improvement) => improvement.instanceId === instanceId);
    }
    getLandTileByTileId(tileId) {
        return this.realm.landTiles[tileId];
    }
    getLandTileByXY(x, y) {
        return this.realm.landTiles[this.realm.getTileIdFromXY(x, y)];
    }
    getNationByControllerUserId(userId) {
        return this.nations.find((nation) => nation.controllerUserId === userId);
    }
    getNationById(nationId) {
        return this.nations.find((nation) => nation.id === nationId);
    }
    getNationByCityId(cityId) {
        const city = this.getCityByInstanceId(cityId);
        return this.getNationById(city.administeringNationId);
    }
    getUnitByInstanceId(instanceId) {
        return (this.militaryUnits.find((unit) => unit.instanceId === instanceId) ||
            this.civilianUnits.find((unit) => unit.instanceId === instanceId));
    }
    // #endregion
    // #region Get Taxes
    getTaxByInstanceId(instanceId) {
        return this.taxes.find((tax) => tax.instanceId === instanceId);
    }
    getShippingForGood(fromCity, toCity) {
        const bestRoute = this.routeTracker.optimalCityToCityPaths[`${fromCity.instanceId}-${toCity.instanceId}`];
        return bestRoute.shippingCost;
    }
    getShippingAndTaxesForGood(goodType, goodCity, quality, productionValue, person, personCity) {
        let totalShippingCost = 0;
        let totalTaxAmount = 0;
        const taxes = [];
        const consumptionSubsidies = this.taxes
            .filter((tax) => tax.taxType === TaxType.ConsumptionSubsidy &&
            tax.ownerNationId === this.getNationByCityId(personCity.instanceId).id)
            .map((tax) => tax);
        for (const cs of consumptionSubsidies) {
            let cost = cs.getCost(goodType, goodCity.administeringNationId, productionValue, this);
            totalTaxAmount += cost;
            taxes.push(new RealizedTaxItem(TaxType.ConsumptionSubsidy, goodType, cs.instanceId, personCity.administeringNationId, personCity.instanceId, cost));
        }
        const consumptionTaxes = this.taxes
            .filter((tax) => tax.taxType === TaxType.ConsumptionTax &&
            tax.ownerNationId === this.getNationByCityId(personCity.instanceId).id)
            .map((tax) => tax);
        for (const ct of consumptionTaxes) {
            let cost = ct.getCost(goodType, goodCity.administeringNationId, productionValue, this);
            totalTaxAmount += cost;
            taxes.push(new RealizedTaxItem(TaxType.ConsumptionTax, goodType, ct.instanceId, personCity.administeringNationId, personCity.instanceId, cost));
        }
        // if the good is in the same city as the person, no import / export taxes
        if (goodCity.instanceId === personCity.instanceId) {
            return new AllShippingAndTaxesItem(0, totalTaxAmount, taxes);
        }
        const exportTariffs = this.taxes
            .filter((tax) => tax.taxType === TaxType.ExportTariff &&
            tax.ownerNationId === this.getNationByCityId(goodCity.instanceId).id)
            .map((tax) => tax);
        for (const et of exportTariffs) {
            let cost = et.getCost(goodType, goodCity.instanceId, productionValue, this);
            totalTaxAmount += cost;
            taxes.push(new RealizedTaxItem(TaxType.ExportTariff, goodType, et.instanceId, goodCity.administeringNationId, personCity.instanceId, cost));
        }
        const importTariffs = this.taxes
            .filter((tax) => tax.taxType === TaxType.ImportTariff &&
            tax.ownerNationId === this.getNationByCityId(personCity.instanceId).id)
            .map((tax) => tax);
        for (const it of importTariffs) {
            let cost = it.getCost(goodType, goodCity.instanceId, productionValue, this);
            totalTaxAmount += cost;
            taxes.push(new RealizedTaxItem(TaxType.ImportTariff, goodType, it.instanceId, personCity.administeringNationId, personCity.instanceId, cost));
        }
        const bestRoute = this.routeTracker.optimalCityToCityPaths[`${goodCity.instanceId}-${personCity.instanceId}`];
        if (!bestRoute) {
            console.log('no best route found for goodCity: ', goodCity.instanceId, 'personCity: ', personCity.instanceId);
            console.log('bestroute string : ', `${goodCity.instanceId}-${personCity.instanceId}`);
            console.log('bestroute : ', this.routeTracker.optimalCityToCityPaths[`${goodCity.instanceId}-${personCity.instanceId}`]);
        }
        totalShippingCost += bestRoute.shippingCost;
        bestRoute.exportDutyIds.forEach((dutyId) => {
            const duty = this.getTaxByInstanceId(dutyId);
            totalTaxAmount += duty.getCost(personCity.instanceId, this);
        });
        bestRoute.importDutyIds.forEach((dutyId) => {
            const duty = this.getTaxByInstanceId(dutyId);
            totalTaxAmount += duty.getCost(goodCity.instanceId, this);
        });
        bestRoute.transitDutyIds.forEach((dutyId) => {
            const duty = this.getTaxByInstanceId(dutyId);
            totalTaxAmount += duty.getCost(goodCity.instanceId, personCity.instanceId, this);
        });
        return new AllShippingAndTaxesItem(totalShippingCost, totalTaxAmount, taxes);
    }
    getDutiesForCity(cityInstanceId) {
        const nation = this.getNationByCityId(cityInstanceId);
        return {
            exportDuties: this.taxes
                .filter((tax) => tax.taxType === TaxType.ExportDuty &&
                tax.ownerNationId === nation.id)
                .map((tax) => tax),
            importDuties: this.taxes
                .filter((tax) => tax.taxType === TaxType.ImportDuty &&
                tax.ownerNationId === nation.id)
                .map((tax) => tax),
            transitDuties: this.taxes
                .filter((tax) => tax.taxType === TaxType.TransitDuty &&
                tax.ownerNationId === nation.id)
                .map((tax) => tax),
        };
    }
    // #endregion
    // #region Game Loop
    runNextTurn(personNameTracker) {
        this.days += TimeEnum.Day;
        // move units
        this.updateUnitMovement();
        // finish improvements, etc.
        // if it's the week, month, etc.
        if (this.days % this.economicCycleInterval === 1) {
            this.runEconomicCycle();
        }
        // run population health update
        if (this.days % this.economicCycleInterval === 0) {
            this.runPopulationUpdate(personNameTracker);
        }
        // this.runEconomicCycle();
        // this.runPopulationUpdate(personNameTracker);
    }
    // #endregion
    // #region Unit Movement
    updateUnitMovement() {
        for (const unit of this.militaryUnits) {
            unit.updateMovement(this);
        }
        for (const unit of this.civilianUnits) {
            unit.updateMovement(this);
        }
    }
    // #endregion
    // #region Population Health Update
    // old version - parent based
    // runPopulationHealthUpdate(personNameTracker: PersonNameTracker) {
    //   for (const city of this.cities) {
    //     const isCapital =
    //       this.getNationByCityId(city.instanceId).capitalCityId ===
    //       city.instanceId;
    //     for (let i = 0; i < city.persons.length; i++) {
    //       const person = city.persons[i];
    //       person.updateHealthFromFood();
    //       const died = person.checkForDeath(this.days);
    //       if (died && !(isCapital && city.persons.length === 1)) {
    //         person.dead = true;
    //         const nation = this.getNationByCityId(city.instanceId);
    //         nation.records.push(
    //           `${TimeMethods.getDateStringFromDays(this.days)}: ${
    //             person.name
    //           } died. They were ${(
    //             (this.days - person.dayBorn) /
    //             TimeEnum.Year
    //           ).toFixed(1)} years old, and ${person.health}% healthly.`
    //         );
    //       } else {
    //         const haveChild = person.checkForChildbirth(city, this);
    //         if (haveChild) {
    //           const child = Person.getNewPerson(
    //             this,
    //             personNameTracker.getName(),
    //             16,
    //             person
    //           );
    //           console.log('A CHILD WAS BORN: ', child);
    //           city.persons.push(child);
    //         }
    //       }
    //     }
    //     city.persons = city.persons.filter((person) => !person.dead);
    //   }
    // }
    runPopulationUpdate(personNameTracker) {
        this.runBirth(personNameTracker);
        this.runHealthUpdate();
        this.runDeathUpdate();
    }
    runBirth(personNameTracker) {
        for (const city of this.cities) {
            const averageNutrition = city.persons.reduce((acc, curr) => acc + curr.nutrition, 0) /
                city.persons.length;
            let chance = (averageNutrition - 15) / 500;
            const pop = city.persons.length;
            const capacity = city.getCapacity(this).length;
            if (pop > capacity) {
                continue;
            }
            console.log('chance: ', chance);
            const random = Math.random();
            const havingChild = random < chance;
            if (havingChild) {
                const child = Person.getNewPerson(this, personNameTracker.getName(), 18, null);
                city.persons.push(child);
            }
        }
    }
    runHealthUpdate() {
        for (const city of this.cities) {
            for (const person of city.persons) {
                person.updateHealthFromFood();
            }
        }
    }
    runDeathUpdate() {
        for (const city of this.cities) {
            const isCapital = this.getNationByCityId(city.instanceId).capitalCityId ===
                city.instanceId;
            if (isCapital && city.persons.length === 1) {
                continue;
            }
            for (const person of city.persons) {
                const died = person.checkForDeath(this.days);
                if (died) {
                    person.dead = true;
                }
            }
        }
        for (const city of this.cities) {
            city.persons = city.persons.filter((person) => !person.dead);
        }
    }
    // #endregion
    // #region Economic Cycle
    runEconomicCycle() {
        // clear old structures
        this.taxData = new TaxData([]);
        // run stuff
        this.produceAndConsume();
        this.smudgeCharacterAndSkill();
        this.checkForCompletionOfProjects();
        // admin stuff
        this.loadAdministration();
        this.distributeAdministrationExperience();
        this.calculateAdministrationEffectiveness();
        this.gainExperience();
        // taxes
        this.assignRevenuesFromTaxes();
        this.distributeRevenues();
        // this.runMilitary
        // end turn
        // improvements
        for (const improvement of this.improvements) {
            ImprovementTypeMethods.endTurnEffect(improvement, this);
        }
        // score
        this.calculateScore();
    }
    produceAndConsume() {
        this.goodsData.startNewCycle();
        // first, we produce goods and record production
        for (let improvement of this.improvements) {
            if (!!improvement.capacity) {
                improvement.capacity.currentNumberOfWorkers = 0;
            }
        }
        for (const city of this.cities) {
            const cityProduction = city.runProduction(this);
            cityProduction.forEach((p) => {
                this.goodsData.addProduction(p.person, city, p.improvement.instanceId, p.goodType, p.productionQuality, p.productionAmount, p.marketPrice, p.inputCost, p.valueToProducer, p.taxes);
                // add production taxes to the taxes object
                this.taxData.taxItems.push(...p.taxes.map((tax) => tax.clone()));
            });
        }
        // now we consume goods
        // iterate down through the persons. They decide what to buy.
        // as they do, we record consumption
        for (const city of this.cities) {
            for (const person of city.persons) {
                person.consumptionChart = PersonalConsumptionChart.getEmptyChart();
            }
        }
        for (const item of this.goodsData.productionByPerson) {
            item.person.consume(item.cityId, item.valueToProducer, this);
        }
        // edit prices based on consumption
        this.goodsData.endCycle();
        // add consumption taxes to the taxes object
        for (const item of this.goodsData.consumptionByPerson) {
            this.taxData.taxItems.push(...item.shippingAndTaxes.taxes.map((tax) => tax.clone()));
        }
    }
    smudgeCharacterAndSkill() {
        for (const city of this.cities) {
            for (const experience of city.experiences) {
                experience.amount = experience.amount * 0.99;
            }
            for (const character of city.cityCharacterTraits) {
                character.amount = character.amount * 0.99;
            }
        }
    }
    assignRevenuesFromTaxes() {
        // for each nation, calculate tax income, losses to corruption, etc.
        // determine amount of revenue from taxes on merchants
        // assign income to Nation
        for (const nation of this.nations) {
            nation.administration.lastCycleRevenueAmount = 0;
            nation.administration.lastCycleTaxes = [];
            nation.administration.lastCycleSpending = [];
        }
        for (const city of this.cities) {
            city.governor.lastCycleTaxes = [];
        }
        for (const tax of this.taxData.taxItems) {
            const nation = this.getNationById(tax.nationId);
            const city = this.getCityByInstanceId(tax.cityId);
            const goodInfo = GoodTypeMethods.getGoodInfo(tax.goodType);
            if (city.governor.collectsTaxes) {
                city.governor.lastCycleTaxes.push(tax.clone());
            }
            else {
                nation.administration.lastCycleTaxes.push(tax.clone());
            }
        }
        // we'll add the effect of admin here later
        for (const city of this.cities) {
            city.governor.lastCycleRevenueAmount +=
                city.governor.lastCycleTaxes.reduce((acc, curr) => acc + curr.incomeToGovernment, 0);
            // city.governor.balance += city.governor.lastCycleRevenueAmount;
            const nation = this.getNationByCityId(city.instanceId);
            nation.administration.lastCycleRevenueAmount +=
                city.governor.lastCycleRevenueAmount;
            nation.administration.balance += city.governor.lastCycleRevenueAmount;
        }
        for (const nation of this.nations) {
            nation.administration.lastCycleRevenueAmount +=
                nation.administration.lastCycleTaxes.reduce((acc, curr) => acc + curr.incomeToGovernment, 0);
            nation.administration.balance +=
                nation.administration.lastCycleRevenueAmount;
        }
    }
    distributeRevenues() {
        // fund military, administration
        // pay subsidies
    }
    // economic utils
    addTax() { }
    calculateScore() {
        for (let city of this.cities) {
            let nation = this.getNationByCityId(city.instanceId);
            let player = this.getPlayerInfoByUserId(nation.controllerUserId);
            for (let person of city.persons) {
                player.score += person.consumptionChart.goods.reduce((acc, curr) => {
                    return acc + curr.qualityOfLife.qol;
                }, 0);
            }
        }
    }
    checkForCompletionOfProjects() {
        const newImprovements = [];
        for (let i = this.improvements.length - 1; i >= 0; i--) {
            let improvement = this.improvements[i];
            if (ImprovementTypeMethods.getImprovementInfo(improvement.improvementType)
                .project !== null) {
                let project = improvement;
                if (project.workAmountDone >= project.workAmountNeeded) {
                    this.improvements.splice(i, 1);
                    newImprovements.push(ImprovementTypeMethods.getCompletedProject(this.currentEntityInstanceId++, project.landTileId, project.improvementType, this));
                }
            }
        }
        this.improvements.push(...newImprovements);
    }
    // #endregion
    // #region Admin - All
    // #region Admin Load Utils
    loadAdministration() {
        // clear all load
        this.clearLoad();
        this.loadImprovements();
        for (let taxItem of this.taxData.taxItems) {
            let nation = this.getNationById(taxItem.nationId);
            let city = this.getCityByInstanceId(taxItem.cityId);
            if (taxItem.taxType === TaxType.ConsumptionTax) {
                this.loadConsumptionTax(city, taxItem, nation);
            }
            if (taxItem.taxType === TaxType.ConsumptionSubsidy) {
                this.loadConsumptionTax(city, taxItem, nation);
            }
        }
    }
    clearLoad() {
        for (const nation of this.nations) {
            nation.administration.clearLoad();
        }
        for (const city of this.cities) {
            city.governor.clearLoad();
        }
    }
    loadImprovements() {
        for (let improvement of this.improvements) {
            let landTile = this.getLandTileByTileId(improvement.landTileId);
            if (!landTile)
                throw new Error('landTile not found');
            let city = this.getCityByInstanceId(landTile.cityInstanceId);
            let nation = this.getNationByCityId(city.instanceId);
            let improvementInfo = ImprovementTypeMethods.getImprovementInfo(improvement.improvementType);
            // production taxes
            if (improvementInfo.loadsProductionTax) {
                this.loadProductionTaxes(city, improvement, nation);
            }
            // education
        }
    }
    loadProductionTaxes(city, improvement, nation) {
        for (const tax of this.taxes) {
            if (tax.taxType === TaxType.ProductionTax &&
                tax.ownerNationId === nation.id) {
                if (tax.isOnGoodFromCity(improvement.capacity.production.goodType, city.instanceId)) {
                    this.loadProductionTax(city, improvement, nation);
                }
            }
        }
    }
    loadProductionTax(city, improvement, nation) {
        const goodInfo = GoodTypeMethods.getGoodInfo(improvement.capacity.production.goodType);
        if (city.governor.collectsTaxes) {
            city.governor.addLoad([
                ResponsibilityType.Admin,
                AdminResponsibilityType.Tax,
                TaxResponsibilityType.Production,
                goodInfo.category,
                goodInfo.subCategory,
                improvement.capacity.production.goodType,
            ], ResponsibilityMethods.getTaxLoadBetweenCities(nation, city, this));
        }
        else {
            nation.administration.addLoad([
                ResponsibilityType.Admin,
                AdminResponsibilityType.Tax,
                TaxResponsibilityType.Production,
                goodInfo.category,
                goodInfo.subCategory,
                improvement.capacity.production.goodType,
            ], ResponsibilityMethods.taxLoadConstant);
        }
    }
    loadConsumptionTax(city, taxItem, nation) {
        const goodInfo = GoodTypeMethods.getGoodInfo(taxItem.goodType);
        if (city.governor.collectsTaxes) {
            city.governor.addLoad([
                ResponsibilityType.Admin,
                AdminResponsibilityType.Tax,
                TaxResponsibilityType.Consumption,
                goodInfo.category,
                goodInfo.subCategory,
                taxItem.goodType,
            ], ResponsibilityMethods.getTaxLoadBetweenCities(nation, city, this));
        }
        else {
            nation.administration.addLoad([
                ResponsibilityType.Admin,
                AdminResponsibilityType.Tax,
                TaxResponsibilityType.Consumption,
                goodInfo.category,
                goodInfo.subCategory,
                taxItem.goodType,
            ], ResponsibilityMethods.taxLoadConstant);
        }
    }
    // #endregion
    // #region Admin Distribute Experience
    distributeAdministrationExperience() {
        this.clearExperience();
        for (const nation of this.nations) {
            nation.administration.distributeExperience();
        }
        for (const city of this.cities) {
            city.governor.distributeExperience();
        }
    }
    clearExperience() {
        for (const nation of this.nations) {
            nation.administration.clearExperience();
        }
        for (const city of this.cities) {
            city.governor.clearExperience();
        }
    }
    // #endregion - Admin Distribute Experience
    // #region Admin Calculate Effectiveness
    calculateAdministrationEffectiveness() {
        for (const nation of this.nations) {
            nation.administration.calculateEffectiveness();
        }
        for (const city of this.cities) {
            city.governor.calculateEffectiveness();
        }
    }
    gainExperience() {
        for (const nation of this.nations) {
            nation.administration.gainExperience();
        }
        for (const city of this.cities) {
            city.governor.gainExperience();
        }
    }
    // #endregion Admin Calculate Effectiveness
    // #endregion Admin - All
    // #region Clone and JSON
    clone() {
        return new GameState(this.playerInfos.map((playerInfo) => playerInfo.clone()), this.realm.clone(), this.cities.map((city) => city.clone()), this.improvements.map((improvement) => improvement.clone()), this.taxes.map((tax) => tax.clone()), this.nations.map((nation) => nation.clone()), this.days, this.rngSeed, this.gameType, this.economicCycleInterval, this.currentEntityInstanceId, this.gamePlayState, this.routeTracker.clone(), this.goodsData.clone(), this.taxData.clone(), this.militaryUnits.map((unit) => unit.clone()), this.civilianUnits.map((unit) => unit.clone()));
    }
    toJSONForPlayer(userId) {
        const nation = this.getNationByControllerUserId(userId);
        let visibleTilesSet = new Set();
        for (const city of this.cities.filter((city) => city.administeringNationId === nation.id)) {
            for (const tileId of city.administeredTileLandtileIds) {
                const tile = this.getLandTileByTileId(tileId);
                tile
                    .findNeighbors(this.realm)
                    .forEach((neighbor) => visibleTilesSet.add(neighbor.id));
            }
        }
        for (const unit of this.militaryUnits.filter((unit) => unit.nationId === nation.id)) {
            const tile = this.getLandTileByTileId(unit.tileId);
            visibleTilesSet.add(tile.id);
            tile
                .findNeighbors(this.realm)
                .forEach((neighbor) => visibleTilesSet.add(neighbor.id));
        }
        for (const unit of this.civilianUnits.filter((unit) => unit.nationId === nation.id)) {
            const tile = this.getLandTileByTileId(unit.tileId);
            visibleTilesSet.add(tile.id);
            tile
                .findNeighbors(this.realm)
                .forEach((neighbor) => visibleTilesSet.add(neighbor.id));
        }
        let visibleTileIds = [];
        for (const tileId of visibleTilesSet) {
            visibleTileIds.push(tileId);
        }
        nation.discoveredTileIds = [
            ...new Set([...nation.discoveredTileIds, ...visibleTileIds]),
        ];
        const json = {
            playerInfos: this.playerInfos.map((playerInfo) => playerInfo.toJSON()),
            realm: this.realm.toJSONForPlayer(nation.discoveredTileIds, visibleTileIds),
            // realm: this.realm.toPerfectJSON(nation.discoveredTileIds, visibleTileIds),
            cities: this.cities
                .filter((city) => nation.discoveredTileIds.includes(city.centerTileLandtileId))
                .map((city) => city.toJSON()),
            improvements: this.improvements.map((improvement) => improvement.toJSON()),
            taxes: this.taxes.map((tax) => tax.toJSON()),
            nations: this.nations.map((nation) => nation.toJSON()),
            days: this.days,
            rngSeed: this.rngSeed,
            gameType: this.gameType,
            economicCycleInterval: this.economicCycleInterval,
            currentEntityInstanceId: this.currentEntityInstanceId,
            gamePlayState: this.gamePlayState,
            routeTracker: this.routeTracker.toJSON(),
            goodsData: this.goodsData.toJSON(),
            taxData: this.taxData.toJSON(),
            militaryUnits: this.militaryUnits
                .filter((unit) => visibleTileIds.includes(unit.tileId))
                .map((unit) => unit.toJSON()),
            civilianUnits: this.civilianUnits
                .filter((unit) => visibleTileIds.includes(unit.tileId))
                .map((unit) => unit.toJSON()),
        };
        return json;
    }
    toPerfectJSON() {
        const json = {
            playerInfos: this.playerInfos.map((playerInfo) => playerInfo.toJSON()),
            realm: this.realm.toPerfectJSON(),
            cities: this.cities.map((city) => city.toJSON()),
            improvements: this.improvements.map((improvement) => improvement.toJSON()),
            taxes: this.taxes.map((tax) => tax.toJSON()),
            nations: this.nations.map((nation) => nation.toJSON()),
            days: this.days,
            rngSeed: this.rngSeed,
            gameType: this.gameType,
            economicCycleInterval: this.economicCycleInterval,
            currentEntityInstanceId: this.currentEntityInstanceId,
            gamePlayState: this.gamePlayState,
            routeTracker: this.routeTracker.toJSON(),
            goodsData: this.goodsData.toJSON(),
            taxData: this.taxData.toJSON(),
            militaryUnits: this.militaryUnits.map((unit) => unit.toJSON()),
            civilianUnits: this.civilianUnits.map((unit) => unit.toJSON()),
        };
        return json;
    }
    static fromJSON(json) {
        return new GameState(json.playerInfos.map((playerInfo) => PlayerInfo.fromRuntimeJSON(playerInfo)), RuntimeRealm.fromRuntimeJSON(json.realm), json.cities.map((city) => City.fromJSON(city)), json.improvements.map((improvement) => RuntimeImprovement.fromRuntimeJSON(improvement)), json.taxes.map((tax) => TaxFactory.fromJSON(tax)), json.nations.map((nation) => Nation.fromJSON(nation)), json.days, json.rngSeed, json.gameType, json.economicCycleInterval, json.currentEntityInstanceId, json.gamePlayState, RouteTracker.fromJSON(json.routeTracker), GoodsData.fromJSON(json.goodsData), TaxData.fromJSON(json.taxData), json.militaryUnits.map((unit) => MilitaryUnit.fromJSON(unit)), json.civilianUnits.map((unit) => CivilianUnit.fromJSON(unit)));
    }
}
export default GameState;
