import styled from 'styled-components';
import '../../../../styles/constants.css';

export const LineItemStandardStyled = styled.div`
  position: relative;
  align-items: center;
  width: 100%;
  padding: 5px;
  pointer-events: auto;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};

  .line-item-main-content {
    cursor: pointer;
    pointer-events: auto;
    align-items: center;
    flex-grow: 1;

    display: ${(props) =>
      props.inlineFieldsAlignment === 'table' ? 'grid' : 'flex'};
    grid-template-columns: ${(props) =>
      props.inlineFieldsAlignment === 'table'
        ? props.hasTitle
          ? 'minmax(120px, 1fr) repeat(auto-fit, minmax(100px, 1fr))'
          : 'repeat(auto-fit, minmax(100px, 1fr))'
        : 'none'};
    justify-content: ${(props) =>
      props.inlineFieldsAlignment === 'left' ? 'flex-start' : 'space-between'};
    gap: ${(props) => (props.inlineFieldsAlignment === 'left' ? '20px' : '0')};
  }

  .line-item-title-area {
    display: inline-block;
    font-family: var(--body-font);
  }

  .line-item-title-text {
    display: inline-block;
    color: ${(props) => props.titleColor};
  }

  .line-item-title-dropdown-arrow {
    display: inline-block;
  }

  .line-item-title-selector {
    display: inline-block;
  }

  .line-item-dropdown-content {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border-radius: var(--ui-border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
`;
