import styled from 'styled-components';

export const BiomePopupStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .other-biomes {
    flex-grow: 1;
    overflow-y: auto;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
`;
