// external
import React, { useState } from 'react';

// comps
import GenericBackground, { IGenericBackground } from './Background.tsx';

// css
import { CenterTitleStyled } from './styles/CenterTitle.styled.js';
import { UIColor, UIOpacity, UIPadding } from '../Enums/UIEnums.ts';

interface ICenterTitle {
  title: string;
  padding?: number;
  roundedCorners?: boolean;
  onClick?: () => void;
  dropdown?: React.ReactNode;
  backgroundData?: IGenericBackground;
  startOpen?: boolean;
  align?: 'left' | 'center' | 'right';
}

const CenterTitle: React.FC<ICenterTitle> = ({
  title,
  padding = UIPadding.Short,
  roundedCorners = false,
  onClick,
  dropdown,
  backgroundData = {
    color: UIColor.HeaderBlue,
    opacity: UIOpacity.Header,
  },
  startOpen = true,
  align = 'center',
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);

  const handleClick = () => {
    if (dropdown) {
      setIsOpen(!isOpen);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <CenterTitleStyled
      className='center-title'
      padding={padding}
      roundedCorners={roundedCorners}
      align={align}
    >
      <div
        className='center-title-main-content clickable'
        onClick={handleClick}
      >
        {backgroundData && (
          <GenericBackground
            color={backgroundData.color}
            opacity={backgroundData.opacity}
          />
        )}

        <div className='center-title-title-area'>
          {dropdown && (
            <div className='center-title-content-arrow'>
              {isOpen ? '\u25BC' : '\u25B6'}
              &nbsp;&nbsp;&nbsp;
            </div>
          )}
          <div className='center-title-title-text'>{title}</div>
        </div>
      </div>
      {isOpen && dropdown && <div className='dropdown-content'>{dropdown}</div>}
    </CenterTitleStyled>
  );
};

export default CenterTitle;
export { ICenterTitle };
