import { ServerMessage } from '../MessageBase.js';
import GameState from '../../Game/GameState.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class EconomicCycleMessage extends ServerMessage {
    constructor(recipientUserId, gameState, cycleTime) {
        super(NetworkProtocol.EconomicCycle, recipientUserId);
        this.gameState = gameState;
        this.cycleTime = cycleTime;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { gameState: this.gameState.toJSONForPlayer(this.recipientUserId), cycleTime: this.cycleTime });
    }
    static fromJSON(json) {
        return new EconomicCycleMessage(json.recipientUserId, GameState.fromJSON(json.gameState), json.cycleTime);
    }
    // check that all fields in the message are valid
    validate() {
        return this.recipientUserId != null && this.gameState != null;
    }
}
