import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import { ClientMessage } from '../MessageBase.js';
export default class CreateCivilianUnitMessage extends ClientMessage {
    constructor(messageId, senderUserId, cityId, isCityFounder, isBuilder, hasMountainTraining, hasShips) {
        super(NetworkProtocol.CreateCivilianUnit, messageId, senderUserId);
        this.cityId = cityId;
        this.isCityFounder = isCityFounder;
        this.isBuilder = isBuilder;
        this.hasMountainTraining = hasMountainTraining;
        this.hasShips = hasShips;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { cityId: this.cityId, isCityFounder: this.isCityFounder, isBuilder: this.isBuilder, hasMountainTraining: this.hasMountainTraining, hasShips: this.hasShips });
    }
    static fromJSON(json) {
        return new CreateCivilianUnitMessage(json.messageId, json.senderUserId, json.cityId, json.isCityFounder, json.isBuilder, json.hasMountainTraining, json.hasShips);
    }
    validate() {
        return super.validate();
    }
}
