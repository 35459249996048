import React, { useState, useContext, useEffect, useCallback } from 'react';
import City from '../../../../../GildedLands/lib/Classes/City/City';
import RuntimeImprovement from '../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement';
import { ImprovementType } from '../../../../../GildedLands/lib/Enums/Improvement';
import Unit from '../../../../../GildedLands/lib/Classes/Unit/Unit';

// this is for saving selected entities for the UI - selecting somethign before sending it to a funtion
// this should never take anythign else as context - it is a reference for other contexts
const SelectedEntityContextProvider = ({ children }) => {
  const [selectedRuntimeImprovement, setSelectedRuntimeImprovement] =
    useState<RuntimeImprovement | null>(null);

  const [selectedImprovementTypeInfo, setSelectedImprovementTypeInfo] =
    useState<ImprovementType | null>(null);

  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

  return (
    <SelectedEntityContext.Provider
      value={{
        selectedRuntimeImprovement,
        setSelectedRuntimeImprovement,
        selectedImprovementTypeInfo,
        setSelectedImprovementTypeInfo,
        selectedCity,
        setSelectedCity,
        selectedUnit,
        setSelectedUnit,
      }}
    >
      {children}
    </SelectedEntityContext.Provider>
  );
};

interface SelectedEntityContextType {
  selectedRuntimeImprovement: RuntimeImprovement | null;
  setSelectedRuntimeImprovement: React.Dispatch<
    React.SetStateAction<RuntimeImprovement | null>
  >;
  selectedImprovementTypeInfo: ImprovementType | null;
  setSelectedImprovementTypeInfo: React.Dispatch<
    React.SetStateAction<ImprovementType | null>
  >;
  selectedCity: City | null;
  setSelectedCity: React.Dispatch<React.SetStateAction<City | null>>;
  selectedUnit: Unit | null;
  setSelectedUnit: (selectedUnit: Unit | null) => void;
}

const SelectedEntityContext =
  React.createContext<SelectedEntityContextType | null>(null);

export { SelectedEntityContextProvider, SelectedEntityContext };
