import styled from 'styled-components';
import '../../../../../../../styles/constants.css';

export const PeopleScreenStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .content {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 100%;
  }

  .sidebar {
    width: 200px;
    height: 100%;
  }

  .people-report-holder {
    width: calc(100% - 200px);
  }
`;
