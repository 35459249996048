// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

import TaxManager from './TaxManager/TaxManager.tsx';
import TaxReports from './TaxReporting/TaxReports.tsx';

// internal context

// css
import { TaxesStyled } from './styles/Taxes.styled.js';

// lol

const Taxes = () => {
  // #region context

  // #endregion
  const [activeScreen, setActiveScreen] = useState('taxManager');

  const toggleScreen = (screenName) => {
    setActiveScreen(screenName);
  };

  return (
    <TaxesStyled className='taxes'>
      <div className='tax-buttons'>
        <button
          className={
            activeScreen === 'taxReporting' ? 'active tax-button' : 'tax-button'
          }
          onClick={() => toggleScreen('taxReporting')}
        >
          Reporting
        </button>
        <button
          className={
            activeScreen === 'taxManager' ? 'active tax-button' : 'tax-button'
          }
          onClick={() => toggleScreen('taxManager')}
        >
          Manage
        </button>
      </div>
      {activeScreen === 'taxReporting' && <TaxReports />}
      {activeScreen === 'taxManager' && <TaxManager />}
    </TaxesStyled>
  );
};

export default Taxes;
