import React from 'react';

// GildedLands imports
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';

// UI Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import ImageBox from '../../GenericComponents/ImageBox.tsx';

// Utility functions
import getPersonData from '../Person/Person.tsx';
import getImprovementDescription from './ImprovementDescription.tsx';
import { RequirementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/Requirement';
import { ExperienceType } from '../../../../../../../GildedLands/lib/Enums/Experience';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';
import SelectionButton from '../../GenericComponents/SelectionButton.tsx';

const getExistingImprovementInfo = (
  improvement: RuntimeImprovement,
  gameState: GameState,
  handleChangeKnowledge: (
    knowledgeIndex: number,
    experienceType: ExperienceType
  ) => void,
  addPopup: (popup: {
    title: string;
    description: string;
    options: { name: string; color: string; onSelect: () => void }[];
  }) => void,
  handleChangeProduction: () => void,
  handleRemoveImprovement: () => void
): React.ReactNode => {
  const data: React.ReactNode[] = [];

  const improvementInfo = ImprovementTypeMethods.getImprovementInfo(
    improvement.improvementType
  );

  if (improvementInfo.image && improvementInfo.image !== '') {
    data.push(<ImageBox url={improvementInfo.image} />);
  }

  data.push(renderHeader('Actions'));

  data.push(renderActions(improvement, gameState, handleRemoveImprovement));

  data.push(renderHeader('Info'));

  data.push(renderImprovementDescription(improvement));

  if (improvement.capacity) {
    data.push(renderHeader('Production Last Cycle'));
    data.push(
      <VerticalList
        data={[
          <SelectionButton
            title='Change Production'
            onClick={handleChangeProduction}
            amSelected={false}
            selectable={true}
          />,
        ]}
        backgroundData={{
          color: UIColor.ListBlue,
          opacity: UIOpacity.List,
        }}
      />
    );
    data.push(
      renderProductionInfo(improvement, gameState, handleChangeProduction)
    );
  }

  if (improvement.knowledge && improvement.knowledge.length > 0) {
    data.push(renderHeader('Knowledge'));
    data.push(
      renderKnowledgeInfo(
        improvement,
        gameState,
        handleChangeKnowledge,
        addPopup
      )
    );
  }

  return <VerticalList data={data} />;
};

const renderHeader = (title: string): React.ReactNode => (
  <CenterTitle
    title={title}
    padding={UIPadding.Short}
    backgroundData={{
      color: UIColor.HeaderBlue,
      opacity: UIOpacity.Header,
    }}
  />
);

const renderActions = (
  improvement: RuntimeImprovement,
  gameState: GameState,
  handleRemoveImprovement: () => void
): React.ReactNode => {
  return (
    <VerticalList
      data={[
        <SelectionButton
          title='Remove'
          onClick={handleRemoveImprovement}
          amSelected={false}
          selectable={true}
        />,
      ]}
    />
  );
};

const renderImprovementDescription = (
  improvement: RuntimeImprovement
): React.ReactNode => {
  const description = getImprovementDescription(improvement.improvementType);
  const descriptionContent = Array.isArray(description)
    ? description
    : [description];

  return (
    <VerticalList
      data={descriptionContent}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.List,
      }}
    />
  );
};

const renderProductionInfo = (
  improvement: RuntimeImprovement,
  gameState: GameState,
  handleChangeProduction: (goodType: GoodType) => void
): React.ReactNode => {
  const goodsData = gameState.goodsData.productionByPerson.filter(
    (item) => item.improvementId === improvement.instanceId
  );

  const workersValue = `${goodsData.length} / ${improvement.capacity?.maxCapacity}`;
  const unitsValue = goodsData
    .reduce((acc, curr) => acc + curr.productionAmount, 0)
    .toFixed(0);
  const revenueValue = goodsData
    .reduce((acc, curr) => acc + curr.marketValue, 0)
    .toFixed(0);
  const taxPaidValue = goodsData
    .reduce(
      (acc, curr) =>
        acc +
        curr.taxes.reduce(
          (accTax, currTax) => accTax + currTax.incomeToGovernment,
          0
        ),
      0
    )
    .toFixed(0);

  const inlineDataFields = [
    {
      fieldData: {
        fieldName: 'Workers',
        textColor: UIColor.White,
        value: workersValue,
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'Units',
        textColor: UIColor.White,
        value: unitsValue,
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'Revenue',
        textColor: UIColor.White,
        value: revenueValue,
      },
      dropdown: null,
    },
    {
      fieldData: {
        fieldName: 'Tax Paid',
        textColor: UIColor.White,
        value: taxPaidValue,
      },
      dropdown: null,
    },
  ];

  return (
    <VerticalList
      data={[
        <LineItemStandard
          title={improvement.capacity?.production.goodType || 'Production'}
          titleColor={UIColor.White}
          inlineDataFields={inlineDataFields}
          dropdown={renderGoodsDropdown(goodsData, gameState)}
        />,
      ]}
      backgroundData={{
        color: UIColor.ListBlue,
        opacity: UIOpacity.List,
      }}
    />
  );
};

const renderGoodsDropdown = (
  goodsData: any[],
  gameState: GameState
): React.ReactNode => (
  <VerticalList
    data={goodsData.map((item, index) => (
      <React.Fragment key={index}>
        {getPersonData(item, gameState, false, false, true)}
      </React.Fragment>
    ))}
  />
);

const renderKnowledgeInfo = (
  improvement: RuntimeImprovement,
  gameState: GameState,
  handleChangeKnowledge: (
    knowledgeIndex: number,
    experienceType: ExperienceType
  ) => void,
  addPopup: (popup: {
    title: string;
    description: string;
    options: { name: string; color: string; onSelect: () => void }[];
  }) => void
): React.ReactNode => {
  const info = ImprovementTypeMethods.getImprovementInfo(
    improvement.improvementType
  );
  const landTile = gameState.getLandTileByTileId(improvement.landTileId);

  return (
    <VerticalList
      data={improvement.knowledge.map((knowledge, index) => {
        const availableExperienceTypes = info.knowledge[
          index
        ].availableExperienceTypes.filter(
          (experienceType) =>
            RequirementTypeMethods.checkRequirement(
              experienceType.requirements,
              improvement.improvementType,
              landTile,
              gameState
            ).isMet
        );

        return (
          <LineItemStandard
            title={knowledge.experienceType}
            titleColor={UIColor.White}
            inlineDataFields={[
              {
                fieldData: {
                  fieldName: 'Amount',
                  textColor: UIColor.White,
                  value: knowledge.amount.toFixed(0),
                },
                dropdown: null,
              },
            ]}
            onMainClick={() => {
              addPopup({
                title: knowledge.experienceType,
                description: '',
                options: availableExperienceTypes.map(
                  (experienceType, index) => ({
                    name: experienceType.experienceType,
                    color: UIColor.White,
                    onSelect: () => {
                      handleChangeKnowledge(
                        index,
                        experienceType.experienceType
                      );
                    },
                  })
                ),
              });
            }}
          />
        );
      })}
    />
  );
};

export default getExistingImprovementInfo;
