// external
import React, { useRef, useState, useEffect, useContext } from 'react';

// internal
// contexts
import { SocketContext } from '../AdminAndNetworking/SocketContextProvider.tsx';
import { GameStatusContext } from './GameStatusContextProvider.tsx';
import { NotificationFlagsDataContext } from '../PopupsAndNotifications/NotificationFlagsDataContextProvider.tsx';
import { GameStateDataContext } from './GameStateDataContextProvider.tsx';
// #region lol imports
import { NetworkProtocol } from '../../../../../GildedLands/lib/Enums/NetworkProtocol.js';
import TargetInfo from '../../../../../GildedLands/lib/Classes/Target/TargetInfo.js';
import StartGameMessage from '../../../../../GildedLands/lib/Classes/Networking/GameLoop/Game/StartGameMessage';
import ServerSendingGamestateForRejoin from '../../../../../GildedLands/lib/Classes/Networking/Connection/ServerSendingGamestateForRejoinMessage';
import GameState from '../../../../../GildedLands/lib/Classes/Game/GameState';
import PlayerInfo from '../../../../../GildedLands/lib/Classes/Player/PlayerInfo';
import EndGameMessage from '../../../../../GildedLands/lib/Classes/Networking/GameLoop/Game/EndGameMessage';
import GameCycleStartedMessage from '../../../../../GildedLands/lib/Classes/Networking/Connection/GameCycleStartedMessage';
import GamePausedMessage from '../../../../../GildedLands/lib/Classes/Networking/Connection/GamePausedMessage';
import { GamePlayState } from '../../../../../GildedLands/lib/Enums/GamePlayState';
import RuntimeLandTile from '../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import CityFoundedMessage from '../../../../../GildedLands/lib/Classes/Networking/City/CityFounded';
import ImprovementCreatedMessage from '../../../../../GildedLands/lib/Classes/Networking/Improvement/ImprovementCreatedMessage';
import GameStateMessage from '../../../../../GildedLands/lib/Classes/Networking/GameLoop/GameStateMessage';
import EconomicCycleMessage from '../../../../../GildedLands/lib/Classes/Networking/GameLoop/EconomicCycleMessage';
import Player from '../../../../../GildedLands/lib/Classes/Player/Player.js';
import {
  GoodCategory,
  GoodSubCategory,
  GoodType,
} from '../../../../../GildedLands/lib/Enums/Good';
import { TaxType } from '../../../../../GildedLands/lib/Enums/Tax';
import Tax from '../../../../../GildedLands/lib/Classes/Tax/Tax';
import NationalAdministration from '../../../../../GildedLands/lib/Classes/Administration/NationalAdministration';
import CityAdministration from '../../../../../GildedLands/lib/Classes/Administration/CityAdministration';
import { ImprovementType } from '../../../../../GildedLands/lib/Enums/Improvement';
import { ExperienceType } from '../../../../../GildedLands/lib/Enums/Experience';

// #endregion

//
const GameStateLogicContextProvider = ({ children }) => {
  // CONTEXT
  // socket
  const { socket, subscribeToEvent, emitEvent } = useContext(SocketContext);

  // game state
  const { setGameState, userId, setUserId } = useContext(GameStateDataContext);
  const { setLastCycleRunTime } = useContext(GameStatusContext);

  // notification flags
  const { resetEventsAndNotifications } = useContext(
    NotificationFlagsDataContext
  );

  // master player state
  const [masterPlayer, setMasterPlayer] = useState<Player>(null);
  const masterPlayerRef = useRef<Player>(); // for socket listeners
  masterPlayerRef.current = masterPlayer;

  useEffect(() => {
    if (!masterPlayer) return;
    if (!masterPlayer.gameState) return;

    setGameState(() => masterPlayer.gameState);
    if (masterPlayer.userId && masterPlayer.userId !== userId)
      setUserId(masterPlayer.userId);
  }, [masterPlayer]);

  // #region Subscribe to Game Events
  // we do this immediately - it's good practice
  useEffect(() => {
    if (!socket) return;

    // Subscribe to socket events using the provided functions from SocketContext
    const unsubscribeStartGame = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.StartGame],
      onStartGame
    );
    const unsubscribeEndGame = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.EndGame],
      onEndGame
    );
    const unsubscribeGamestateForRejoin = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.ServerSendingGamestateForRejoin],
      onGamestateForRejoin
    );

    const unsubscribeCityFounded = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.CityFounded],
      onCityFounded
    );

    const unsubscribeImprovementCreated = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.ImprovementCreated],
      onImprovementCreated
    );

    const unsubscribeGameStateUpdate = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.GameStateUpdate],
      onGameStateReceived
    );

    const unsubscribeEconomicCycleRan = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.EconomicCycle],
      onEconomicCycleRan
    );

    const unsubscribeGameCycleStarted = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.GameCycleStarted],
      onGameCycleStarted
    );
    const unsubscribeGameCyclePaused = subscribeToEvent(
      NetworkProtocol[NetworkProtocol.GameCyclePaused],
      onGameCyclePaused
    );

    return () => {
      unsubscribeStartGame();
      unsubscribeEndGame();
      unsubscribeGamestateForRejoin();
      unsubscribeCityFounded();
      unsubscribeImprovementCreated();
      unsubscribeGameStateUpdate();
      unsubscribeEconomicCycleRan();
      unsubscribeGameCycleStarted();
      unsubscribeGameCyclePaused();
    };
  }, [socket, subscribeToEvent]);

  // #endregion

  // #region Subscribe to Rejoin Event
  useEffect(() => {
    if (!socket) return;

    const handleRejoinedGame = () => {
      let newPlayer = new Player('', emitEvent);
      newPlayer.fetchUpdatedGamestate();
    };

    socket.on('rejoined-game', handleRejoinedGame);

    return () => {
      socket.off('rejoined-game', handleRejoinedGame);
    };
  }, [socket]);
  // #endregion

  // #region Recieve Messages From Server

  const onStartGame = (msg: StartGameMessage) => {
    try {
      resetEventsAndNotifications();

      const newPlayer = new Player('', emitEvent);
      newPlayer.onStartGame(msg);
      setMasterPlayer(newPlayer);
      console.log('onStartGame, newPlayer:', newPlayer);

      const tile = newPlayer.gameState.getLandTileByTileId(
        newPlayer.gameState.civilianUnits[0].tileId
      );
      // setCenteredTilePosition({ x: tile.x, y: tile.y });
    } catch (err) {
      console.error('Error in onStartGame:', err);
    }
  };

  const onEndGame = (msg: EndGameMessage) => {
    try {
      if (!checkForSavedPlayer('onEndGame')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onEndGame(msg);
        console.log('onEndGame - newMasterPlayer: ', newMasterPlayer);
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in onEndGame:', err);
    }
  };

  const onGamestateForRejoin = (msg: ServerSendingGamestateForRejoin) => {
    try {
      const newPlayer = new Player('', emitEvent);
      newPlayer.onGamestateForRejoin(msg);
      setMasterPlayer(newPlayer);
      console.log('onGamestateForRejoin, newPlayer:', newPlayer);

      // #region center the tile
      const nation = newPlayer.gameState.getNationByControllerUserId(
        newPlayer.userId
      );
      const firstCity = newPlayer.gameState.cities.filter(
        (city) => city.ownerNationId === nation.id
      )[0];

      let hoverTile = null;
      if (!!firstCity) {
        hoverTile = newPlayer.gameState.getLandTileByTileId(
          firstCity.centerTileLandtileId
        );
      } else {
        const settler = newPlayer.gameState.civilianUnits.filter(
          (unit) => unit.nationId === nation.id
        )[0];
        hoverTile = newPlayer.gameState.getLandTileByTileId(settler.tileId);
      }
      // if (!!hoverTile) {
      //   setCenteredTilePosition({ x: hoverTile.x, y: hoverTile.y });
      // }
      // #endregion
    } catch (err) {
      console.error('Error in onGamestateForRejoin:', err);
    }
  };

  const onCityFounded = (msg: CityFoundedMessage) => {
    try {
      if (!checkForSavedPlayer('onCityFounded')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onCityFounded(msg);
        console.log('onCityFounded - newMasterPlayer: ', newMasterPlayer);
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in onCityFounded:', err);
    }
  };

  const onImprovementCreated = (msg: ImprovementCreatedMessage) => {
    try {
      if (!checkForSavedPlayer('onImprovementCreated')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onImprovementCreated(msg);
        console.log(
          'onImprovementCreated - newMasterPlayer: ',
          newMasterPlayer
        );
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in onImprovementCreated:', err);
    }
  };

  const onGameStateReceived = (msg: GameStateMessage) => {
    try {
      if (!checkForSavedPlayer('onGameStateReceived')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onGameStateReceived(msg);
        console.log('onGameStateReceived - newMasterPlayer: ', newMasterPlayer);
        return newMasterPlayer;
      });
    } catch (err) {
      console.error('Error in onGameStateReceived:', err);
    }
  };

  const onEconomicCycleRan = (msg: EconomicCycleMessage) => {
    try {
      if (!checkForSavedPlayer('onEconomicCycleRan')) return;

      setMasterPlayer((prevMasterPlayer) => {
        const newMasterPlayer = prevMasterPlayer.clone();
        newMasterPlayer.onEconomicCycleRan(msg);
        return newMasterPlayer;
      });

      setLastCycleRunTime(msg.cycleTime);
    } catch (err) {
      console.error('Error in onEconomicCycleRan:', err);
    }
  };

  const onGameCycleStarted = (msg: GameCycleStartedMessage) => {
    console.log('onGameCycleStarted');
    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.gameState.gamePlayState = GamePlayState.Ongoing;
      console.log('onGameCycleStarted - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  const onGameCyclePaused = (msg: GamePausedMessage) => {
    console.log('onGameCyclePaused');
    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.gameState.gamePlayState = GamePlayState.Paused;
      console.log('onGameCyclePaused - newMasterPlayer: ', newMasterPlayer);
      return newMasterPlayer;
    });
  };

  // const onLandExplored = (msg: LandExploredMessage) => {
  //   try {
  //     if (!checkForSavedPlayer('onLandExplored')) return;

  //     setMasterPlayer((prevMasterPlayer) => {
  //       const newMasterPlayer = prevMasterPlayer.clone();
  //       newMasterPlayer.onLandExplored(msg);
  //       console.log('onLandExplored - newMasterPlayer: ', newMasterPlayer);
  //       return newMasterPlayer;
  //     });
  //   } catch (err) {
  //     console.error('Error in onLandExplored:', err);
  //   }
  // };

  // #endregion

  // #region Send Messages To Server

  const foundCity = (landTileId: number, founderInstanceId: number) => {
    if (!checkForSavedPlayer('foundCity')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.foundCity(landTileId, founderInstanceId);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const buildImprovement = (
    landTileId: number,
    improvementType: ImprovementType,
    goodType: GoodType | null
  ) => {
    if (!checkForSavedPlayer('buildImprovement')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.createImprovement(
          landTileId,
          improvementType,
          goodType
        );
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const removeImprovement = (improvementInstanceId: number) => {
    if (!checkForSavedPlayer('removeImprovement')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.removeImprovement(improvementInstanceId);
      return newMasterPlayer;
    });
  };

  const increasePopulation = (cityInstanceId: number) => {
    if (!checkForSavedPlayer('increasePopulation')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.increasePopulation(cityInstanceId);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const decreasePopulation = (cityInstanceId: number) => {
    if (!checkForSavedPlayer('decreasePopulation')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.decreasePopulation(cityInstanceId);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const createCivilianUnit = (
    cityInstanceId: number,
    isCityFounder: boolean,
    isBuilder: boolean,
    isMountainTrained: boolean,
    hasShips: boolean
  ) => {
    if (!checkForSavedPlayer('createCivilianUnit')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.createCivilianUnit(
        cityInstanceId,
        isCityFounder,
        isBuilder,
        isMountainTrained,
        hasShips
      );
      return newMasterPlayer;
    });
  };

  const addTax = (taxType: TaxType) => {
    if (!checkForSavedPlayer('editTax')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.addTax(taxType);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const deleteTax = (taxInstanceId: number) => {
    if (!checkForSavedPlayer('deleteTax')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.deleteTax(taxInstanceId);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const editTax = (tax: Tax) => {
    if (!checkForSavedPlayer('editTax')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      try {
        newMasterPlayer.editTax(tax);
      } catch (err) {
        console.log(err);
      }
      return newMasterPlayer;
    });
  };

  const changeImprovementProduction = (
    improvementInstanceId: number,
    goodType: GoodType
  ) => {
    if (!checkForSavedPlayer('changeImprovementProduction')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.changeImprovementProduction(
        improvementInstanceId,
        goodType
      );
      return newMasterPlayer;
    });
  };

  const changeKnowledge = (
    improvementInstanceId: number,
    knowledgeIndex: number,
    experienceType: ExperienceType
  ) => {
    if (!checkForSavedPlayer('changeKnowledge')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.changeKnowledge(
        improvementInstanceId,
        knowledgeIndex,
        experienceType
      );
      return newMasterPlayer;
    });
  };

  const saveNationalAdministration = (
    nationalAdministration: NationalAdministration
  ) => {
    if (!checkForSavedPlayer('saveNationalAdministration')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.saveNationalAdministration(nationalAdministration);
      return newMasterPlayer;
    });
  };

  const saveCityAdministration = (
    cityAdministration: CityAdministration,
    cityId: number
  ) => {
    if (!checkForSavedPlayer('saveCityAdministration')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.saveCityAdministration(cityAdministration, cityId);
      return newMasterPlayer;
    });
  };

  const moveUnit = (unitInstanceId: number, destinationTileId: number) => {
    if (!checkForSavedPlayer('moveUnit')) return;

    setMasterPlayer((prevMasterPlayer) => {
      const newMasterPlayer = prevMasterPlayer.clone();
      newMasterPlayer.moveUnit(unitInstanceId, destinationTileId);
      return newMasterPlayer;
    });
  };

  // #endregion

  // #region checkForSavedPlayer - utility function

  const checkForSavedPlayer = (msg) => {
    if (!masterPlayerRef.current) {
      console.log(
        'DID NOT FIND THE PLAYER during',
        msg,
        '- FETCHING NEW GAMESTATE'
      );
      const newPlayer = new Player('', emitEvent);
      newPlayer.fetchUpdatedGamestate();
      return false;
    }

    return true;
  };

  // #endregion

  const value: GameStateLogicContextType = {
    foundCity,
    buildImprovement,
    removeImprovement,
    changeImprovementProduction,
    changeKnowledge,
    increasePopulation,
    decreasePopulation,
    addTax,
    deleteTax,
    editTax,
    saveNationalAdministration,
    saveCityAdministration,
    createCivilianUnit,
    moveUnit,
  };

  return (
    <GameStateLogicContext.Provider value={value}>
      {children}
    </GameStateLogicContext.Provider>
  );
};

interface GameStateLogicContextType {
  foundCity: (landTileId: number, founderInstanceId: number) => void;
  buildImprovement: (
    landTileId: number,
    improvementType: ImprovementType,
    goodType: GoodType | null
  ) => void;
  removeImprovement: (improvementInstanceId: number) => void;
  changeImprovementProduction: (
    improvementInstanceId: number,
    goodType: GoodType
  ) => void;
  changeKnowledge: (
    improvementInstanceId: number,
    knowledgeIndex: number,
    experienceType: ExperienceType
  ) => void;
  increasePopulation: (cityInstanceId: number) => void;
  decreasePopulation: (cityInstanceId: number) => void;
  createCivilianUnit: (
    cityInstanceId: number,
    isCityFounder: boolean,
    isBuilder: boolean,
    isMountainTrained: boolean,
    hasShips: boolean
  ) => void;
  addTax: (taxType: TaxType) => void;
  deleteTax: (taxInstanceId: number) => void;
  editTax: (tax: Tax) => void;
  saveNationalAdministration: (
    nationalAdministration: NationalAdministration
  ) => void;
  saveCityAdministration: (
    cityAdministration: CityAdministration,
    cityId: number
  ) => void;
  moveUnit: (unitInstanceId: number, destinationTileId: number) => void;
}

const GameStateLogicContext =
  React.createContext<GameStateLogicContextType | null>(null);

export { GameStateLogicContextProvider, GameStateLogicContext };
