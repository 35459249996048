// external
import React, { useEffect, useState, useContext } from 'react';

// internal
// hooks

// components

// css
import { ImprovementBuilderPageStyled } from './styles/ImprovementBuilderPage.styled.js';
import QuickPage from '../../UtilityComponents/QuickPage.tsx';
import getImprovementInfoForBuilder from '../../UI/MappingToData/ImprovementEditing/ImprovementEditing.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../../UI/Enums/UIEnums.ts';
import VerticalList from '../../UI/GenericComponents/VerticalList.tsx';
import { ScreenModeContext } from '../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';

// gl

function ImprovementBuilderPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setScreenMode, ScreenMode } = useContext(ScreenModeContext);

  useEffect(() => {
    setScreenMode(ScreenMode.NotInGame);
  }, []);

  return (
    <ImprovementBuilderPageStyled>
      <QuickPage />
      <div className='improvement-builder-content'>
        <div className='improvement-info-list ui-gold'>
          <div className='improvement-info-line-items'>
            <VerticalList
              data={getImprovementInfoForBuilder()}
              backgroundData={{
                color: UIColor.ListBlue,
                opacity: 1,
              }}
            />
          </div>
        </div>
      </div>
    </ImprovementBuilderPageStyled>
  );
}

export default ImprovementBuilderPage;
