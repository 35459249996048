// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import CivilianUnitInfo from './CivilianUnitInfo.tsx';
import MilitaryUnitInfo from './MilitaryUnitInfo.tsx';

// internal context
import { SelectedEntityContext } from '../../../../../contexts/GameData/SelectedEntityContextProvider.tsx';

// gl
import CivilianUnit from '../../../../../../../../GildedLands/lib/Classes/Unit/CivilianUnit';
import MilitaryUnit from '../../../../../../../../GildedLands/lib/Classes/Unit/MilitaryUnit';

const UnitInfo = () => {
  const { selectedUnit } = useContext(SelectedEntityContext);

  if (!selectedUnit) {
    return null;
  }

  return (
    <div className='unit-info'>
      {selectedUnit instanceof CivilianUnit && (
        <CivilianUnitInfo selectedUnitId={selectedUnit.instanceId} />
      )}
      {selectedUnit instanceof MilitaryUnit && (
        <MilitaryUnitInfo selectedUnit={selectedUnit} />
      )}
    </div>
  );
};

export default UnitInfo;
