import { GoodTypeMethods, } from '../../Enums/Good.js';
import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class ProductionTax extends Tax {
    constructor(instanceId, ownerNationId, taxPercentage, isOnAllGoods, excludedCategories, excludedSubCategories, excludedGoods, includedCategories, includedSubCategories, includedGoods, isOnAllCities, excludedCityInstanceIds, includedCityInstanceIds) {
        super(instanceId, ownerNationId, TaxType.ProductionTax);
        this.taxPercentage = taxPercentage;
        this.isOnAllGoods = isOnAllGoods;
        this.excludedCategories = excludedCategories;
        this.excludedSubCategories = excludedSubCategories;
        this.excludedGoods = excludedGoods;
        this.includedCategories = includedCategories;
        this.includedSubCategories = includedSubCategories;
        this.includedGoods = includedGoods;
        this.isOnAllCities = isOnAllCities;
        this.excludedCityInstanceIds = excludedCityInstanceIds;
        this.includedCityInstanceIds = includedCityInstanceIds;
    }
    isOnGoodFromCity(goodType, producingCityInstanceId) {
        if (this.isOnAllCities) {
            if (this.excludedCityInstanceIds.includes(producingCityInstanceId)) {
                return false;
            }
        }
        else {
            if (!this.includedCityInstanceIds.includes(producingCityInstanceId)) {
                return false;
            }
        }
        if (this.includedGoods.includes(goodType)) {
            return true;
        }
        if (this.excludedGoods.includes(goodType)) {
            return false;
        }
        if (this.includedSubCategories.includes(GoodTypeMethods.getGoodInfo(goodType).subCategory)) {
            return true;
        }
        if (this.excludedSubCategories.includes(GoodTypeMethods.getGoodInfo(goodType).subCategory)) {
            return false;
        }
        if (this.includedCategories.includes(GoodTypeMethods.getGoodInfo(goodType).category)) {
            return true;
        }
        if (this.excludedCategories.includes(GoodTypeMethods.getGoodInfo(goodType).category)) {
            return false;
        }
        if (this.isOnAllGoods) {
            return true;
        }
        return false;
    }
    getCost(goodType, producingCityInstanceId, cost, gameState) {
        if (this.isOnGoodFromCity(goodType, producingCityInstanceId)) {
            return cost * this.taxPercentage;
        }
        return 0;
    }
    clone() {
        return new ProductionTax(this.instanceId, this.ownerNationId, this.taxPercentage, this.isOnAllGoods, [...this.excludedCategories], [...this.excludedSubCategories], [...this.excludedGoods], [...this.includedCategories], [...this.includedSubCategories], [...this.includedGoods], this.isOnAllCities, [...this.excludedCityInstanceIds], [...this.includedCityInstanceIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            taxPercentage: this.taxPercentage,
            isOnAllGoods: this.isOnAllGoods,
            excludedCategories: [...this.excludedCategories],
            excludedSubCategories: [...this.excludedSubCategories],
            excludedGoods: [...this.excludedGoods],
            includedCategories: [...this.includedCategories],
            includedSubCategories: [...this.includedSubCategories],
            includedGoods: [...this.includedGoods],
            isOnAllCities: this.isOnAllCities,
            excludedCityInstanceIds: [...this.excludedCityInstanceIds],
            includedCityInstanceIds: [...this.includedCityInstanceIds],
        };
    }
    static fromJSON(json) {
        return new ProductionTax(json.instanceId, json.ownerNationId, json.taxPercentage, json.isOnAllGoods, [...json.excludedCategories], [...json.excludedSubCategories], [...json.excludedGoods], [...json.includedCategories], [...json.includedSubCategories], [...json.includedGoods], json.isOnAllCities, [...json.excludedCityInstanceIds], [...json.includedCityInstanceIds]);
    }
}
export default ProductionTax;
