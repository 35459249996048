import React, { useState, useContext } from 'react';
import { TaxItemStyled } from './styles/TaxItem.styled.js';
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import ProductionTax from '../../../../../../../../../GildedLands/lib/Classes/Tax/ProductionTax.js';
import {
  GoodCategory,
  GoodSubCategory,
  GoodType,
} from '../../../../../../../../../GildedLands/lib/Enums/Good.js';
import EnumList from '../Utilities/EnumList.tsx';
import CityList from '../Utilities/CityList.tsx';

interface ProductionTaxItemProps {
  tax: ProductionTax;
}

const ProductionTaxItem = ({ tax }: ProductionTaxItemProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { editTax, deleteTax } = useContext(GameStateLogicContext);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const saveNewTax = (newTax: ProductionTax) => {
    editTax(newTax);
  };

  return (
    <TaxItemStyled>
      <div className='tax-main-info' onClick={toggleDetails}>
        <span className='tax-title'>
          {showDetails ? '^ ' : '> '}
          Production Tax
        </span>
        <span className='tax-info'>Tax Percentage: {tax.taxPercentage}</span>
      </div>
      {showDetails && (
        <div className='tax-item-details'>
          <div className='tax-details-category-content'>
            <label>
              Tax Percentage:
              <input
                type='number'
                name='taxPercentage'
                defaultValue={tax.taxPercentage * 100}
                onBlur={(e) => {
                  const newTax = tax.clone();
                  newTax.taxPercentage = parseFloat(e.target.value) / 100;
                  saveNewTax(newTax);
                }}
              />
              <span>%</span>
            </label>
            <div className='tax-goods'>
              <label>
                Is On All Goods:
                <input
                  type='checkbox'
                  name='isOnAllGoods'
                  defaultChecked={tax.isOnAllGoods}
                  onChange={(e) => {
                    const newTax = tax.clone();
                    newTax.isOnAllGoods = e.target.checked;
                    saveNewTax(newTax);
                  }}
                />
              </label>
              <EnumList
                title='Excluded Categories'
                enumObject={GoodCategory}
                values={tax.excludedCategories}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.excludedCategories = values;
                  saveNewTax(newTax);
                }}
              />
              <EnumList
                title='Excluded SubCategories'
                enumObject={GoodSubCategory}
                values={tax.excludedSubCategories}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.excludedSubCategories = values;
                  saveNewTax(newTax);
                }}
              />
              <EnumList
                title='Excluded Goods'
                enumObject={GoodType}
                values={tax.excludedGoods}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.excludedGoods = values;
                  saveNewTax(newTax);
                }}
              />
              <EnumList
                title='Included Categories'
                enumObject={GoodCategory}
                values={tax.includedCategories}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.includedCategories = values;
                  saveNewTax(newTax);
                }}
              />
              <EnumList
                title='Included SubCategories'
                enumObject={GoodSubCategory}
                values={tax.includedSubCategories}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.includedSubCategories = values;
                  saveNewTax(newTax);
                }}
              />
              <EnumList
                title='Included Goods'
                enumObject={GoodType}
                values={tax.includedGoods}
                save={(values) => {
                  const newTax = tax.clone();
                  newTax.includedGoods = values;
                  saveNewTax(newTax);
                }}
              />
            </div>
            <div className='tax-cities'>
              <label>
                Is On All Cities:
                <input
                  type='checkbox'
                  name='isOnAllCities'
                  defaultChecked={tax.isOnAllCities}
                  onChange={(e) => {
                    const newTax = tax.clone();
                    newTax.isOnAllCities = e.target.checked;
                    saveNewTax(newTax);
                  }}
                />
              </label>
              <CityList
                cityData={{
                  isOnAllCities: tax.isOnAllCities,
                  excludedCityInstanceIds: tax.excludedCityInstanceIds,
                  includedCityInstanceIds: tax.includedCityInstanceIds,
                }}
                save={(cityData: {
                  isOnAllCities: boolean;
                  excludedCityInstanceIds: number[];
                  includedCityInstanceIds: number[];
                }) => {
                  const newTax = tax.clone();
                  newTax.isOnAllCities = cityData.isOnAllCities;
                  newTax.excludedCityInstanceIds =
                    cityData.excludedCityInstanceIds;
                  newTax.includedCityInstanceIds =
                    cityData.includedCityInstanceIds;
                  saveNewTax(newTax);
                }}
              />
            </div>
          </div>
          <button
            className='delete-tax-button'
            onClick={() => deleteTax(tax.instanceId)}
          >
            Delete
          </button>
        </div>
      )}
    </TaxItemStyled>
  );
};

export default ProductionTaxItem;
