// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

// internal context

// css
import { CitySidebarStyled } from './styles/CitySidebar.styled.js';

// lol

interface ICitySidebar {
  cityList: { name: string; instanceId: number }[];
  selectedCity: { name: string; instanceId: number } | null;
  setSelectedCity: (city: { name: string; instanceId: number }) => void;
}

const CitySidebar: React.FC<ICitySidebar> = ({
  cityList,
  selectedCity,
  setSelectedCity,
}) => {
  return (
    <CitySidebarStyled className='city-sidebar'>
      {cityList.map((city) => (
        <button
          className={
            selectedCity === city
              ? 'selected city-sidebar-button'
              : 'city-sidebar-button'
          }
          key={city.instanceId}
          onClick={() => setSelectedCity(city)}
        >
          {city.name}
        </button>
      ))}
    </CitySidebarStyled>
  );
};

export default CitySidebar;
