import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const BottomInfoStyled = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  color: white;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;

  .bottom-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid var(--lol-gold);
  }
  .bottom-info-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .bottom-info-description {
    font-size: 14px;
  }
`;
