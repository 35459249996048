import { GamePlayState } from '../../Enums/GamePlayState.js';
import { NetworkProtocol } from '../../Enums/NetworkProtocol.js';
import GameManager from '../Game/GameManager.js';
import SaveCityAdministrationMessage from '../Networking/Administration/SaveCityAdministration.js';
import SaveNationalAdministrationMessage from '../Networking/Administration/SaveNationalAdministration.js';
import CityFoundedMessage from '../Networking/City/CityFounded.js';
import FoundCityMessage from '../Networking/City/FoundCity.js';
import RejoinedGameMessage from '../Networking/Connection/RejoinedGameMessage.js';
import ServerSendingGamestateForRejoin from '../Networking/Connection/ServerSendingGamestateForRejoinMessage.js';
import DecreasePopulationMessage from '../Networking/Dev/DecreasePopulationMessage.js';
import IncreasePopulationMessage from '../Networking/Dev/IncreasePopulationMessage.js';
import EconomicCycleMessage from '../Networking/GameLoop/EconomicCycleMessage.js';
import StartGameMessage from '../Networking/GameLoop/Game/StartGameMessage.js';
import GameStateMessage from '../Networking/GameLoop/GameStateMessage.js';
import ChangeImprovementProductionMessage from '../Networking/Improvement/ChangeImprovementProductionMessage.js';
import ChangeKnowledgeMessage from '../Networking/Improvement/ChangeKnowledge.js';
import CreateImprovementMessage from '../Networking/Improvement/CreateImprovement.js';
import ImprovementCreatedMessage from '../Networking/Improvement/ImprovementCreatedMessage.js';
import RemoveImprovementMessage from '../Networking/Improvement/RemoveImprovement.js';
import { ClientMessage } from '../Networking/MessageBase.js';
import AddTaxMessage from '../Networking/Taxes/AddTaxMessage.js';
import DeleteTaxMessage from '../Networking/Taxes/DeleteTaxMessage.js';
import EditTaxMessage from '../Networking/Taxes/EditTaxMessage.js';
import CreateCivilianUnitMessage from '../Networking/Units/CreateCivilianUnitMessage.js';
import MoveUnitMessage from '../Networking/Units/MoveUnitMessage.js';
class Player {
    // #endregion
    constructor(userId, _sendToServer) {
        this.userId = userId;
        this._sendToServer = _sendToServer;
        this.sendToServer = (protocol, message) => {
            console.log('player send to server - protocol: ', protocol, ' message: ', message);
            const messageString = NetworkProtocol[protocol];
            this._sendToServer(messageString, message.toJSON());
        };
        GameManager.initializeClassesWithRegistration();
    }
    // #region Utilities
    getPlayerInfo() {
        return this.gameState.playerInfos.find((pi) => pi.userId === this.userId);
    }
    // #endregion
    // #region Connecting and Reconnecting
    onGamestateForRejoin(msg) {
        const message = ServerSendingGamestateForRejoin.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid ServerSendingGamestateForRejoin message: ', message);
            return;
        }
        this.userId = message.recipientUserId;
        this.gameState = message.gameState;
    }
    fetchUpdatedGamestate() {
        const message = new RejoinedGameMessage(ClientMessage.generateUniqueId());
        this.sendToServer(NetworkProtocol.RejoinedGame, message);
    }
    // #endregion
    // #region Start and End Game
    onStartGame(msg) {
        const message = StartGameMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid StartGameMessage: ', message);
            return;
        }
        this.userId = message.recipientUserId;
        this.gameState = message.gameState;
    }
    onEndGame(msg) {
        this.gameState.gamePlayState = GamePlayState.GameFinished;
    }
    // #endregion
    // #region Game Loop
    onEconomicCycleRan(msg) {
        const message = EconomicCycleMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid EconomicCycleMessage message: ', message);
            return;
        }
        this.gameState = message.gameState;
    }
    // this is generic for development - everything can just send the state
    onGameStateReceived(msg) {
        const message = GameStateMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid EconomicCycleMessage message: ', message);
            return;
        }
        this.gameState = message.gameState;
    }
    // #endregion
    // #region City Actions
    foundCity(landTileId, founderInstanceId) {
        console.log('found city');
        const message = new FoundCityMessage(ClientMessage.generateUniqueId(), this.userId, landTileId, founderInstanceId);
        this.sendToServer(NetworkProtocol.FoundCity, message);
    }
    onCityFounded(msg) {
        const message = CityFoundedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid CityFoundedMessage: ', message);
            return;
        }
        this.gameState = message.gameState;
    }
    // #endregion
    // #region Improvements
    createImprovement(landTileId, improvementType, goodType) {
        const message = new CreateImprovementMessage(ClientMessage.generateUniqueId(), this.userId, landTileId, improvementType, goodType);
        this.sendToServer(NetworkProtocol.CreateImprovement, message);
    }
    removeImprovement(improvementInstanceId) {
        const message = new RemoveImprovementMessage(ClientMessage.generateUniqueId(), this.userId, improvementInstanceId);
        this.sendToServer(NetworkProtocol.RemoveImprovement, message);
    }
    onImprovementCreated(msg) {
        const message = ImprovementCreatedMessage.fromJSON(msg);
        if (!message || !message.validate()) {
            console.log('Invalid ImprovementCreatedMessage: ', message);
            return;
        }
        this.gameState = message.gameState;
    }
    changeImprovementProduction(improvementInstanceId, goodType) {
        const message = new ChangeImprovementProductionMessage(ClientMessage.generateUniqueId(), this.userId, improvementInstanceId, goodType);
        this.sendToServer(NetworkProtocol.ChangeImprovementProduction, message);
    }
    changeKnowledge(improvementInstanceId, knowledgeIndex, experienceType) {
        const message = new ChangeKnowledgeMessage(ClientMessage.generateUniqueId(), this.userId, improvementInstanceId, knowledgeIndex, experienceType);
        this.sendToServer(NetworkProtocol.ChangeKnowledge, message);
    }
    // #endregion
    // #region Taxes
    // #region Add and Edit Consumption Taxes
    addTax(taxType) {
        const message = new AddTaxMessage(ClientMessage.generateUniqueId(), this.userId, taxType);
        this.sendToServer(NetworkProtocol.AddTax, message);
    }
    deleteTax(taxId) {
        const message = new DeleteTaxMessage(ClientMessage.generateUniqueId(), this.userId, taxId);
        this.sendToServer(NetworkProtocol.DeleteTax, message);
    }
    editTax(tax) {
        const message = new EditTaxMessage(ClientMessage.generateUniqueId(), this.userId, tax);
        this.sendToServer(NetworkProtocol.EditTax, message);
    }
    // #endregion
    // #endregion
    // #region Units
    createCivilianUnit(cityInstanceId, isCityFounder, isBuilder, isMountainTrained, hasShips) {
        const message = new CreateCivilianUnitMessage(ClientMessage.generateUniqueId(), this.userId, cityInstanceId, isCityFounder, isBuilder, isMountainTrained, hasShips);
        this.sendToServer(NetworkProtocol.CreateCivilianUnit, message);
    }
    moveUnit(unitInstanceId, destinationTileId) {
        const message = new MoveUnitMessage(ClientMessage.generateUniqueId(), this.userId, unitInstanceId, destinationTileId);
        this.sendToServer(NetworkProtocol.MoveUnit, message);
    }
    // #endregion
    // #region Administration
    saveNationalAdministration(nationalAdministration) {
        const message = new SaveNationalAdministrationMessage(ClientMessage.generateUniqueId(), this.userId, nationalAdministration);
        this.sendToServer(NetworkProtocol.SaveNationalAdministration, message);
    }
    saveCityAdministration(cityAdministration, cityId) {
        const message = new SaveCityAdministrationMessage(ClientMessage.generateUniqueId(), this.userId, cityAdministration, cityId);
        this.sendToServer(NetworkProtocol.SaveCityAdministration, message);
    }
    // #endregion
    // #region Dev Actions
    increasePopulation(cityInstanceId) {
        const message = new IncreasePopulationMessage(ClientMessage.generateUniqueId(), this.userId, cityInstanceId);
        this.sendToServer(NetworkProtocol.IncreasePopulation, message);
    }
    decreasePopulation(cityInstanceId) {
        const message = new DecreasePopulationMessage(ClientMessage.generateUniqueId(), this.userId, cityInstanceId);
        this.sendToServer(NetworkProtocol.DecreasePopulation, message);
    }
    // #endregion
    // #region clone
    clone() {
        const player = new Player(this.userId, this._sendToServer);
        player.gameState = this.gameState ? this.gameState.clone() : null;
        return player;
    }
}
export default Player;
