import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const RoomTileStyled = styled.div`
  --interior-border-margin: 5px;

  // display: inline-block;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  transition: 0.5s;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  color: white;

  .players-container {
    border-bottom: 1px solid var(--border-color-dim);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;
