import React from 'react';
import { ArmyPanelStyled } from './styles/ArmyPanel.styled.js';
import RangeSlider from '../../UI/GenericComponents/RangeSlider.tsx';
import CenterTitle from '../../UI/GenericComponents/CenterTitle.tsx';
import { UIColor, UIOpacity, UIPadding } from '../../UI/Enums/UIEnums.ts';
import VerticalList from '../../UI/GenericComponents/VerticalList.tsx';
import CenterButton from '../../UI/GenericComponents/CenterButton.tsx';
import CohortComponent from './CohortComponent.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';
import SelectionButton from '../../UI/GenericComponents/SelectionButton.tsx';
import ActiveArmy from '../../../../../../GildedLands/lib/Classes/Army/ActiveArmy';
import Cohort from '../../../../../../GildedLands/lib/Classes/Army/Cohort';

function ArmyPanel({
  side,
  army,
  onArmyChange,
  getDefaultCohort,
}: {
  side: string;
  army: ActiveArmy;
  onArmyChange: (army: ActiveArmy) => void;
  getDefaultCohort: () => Cohort;
}) {
  const handleDugInChange = (value) => {
    const newArmy = army.clone();
    newArmy.dugIn = value;
    onArmyChange(newArmy);
  };

  const handleCohortChange = (index, newCohort) => {
    const newArmy = army.clone();
    newArmy.cohorts[index] = newCohort;
    onArmyChange(newArmy);
  };

  const addCohort = (cohortIndex: number = null) => {
    const newArmy = army.clone();
    const templateCohort = cohortIndex
      ? army.cohorts[cohortIndex]
      : getDefaultCohort();
    newArmy.cohorts.push(templateCohort.clone());
    onArmyChange(newArmy);
  };

  const removeCohort = (index) => {
    const newArmy = army.clone();
    newArmy.cohorts.splice(index, 1);
    onArmyChange(newArmy);
  };

  return (
    <ArmyPanelStyled className='army-panel-component ui-gold'>
      <GenericBackground color={UIColor.Blue1} opacity={UIOpacity.Full} />
      <CenterTitle
        title={`${side} Army`}
        padding={UIPadding.Medium}
        backgroundData={{
          color: UIColor.ListBlue,
          opacity: UIOpacity.Header,
        }}
      />
      <div className='dug-in'>
        <RangeSlider
          title='Dug In Level'
          min={0}
          max={10}
          step={1}
          startingValue={army.dugIn || 0}
          onValueChange={handleDugInChange}
        />
      </div>
      <div className='cohort-list'>
        <VerticalList
          data={[
            <SelectionButton
              title='Add Cohort'
              amSelected={false}
              selectable={true}
              onClick={() => addCohort()}
            />,
            <div>
              {army.cohorts.map((cohort, index) => (
                <CohortComponent
                  key={index}
                  cohort={cohort}
                  onCohortChange={(newCohort) =>
                    handleCohortChange(index, newCohort)
                  }
                  onRemove={() => removeCohort(index)}
                  onDuplicate={() => addCohort(index)}
                />
              ))}
            </div>,
          ]}
        />
      </div>
    </ArmyPanelStyled>
  );
}

export default ArmyPanel;
