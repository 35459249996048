import React, { useState, useEffect, useContext } from 'react';

// context
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { CityListStyled } from './styles/CityList.styled.js';

interface CityListProps {
  cityData: {
    isOnAllCities: boolean;
    excludedCityInstanceIds: number[];
    includedCityInstanceIds: number[];
  };
  save: (cityData: any) => void;
}

function CityList({ cityData, save }: CityListProps) {
  const { gameState } = useContext(GameStateDataContext);
  const [cities, setCities] = useState(
    gameState.cities.map((city) => ({
      id: city.instanceId,
      name: city.name,
    }))
  );
  const [chosenCities, setChosenCities] = useState([]);
  const [removedCities, setRemovedCities] = useState([]);

  useEffect(() => {
    setChosenCities(
      cityData.isOnAllCities
        ? cities.filter((city) =>
            cityData.excludedCityInstanceIds.includes(city.id)
          )
        : cities.filter((city) =>
            cityData.includedCityInstanceIds.includes(city.id)
          )
    );
    setRemovedCities(
      cityData.isOnAllCities
        ? cities.filter(
            (city) => !cityData.excludedCityInstanceIds.includes(city.id)
          )
        : cities.filter(
            (city) => !cityData.includedCityInstanceIds.includes(city.id)
          )
    );
  }, [cityData, cities]);

  const removeCity = (cityId) => {
    if (cityData.isOnAllCities) {
      cityData.excludedCityInstanceIds =
        cityData.excludedCityInstanceIds.filter((id) => id !== cityId);
    } else {
      cityData.includedCityInstanceIds =
        cityData.includedCityInstanceIds.filter((id) => id !== cityId);
    }
    save(cityData);
  };
  const addCity = (cityId) => {
    if (cityData.isOnAllCities) {
      cityData.excludedCityInstanceIds.push(cityId);
    } else {
      cityData.includedCityInstanceIds.push(cityId);
    }
    save(cityData);
  };

  return (
    <CityListStyled>
      <label>
        Is On All Cities:
        <input
          type='checkbox'
          name='isOnAllCities'
          defaultChecked={cityData.isOnAllCities}
          onChange={(e) => {
            cityData.isOnAllCities = e.target.checked;
            save(cityData);
          }}
        />
      </label>
      <div className='chosen-city-list'>
        <p className='city-list-title'>
          {cityData.isOnAllCities ? 'Excluded' : 'Included'}
        </p>
        {chosenCities.map((city) => (
          <CityListItem
            key={city.id}
            cityId={city.id}
            cityName={city.name}
            isIncluded={true}
            removeCity={removeCity}
            addCity={addCity}
          />
        ))}
      </div>
      <div className='removed-city-list'>
        <p className='city-list-title'>Available Cities</p>
        {removedCities.map((city) => (
          <CityListItem
            key={city.id}
            cityId={city.id}
            cityName={city.name}
            isIncluded={false}
            removeCity={removeCity}
            addCity={addCity}
          />
        ))}
      </div>
    </CityListStyled>
  );
}

function CityListItem({ cityId, cityName, isIncluded, removeCity, addCity }) {
  return (
    <div
      className='city-list-item'
      onClick={() => {
        if (isIncluded) {
          removeCity(cityId);
        } else {
          addCity(cityId);
        }
      }}
    >
      {cityName}
    </div>
  );
}

export default CityList;
