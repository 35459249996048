// Import necessary modules and components
import React from 'react';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import {
  ImprovementCategory,
  ImprovementType,
} from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import { RequirementTypeEnum } from '../../../../../../../GildedLands/lib/Enums/Requirement.js';
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import RuntimeLandTile from '../../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';

const getBuildingsData = (
  gameState: GameState,
  selectedCity: City,
  filter: string,
  selectImprovementType: (improvementType: ImprovementType) => void
): React.ReactNode => {
  const data: React.ReactNode[] = [];
  const categoryMap = new Map<ImprovementCategory, ImprovementType[]>();
  const nation = gameState.getNationById(selectedCity.ownerNationId);
  const landTile = gameState.getLandTileByTileId(
    selectedCity.centerTileLandtileId
  );

  // ImprovementTypes by category
  Object.values(ImprovementType).forEach((improvementType) => {
    const info = ImprovementTypeMethods.getImprovementInfo(improvementType);
    if (!info) {
      return;
    }
    if (!categoryMap.has(info.category)) {
      categoryMap.set(info.category, []);
    }
    categoryMap.get(info.category)!.push(improvementType);
  });

  categoryMap.forEach((improvementTypes, category) => {
    const filteredImprovements = improvementTypes.filter((improvementType) => {
      const info = ImprovementTypeMethods.getImprovementInfo(improvementType);
      if (
        info.requirements.hasRequirementType(
          RequirementTypeEnum.NotPlayerCreated
        )
      )
        return false;
      if (improvementType === ImprovementType.Outpost) return false;

      if (filter === 'buildable') {
        const notEnoughMoney =
          nation.administration.balance <
          info.cost.getCost(landTile, gameState);

        const cannotBuild = !ImprovementTypeMethods.canBuildImprovementInCity(
          improvementType,
          selectedCity,
          gameState
        ).canBuild;

        if (notEnoughMoney || cannotBuild) return false;
      }
      return true;
    });

    if (filteredImprovements.length > 0) {
      const lineItems = filteredImprovements.map((improvementType) =>
        getBuildingLineItemComponent(
          improvementType,
          gameState,
          selectedCity,
          landTile,
          nation.administration.balance,
          selectImprovementType
        )
      );

      const verticalList = (
        <VerticalList
          key={category}
          data={lineItems}
          backgroundData={{
            color: UIColor.ListBlue,
            opacity: UIOpacity.List,
          }}
        />
      );

      const centerTitle = (
        <CenterTitle
          key={category}
          title={category.toString()}
          padding={UIPadding.Short}
          backgroundData={{
            color: UIColor.HeaderBlue,
            opacity: UIOpacity.Header,
          }}
          dropdown={verticalList}
        />
      );

      data.push(centerTitle);
    }
  });

  return <VerticalList data={data} />;
};

const getBuildingLineItemComponent = (
  improvementType: ImprovementType,
  gameState: GameState,
  city: City,
  landTile: RuntimeLandTile,
  balance: number,
  selectImprovementType: (improvementType: ImprovementType) => void
): React.ReactNode => {
  const info = ImprovementTypeMethods.getImprovementInfo(improvementType);
  const canBuildResult = ImprovementTypeMethods.canBuildImprovementInCity(
    improvementType,
    city,
    gameState
  );
  const cost = info.cost;

  const titleColor =
    canBuildResult.canBuild && balance >= cost.getCost(landTile, gameState)
      ? UIColor.White
      : UIColor.Red;

  const inlineDataFields = [
    {
      fieldData: {
        fieldName: 'Cost',
        textColor:
          balance >= cost.getCost(landTile, gameState)
            ? UIColor.White
            : UIColor.Red,
        iconUrl: '',
        value: cost.getCost(landTile, gameState).toFixed(0),
        hoverData: null,
        onClick: null,
      },
      dropdown: null,
    },
  ];

  return (
    <LineItemStandard
      key={improvementType}
      title={info.name}
      titleColor={titleColor}
      inlineDataFields={inlineDataFields}
      onMainClick={() => selectImprovementType(improvementType)}
    />
  );
};

export default getBuildingsData;
