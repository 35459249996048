// ExperienceCurveItem.tsx

import React, { useState, useMemo } from 'react';
import Experience from '../../../../../../GildedLands/lib/Classes/Experience/Experience.js';
import ExperienceCurveInfo from '../../../../../../GildedLands/lib/Classes/Improvement/ExperienceCurveInfo.js';
import RangeSlider from '../../UI/GenericComponents/RangeSlider.tsx';
import LineItemStandard from '../../UI/GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../UI/GenericComponents/VerticalList.tsx';
import Graph from '../../UI/GenericComponents/Graph.tsx';

interface ExperienceCurveItemProps {
  experienceCurve: ExperienceCurveInfo;
}

const ExperienceCurveItem: React.FC<ExperienceCurveItemProps> = ({
  experienceCurve,
}) => {
  const [curve, setCurve] = useState(experienceCurve.clone());
  const [simulationExperienceMax, setSimulationExperienceMax] = useState(100);

  // Simulate values over experience range
  const simulateValues = useMemo(() => {
    const values: number[] = [];
    const labels: string[] = [];

    for (let expAmount = 0; expAmount <= simulationExperienceMax; expAmount++) {
      const experience = curve.experienceInfluence.map((expInf) => {
        return new Experience(expInf.experienceType, expAmount * expInf.amount);
      });

      const value = curve.evaluate(experience);
      values.push(parseFloat(value.toFixed(2)));
      labels.push(`${expAmount}`);
    }

    return { labels, values };
  }, [curve, simulationExperienceMax]);

  // Render sliders for function variables
  const vars = curve.functionInfo.vars;
  const functionVariableSliders = Object.keys(vars).map((varName) => {
    const currentValue = vars[varName];
    const min = currentValue - Math.abs(currentValue) * 2 || -100;
    const max = currentValue + Math.abs(currentValue) * 2 || 100;

    return (
      <RangeSlider
        key={varName}
        title={varName}
        min={min}
        max={max}
        step={0.01}
        startingValue={currentValue}
        onValueChange={(value) => {
          setCurve((prevCurve) => {
            const newCurve = prevCurve.clone();
            newCurve.functionInfo.vars[varName] = value;
            return newCurve;
          });
        }}
      />
    );
  });

  // Render sliders for experience influences
  const experienceInfluenceSliders = curve.experienceInfluence.map(
    (expInf, index) => (
      <RangeSlider
        key={index}
        title={expInf.experienceType}
        min={0}
        max={3}
        step={0.01}
        startingValue={expInf.amount}
        onValueChange={(value) => {
          setCurve((prevCurve) => {
            const newCurve = prevCurve.clone();
            newCurve.experienceInfluence[index] = {
              ...newCurve.experienceInfluence[index],
              amount: value,
            };
            return newCurve;
          });
        }}
      />
    )
  );

  // Prepare dataset for the graph
  const dataset = {
    label: `${curve.name} Value`,
    data: simulateValues.values,
    borderColor: 'white',
    backgroundColor: 'white',
  };

  return (
    <LineItemStandard
      title={`${curve.name} (${curve.functionInfo.type})`}
      dropdown={
        <VerticalList
          data={[
            <LineItemStandard
              key='experienceInfluence'
              title='Experience Influence'
              dropdown={<VerticalList data={experienceInfluenceSliders} />}
            />,
            <LineItemStandard
              key='vars'
              title='Function Variables'
              dropdown={<VerticalList data={functionVariableSliders} />}
            />,
            <LineItemStandard
              key='graph'
              title={`${curve.name} Value Over Experience`}
              dropdown={
                <Graph labels={simulateValues.labels} datasets={[dataset]} />
              }
            />,
            <RangeSlider
              key='simulationExperienceMax'
              title='Max Experience'
              min={1}
              max={500}
              startingValue={simulationExperienceMax}
              onValueChange={setSimulationExperienceMax}
            />,
          ]}
        />
      }
    />
  );
};

export default ExperienceCurveItem;
