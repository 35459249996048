import Responsibility from './Responsibility.js';
class Load {
    constructor(responsibility, amount, coverage) {
        this.responsibility = responsibility;
        this.amount = amount;
        this.coverage = coverage;
    }
    getMultForExperienceCoverage(str) {
        return this.responsibility.name.findIndex((exp) => exp === str) + 1;
    }
    clone() {
        return new Load(this.responsibility.clone(), this.amount, this.coverage);
    }
    toJSON() {
        return {
            responsibility: this.responsibility.toJSON(),
            amount: this.amount,
            coverage: this.coverage,
        };
    }
    static fromJSON(json) {
        return new Load(Responsibility.fromJSON(json.responsibility), json.amount, json.coverage);
    }
}
export default Load;
