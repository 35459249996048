// external
import React, { useState, useEffect, useContext } from 'react';

// context
import { ScreenSizeContext } from '../ScreenAndMap/ScreenSizeContextProvider.tsx';

// sizing constants

// game specific sizing
const GameElementSizeContextProvider = ({ children }) => {
  const {
    masterGameContainerHeight,
    masterGameContainerWidth,
    masterGameContainerMargin,
  } = useContext(ScreenSizeContext);

  // #region game container - size most things off this

  const [gameContainerHeight, setGameContainerHeight] = useState(
    masterGameContainerHeight
  );
  const [gameContainerWidth, setGameContainerWidth] = useState(
    masterGameContainerWidth
  );

  const [gameContainerMargin, setGameContainerMargin] = useState(
    masterGameContainerMargin
  );

  useEffect(() => {
    setGameContainerHeight(masterGameContainerHeight);
  }, [masterGameContainerHeight]);

  useEffect(() => {
    setGameContainerWidth(masterGameContainerWidth);
  }, [masterGameContainerWidth]);

  useEffect(() => {
    setGameContainerMargin(masterGameContainerMargin);
  }, [masterGameContainerMargin]);

  // #endregion

  // #region land type selector
  const [biomeTypeSelectorSize, setBiomeTypeSelectorSize] = useState(20);
  // useEffect(() => {
  //   setBiomeTypeSelectorSize(20);
  // }, []);

  // #endregion

  const value: GameElementSizeContextType = {
    gameContainerHeight,
    gameContainerWidth,
    gameContainerMargin,
    biomeTypeSelectorSize,
  };

  return (
    <GameElementSizeContext.Provider value={value}>
      {children}
    </GameElementSizeContext.Provider>
  );
};

interface GameElementSizeContextType {
  gameContainerHeight: number;
  gameContainerWidth: number;
  gameContainerMargin: number;
  biomeTypeSelectorSize: number;
}

const GameElementSizeContext = React.createContext(null);

export { GameElementSizeContextProvider, GameElementSizeContext };
