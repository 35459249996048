// WeaponItem.tsx

import React, { useState, useMemo } from 'react';

// internal imports
import { IWeaponInfo } from '../../../../../../../GildedLands/lib/Enums/WeaponInfo';
import LineItemStandard from '../../GenericComponents/LineItemStandard';
import VerticalList from '../../GenericComponents/VerticalList';
import RangeSlider from '../../GenericComponents/RangeSlider';
import Graph from '../../GenericComponents/Graph';
import Experience from '../../../../../../../GildedLands/lib/Classes/Experience/Experience';

interface WeaponItemProps {
  weaponInfo: IWeaponInfo;
}

const WeaponItem: React.FC<WeaponItemProps> = ({ weaponInfo }) => {
  // State for starting experience
  const [startingExperienceValues, setStartingExperienceValues] = useState(
    () => {
      const experienceTypes = Array.from(
        new Set(
          weaponInfo.experienceCurves.flatMap((curve) =>
            curve.experienceInfluence.map((exp) => exp.experienceType)
          )
        )
      );
      return experienceTypes.map((experienceType) => ({
        experienceType,
        amount: 0,
      }));
    }
  );

  // State for experience curves
  const [experienceCurvesValues, setExperienceCurvesValues] = useState(
    weaponInfo.experienceCurves.map((curve) => curve.clone())
  );

  // State for equipment quality curves
  const [equipmentQualityCurvesValues, setEquipmentQualityCurvesValues] =
    useState(weaponInfo.equipmentQualityCurves.map((curve) => curve.clone()));

  // State for weapon quality
  const [weaponQuality, setWeaponQuality] = useState(5);
  const [weaponQualityMin, setWeaponQualityMin] = useState(0);
  const [weaponQualityMax, setWeaponQualityMax] = useState(10);

  // State for simulation turns
  const [simulationTurns, setSimulationTurns] = useState(10); // Default to 10 turns

  // Simulate attack values over turns
  const simulateAttackValues = useMemo(() => {
    const attackValues: number[] = [];
    const labels: string[] = [];
    const perCurveContributions: number[][] = experienceCurvesValues.map(
      () => []
    );

    for (let turn = 1; turn <= simulationTurns; turn++) {
      // Calculate experience for this turn
      const experienceArray = startingExperienceValues.map(
        (exp) => new Experience(exp.experienceType, exp.amount + turn)
      );

      const equipment = [
        {
          goodType: weaponInfo.goodType,
          quality: weaponQuality,
        },
      ];

      // Calculate attack value for this turn
      const attackValue = weaponInfo.attackCalculation.evaluate(
        weaponInfo.goodType,
        experienceCurvesValues,
        equipmentQualityCurvesValues,
        equipment,
        experienceArray
      );

      attackValues.push(parseFloat(attackValue.toFixed(2)));
      labels.push(`Turn ${turn}`);

      // Calculate per-curve contributions
      experienceCurvesValues.forEach((curve, index) => {
        const curveValue = curve.evaluate(experienceArray);
        perCurveContributions[index].push(parseFloat(curveValue.toFixed(2)));
      });
    }

    return { labels, attackValues, perCurveContributions };
  }, [
    simulationTurns,
    startingExperienceValues,
    experienceCurvesValues,
    equipmentQualityCurvesValues,
    weaponQuality,
    weaponInfo,
  ]);

  // Prepare datasets for the main graph
  const attackDataset = {
    label: 'Attack Value',
    data: simulateAttackValues.attackValues,
    borderColor: 'white',
    backgroundColor: 'white',
  };

  const simulateEquipmentQualityCurves = useMemo(() => {
    const labels: string[] = [];
    const perCurveValues: number[][] = equipmentQualityCurvesValues.map(
      () => []
    );

    const stepSize = 1; // Adjust for smoothness vs performance

    for (
      let quality = weaponQualityMin;
      quality <= weaponQualityMax;
      quality += stepSize
    ) {
      labels.push(`${quality}`);

      // For each equipment quality curve, calculate its value at this quality
      equipmentQualityCurvesValues.forEach((curve, index) => {
        const curveValue = curve.evaluate(quality);
        perCurveValues[index].push(parseFloat(curveValue.toFixed(2)));
      });
    }

    return { labels, perCurveValues };
  }, [
    equipmentQualityCurvesValues,
    weaponQuality,
    weaponQualityMin,
    weaponQualityMax,
  ]);

  // Render slider for weapon quality
  const weaponQualitySlider = (
    <RangeSlider
      key='weaponQuality'
      title='Weapon Quality'
      min={weaponQualityMin}
      max={weaponQualityMax}
      startingValue={weaponQuality}
      onValueChange={(value) => setWeaponQuality(value)}
      onMaxChange={(value) => setWeaponQualityMax(value)}
    />
  );

  // Render sliders for starting experience
  const startingExperienceSliders = startingExperienceValues.map(
    (exp, index) => (
      <RangeSlider
        key={index}
        title={exp.experienceType}
        min={0}
        max={1000}
        startingValue={exp.amount}
        onValueChange={(value) => {
          setStartingExperienceValues((prev) => {
            const newStartingExperience = [...prev];
            newStartingExperience[index] = {
              ...newStartingExperience[index],
              amount: value,
            };
            return newStartingExperience;
          });
        }}
      />
    )
  );

  // Render sliders and graphs for experience curves
  const experienceCurvesLineItems = experienceCurvesValues.map(
    (curve, curveIndex) => {
      const functionType = curve.functionInfo.type;
      const vars = curve.functionInfo.vars;

      const functionVariableSliders = Object.keys(vars).map((varName) => {
        const currentValue = vars[varName];
        const min = currentValue - Math.abs(currentValue) * 2 || -100;
        const max = currentValue + Math.abs(currentValue) * 2 || 100;

        return (
          <RangeSlider
            key={varName}
            title={varName}
            min={min}
            max={max}
            step={0.01}
            startingValue={currentValue}
            onValueChange={(value) =>
              setExperienceCurvesValues((prev) => {
                const newCurves = [...prev];
                newCurves[curveIndex] = newCurves[curveIndex].clone();
                newCurves[curveIndex].functionInfo.vars[varName] = value;
                return newCurves;
              })
            }
          />
        );
      });

      // Experience Influence Sliders
      const experienceInfluenceSliders = curve.experienceInfluence.map(
        (expInf, expIndex) => (
          <RangeSlider
            key={expIndex}
            title={expInf.experienceType}
            min={0}
            max={3}
            step={0.01}
            startingValue={expInf.amount}
            onValueChange={(value) =>
              setExperienceCurvesValues((prev) => {
                const newCurves = [...prev];
                newCurves[curveIndex] = newCurves[curveIndex].clone();
                newCurves[curveIndex].experienceInfluence[expIndex] = {
                  ...newCurves[curveIndex].experienceInfluence[expIndex],
                  amount: value,
                };
                return newCurves;
              })
            }
          />
        )
      );

      // Graph for this curve's value over turns
      const curveGraphDataset = {
        label: `${curve.name || `Curve ${curveIndex + 1}`} Value`,
        data: simulateAttackValues.perCurveContributions[curveIndex],
        borderColor: 'white',
        backgroundColor: 'white',
      };

      return (
        <LineItemStandard
          key={curveIndex}
          title={`${curve.name} (${functionType})`}
          dropdown={
            <VerticalList
              data={[
                <LineItemStandard
                  key='experienceInfluence'
                  title='Experience Influence'
                  dropdown={<VerticalList data={experienceInfluenceSliders} />}
                />,
                <LineItemStandard
                  key='vars'
                  title='Function Variables'
                  dropdown={<VerticalList data={functionVariableSliders} />}
                />,
                <LineItemStandard
                  key='curveGraph'
                  title='Curve Value Over Turns'
                  dropdown={
                    <Graph
                      labels={simulateAttackValues.labels}
                      datasets={[curveGraphDataset]}
                    />
                  }
                />,
              ]}
            />
          }
        />
      );
    }
  );

  // Render sliders and graphs for equipment quality curves
  const equipmentQualityCurvesLineItems = equipmentQualityCurvesValues.map(
    (curve, curveIndex) => {
      const functionType = curve.functionInfo.type;
      const vars = curve.functionInfo.vars;

      const functionVariableSliders = Object.keys(vars).map((varName) => {
        const currentValue = vars[varName];
        const min = 0;
        const max = 50;

        return (
          <RangeSlider
            key={varName}
            title={varName}
            min={min}
            max={max}
            step={0.01}
            startingValue={currentValue}
            onValueChange={(value) =>
              setEquipmentQualityCurvesValues((prev) => {
                const newCurves = [...prev];
                newCurves[curveIndex] = newCurves[curveIndex].clone();
                newCurves[curveIndex].functionInfo.vars[varName] = value;
                return newCurves;
              })
            }
          />
        );
      });

      // Graph for this equipment quality curve over weapon quality range
      const curveGraphDataset = {
        label: `${curve.name || `Curve ${curveIndex + 1}`} Value`,
        data: simulateEquipmentQualityCurves.perCurveValues[curveIndex],
        borderColor: 'white',
        backgroundColor: 'white',
      };

      return (
        <LineItemStandard
          key={curveIndex}
          title={`${curve.name} (${functionType})`}
          dropdown={
            <VerticalList
              data={[
                <LineItemStandard
                  key='vars'
                  title='Function Variables'
                  dropdown={<VerticalList data={functionVariableSliders} />}
                />,
                <LineItemStandard
                  key='curveGraph'
                  title='Curve Value Over Weapon Quality'
                  dropdown={
                    <Graph
                      labels={simulateEquipmentQualityCurves.labels}
                      datasets={[curveGraphDataset]}
                    />
                  }
                />,
              ]}
            />
          }
        />
      );
    }
  );

  return (
    <LineItemStandard
      title={weaponInfo.goodType}
      dropdown={
        <VerticalList
          data={[
            weaponQualitySlider,
            <LineItemStandard
              key='startingExperience'
              title='Starting Experience'
              dropdown={<VerticalList data={startingExperienceSliders} />}
            />,
            ...experienceCurvesLineItems,
            ...equipmentQualityCurvesLineItems,
            <LineItemStandard
              key='mainGraph'
              title='Attack Value Over Turns'
              dropdown={
                <Graph
                  labels={simulateAttackValues.labels}
                  datasets={[attackDataset]}
                />
              }
            />,
            <RangeSlider
              key='simulationTurns'
              title='Simulation Turns'
              min={1}
              max={50}
              startingValue={simulationTurns}
              onValueChange={setSimulationTurns}
            />,
          ]}
        />
      }
    />
  );
};

export default WeaponItem;
