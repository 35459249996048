import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const InfoSidebarStyled = styled.div`
  position: absolute;
  top: var(--game-ui-margin);
  left: var(--game-ui-margin);
  width: 200px;
  z-index: var(--overlay-z-index);
  pointer-events: auto;

  .info-box {
    position: relative;
    padding: 5px;
    color: white;

    .info-title {
      display: inline;
      font-family: var(--header-font);
      font-size: 12px;
      font-weight: bold;
    }

    .info-data {
      display: inline;
      font-size: 15px;
    }
  }

  .align-right {
    position: absolute;
    right: 5px;
    text-align: right;
  }

  .info-list-item {
    font-size: 14px;
    cursor: pointer;
  }

  .info-sidebar-list-content {
    padding: 10px;
    min-height: 20px;
  }
`;
