import styled from 'styled-components';
import '../../../../styles/constants.css';

export const OverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--overlay-z-index);
  box-sizing: border-box;
  pointer-events: none;

  .fader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: -1;
  }
`;
