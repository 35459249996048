// WeaponEditing.tsx

import React from 'react';

// internal imports
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';
import LineItemStandard from '../../GenericComponents/LineItemStandard';
import VerticalList from '../../GenericComponents/VerticalList';
import getWeaponInfo from '../../../../../../../GildedLands/lib/Enums/WeaponInfo';
import WeaponItem from './WeaponItem';

const getWeaponInfoForBuilder = (): React.ReactNode => {
  const weaponTypes = Object.values(GoodType);

  const weaponItems = weaponTypes.map((goodType) => {
    const weaponInfo = getWeaponInfo(goodType);
    if (weaponInfo) {
      return <WeaponItem key={goodType} weaponInfo={weaponInfo} />;
    }
    return null;
  });

  return <VerticalList data={weaponItems} />;
};

export default getWeaponInfoForBuilder;
