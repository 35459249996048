import React, { useEffect } from 'react';

// components
import RegisterComponent from './RegisterComponent';
// css
import { RegisterPageStyled } from './styles/RegisterPage.styled';
import QuickPage from '../../UtilityComponents/QuickPage.tsx';

function RegisterPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // context

  return (
    <RegisterPageStyled className='register-page' height={window.innerHeight}>
      <QuickPage />
      <div className='main-content'>
        <RegisterComponent />
      </div>
    </RegisterPageStyled>
  );
}

export default RegisterPage;
