// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import CenterTitle from '../../../../UI/GenericComponents/CenterTitle.tsx';

// internal context

// css
import { NotificationFlagStyled } from './styles/NotificationFlag.styled.js';
import { UIPadding, UIColor, UIOpacity } from '../../../../UI/Enums/UIEnums.ts';

// gl

const NotificationFlag = ({ notification, removeNotification }) => {
  const { title, description, callback } = notification;

  return (
    <NotificationFlagStyled
      className='notification-flag round-corners'
      onClick={callback}
      onContextMenu={(e) => {
        e.preventDefault();
        removeNotification(notification.id);
      }}
    >
      <CenterTitle
        title={title}
        padding={UIPadding.Short}
        onClick={null}
        backgroundData={{
          color: notification.color,
          opacity: UIOpacity.Header,
        }}
      />
      <div className='flag-description light-background'>{description}</div>
    </NotificationFlagStyled>
  );
};

export default NotificationFlag;
