import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const NotificationFlagsStyled = styled.div`
  position: absolute;
  bottom: var(--game-ui-margin);
  right: 50%;
  transform: translateX(50%);
  width: 50%;
  height: 120px;
  overflow: hidden;

  -webkit-mask: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  mask: linear-gradient(90deg, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);

  .notification-flags-list-holder {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    height: 100%;
    max-width: 100%;
  }

  .notification-flags-list {
    display: flex;
    position: relative;
    top: 10px;
    right: 50%;
    transform: translateX(50%);
    overflow: hidden;
    height: 100px;
    max-width: 100%;
  }

  .border-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid var(--lol-gold);
    border-bottom: 2px solid var(--lol-gold);

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
