import styled from 'styled-components';
import '../../../../styles/constants.css';

export const SimTileStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.hexagonSize * Math.sqrt(3)}px;
  height: ${(props) => props.hexagonSize * 3}px;

  .biome-info {
    pointer-events: none;
  }
`;
