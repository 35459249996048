import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const RequirementReturnStyled = styled.div`
  position: relative;
  padding: 5px;
  overflow: hidden;
`;

export const SingleRequirementReturnStyled = styled.div`
  position: relative;
  .single-requirement-return-reason {
    color: ${({ isMet }) => (isMet ? '' : 'red')};
    font-family: var(--body-font);
    font-size: 1rem;
  }
`;
export const RequirementBoxReturnStyled = styled.div`
  position: relative;
  padding-left: 10px;
  .requirement-box-return-reason {
    padding-left: 10px;
    border-left: 1px solid ${({ color }) => color};
  }
  .requirement-box-return-reason-separator {
    font-size: 0.8rem;
    font-family: var(--header-font);
  }
`;
