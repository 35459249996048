import React, { useMemo, useContext, useCallback } from 'react';
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';
import { HighlightedTileContext } from '../../../contexts/ScreenAndMap/HighlightedTileContextProvider.tsx';
import { GameStateDataContext } from '../../../contexts/GameData/GameStateDataContextProvider.tsx';
import TileImage from './TileImage.tsx';
import TileHexagon from './TileHexagon.tsx';
import MapCity from './City/MapCity.tsx';
import MapImprovement from './Improvement/MapImprovement.tsx';
import { HexTileStyled } from './styles/HexTile.styled.js';
import StartingTileMarker from './StartingTileMarker.tsx';

function HexTileComponent({
  tile,
  x,
  y,
  hexagonSize,
  onMouseUpOnTile,
  onRightClickTile,
  onMouseEnterCallback,
  onMouseLeaveCallback,
}) {
  // console.log('HexTileComponent: ', tile.id);
  const { landTileToImage, improvementDisplayInfo } = useContext(ImageContext);
  const { highlightedTiles } = useContext(HighlightedTileContext);
  const { gameState } = useContext(GameStateDataContext);

  const tileImageUrl = useMemo(() => {
    return landTileToImage(
      tile.mainBiomeType,
      tile.improvementInstanceIds,
      tile.imagePickerNumber
    );
  }, [tile, landTileToImage]);

  const improvementsToIcons = useMemo(() => {
    const improvements = tile.improvementInstanceIds
      .map((id) => {
        const imp = gameState.improvements.find(
          (improvement) => improvement.instanceId === id
        );
        return imp
          ? { id, ...improvementDisplayInfo(imp, tile.imagePickerNumber) }
          : null;
      })
      .filter((info) => info && info.tileIcon);

    return tile.amCityCenter
      ? improvements.filter((info) => info.iconInCity)
      : improvements;
  }, [gameState.improvements, tile, improvementDisplayInfo]);

  const isStartingTile = useMemo(() => {
    return gameState.realm.isStartingLandTile(tile.id);
  }, [gameState.realm, tile.id]);

  // Compute amDimmed without useEffect
  const amDimmed = useMemo(() => {
    if (highlightedTiles && !highlightedTiles.includes(tile.id)) {
      return true;
    }
    return !tile.visible || !tile.discovered;
  }, [highlightedTiles, tile.visible, tile.discovered, tile.id]);

  const handleClick = useCallback(
    (e) => {
      if (!onMouseUpOnTile) return;
      if (e.nativeEvent.button === 2) return;
      onMouseUpOnTile(tile.id);
    },
    [onMouseUpOnTile, tile.id]
  );

  const handleRightClick = useCallback(
    (e) => {
      e.preventDefault();
      onRightClickTile?.(tile.id);
    },
    [onRightClickTile, tile.id]
  );

  const handleMouseEnter = useCallback(() => {
    onMouseEnterCallback?.(tile.id);
  }, [onMouseEnterCallback, tile.id]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeaveCallback?.(tile.id);
  }, [onMouseLeaveCallback, tile.id]);

  return (
    <HexTileStyled
      className='game-tile'
      onMouseUp={handleClick}
      onContextMenu={handleRightClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position={{ x, y }}
      hexagonSize={hexagonSize}
    >
      <TileImage
        hexagonSize={hexagonSize}
        url={tileImageUrl}
        amDimmed={amDimmed}
      />
      <TileHexagon hexagonSize={hexagonSize} tileId={tile.id} />
      {tile.cityInstanceId !== null && tile.amCityCenter && (
        <MapCity
          key={tile.cityInstanceId}
          cityInstanceId={tile.cityInstanceId}
          hexagonSize={hexagonSize}
        />
      )}
      {improvementsToIcons.map((info) => (
        <MapImprovement
          key={info.id}
          improvementInstanceId={info.id}
          hexagonSize={hexagonSize}
        />
      ))}
      {isStartingTile && <StartingTileMarker hexagonSize={hexagonSize} />}
    </HexTileStyled>
  );
}

const HexTile = React.memo(HexTileComponent);
export default HexTile;
