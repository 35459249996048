// RiverTypeSelector.jsx
import React, { useMemo, useContext } from 'react';
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { GameElementSizeContext } from '../../contexts/ScreenAndMap/GameElementSizeContextProvider.tsx';

// Styled component similar to BiomeTypeSelector
import { BiomeTypeSelectorStyled } from './styles/BiomeTypeSelector.styled.js';
// We can reuse the Prairie tile as the base
import { BiomeType } from '../../../../../GildedLands/lib/Enums/Biome';

import TileImage from '../Game/Map/TileImage.tsx';
import TileHexagon from '../Game/Map/TileHexagon.tsx';

function RoadTypeSelector({ roadSelected, onClick }) {
  const { enumsToTile } = useContext(ImageContext);
  const { biomeTypeSelectorSize } = useContext(GameElementSizeContext);

  // Use Prairie as the base tile
  const baseUrl = useMemo(() => enumsToTile(BiomeType.Prairie), [enumsToTile]);

  const handleClick = () => {
    onClick();
  };

  return (
    <BiomeTypeSelectorStyled
      className='land-type-selector road-type-selector'
      onClick={handleClick}
      height={biomeTypeSelectorSize * 2}
      width={biomeTypeSelectorSize * Math.sqrt(3)}
      title='Add Road'
    >
      <div className={'visual' + (roadSelected ? ' selected' : '')}>
        {/* Base Prairie tile */}
        <TileImage
          hexagonSize={biomeTypeSelectorSize}
          url={baseUrl}
          amDimmed={false}
        />
        <TileImage
          hexagonSize={biomeTypeSelectorSize}
          url={
            'https://ik.imagekit.io/hfywj4j0a/GildedLands/LandTiles/v2/Roads/hexRoad-010010-01.png'
          }
          amDimmed={false}
        />
        <TileHexagon tileId={null} hexagonSize={biomeTypeSelectorSize} />
      </div>
    </BiomeTypeSelectorStyled>
  );
}

export default RoadTypeSelector;
