import ExperienceCurveInfo from '../Classes/Improvement/ExperienceCurveInfo.js';
import { ImprovementCalculation } from '../Classes/Improvement/ImprovementCalculation.js';
import { BiomeType } from './Biome.js';
import { ExperienceType } from './Experience.js';
import { GoodType } from './Good.js';
import { ImprovementCategory, ImprovementType } from './Improvement.js';
import { ImprovementCurveEnum } from './ImprovementCurve.js';
import { RequirementBox, RequirementBoxTypeEnum, TileHasNoOtherFarmRequirement, TileHasResourceRequirement, NotPlayerCreatedRequirement, TileIsNotCityRequirement, TileIsCityRequirement, CityExperienceRequirement, ProductionGoodAvailableRequirement, TileHasBiomeInListRequirement, } from './Requirement.js';
function getImprovementInfo(improvementType) {
    switch (improvementType) {
        // #region Production Improvements
        // #region Agriculture
        case ImprovementType.NomadicHerd:
            return {
                name: 'Nomadic Herd',
                description: [
                    {
                        title: 'Produces',
                        content: 'Mutton',
                    },
                    {
                        title: null,
                        content: `Your capital starts with the herd your people tended while they were nomads, 
              provided an early source of food and capacity.`,
                    },
                    {
                        title: null,
                        content: `Provides capacity at reduced yields when no other capacity is available.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/nomadic_herd_v2.png',
                // build info
                requirements: new NotPlayerCreatedRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: true,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Sheep',
                            goodType: GoodType.Mutton,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0.7,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Mutton),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.029,
                                    finSlope: 0.021,
                                    xAdj: 3,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.NomadicLife,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.NomadicLife,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Nomadic Herd
                },
            };
        case ImprovementType.WildCropGatheringSite:
            return {
                name: 'Wild Fields',
                description: [
                    {
                        title: null,
                        content: `A place to collect and begin to nuture pre-domesticated plants.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wheat',
                            goodType: GoodType.Wheat,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Wheat),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rutabaga',
                            goodType: GoodType.Rutabaga,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rutabaga),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Potato',
                            goodType: GoodType.Potato,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Potato),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rice',
                            goodType: GoodType.Rice,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rice),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Carrot',
                            goodType: GoodType.Carrot,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Carrot),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Cabbage',
                            goodType: GoodType.Cabbage,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Cabbage),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Turnip',
                            goodType: GoodType.Turnip,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Turnip),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Peas',
                            goodType: GoodType.Peas,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Peas),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.029,
                                    finSlope: 0.021,
                                    xAdj: 3,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.HuntingGrounds:
            return {
                name: 'Hunting Grounds',
                description: [
                    {
                        title: null,
                        content: `A place to hunt wild game.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wild Cows',
                            goodType: GoodType.Beef,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Beef),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Wild Sheep',
                            goodType: GoodType.Mutton,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Mutton),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Wild Deer',
                            goodType: GoodType.Venison,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Venison),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [
                    // {
                    //   name: 'Mountain Goat Hunting',
                    //   description: [
                    //     {
                    //       title: null,
                    //       content: 'An upgrade to hunt mountain goats for meat and resources.',
                    //     },
                    //   ],
                    //   requirements: new TileHasResourceRequirement(GoodType.MountainGoat),
                    //   upgradeFunction: (improvement: RuntimeImprovement) => {
                    //     improvement.capacity.production
                    //   },
                    //   cost: {
                    //     description: [],
                    //     getCost: (improvement: RuntimeImprovement, gameState: GameState) => 15,
                    //   },
                    // }
                    ],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.001,
                                    finSlope: 0,
                                    xAdj: 4,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.FishingSpot:
            return {
                name: 'Fishing Spot',
                description: [
                    {
                        title: null,
                        content: ``,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Fish',
                            goodType: GoodType.Fish,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Fish),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.004,
                                    finSlope: 0.02,
                                    xAdj: 4.4,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.FlatlandFarm:
            return {
                name: 'Flatland Farm',
                description: [
                    {
                        title: null,
                        content: `A place to collect and begin to nuture pre-domesticated plants.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.Agriculture, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wheat',
                            goodType: GoodType.Wheat,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Wheat),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rutabaga',
                            goodType: GoodType.Rutabaga,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rutabaga),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Potato',
                            goodType: GoodType.Potato,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Potato),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rice',
                            goodType: GoodType.Rice,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rice),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Carrot',
                            goodType: GoodType.Carrot,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Carrot),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Cabbage',
                            goodType: GoodType.Cabbage,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Cabbage),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Turnip',
                            goodType: GoodType.Turnip,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Turnip),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Peas',
                            goodType: GoodType.Peas,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Peas),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [
                    // flood irrigation - costs some money, boosts output, need to be next to a river
                    // ox-tilling - big-time boost to output, reduces capacity
                    ],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.017,
                                    finSlope: 0.1,
                                    xAdj: 4.8,
                                    yAdj: 200,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.HillFarm:
            return {
                name: 'Hill Farm',
                description: [
                    {
                        title: null,
                        content: `A place to collect and begin to nuture pre-domesticated plants.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.Agriculture, 100),
                    new TileHasBiomeInListRequirement([
                        BiomeType.Hills,
                        BiomeType.MountainsHighAlpine,
                    ]),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wheat',
                            goodType: GoodType.Wheat,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Wheat),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rutabaga',
                            goodType: GoodType.Rutabaga,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rutabaga),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Potato',
                            goodType: GoodType.Potato,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Potato),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rice',
                            goodType: GoodType.Rice,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rice),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Carrot',
                            goodType: GoodType.Carrot,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Carrot),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Cabbage',
                            goodType: GoodType.Cabbage,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Cabbage),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Turnip',
                            goodType: GoodType.Turnip,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Turnip),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Peas',
                            goodType: GoodType.Peas,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Peas),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.01,
                                    finSlope: 0.075,
                                    xAdj: 4.3,
                                    yAdj: 150,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.MountainFarm:
            return {
                name: 'Mountain Farm',
                description: [
                    {
                        title: null,
                        content: ``,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.Agriculture, 100),
                    new TileHasBiomeInListRequirement([
                        BiomeType.MountainsFullSnow,
                        BiomeType.MountainsPartSnow,
                    ]),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wheat',
                            goodType: GoodType.Wheat,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Wheat),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rutabaga',
                            goodType: GoodType.Rutabaga,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rutabaga),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Potato',
                            goodType: GoodType.Potato,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Potato),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Rice',
                            goodType: GoodType.Rice,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Rice),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Carrot',
                            goodType: GoodType.Carrot,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Carrot),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Cabbage',
                            goodType: GoodType.Cabbage,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Cabbage),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Turnip',
                            goodType: GoodType.Turnip,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Turnip),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Peas',
                            goodType: GoodType.Peas,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Peas),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.01,
                                    finSlope: 0.05,
                                    xAdj: 3.9,
                                    yAdj: 120,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Agriculture,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.FlatlandPasture:
            return {
                name: 'Flatland Pasture',
                description: [
                    {
                        title: null,
                        content: `A place to collect and begin to nuture pre-domesticated plants.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.AnimalHusbandry, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Cows',
                            goodType: GoodType.Beef,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Beef),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Sheep',
                            goodType: GoodType.Mutton,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0.7,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Mutton),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.029,
                                    finSlope: 0.021,
                                    xAdj: 3,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.HillPasture:
            return {
                name: 'Hill Pasture',
                description: [
                    {
                        title: null,
                        content: ``,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.AnimalHusbandry, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Cows',
                            goodType: GoodType.Beef,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0.7,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Beef),
                            additionalModifyProducer: [],
                        },
                        {
                            goodName: 'Sheep',
                            goodType: GoodType.Mutton,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Mutton),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.029,
                                    finSlope: 0.021,
                                    xAdj: 3,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.RuggedTravel,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.RuggedTravel,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        case ImprovementType.MountainPasture:
            return {
                name: 'Mountain Pasture',
                description: [
                    {
                        title: null,
                        content: ``,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                    new CityExperienceRequirement(ExperienceType.AnimalHusbandry, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Agriculture,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Sheep',
                            goodType: GoodType.Mutton,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0.7,
                            },
                            requirements: new TileHasResourceRequirement(GoodType.Mutton),
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 3,
                    upgrades: [],
                    // production
                    // amount
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Agriculture,
                                    amount: 0.5,
                                },
                                {
                                    experienceType: ExperienceType.RuggedTravel,
                                    amount: 0.25,
                                },
                                {
                                    experienceType: ExperienceType.AlpineTravel,
                                    amount: 0.25,
                                },
                            ],
                            functionInfo: {
                                type: 'exponential decay',
                                vars: {
                                    initSlope: 0.029,
                                    finSlope: 0.021,
                                    xAdj: 3,
                                    yAdj: 100,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Good Density',
                                        type: 'goodDensity',
                                        operation: null,
                                        operands: [],
                                        value: null,
                                    },
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Convert To Percentage',
                                type: 'operation',
                                operation: 'divide',
                                operands: [
                                    {
                                        name: 'Multiplier From Improvement Experience',
                                        type: 'curve',
                                        operation: null,
                                        operands: [],
                                        value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                                    },
                                    {
                                        name: 'Constant',
                                        type: 'constant',
                                        operation: null,
                                        operands: [],
                                        value: 100,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.RuggedTravel,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AlpineTravel,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.AnimalHusbandry,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.RuggedTravel,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AlpineTravel,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Wild Crop Gathering Site
                },
            };
        // #endregion
        // #region Consumer Goods
        // case ImprovementType.Linenary:
        //  // no inputs, but needs fiber workikng experience
        case ImprovementType.Linenary:
            return {
                name: 'Linenary',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new TileHasNoOtherFarmRequirement(),
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Linen',
                            goodType: GoodType.Linen,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'operation',
                        operation: 'add',
                        operands: [
                            {
                                name: 'Good Specific Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.MainGoodQualityCurve,
                            },
                            {
                                name: 'Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            },
                        ],
                        value: null,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        case ImprovementType.Woolery:
            return {
                name: 'Woolery',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Wool',
                            goodType: GoodType.Wool,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'operation',
                        operation: 'add',
                        operands: [
                            {
                                name: 'Good Specific Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.MainGoodQualityCurve,
                            },
                            {
                                name: 'Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            },
                        ],
                        value: null,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        case ImprovementType.Silker:
            return {
                name: 'Woolery',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Silk',
                            goodType: GoodType.Silk,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'operation',
                        operation: 'add',
                        operands: [
                            {
                                name: 'Good Specific Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.MainGoodQualityCurve,
                            },
                            {
                                name: 'Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            },
                        ],
                        value: null,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        case ImprovementType.Leathercrofter:
            return {
                name: 'Leathercrofter',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new ProductionGoodAvailableRequirement(),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Leather',
                            goodType: GoodType.Leather,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.AnimalProcessing,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.LeatherProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.AnimalProcessing,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'operation',
                        operation: 'add',
                        operands: [
                            {
                                name: 'Good Specific Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.MainGoodQualityCurve,
                            },
                            {
                                name: 'Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            },
                        ],
                        value: null,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.LeatherProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.LeatherProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        case ImprovementType.Tunicary:
            return {
                name: 'Tunicary',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new CityExperienceRequirement(ExperienceType.Weaving, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: true,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Simple Tunic',
                            goodType: GoodType.Tunic,
                            inputs: [
                                {
                                    goodOfType: [GoodType.Linen],
                                    amount: 1,
                                    inputType: 'perProductionAmount',
                                },
                            ],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [
                        {
                            name: 'Dyeing',
                            description: [],
                            requirements: new RequirementBox(RequirementBoxTypeEnum.All, []),
                            upgradeFunction: (improvement) => {
                                const capacity = improvement.capacity;
                                const production = capacity.production;
                                // **1. Add dyes as an input**
                                production.inputs.push({
                                    goodOfType: [
                                        GoodType.RedDye,
                                        GoodType.BlueDye,
                                        GoodType.YellowDye,
                                        GoodType.GreenDye,
                                    ],
                                    amount: 1,
                                    inputType: 'perProductionAmount',
                                });
                                capacity.modifyProducer.push({
                                    experienceType: ExperienceType.Dyeing,
                                    amount: 1,
                                });
                                // additional additive curve to production quality based on dyeing skill
                                const dyeingQualityCurve = ExperienceCurveInfo.fromJSON({
                                    name: 'DyeingProductionQualityCurve',
                                    experienceInfluence: [
                                        {
                                            experienceType: ExperienceType.Dyeing,
                                            amount: 1,
                                        },
                                    ],
                                    functionInfo: {
                                        type: 'sigmoid',
                                        vars: {
                                            linearSlope: 0.0022,
                                            sigmoidSlope: -0.0056,
                                            height: 16,
                                            xAdj: 421,
                                            yAdj: 0,
                                        },
                                    },
                                });
                                capacity.productionQualityExperienceCurves.push(dyeingQualityCurve);
                                console.log(capacity.productionQualityExperienceCurves);
                                // additional additive curve to production quality
                                capacity.productionQualityCalculation =
                                    new ImprovementCalculation('Total Production Quality', 'operation', 'add', [
                                        capacity.productionQualityCalculation.clone(),
                                        new ImprovementCalculation('Dyeing Experience', 'curve', null, [], 'DyeingProductionQualityCurve'),
                                    ], null);
                            },
                            cost: {
                                description: [],
                                getCost: (improvement, gameState) => 10,
                            },
                        },
                    ],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothingProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothingProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Improvement Experience',
                        type: 'curve',
                        operation: null,
                        operands: [],
                        value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothingProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothingProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        case ImprovementType.Peplosary:
            return {
                name: 'Peplosary',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new RequirementBox(RequirementBoxTypeEnum.All, [
                    new CityExperienceRequirement(ExperienceType.Weaving, 100),
                ]),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Production,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: true,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Peplos',
                            goodType: GoodType.Tunic,
                            inputs: [
                                {
                                    goodOfType: [GoodType.Wool],
                                    amount: 1,
                                    inputType: 'perProductionAmount',
                                },
                            ],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 1,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 1,
                    upgrades: [
                        {
                            name: 'Dyeing',
                            description: [],
                            requirements: new RequirementBox(RequirementBoxTypeEnum.All, []),
                            upgradeFunction: (improvement) => { },
                            cost: {
                                description: [],
                                getCost: (improvement, gameState) => 10,
                            },
                        },
                    ],
                    // production
                    productionAmountExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothingProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0016,
                                    sigmoidSlope: -0.0006,
                                    height: 8,
                                    xAdj: 3405,
                                    yAdj: 0,
                                },
                            },
                        }),
                    ],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'operation',
                        operation: 'multiply',
                        operands: [
                            {
                                name: 'Amount from Good Experience',
                                type: 'operation',
                                operation: 'multiply',
                                operands: [
                                    {
                                        name: 'Convert To Percentage',
                                        type: 'operation',
                                        operation: 'divide',
                                        operands: [
                                            {
                                                name: 'Good Specific Experience',
                                                type: 'curve',
                                                operation: null,
                                                operands: [],
                                                value: ImprovementCurveEnum.MainGoodAmountCurve,
                                            },
                                            {
                                                name: 'Constant',
                                                type: 'constant',
                                                operation: null,
                                                operands: [],
                                                value: 100,
                                            },
                                        ],
                                        value: null,
                                    },
                                ],
                                value: null,
                            },
                            {
                                name: 'Multiplier From Improvement Experience',
                                type: 'curve',
                                operation: null,
                                operands: [],
                                value: ImprovementCurveEnum.BaseImprovementProductionAmountCurve,
                            },
                            {
                                name: 'Good Affinity Multiplier',
                                type: 'goodAffinityMultiplier',
                                operation: null,
                                operands: [],
                                value: null,
                            },
                        ],
                        value: null,
                    }),
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [
                        ExperienceCurveInfo.fromJSON({
                            name: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                            experienceInfluence: [
                                {
                                    experienceType: ExperienceType.Weaving,
                                    amount: 1,
                                },
                                {
                                    experienceType: ExperienceType.ClothingProduction,
                                    amount: 1,
                                },
                            ],
                            functionInfo: {
                                type: 'sigmoid',
                                vars: {
                                    linearSlope: 0.0033,
                                    sigmoidSlope: -0.0044,
                                    height: 16,
                                    xAdj: 1229.7,
                                    yAdj: 0.9,
                                },
                            },
                        }),
                    ],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Improvement Experience',
                        type: 'curve',
                        operation: null,
                        operands: [],
                        value: ImprovementCurveEnum.BaseImprovementProductionQualityCurve,
                    }),
                    // modify producers
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothingProduction,
                            amount: 1,
                        },
                    ],
                    modifyCity: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothingProduction,
                            amount: 1,
                        },
                    ],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Linenary
                },
            };
        // case ImprovementType.Parchmentary:
        //  // needs vegetable processing
        //  // needs leatherworking
        // case ImprovementType.YellowDyeMaker:
        //   // needs carrot familiarity
        // needs carrot input
        // case ImprovementType.RedDyeMaker:
        // needs cabbage familiarity
        // needs cabbage input
        // case ImprovementType.GreenDyeMaker:
        // needs turnip familiarity
        // needs turnip input
        // case ImprovementType.PaperMaker:
        //   // needs fiber working
        // case ImprovementType.Bookbinder:
        //   // needs paper making
        // case ImprovementType.LinenTunicMaker:
        //   // needs fiber working
        // needs linen as input
        // has upgrades for dyeing, unlocking different types of tunic, etc.
        // case ImprovementType.
        // #endregion Consumer Goods
        // #region Science
        case ImprovementType.Schoolhouse:
            return {
                name: 'Schoolhouse',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsNotCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: true,
                loadsMilitary: false,
                // capacity
                capacity: null,
                knowledge: [
                    {
                        type: 'education',
                        availableExperienceTypes: [
                            {
                                experienceType: ExperienceType.GeneralEducation,
                                requirements: null,
                            },
                        ],
                        amount: 5,
                    },
                ],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Schoolhouse
                },
            };
        case ImprovementType.Library:
            return {
                name: 'Library',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsNotCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: true,
                loadsMilitary: false,
                // capacity
                capacity: null,
                knowledge: [
                    {
                        type: 'library',
                        availableExperienceTypes: [
                            {
                                experienceType: ExperienceType.GeneralEducation,
                                requirements: null,
                            },
                        ],
                        amount: 5,
                    },
                ],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Schoolhouse
                },
            };
        case ImprovementType.ResearchCouncil:
            return {
                name: 'Research Council',
                description: [],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsNotCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 10,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: true,
                loadsMilitary: false,
                // capacity
                capacity: null,
                knowledge: [
                    {
                        type: 'research',
                        availableExperienceTypes: [
                            {
                                experienceType: ExperienceType.GeneralEducation,
                                requirements: null,
                            },
                        ],
                        amount: 5,
                    },
                ],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Schoolhouse
                },
            };
        case ImprovementType.Apprecticehouse:
        // #endregion Science
        // #region Outpost
        case ImprovementType.Outpost:
            return {
                name: 'Outpost',
                description: [
                    {
                        title: null,
                        content: `A special improvement that allows for a variety of functions. By adding 
            specializations, nations can use outposts to defend against invading armies, 
            control trade routes, gather luxury resources, and more.`,
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsNotCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Military,
                isCityImprovement: false,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: true,
                // capacity
                capacity: null,
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // Example: Specific end turn effects can be defined here if needed
                    // Currently, Outpost does not have a specific end turn effect
                },
            };
        // #endregion Outpost
        // #region Projects
        case ImprovementType.CathedralProject:
            return {
                name: 'Cathedral Project',
                description: [
                    {
                        title: null,
                        content: 'State project to build a cathedral',
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: true,
                project: {
                    outputNeeded: 1000,
                },
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'Cathedral',
                            goodType: GoodType.ProjectOutput,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 4,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [],
                    modifyCity: [],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Cathedral Project
                },
            };
        case ImprovementType.Cathedral:
            return {
                name: 'Cathedral',
                description: [
                    {
                        title: null,
                        content: 'Great cathedral',
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new NotPlayerCreatedRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: true,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: null,
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Cathedral
                },
            };
        case ImprovementType.UniversityProject:
            return {
                name: 'University Project',
                description: [
                    {
                        title: null,
                        content: 'State project to build a university',
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new TileIsCityRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: true,
                project: {
                    outputNeeded: 1000,
                },
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: {
                    possibleProduction: [
                        {
                            goodName: 'University',
                            goodType: GoodType.ProjectOutput,
                            inputs: [],
                            improvementAffinity: {
                                fixed: 0,
                                multiplier: 0,
                            },
                            requirements: null,
                            additionalModifyProducer: [],
                        },
                    ],
                    maxCapacity: 4,
                    upgrades: [],
                    // production
                    productionAmountExperienceCurves: [],
                    productionAmountCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Amount',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // quality
                    baseProductionQuality: 1,
                    productionQualityExperienceCurves: [],
                    productionQualityCalculation: ImprovementCalculation.fromJSON({
                        name: 'Production Quality',
                        type: 'constant',
                        operation: null,
                        operands: [],
                        value: 1,
                    }),
                    // modify producers
                    modifyProducer: [],
                    modifyCity: [],
                },
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Cathedral Project
                },
            };
        case ImprovementType.University:
            return {
                name: 'University',
                description: [
                    {
                        title: null,
                        content: 'Great university',
                    },
                ],
                image: 'https://ik.imagekit.io/hfywj4j0a/LoL/canyon_city_N6bb4PTK3.png',
                // build info
                requirements: new NotPlayerCreatedRequirement(),
                cost: {
                    description: [],
                    getCost: (landtile, gameState) => 100,
                },
                // type info
                category: ImprovementCategory.Admin,
                isCityImprovement: true,
                project: null,
                // load
                loadsProductionTax: false,
                loadsEducation: false,
                loadsMilitary: false,
                // capacity
                capacity: null,
                knowledge: [],
                // end turn effect
                endTurnEffect: (improvement, gameState) => {
                    // No specific end turn effect for Cathedral
                },
            };
        // #endregion Projects
        default:
            return null;
    }
}
export default getImprovementInfo;
