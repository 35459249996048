// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

// context
// import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { DepartmentItemStyled } from './styles/DepartmentItem.styled.js';
import Department from '../../../../../../../GildedLands/lib/Classes/Administration/Department/Department/Department';
import ResponsibilityPopup from './ResponsibilityPopup.tsx';
import Responsibility from '../../../../../../../GildedLands/lib/Classes/Administration/Responsibility';
import ResponsibilityItem from './ResponsibilityItem.tsx';
import ListPopup from '../../../UI/GenericComponents/ListPopup.tsx';
import Eunuch from '../../../../../../../GildedLands/lib/Classes/Administration/Eunuch';

// lol

interface DepartmentItemProps {
  department: Department;
  parent: Department | null;
  addDepartment: (parentId: string) => void;
  saveDepartment: (department: Department) => void;
  deleteDepartment: (id: string) => void;
}

const DepartmentItem = ({
  department,
  parent,
  addDepartment,
  saveDepartment,
  deleteDepartment,
}: DepartmentItemProps) => {
  // context
  // state

  const [showDepartments, setShowDepartments] = useState(true);
  const [showEunuchs, setShowEunuchs] = useState(false);
  const [showResponsibilityPopup, setShowResponsibilityPopup] = useState(false);

  // #region expandable parental responibilties
  const [parentalResponsibilities, setParentalResponsibilities] = useState([]);
  useEffect(() => {
    if (!!parent) {
      setParentalResponsibilities(
        parent.responsibilities.filter(
          (resp) => resp.name[resp.name.length - 1] === 'All'
        )
      );
    }
  }, [parent]);

  const handleParentResponsibilitySelect = (resp: Responsibility) => {
    const temp = department.clone();
    const respClone = resp.clone();
    temp.responsibilities.push(respClone);
    saveDepartment(temp);
    setShowResponsibilityPopup(false);
  };
  // #endregion

  // #region handlers

  const handleAddResponsibility = () => {
    if (!!parent) {
      setShowResponsibilityPopup(true);
    } else {
      console.log('parent is null');
      const temp = department.clone();
      const resp = new Responsibility(['Admin', 'All']);
      temp.responsibilities.push(resp);
      saveDepartment(temp);
    }
  };

  const handleSaveResponsibility = (resp: Responsibility, index: number) => {
    const temp = department.clone();
    temp.responsibilities = temp.responsibilities.map((r, i) => {
      if (i === index) return resp;
      return r;
    });
    saveDepartment(temp);
  };

  const handleRemoveResponsibility = (index: number) => {
    const temp = department.clone();
    temp.responsibilities = temp.responsibilities.filter((_, i) => i !== index);
    saveDepartment(temp);
  };

  const handleAddDepartment = () => {
    addDepartment(department.instanceIdentifier);
    setShowDepartments(true);
  };

  const handleSaveSubdepartment = (subdept: Department) => {
    const temp = department.clone();
    temp.subDepartments = temp.subDepartments.map((dept) => {
      if (dept.instanceIdentifier === subdept.instanceIdentifier)
        return subdept;
      return dept;
    });
    saveDepartment(temp);
  };

  const deleteSubdepartment = (id: string) => {
    const temp = department.clone();
    temp.subDepartments = temp.subDepartments.filter(
      (dept) => dept.instanceIdentifier !== id
    );
    saveDepartment(temp);
  };
  // #endregion

  // #region handle eunuchs

  const decrementEunuchs = () => {
    if (department.eunuchs.length <= 0) return;

    const temp = department.clone();
    temp.eunuchs.pop();
    saveDepartment(temp);
  };

  const incrementEunuchs = () => {
    const temp = department.clone();
    temp.eunuchs.push(new Eunuch([]));
    saveDepartment(temp);
  };

  // #endregion

  return (
    <DepartmentItemStyled>
      <div className='department-header'>
        <span className='department-name'>{department?.name}</span>
        {!!department?.managingDepartmentId && (
          <button
            className='delete-department-button border red'
            onClick={() => deleteDepartment(department.instanceIdentifier)}
          >
            {'\u00A0\u00D7\u00A0'}
          </button>
        )}
      </div>

      <div className='department-info'>
        <span>Department Info</span>
        <div>
          {department?.loads.map((load) => (
            <div key={load?.responsibility.name.join(' - ')}>
              {load?.responsibility.name.join(' - ')}: load:{' '}
              {load?.amount.toFixed(1)}: coverage: {load?.coverage.toFixed(1)}
            </div>
          ))}
        </div>
        <div>
          cycle effectiveness:{' '}
          {department?.cycleEffectivenessPercentage?.toFixed(1)}
        </div>
      </div>

      <div className='eunuchs'>
        <span onClick={() => setShowEunuchs(!showEunuchs)}>
          {(showEunuchs ? '\u25BC' : '\u25B6') +
            '\u00A0\u00A0Eunuchs:\u00A0\u00A0'}
        </span>
        <button onClick={decrementEunuchs}>{'\u00A0\u25BC\u00A0'}</button>
        <span>{'\u00A0' + department?.eunuchs.length + '\u00A0'}</span>
        <button onClick={incrementEunuchs}>{'\u00A0\u25B2\u00A0'}</button>
        {showEunuchs && (
          <div className='eunuch-list'>
            {department?.eunuchs?.map((eunuch, index) => (
              <div key={index} className='eunuch-item'>
                <p>Eunuch {index + 1}</p>
                <div className='eunuch-experience'>
                  {eunuch?.experience?.map((exp, index) => (
                    <p key={index}>
                      {exp.experienceType +
                        ':\u00A0\u00A0' +
                        exp.amount.toFixed(1)}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {department?.experience.length > 0 && (
        <div className='experience'>
          <div className='experience-header'>
            <span>Department Experience</span>
          </div>
          <div className='experience-list'>
            {department?.experience.map((exp, index) => (
              <div key={index} className='experience-item'>
                <span>{exp.experienceType + ':\u00A0\u00A0'}</span>
                <span>{exp.amount.toFixed(1)}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* department costs */}
      {/* fixed department cost. eunuch cost. field costs */}
      {/* last cycle admin load. last cycle admin power. last cycle effectiveness */}

      <div className='responsibilities'>
        <div className='list-header'>
          <span>Responsibilities</span>
          <button
            className='add-item-button border green'
            onClick={handleAddResponsibility}
          >
            {'\u00A0+\u00A0'}
          </button>
        </div>
        <div className='responsibility-list'>
          {department?.responsibilities.map((resp, index) => (
            <ResponsibilityItem
              key={index}
              responsibility={resp}
              saveResponsibility={(resp) =>
                handleSaveResponsibility(resp, index)
              }
              deleteResponsibility={() => handleRemoveResponsibility(index)}
            />
          ))}
        </div>
      </div>

      {showResponsibilityPopup && (
        <ListPopup
          list={parentalResponsibilities.map((resp) => ({
            name: resp.name.join(' - '),
            value: resp,
          }))}
          onSelect={handleParentResponsibilitySelect}
          close={() => setShowResponsibilityPopup(false)}
        />
      )}

      <div className='departments'>
        <div className='list-header'>
          <span onClick={() => setShowDepartments(!showDepartments)}>
            {(showDepartments ? '\u25BC Departments' : '\u25B6 Departments') +
              ' (' +
              department?.subDepartments.length +
              ')'}
          </span>
          <button
            className='add-item-button border green'
            onClick={handleAddDepartment}
          >
            {'\u00A0+\u00A0'}
          </button>
        </div>
        {!!showDepartments && (
          <div className='department-list'>
            {department?.subDepartments.map((sub) => {
              return (
                <DepartmentItem
                  key={sub?.instanceIdentifier}
                  department={sub}
                  parent={department}
                  addDepartment={addDepartment}
                  saveDepartment={handleSaveSubdepartment}
                  deleteDepartment={deleteSubdepartment}
                />
              );
            })}
          </div>
        )}
      </div>
    </DepartmentItemStyled>
  );
};

export default DepartmentItem;
