import { GoodTypeMethods, } from '../../Enums/Good.js';
import ConsumptionLineItem from '../EconomicData/Consumption/ConsumptionLineItem.js';
class PersonalConsumptionChart {
    constructor(goods, potentialUpgrades, goodAmountTracker, subCategoryAmountTracker, categoryAmountTracker, totalQualityOfLife, totalCost) {
        this.potentialUpgrades = [];
        this.goods = goods;
        this.potentialUpgrades = potentialUpgrades;
        this.goodAmountTracker = goodAmountTracker;
        this.subCategoryAmountTracker = subCategoryAmountTracker;
        this.categoryAmountTracker = categoryAmountTracker;
        this.totalQualityOfLife = totalQualityOfLife;
        this.totalCost = totalCost;
    }
    //#endregion
    static getEmptyChart() {
        return new PersonalConsumptionChart([], [], {}, {}, {}, 0, 0);
    }
    // #region consumption
    addConsumption(goodType, goodCityInfo, category, subCategory, quality, qualityOfLife, cost, person, personCity, shippingAndTaxes, gameState) {
        const newGood = new ConsumptionLineItem(goodType, goodCityInfo, category, subCategory, quality, qualityOfLife, cost, shippingAndTaxes);
        this.goods.push(newGood);
        this.goodAmountTracker[goodType] =
            (this.goodAmountTracker[goodType] || 0) + 1;
        this.subCategoryAmountTracker[subCategory] =
            (this.subCategoryAmountTracker[subCategory] || 0) + 1;
        this.categoryAmountTracker[category] =
            (this.categoryAmountTracker[category] || 0) + 1;
        this.totalQualityOfLife += qualityOfLife.qol;
        this.totalCost += cost;
        // add potential upgrades
        this.addUpgradesForGood(newGood, person, personCity, gameState, this.goods.length - 1);
    }
    // #endregion
    // #region upgrades
    upgradeGood(bestUpgrade, person, personCity, gameState) {
        const { chartIndex, good } = bestUpgrade;
        const oldGood = this.goods[chartIndex];
        // Replace the good at the index
        this.goods[chartIndex] = good;
        // Update trackers
        this.goodAmountTracker[oldGood.goodType]--;
        this.goodAmountTracker[good.goodType] =
            (this.goodAmountTracker[good.goodType] || 0) + 1;
        this.subCategoryAmountTracker[oldGood.subCategory]--;
        this.subCategoryAmountTracker[good.subCategory] =
            (this.subCategoryAmountTracker[good.subCategory] || 0) + 1;
        this.categoryAmountTracker[oldGood.category]--;
        this.categoryAmountTracker[good.category] =
            (this.categoryAmountTracker[good.category] || 0) + 1;
        this.totalQualityOfLife +=
            good.qualityOfLife.qol - oldGood.qualityOfLife.qol;
        this.totalCost += good.cost - oldGood.cost;
        // Remove any upgrades associated with the old good
        this.potentialUpgrades = this.potentialUpgrades.filter((upgrade) => upgrade.chartIndex !== chartIndex);
        // Add new potential upgrades for the new good
        this.addUpgradesForGood(good, person, personCity, gameState, chartIndex);
    }
    getBestUpgrade() {
        return this.potentialUpgrades.reduce((best, current) => {
            return best.qolPerUpgradePrice < current.qolPerUpgradePrice
                ? best
                : current;
        }, this.potentialUpgrades[0]);
    }
    addUpgradesForGood(good, person, personCity, gameState, chartIndex) {
        const upgrades = gameState.goodsData.goods.filter((g) => g.goodType === good.goodType && g.quality > good.quality);
        for (const upgrade of upgrades) {
            const qol = GoodTypeMethods.calculateQualityOfLife(upgrade.goodType, upgrade.quality, person, this);
            const upgradeGoodCity = gameState.getCityByInstanceId(upgrade.cityId);
            const taxes = gameState.getShippingAndTaxesForGood(upgrade.goodType, upgradeGoodCity, upgrade.quality, upgrade.marketPrice, person, personCity);
            const cost = upgrade.marketPrice - taxes.totalTaxAmount - taxes.totalShippingCost;
            let qolDiff = qol.qol - good.qualityOfLife.qol;
            let costDiff = cost - good.cost;
            let value = qolDiff / costDiff;
            this.potentialUpgrades.push({
                goodType: upgrade.goodType,
                quality: upgrade.quality,
                qolPerUpgradePrice: value,
                chartIndex: chartIndex,
                good: new ConsumptionLineItem(upgrade.goodType, {
                    instanceId: upgradeGoodCity.instanceId,
                    name: upgradeGoodCity.name,
                }, GoodTypeMethods.getGoodInfo(upgrade.goodType).category, GoodTypeMethods.getGoodInfo(upgrade.goodType).subCategory, upgrade.quality, qol, cost, taxes),
            });
        }
    }
    // #endregion
    // #region clone and JSON
    clone() {
        return new PersonalConsumptionChart(this.goods.map((good) => good.clone()), this.potentialUpgrades.map((upgrade) => ({
            goodType: upgrade.goodType,
            quality: upgrade.quality,
            qolPerUpgradePrice: upgrade.qolPerUpgradePrice,
            chartIndex: upgrade.chartIndex,
            good: upgrade.good.clone(),
        })), Object.assign({}, this.goodAmountTracker), Object.assign({}, this.subCategoryAmountTracker), Object.assign({}, this.categoryAmountTracker), this.totalQualityOfLife, this.totalCost);
    }
    toJSON() {
        // console.log('personal consumption chart: ', this);
        return {
            goods: this.goods.map((good) => good.toJSON()),
            potentialUpgrades: this.potentialUpgrades.map((upgrade) => ({
                goodType: upgrade.goodType,
                quality: upgrade.quality,
                qolPerUpgradePrice: upgrade.qolPerUpgradePrice,
                chartIndex: upgrade.chartIndex,
                good: upgrade.good.toJSON(),
            })),
            goodAmountTracker: Object.entries(this.goodAmountTracker).map(([goodType, amount]) => ({
                goodType: goodType,
                amount,
            })),
            subCategoryAmountTracker: Object.entries(this.subCategoryAmountTracker).map(([subCategory, amount]) => ({
                subCategory: subCategory,
                amount,
            })),
            categoryAmountTracker: Object.entries(this.categoryAmountTracker).map(([category, amount]) => ({
                category: category,
                amount,
            })),
            totalQualityOfLife: this.totalQualityOfLife,
            totalCost: this.totalCost,
        };
    }
    static fromJSON(json) {
        return new PersonalConsumptionChart(json.goods.map((good) => ConsumptionLineItem.fromJSON(good)), json.potentialUpgrades.map((upgrade) => ({
            goodType: upgrade.goodType,
            quality: upgrade.quality,
            qolPerUpgradePrice: upgrade.qolPerUpgradePrice,
            chartIndex: upgrade.chartIndex,
            good: ConsumptionLineItem.fromJSON(upgrade.good),
        })), Object.fromEntries(json.goodAmountTracker.map((item) => [item.goodType, item.amount])), Object.fromEntries(json.subCategoryAmountTracker.map((item) => [
            item.subCategory,
            item.amount,
        ])), Object.fromEntries(json.categoryAmountTracker.map((item) => [
            item.category,
            item.amount,
        ])), json.totalQualityOfLife, json.totalCost);
    }
}
export default PersonalConsumptionChart;
