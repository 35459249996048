// external
import React, { useState, useRef } from 'react';

// css
import { InlineDataFieldStyled } from './styles/InlineDataField.styled.js';
import HoverBox, { IHoverBox } from './HoverBox.tsx';
import { UIColor } from '../Enums/UIEnums.ts';
import GenericBackground from './Background.tsx';

interface IInlineDataField {
  fieldName: string;
  textColor?: UIColor;
  iconUrl?: string;
  value: string | number | boolean;
  hoverData?: IHoverBox;
  onClick?: () => void;
  isDropdown?: boolean;
  dropdownActive?: boolean;
}

const InlineDataField: React.FC<IInlineDataField> = ({
  fieldName,
  textColor = UIColor.White,
  iconUrl = null,
  value,
  hoverData = null,
  onClick = null,
  isDropdown = false,
  dropdownActive = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);

  // display value
  let displayValue = value;
  if (typeof value === 'number') {
    displayValue = value.toFixed(2);
  }

  const handleClick = () => {
    console.log('data field clicked');
    if (onClick) onClick();
  };

  return (
    <InlineDataFieldStyled
      className='inline-data-field rounded'
      onClick={handleClick}
      ref={fieldRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      textColor={textColor}
      clickable={!!onClick || isDropdown}
    >
      {isHovered && !!onClick && (
        <GenericBackground color={UIColor.White} opacity={0.2} />
      )}
      {isDropdown && (
        <div className='inline-data-field-dropdown-arrow dropdown-arrow'>
          {dropdownActive ? '\u25BC' : '\u25B6'}
          &nbsp;&nbsp;&nbsp;
        </div>
      )}
      <div className='inline-data-field-field-name'>
        {fieldName}&nbsp;&nbsp;
      </div>
      {iconUrl && (
        <div className='inline-data-field-icon'>
          <img src={iconUrl} alt={fieldName} />
        </div>
      )}
      <div className='inline-data-field-value'>{displayValue}</div>
      {isHovered && hoverData && (
        <HoverBox
          content={hoverData.content}
          width={hoverData.width}
          parentRef={fieldRef}
        />
      )}
    </InlineDataFieldStyled>
  );
};

export default InlineDataField;
export { IInlineDataField };
