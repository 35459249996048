import styled from 'styled-components';
import '../../../styles/constants.css';

export const StartingStateListStyled = styled.div`
  height: calc(100vh - var(--navbar-height) - 3 * var(--screen-margin));
  width: 100%;

  padding: var(--screen-margin);
  box-sizing: border-box;

  overflow: hidden;

  z-index: 1;

  transition: top 0.5s ease;

  .underlined-title {
    color: var(--lol-gold);
    margin-bottom: 0;
  }

  .starting-state-tile-holder {
    position: relative;
    height: calc(100vh - var(--navbar-height) - 70px);
    overflow-y: scroll;
    overflow-x: visible;
    width: 100%;
  }

  .starting-state-tile-holder::-webkit-scrollbar {
    display: none;
  }

  .new-realm-button {
    font-size: 1.2rem;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 5px;
    width: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    transition: 0.2s ease;
  }
  .new-realm-button:hover {
    color: var(--lol-gold);
    border-color: var(--lol-gold);
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }
`;
