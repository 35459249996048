import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const DateBoxStyled = styled.div`
  position: absolute;
  top: var(--game-ui-margin);
  right: var(--game-ui-margin);
  pointer-events: auto;

  .date-box-content {
    position: relative;
    width: 200px;
    padding: 2px 5px;

    // border
    border: 2px solid var(--lol-gold);
    border-radius: var(--ui-border-radius);

    // text
    text-align: center;
    // font-family: var(--body-font);
    font-size: 16px;
    font-weight: 100;

    .date-info {
      display: inline;
    }

    .toggle-play-button {
      font-size: 16px;
      cursor: pointer;
      display: inline;
    }
  }

  .align-right {
    position: absolute;
    right: 5px;
    text-align: right;
  }
`;
