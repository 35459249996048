// external
import React, { useState, useEffect, useContext, useCallback } from 'react';

// context
import { NotificationFlagsDataContext } from './NotificationFlagsDataContextProvider.tsx';
import { GameStateDataContext } from '../GameData/GameStateDataContextProvider.tsx';
import { ScreenModeContext } from '../ScreenAndMap/ScreenModeContextProvider.tsx';

// gl
import { TimeEnum } from '../../../../../GildedLands/lib/Enums/Time';
import { UIColor } from '../../components/UI/Enums/UIEnums.ts';

const NotificationFlagsTriggerContextProvider = ({ children }) => {
  // context
  const { gameState } = useContext(GameStateDataContext);

  const { addNotification, removeNotification, eventTracker, setEventTracker } =
    useContext(NotificationFlagsDataContext);

  const { ScreenMode, setScreenMode } = useContext(ScreenModeContext);

  useEffect(() => {
    if (!gameState) return;

    if (!eventTracker.gameStarted) {
      setEventTracker((prev) => ({
        ...prev,
        gameStarted: true,
      }));
    }

    // tell the player to c reate a tax
    if (!eventTracker.playerCreatedTax && gameState.days >= TimeEnum.Year) {
      addNotification({
        title: 'The People Owe Us',
        description: 'Create a tax for the good of the state.',
        color: UIColor.HeaderBlue,
        callback: () => {
          setScreenMode(ScreenMode.TaxesScreen);
        },
      });
      setEventTracker((prev) => ({
        ...prev,
        playerCreatedTax: true,
      }));
    }

    // someone died in a city

    // other
  }, [gameState]);

  // state
  const value: NotificationFlagsTriggerContextType = {};

  return (
    <NotificationFlagsTriggerContext.Provider value={value}>
      {children}
    </NotificationFlagsTriggerContext.Provider>
  );
};

interface NotificationFlagsTriggerContextType {}

const NotificationFlagsTriggerContext =
  React.createContext<NotificationFlagsTriggerContextType | null>(null);

export {
  NotificationFlagsTriggerContextProvider,
  NotificationFlagsTriggerContext,
};
