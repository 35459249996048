import { ExperienceMethods, } from '../../Enums/Experience.js';
import { EvaluationResult } from './EvaluationResult.js'; // Make sure to import EvaluationResult
export class SoldierCalculation {
    constructor(name, type, operation, operands, value) {
        this.name = name;
        this.type = type;
        this.operation = operation;
        this.operands = operands.map((operand) => new SoldierCalculation(operand.name, operand.type, operand.operation, operand.operands, operand.value));
        this.value = value;
    }
    evaluate(equipmentCurves, equipment, experience) {
        switch (this.type) {
            case 'constant':
                return new EvaluationResult(this.name, this.type, null, // Operation is null for constants
                this.value, this.value, [] // No inputs for constants
                );
            case 'operation':
                if (!this.operation || !this.operands || this.operands.length === 0) {
                    throw new Error(`Invalid operation in calculation ${this.name}`);
                }
                // Evaluate operands recursively
                const operandResults = this.operands.map((op) => op.evaluate(equipmentCurves, equipment, experience));
                // Extract results from operand evaluations
                const operandValues = operandResults.map((res) => res.result);
                // Perform the operation
                const resultNumber = SoldierCalculation.performOperation(this.operation, operandValues);
                // Return the EvaluationResult with operands as inputs
                return new EvaluationResult(this.name, this.type, this.operation, this.value, resultNumber, operandResults);
            case 'experienceCurve':
                const curve = ExperienceMethods.getExperienceCurve(this.value);
                if (!curve) {
                    throw new Error(`Curve ${this.value} not found in ${this.name}`);
                }
                const expResultNumber = curve.evaluate(experience);
                // Compute xValue and breakdown
                const breakdown = curve.experienceInfluence.map((influence) => {
                    const matchingExperience = experience.find((exp) => exp.experienceType === influence.experienceType);
                    const baseAmount = matchingExperience ? matchingExperience.amount : 0;
                    const contribution = baseAmount * influence.amount;
                    return {
                        experienceType: influence.experienceType,
                        experienceAmount: baseAmount,
                        influence: influence.amount,
                        contribution,
                    };
                });
                const xValue = breakdown.reduce((sum, entry) => sum + entry.contribution, 0);
                // Return the EvaluationResult with extraData containing xValue and breakdown
                return new EvaluationResult(this.name, this.type, null, this.value, expResultNumber, [], { xValue, breakdown });
            case 'equipmentCurve':
                const equipmentCurve = equipmentCurves.find((c) => c.name === this.value);
                if (!equipmentCurve) {
                    throw new Error(`Curve ${this.value} not found in ${this.name}`);
                }
                const equipmentItem = equipment.find((e) => e.goodType === equipmentCurve.goodType);
                if (!equipmentItem) {
                    throw new Error(`Equipment for curve ${this.value} not found in ${this.name}`);
                }
                const eqpQuality = equipmentItem.quality;
                const eqpResultNumber = equipmentCurve.evaluate(eqpQuality);
                // Create EvaluationResult for equipment quality
                const eqpInput = new EvaluationResult('equipment quality', 'constant', null, eqpQuality, eqpQuality, []);
                // Return the EvaluationResult
                return new EvaluationResult(this.name, this.type, null, // No operation for equipmentCurve
                this.value, eqpResultNumber, [eqpInput]);
            default:
                throw new Error(`Unsupported calculation type ${this.type}`);
        }
    }
    static performOperation(operation, operands) {
        switch (operation) {
            case 'add':
                return operands.reduce((a, b) => a + b, 0);
            case 'multiply':
                return operands.reduce((a, b) => a * b, 1);
            case 'subtract':
                if (operands.length !== 2) {
                    throw new Error('Subtract operation requires exactly two operands');
                }
                return operands[0] - operands[1];
            case 'divide':
                if (operands.length !== 2) {
                    throw new Error('Divide operation requires exactly two operands');
                }
                if (operands[1] === 0) {
                    throw new Error('Division by zero');
                }
                return operands[0] / operands[1];
            default:
                throw new Error(`Unsupported operation ${operation}`);
        }
    }
    // #region Clone and JSON
    clone() {
        return new SoldierCalculation(this.name, this.type, this.operation, this.operands.map((operand) => operand.clone()), this.value);
    }
    toJSON() {
        return {
            name: this.name,
            type: this.type,
            operation: this.operation,
            operands: this.operands.map((operand) => operand.toJSON()),
            value: this.value,
        };
    }
    static fromJSON(json) {
        return new SoldierCalculation(json.name, json.type, json.operation, json.operands.map((operand) => SoldierCalculation.fromJSON(operand)), json.value);
    }
}
