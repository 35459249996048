// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import Popup from '../../../UI/GenericComponents/OptionsPopup.tsx';

// internal context
import { PopupDataContext } from '../../../../contexts/PopupsAndNotifications/PopupDataContextProvider.tsx';
import { ScreenModeContext } from '../../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';

// css
import { PopupsStyled } from './styles/Popups.styled.js';
import SelectProductionPopup from '../CityScreen/SelectProductionPopup.tsx';

// gl

const Popups = () => {
  const { popups, removePopup, largeEventPopups, removeLargeEventPopup } =
    useContext(PopupDataContext);

  const { actionMode, ActionMode } = useContext(ScreenModeContext);

  return (
    <PopupsStyled>
      <div className='popups-container'>
        {actionMode === ActionMode.PickingGoodTypeForImprovement ? (
          <SelectProductionPopup />
        ) : largeEventPopups.length > 0 ? (
          <Popup
            key={0}
            index={0}
            title={largeEventPopups[0].title}
            description={largeEventPopups[0].description}
            options={largeEventPopups[0].options}
            close={() => removeLargeEventPopup()}
          />
        ) : (
          popups.map((popup, index) => {
            console.log('popup', popup);
            return (
              <Popup
                key={index}
                index={index}
                title={popup.title}
                description={popup.description}
                options={popup.options}
                close={() => {
                  console.log('removing popup: ', index);
                  removePopup(index);
                }}
              />
            );
          })
        )}
      </div>
    </PopupsStyled>
  );
};

export default Popups;
