import React from 'react';

// GildedLands imports
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';

// UI Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import ImageBox from '../../GenericComponents/ImageBox.tsx';

// Utility functions
import getPersonData from '../Person/Person.tsx';
import { IImprovementInfo } from '../../../../../../../GildedLands/lib/Enums/ImprovementInfo';
import ImprovementGraph from './ImprovementGraph.tsx';
import ImprovementGraphWithUpgrades from './ImprovementGraphWithUpgrades.tsx';

const getImprovementInfoForBuilder = (): React.ReactNode => {
  const improvementTypes = Object.values(ImprovementType);

  const improvementItems = improvementTypes.map((improvementType) => {
    return renderImprovementItem(improvementType);
  });

  return <VerticalList data={improvementItems} />;
};

const renderImprovementItem = (
  improvementType: ImprovementType
): React.ReactNode => {
  const improvementInfo =
    ImprovementTypeMethods.getImprovementInfo(improvementType);

  if (!improvementInfo) {
    return null;
  }

  if (!improvementInfo.capacity) {
    return <LineItemStandard title={improvementInfo.name} />;
  }

  const modifyProducerLineItem = getModifyProducerLineItem(improvementInfo);
  const productionQualityLineItem = getProductionQualityLineItem(
    improvementType,
    improvementInfo
  );
  const productionAmountLineItem = getProductionAmountLineItem(
    improvementType,
    improvementInfo
  );

  return (
    <LineItemStandard
      title={improvementInfo.name}
      dropdown={
        <VerticalList
          data={[
            modifyProducerLineItem,
            productionQualityLineItem,
            productionAmountLineItem,
          ]}
        />
      }
      inlineDataFields={[
        {
          fieldData: {
            fieldName: 'Good Type',
            value: improvementInfo.capacity.possibleProduction[0].goodType,
          },
          dropdown: null,
        },
        {
          fieldData: {
            fieldName: 'Max Capacity',
            value: improvementInfo.capacity.maxCapacity,
          },
          dropdown: null,
        },
      ]}
    />
  );
};

const getModifyProducerLineItem = (
  improvementInfo: IImprovementInfo
): React.ReactNode => {
  return (
    <LineItemStandard
      title='Modifies Producer'
      dropdown={
        <VerticalList
          data={improvementInfo.capacity.modifyProducer.map((producer) => {
            return (
              <LineItemStandard
                key={producer.experienceType}
                title={producer.experienceType}
                inlineFieldsAlignment='left'
                inlineDataFields={[
                  {
                    fieldData: {
                      fieldName: null,
                      value: producer.amount,
                    },
                    dropdown: null,
                  },
                ]}
              />
            );
          })}
        />
      }
    />
  );
};

const getProductionQualityLineItem = (
  improvementType: ImprovementType,
  improvementInfo: IImprovementInfo
): React.ReactNode => {
  const baseProductionQuality = (
    <LineItemStandard
      title='Base Quality'
      inlineFieldsAlignment='left'
      inlineDataFields={[
        {
          fieldData: {
            fieldName: null,
            value: improvementInfo.capacity.baseProductionQuality,
          },
          dropdown: null,
        },
      ]}
    />
  );

  const productionQualityExperienceCurves =
    improvementInfo.capacity.productionQualityExperienceCurves || [];

  const productionQualityCurves = productionQualityExperienceCurves.map(
    (curve, index) => (
      <LineItemStandard
        key={index}
        title={`Curve ${index + 1}`}
        dropdown={
          <VerticalList
            data={[
              <LineItemStandard
                key='experienceTypes'
                title='Experience Influence'
                dropdown={
                  <VerticalList
                    data={curve.experienceInfluence.map((experience) => (
                      <LineItemStandard
                        key={experience.experienceType}
                        title={experience.experienceType}
                        inlineFieldsAlignment='left'
                        inlineDataFields={[
                          {
                            fieldData: {
                              fieldName: null,
                              value: experience.amount,
                            },
                            dropdown: null,
                          },
                        ]}
                      />
                    ))}
                  />
                }
              />,
              <LineItemStandard
                key='vars'
                title='Vars'
                dropdown={
                  <VerticalList
                    data={Object.entries(curve.functionInfo.vars).map(
                      ([key, value]) => (
                        <LineItemStandard
                          key={key}
                          title={key}
                          inlineFieldsAlignment='left'
                          inlineDataFields={[
                            {
                              fieldData: {
                                fieldName: null,
                                value: value,
                              },
                              dropdown: null,
                            },
                          ]}
                        />
                      )
                    )}
                  />
                }
              />,
            ]}
          />
        }
      />
    )
  );

  const productionQualityGraph = (
    <LineItemStandard
      title='Graph'
      dropdown={
        <ImprovementGraphWithUpgrades
          improvementType={improvementType}
          graphType='productionQuality'
        />
      }
    />
  );

  return (
    <LineItemStandard
      title='Production Quality'
      dropdown={
        <VerticalList
          data={[
            baseProductionQuality,
            ...productionQualityCurves,
            productionQualityGraph,
          ]}
        />
      }
    />
  );
};

const getProductionAmountLineItem = (
  improvementType: ImprovementType,
  improvementInfo: IImprovementInfo
): React.ReactNode => {
  const productionAmountExperienceCurves =
    improvementInfo.capacity.productionAmountExperienceCurves || [];

  const productionAmountCurves = productionAmountExperienceCurves.map(
    (curve, index) => (
      <LineItemStandard
        key={index}
        title={`Curve ${index + 1}`}
        dropdown={
          <VerticalList
            data={[
              <LineItemStandard
                key='experienceTypes'
                title='Experience Types'
                dropdown={
                  <VerticalList
                    data={curve.experienceInfluence.map((experience) => (
                      <LineItemStandard
                        key={experience.experienceType}
                        title={experience.experienceType}
                        inlineFieldsAlignment='left'
                        inlineDataFields={[
                          {
                            fieldData: {
                              fieldName: null,
                              value: experience.amount,
                            },
                            dropdown: null,
                          },
                        ]}
                      />
                    ))}
                  />
                }
              />,
              <LineItemStandard
                key='vars'
                title='Vars'
                dropdown={
                  <VerticalList
                    data={Object.entries(curve.functionInfo.vars).map(
                      ([key, value]) => (
                        <LineItemStandard
                          key={key}
                          title={key}
                          inlineFieldsAlignment='left'
                          inlineDataFields={[
                            {
                              fieldData: {
                                fieldName: null,
                                value: value,
                              },
                              dropdown: null,
                            },
                          ]}
                        />
                      )
                    )}
                  />
                }
              />,
            ]}
          />
        }
      />
    )
  );

  const productionAmountGraph = (
    <LineItemStandard
      title='Graph'
      dropdown={
        <ImprovementGraphWithUpgrades
          improvementType={improvementType}
          graphType='productionAmount'
        />
      }
    />
  );

  return (
    <LineItemStandard
      title='Production Amount'
      dropdown={
        <VerticalList
          data={[...productionAmountCurves, productionAmountGraph]}
        />
      }
    />
  );
};

export default getImprovementInfoForBuilder;
