import React, { useState, useEffect, useContext, Component } from 'react';

// components
import Cycler from './Cycler.tsx';
// context
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { NationListStyled } from './styles/NationList.styled.js';

interface NationListProps {
  nationData: {
    isOnAllNations: boolean;
    excludedNationIds: number[];
    includedNationIds: number[];
  };
  save: (nationData: any) => void;
}

function NationList({ nationData, save }: NationListProps) {
  const { gameState } = useContext(GameStateDataContext);
  const [nations, setNations] = useState(
    gameState.nations.map((nation) => ({
      id: nation.id,
      name: nation.name,
    }))
  );
  const [chosenNations, setChosenNations] = useState([]);
  const [removedNations, setRemovedNations] = useState([]);

  useEffect(() => {
    setChosenNations(
      nationData.isOnAllNations
        ? nations.filter((nation) =>
            nationData.excludedNationIds.includes(nation.id)
          )
        : nations.filter((nation) =>
            nationData.includedNationIds.includes(nation.id)
          )
    );
    setRemovedNations(
      nationData.isOnAllNations
        ? nations.filter(
            (nation) => !nationData.excludedNationIds.includes(nation.id)
          )
        : nations.filter(
            (nation) => !nationData.includedNationIds.includes(nation.id)
          )
    );
  }, [nationData, nations]);

  const removeNation = (nationId) => {
    if (nationData.isOnAllNations) {
      nationData.excludedNationIds = nationData.excludedNationIds.filter(
        (id) => id !== nationId
      );
    } else {
      nationData.includedNationIds = nationData.includedNationIds.filter(
        (id) => id !== nationId
      );
    }
    save(nationData);
  };
  const addNation = (nationId) => {
    if (nationData.isOnAllNations) {
      nationData.excludedNationIds.push(nationId);
    } else {
      nationData.includedNationIds.push(nationId);
    }
    save(nationData);
  };

  return (
    <NationListStyled>
      <label>
        Is On All Nations:
        <input
          type='checkbox'
          name='isOnAllGoods'
          defaultChecked={nationData.isOnAllNations}
          onChange={(e) => {
            nationData.isOnAllNations = e.target.checked;
            save(nationData);
          }}
        />
      </label>
      <div className='chosen-nation-list'>
        <p className='nation-list-title'>
          {nationData.isOnAllNations ? 'Excluded' : 'Included'}
        </p>
        {chosenNations.map((nation) => (
          <NationListItem
            key={nation.id}
            nationId={nation.id}
            nationName={nation.name}
            isIncluded={true}
            removeNation={removeNation}
            addNation={addNation}
          />
        ))}
      </div>
      <div className='removed-nation-list'>
        <p className='nation-list-title'>Available Nations</p>
        {removedNations.map((nation) => (
          <NationListItem
            key={nation.id}
            nationId={nation.id}
            nationName={nation.name}
            isIncluded={false}
            removeNation={removeNation}
            addNation={addNation}
          />
        ))}
      </div>
    </NationListStyled>
  );
}

function NationListItem({
  nationId,
  nationName,
  isIncluded,
  removeNation,
  addNation,
}) {
  return (
    <div
      className='nation-list-item'
      onClick={() => {
        if (isIncluded) {
          removeNation(nationId);
        } else {
          addNation(nationId);
        }
      }}
    >
      {nationName}
    </div>
  );
}

export default NationList;
