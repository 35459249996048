import React, { useContext } from 'react';
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import StartingStateTile from './StartingStateTile.tsx';

// Styled component import - rename your styled files accordingly
import { StartingStateListStyled } from './styles/StartingStateList.styled.js';
import GameState from '../../../../../GildedLands/lib/Classes/Game/GameState';
import { StartingState } from '../../contexts/GameData/StartingStatesContextProvider.tsx';

interface StartingStateListProps {
  startingStates: StartingState[];
  selectedStartingState: StartingState | null;
  onStartingStateClicked: (ss: StartingState) => void;
  deleteStartingState: (startingStateId: string) => void;
  createNewStartingState: () => void;
}

function StartingStateList({
  startingStates,
  selectedStartingState,
  onStartingStateClicked,
  deleteStartingState,
  createNewStartingState,
}: StartingStateListProps) {
  return (
    <StartingStateListStyled className='startingstate-list'>
      <div className='background-image'></div>
      <div className='underlined-title'>Starting States</div>
      <div
        className='new-startingstate-button ui-button'
        onClick={createNewStartingState}
      >
        +
      </div>
      <div className='startingstatetile-holder'>
        {startingStates.map((startingState) => (
          <StartingStateTile
            key={startingState._id}
            startingState={startingState}
            isSelected={startingState._id === selectedStartingState?._id}
            onStartingStateClicked={onStartingStateClicked}
            deleteStartingState={deleteStartingState}
          />
        ))}
      </div>
    </StartingStateListStyled>
  );
}

export default StartingStateList;
