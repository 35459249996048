// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import TaxTypeSummary from './TaxTypeSummary.tsx';

// internal context
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { OverviewScreenStyled } from './styles/OverviewScreen.styled.js';

// lol
import GoodsData from '../../../../../../../../GildedLands/lib/Classes/EconomicData/PriceAndProduction/GoodsData.js';
import Nation from '../../../../../../../../GildedLands/lib/Classes/Nation/Nation.js';
import RealizedTaxItem from '../../../../../../../../GildedLands/lib/Classes/EconomicData/TaxTracking/TaxItem.js';

const OverviewScreen = () => {
  // #region context
  const { gameState, userId } = useContext(GameStateDataContext);
  // #endregion

  // set goods data to the data on load - don't update it
  const [goodsData, setGoodsData] = useState<GoodsData>();
  const [nationData, setNationData] = useState<Nation>();

  useEffect(() => {
    if (userId && gameState) {
      setGoodsData(gameState.goodsData);
      const nation = gameState.getNationByControllerUserId(userId);
      setNationData(nation);
    }
  }, [userId, gameState]);

  const renderTaxSummaries = () => {
    if (!nationData) return null;

    const taxSummary: { [key: string]: RealizedTaxItem[] } =
      nationData.administration.lastCycleTaxes.reduce((summary, tax) => {
        const taxType = tax.taxType;
        if (!summary[taxType]) {
          summary[taxType] = [];
        }
        summary[taxType].push(tax);
        return summary;
      }, {} as { [key: string]: RealizedTaxItem[] });

    return (
      <div>
        {Object.entries(taxSummary).map(([taxType, taxItems]) => (
          <TaxTypeSummary key={taxType} taxType={taxType} taxItems={taxItems} />
        ))}
      </div>
    );
  };

  return (
    <OverviewScreenStyled className='overview-screen'>
      {nationData && (
        <div>
          <h2>National Overview</h2>
          <p>
            Total Cash Balance: {nationData.administration.balance.toFixed(2)}
          </p>
          <p>
            Last Cycle Revenue Amount:{' '}
            {nationData.administration.lastCycleRevenueAmount.toFixed(2)}
          </p>
          <h3 className='tax-revenue-summary-title'>Tax Revenue Summary</h3>
          {renderTaxSummaries()}
        </div>
      )}
    </OverviewScreenStyled>
  );
};

export default OverviewScreen;
