// external
import React, { useState, useEffect, useContext, useMemo } from 'react';

// context
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
import { ProductionPopupDataContext } from '../../../../contexts/PopupsAndNotifications/SelectProductionPopupContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import { CreateMissionPopupStyled } from './styles/CreateMission.styled.js';
import CenterTitle from '../../../UI/GenericComponents/CenterTitle.tsx';
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';
import Background from '../../../UI/GenericComponents/Background.tsx';
import getSelectProductionData from '../../../UI/MappingToData/CityScreen/SelectProduction.tsx';
import CenterButton from '../../../UI/GenericComponents/CenterButton.tsx';
import { MissionMethods } from '../../../../../../../GildedLands/lib/Enums/Mission';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';

const SelectProductionPopup = () => {
  // contexts
  const { gameState } = useContext(GameStateDataContext);
  const { productionPopup, setProductionPopup } = useContext(
    ProductionPopupDataContext
  );
  const [selectedGoodType, setSelectedGoodType] = useState<GoodType>(null);

  // end of contexts

  const productionData = useMemo(() => {
    if (!gameState) {
      console.error('No master player');
      return null;
    }

    if (!productionPopup) {
      console.error('No production popup');
      return null;
    }

    return getSelectProductionData(
      selectedGoodType,
      setSelectedGoodType,
      productionPopup.improvementType,
      productionPopup.tileIndex,
      gameState
    );
  }, [selectedGoodType, setSelectedGoodType, productionPopup]);

  return (
    <CreateMissionPopupStyled className='create-mission-popup'>
      <div className='create-mission-popup-closer' onClick={close}></div>
      <div className='create-mission-popup-area'>
        <Background color={UIColor.Blue6} opacity={UIOpacity.List} />
        {productionData}
        <div className='create-mission-popup-button-wrapper'>
          <CenterButton
            label='Select Production'
            color={UIColor.Blue}
            onClick={() => {
              productionPopup.onSelect(selectedGoodType);
              setProductionPopup(null);
            }}
          />
          <CenterButton
            label='Cancel'
            color={UIColor.Red}
            onClick={() => {
              setProductionPopup(null);
            }}
          />
        </div>
      </div>
    </CreateMissionPopupStyled>
  );
};

export default SelectProductionPopup;
