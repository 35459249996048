import styled from 'styled-components';
import '../../../../../../../styles/constants.css';

export const ProductionScreenStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .content {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 100%;
  }

  // need to set here - the component is set to max out
  .sidebar {
    width: 200px;
    height: 100%;
  }

  // need to set here - the component is set to max out
  .production-report-holder {
    width: calc(100% - 200px);
  }
`;
