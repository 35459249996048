// external
import React, { useState, useRef, useEffect } from 'react';
import GameState from '../../../../../GildedLands/lib/Classes/Game/GameState';
import PlayerInfo from '../../../../../GildedLands/lib/Classes/Player/PlayerInfo';
import RuntimeRealm from '../../../../../GildedLands/lib/Classes/Land/Realm/RuntimeRealm';

const GameStateDataContextProvider = ({ children }) => {
  const [userId, setUserId] = useState<string>(null);
  const [gameState, setGameState] = useState<GameState>(null);
  const [playerInfo, setPlayerInfo] = useState<PlayerInfo>(null);
  const [realm, setRealm] = useState<RuntimeRealm>(null);

  useEffect(() => {
    if (gameState)
      setPlayerInfo(
        gameState.playerInfos.find((info) => info.userId === userId)
      );
    if (gameState) setRealm(gameState.realm);
  }, [gameState, userId]);

  const value: GameStateDataContextType = {
    userId,
    setUserId,
    gameState,
    setGameState,
    playerInfo,
    setPlayerInfo,
    realm,
    setRealm,
  };

  return (
    <GameStateDataContext.Provider value={value}>
      {children}
    </GameStateDataContext.Provider>
  );
};

interface GameStateDataContextType {
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  gameState: GameState;
  setGameState: React.Dispatch<React.SetStateAction<GameState>>;
  playerInfo: PlayerInfo;
  setPlayerInfo: React.Dispatch<React.SetStateAction<PlayerInfo>>;
  realm: RuntimeRealm;
  setRealm: React.Dispatch<React.SetStateAction<RuntimeRealm>>;
}

const GameStateDataContext =
  React.createContext<GameStateDataContextType | null>(null);

export { GameStateDataContextProvider, GameStateDataContext };
