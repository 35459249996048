import styled from 'styled-components';
import '../../../styles/constants.css';

export const GamePageStyled = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  .game-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  .map-container {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 10;
  }
`;
