import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class CreateImprovementMessage extends ClientMessage {
    constructor(messageId, senderUserId, landTileId, improvementType, goodType) {
        super(NetworkProtocol.CreateImprovement, messageId, senderUserId);
        this.landTileId = landTileId;
        this.improvementType = improvementType;
        this.goodType = goodType;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { landTileId: this.landTileId, improvementType: this.improvementType, goodType: this.goodType });
    }
    static fromJSON(json) {
        return new CreateImprovementMessage(json.messageId, json.senderUserId, json.landTileId, json.improvementType, json.goodType);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('create improvement message validating: ', this.toJSON());
        return (this.landTileId !== undefined &&
            this.improvementType !== undefined &&
            super.validate());
    }
}
