import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const AdministrationScreenStyled = styled.div`
  position: absolute;
  top: calc(3% + 25px);
  left: 5%;
  width: 90%;
  height: calc(92% - 25px);
  box-sizing: border-box;
  border: 2px solid var(--lol-gold);
  pointer-events: auto;
`;
