import React, { useContext, useMemo } from 'react';
import { line, curveCardinal } from 'd3-shape';
import { getTilePosition } from '../Utils/GetTilePosition.ts';
import RuntimeLandTile from '../../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile.js';

type PathProps = {
  tiles: RuntimeLandTile[];
  mapTileSize: number;
  color?: string;
  adjustStartForUnit?: boolean;
  strokeWidth?: number;
};

const Path: React.FC<PathProps> = ({
  tiles,
  mapTileSize,
  color = 'rgba(255, 255, 255, 0.7)',
  adjustStartForUnit = false,
  strokeWidth,
}) => {
  const adjustForCenter = (position) => ({
    x: position.x + (Math.sqrt(3) * mapTileSize) / 2,
    y: position.y + mapTileSize,
  });

  const adjustForUnitStart = (position) => ({
    x: position.x + (Math.sqrt(3) * mapTileSize) / 2 + mapTileSize * 0.4,
    y: position.y + mapTileSize + mapTileSize * 0.4,
  });

  const adjustForMovement = (position, nextTile) => {
    if (nextTile) {
      const nextTilePosition = getTilePosition(nextTile, mapTileSize);

      position.x += (nextTilePosition.x - position.x) * 0.3;
      position.y += (nextTilePosition.y - position.y) * 0.3;
    }
    return position;
  };

  const positions = useMemo(() => {
    if (!tiles || tiles.length === 0) return [];

    return tiles.map((tile, index) => {
      let position = getTilePosition(tile, mapTileSize);

      if (index === 0 && adjustStartForUnit) {
        position = adjustForMovement(
          adjustForUnitStart(position),
          tiles[index + 1]
        );
      } else {
        position = adjustForCenter(position);
      }

      return position;
    });
  }, [tiles, adjustStartForUnit, mapTileSize]);

  if (positions.length < 2) return null;

  const lineGenerator = line()
    .x((d) => d.x)
    .y((d) => d.y)
    .curve(curveCardinal.tension(0.4));

  const pathData = lineGenerator(positions);

  const arrowScale = 0.4 + mapTileSize / 180;
  const defaultStrokeWidth = 6 * arrowScale;

  const markerWidth = 5 * arrowScale;
  const markerHeight = 5 * arrowScale;
  const refX = markerWidth / 2;
  const refY = markerHeight / 2;

  return (
    <>
      <defs>
        <marker
          id='arrowhead'
          markerWidth={markerWidth}
          markerHeight={markerHeight}
          refX={refX}
          refY={refY}
          orient='auto'
        >
          <polygon
            points={`0 0, ${markerWidth} ${refY}, 0 ${markerHeight}`}
            fill={color}
          />
        </marker>
      </defs>
      <path
        d={pathData}
        stroke={color}
        strokeWidth={strokeWidth || defaultStrokeWidth}
        fill='none'
        markerMid='url(#arrowhead)'
        markerEnd='url(#arrowhead)'
      />
    </>
  );
};

export default Path;
