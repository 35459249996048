import { BiomeTypeMethods } from '../../Enums/Biome.js';
class Unit {
    constructor(instanceId, name, nationId, tileId, isMoving, finalDestinationTileId, currentDestinationTileId, path, travelTimes, percentageMoved) {
        this.instanceId = instanceId;
        this.name = name;
        this.nationId = nationId;
        this.tileId = tileId;
        this.isMoving = isMoving;
        this.finalDestinationTileId = finalDestinationTileId;
        this.currentDestinationTileId = currentDestinationTileId;
        this.path = path;
        this.travelTimes = travelTimes;
        this.percentageMoved = percentageMoved;
    }
    //#region Movement
    setMove(newDestinationTileId, gameState) {
        if (newDestinationTileId === this.finalDestinationTileId)
            return;
        if (newDestinationTileId === this.tileId) {
            this.isMoving = false;
            this.finalDestinationTileId = null;
            this.currentDestinationTileId = null;
            this.travelTimes = [];
            this.path = [];
            return;
        }
        const startTile = gameState.getLandTileByTileId(this.tileId);
        const endTile = gameState.getLandTileByTileId(newDestinationTileId);
        if (BiomeTypeMethods.isWater(endTile.mainBiomeType)) {
            return;
        }
        if (!startTile || !endTile) {
            throw new Error('Invalid tile IDs');
        }
        this.path = this.findPath(startTile, endTile, gameState);
        this.path.shift();
        this.travelTimes = this.path.map((tileId) => {
            const tile = gameState.getLandTileByTileId(tileId);
            if (!tile)
                throw new Error('Invalid tile ID');
            return this.getTileCost(tile);
        });
        if (this.path.length > 0) {
            this.isMoving = true;
            this.percentageMoved = 0;
            this.finalDestinationTileId = newDestinationTileId;
            this.currentDestinationTileId = this.path[0];
        }
    }
    updateMovement(gameState) {
        if (!this.isMoving || !this.currentDestinationTileId)
            return;
        let nextTile = gameState.getLandTileByTileId(this.currentDestinationTileId);
        if (!nextTile)
            throw new Error('Invalid tile ID');
        this.percentageMoved +=
            1 / BiomeTypeMethods.baseTravelTime(nextTile.mainBiomeType);
        if (this.percentageMoved >= 1) {
            this.moveTile(gameState);
        }
    }
    moveTile(gameState) {
        this.tileId = this.currentDestinationTileId;
        this.percentageMoved = 0;
        if (this.path && this.path.length > 0) {
            this.path.shift();
            this.travelTimes.shift();
            if (this.path.length > 0) {
                this.currentDestinationTileId = this.path[0];
            }
            else {
                this.finishMovement();
            }
        }
        else {
            this.finishMovement();
        }
    }
    finishMovement() {
        this.isMoving = false;
        this.finalDestinationTileId = null;
        this.currentDestinationTileId = null;
    }
    findPath(startTile, endTile, gameState) {
        var _a;
        let openSet = [startTile];
        const cameFrom = new Map();
        const gScore = new Map();
        const fScore = new Map();
        gScore.set(startTile, 0);
        fScore.set(startTile, this.heuristicCostEstimate(startTile, endTile));
        const calculatedTravelTimes = [];
        while (openSet.length > 0) {
            let current = openSet.reduce((lowest, tile) => fScore.get(tile) < fScore.get(lowest) ? tile : lowest, openSet[0]);
            if (current === endTile) {
                const totalPath = [current.id];
                while (cameFrom.has(current)) {
                    current = cameFrom.get(current);
                    totalPath.unshift(current.id);
                }
                return totalPath;
            }
            openSet = openSet.filter((tile) => tile !== current);
            const neighbors = current.findNeighbors(gameState.realm);
            for (const neighbor of neighbors) {
                // Skip water tiles for now
                if (BiomeTypeMethods.isWater(neighbor.mainBiomeType)) {
                    continue;
                }
                const tentativeGScore = gScore.get(current) + this.getTileCost(neighbor);
                if (tentativeGScore < ((_a = gScore.get(neighbor)) !== null && _a !== void 0 ? _a : Infinity)) {
                    cameFrom.set(neighbor, current);
                    gScore.set(neighbor, tentativeGScore);
                    fScore.set(neighbor, tentativeGScore + this.heuristicCostEstimate(neighbor, endTile));
                    if (!openSet.includes(neighbor)) {
                        openSet.push(neighbor);
                    }
                }
            }
        }
        return [];
    }
    getTileCost(tile) {
        return BiomeTypeMethods.baseTravelTime(tile.mainBiomeType);
    }
    heuristicCostEstimate(start, goal) {
        const dx = start.x - goal.x;
        const dy = start.y - goal.y;
        const euclideanDistance = Math.sqrt(dx * dx + dy * dy);
        // Calculate the average cost of moving through the tiles
        const averageTileCost = (BiomeTypeMethods.baseTravelTime(start.mainBiomeType) +
            BiomeTypeMethods.baseTravelTime(goal.mainBiomeType)) /
            2;
        // Weighted heuristic
        return euclideanDistance * averageTileCost;
    }
    timeToNextTile() {
        if (!this.isMoving || this.travelTimes.length === 0)
            return 0;
        const currentTravelTime = this.travelTimes[0];
        const timeToNextTile = currentTravelTime * (1 - this.percentageMoved);
        return timeToNextTile;
    }
    timeToFinalDestination() {
        if (!this.isMoving || this.travelTimes.length === 0)
            return 0;
        const timeToNextTile = this.timeToNextTile();
        const timeToFinalDestination = timeToNextTile +
            this.travelTimes.slice(1).reduce((acc, time) => acc + time, 0);
        return timeToFinalDestination;
    }
}
export default Unit;
