// ImprovementGraph.tsx

import React, { useMemo, useState, useEffect } from 'react';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import Experience from '../../../../../../../GildedLands/lib/Classes/Experience/Experience';
import Graph from '../../GenericComponents/Graph';
import { TimeEnum } from '../../../../../../../GildedLands/lib/Enums/Time';
import RangeSlider from '../../GenericComponents/RangeSlider';
import LineItemStandard from '../../GenericComponents/LineItemStandard';
import VerticalList from '../../GenericComponents/VerticalList';
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement.js';
import ExperienceCurveInfo from '../../../../../../../GildedLands/lib/Classes/Improvement/ExperienceCurveInfo.js';
import { GoodTypeMethods } from '../../../../../../../GildedLands/lib/Enums/Good.js';

interface ModifyProducer {
  experienceType: string;
  amount: number;
}

export interface ImprovementGraphProps {
  runtimeImprovement: RuntimeImprovement;
  graphType: 'productionAmount' | 'productionQuality';
}

const ImprovementGraph: React.FC<ImprovementGraphProps> = ({
  runtimeImprovement,
  graphType,
}) => {
  const [simulationYears, setSimulationYears] = useState(5);

  const [startingExperienceValues, setStartingExperienceValues] = useState<
    {
      experienceType: string;
      amount: number;
    }[]
  >([]);

  const [experienceCurvesValues, setExperienceCurvesValues] = useState<
    ExperienceCurveInfo[]
  >(
    graphType === 'productionAmount'
      ? runtimeImprovement.capacity.productionAmountExperienceCurves.concat(
          GoodTypeMethods.getGoodInfo(
            runtimeImprovement.capacity.production.goodType
          ).productionAmountExperienceCurves
        )
      : runtimeImprovement.capacity.productionQualityExperienceCurves.concat(
          GoodTypeMethods.getGoodInfo(
            runtimeImprovement.capacity.production.goodType
          ).productionQualityExperienceCurves
        )
  );

  const [capacityModifyProducerValues, setCapacityModifyProducerValues] =
    useState<ModifyProducer[]>([]);

  const [goodModifyProducerValues, setGoodModifyProducerValues] = useState<
    ModifyProducer[]
  >([]);

  useEffect(() => {
    console.log('in graph runtimeImprovement', runtimeImprovement);
    const capacity = runtimeImprovement.capacity;
    if (capacity) {
      const experienceCurves =
        graphType === 'productionAmount'
          ? capacity.productionAmountExperienceCurves.concat(
              GoodTypeMethods.getGoodInfo(capacity.production.goodType)
                .productionAmountExperienceCurves
            )
          : capacity.productionQualityExperienceCurves.concat(
              GoodTypeMethods.getGoodInfo(capacity.production.goodType)
                .productionQualityExperienceCurves
            );

      setExperienceCurvesValues(experienceCurves.map((curve) => curve.clone()));

      const experienceTypes = Array.from(
        new Set(
          experienceCurves.flatMap((curve) =>
            curve.experienceInfluence.map((exp) => exp.experienceType)
          )
        )
      );
      setStartingExperienceValues(
        experienceTypes.map((experienceType) => ({
          experienceType,
          amount: 0,
        }))
      );

      // Initialize capacityModifyProducerValues
      setCapacityModifyProducerValues(
        capacity.modifyProducer.map((mod) => ({ ...mod }))
      );

      // Initialize goodModifyProducerValues
      const goodInfo = GoodTypeMethods.getGoodInfo(
        capacity.production.goodType
      );
      setGoodModifyProducerValues(
        goodInfo.modifyProducer.map((mod) => ({ ...mod }))
      );
    }
  }, [runtimeImprovement, graphType]);

  const simulateProduction = useMemo(() => {
    if (!runtimeImprovement.capacity) {
      return { labels: [], productionData: [], perCurveContributions: [] };
    }

    const capacity = runtimeImprovement.capacity;
    const productionData: number[] = [];
    const perCurveContributions: number[][] = experienceCurvesValues.map(
      () => []
    );
    const labels: string[] = [];

    const lifetimeExperience: { [key: string]: Experience } = {};

    startingExperienceValues.forEach((exp) => {
      lifetimeExperience[exp.experienceType] = new Experience(
        exp.experienceType,
        exp.amount
      );
    });

    const totalWeeks = (simulationYears * TimeEnum.Year) / TimeEnum.Week;

    for (let week = 0; week < totalWeeks; week++) {
      // Accumulate experience based on capacityModifyProducerValues
      capacityModifyProducerValues.forEach((mod) => {
        if (lifetimeExperience[mod.experienceType]) {
          lifetimeExperience[mod.experienceType].amount += mod.amount;
        } else {
          lifetimeExperience[mod.experienceType] = new Experience(
            mod.experienceType,
            mod.amount
          );
        }
      });

      // Accumulate experience based on goodModifyProducerValues
      goodModifyProducerValues.forEach((mod) => {
        if (lifetimeExperience[mod.experienceType]) {
          lifetimeExperience[mod.experienceType].amount += mod.amount;
        } else {
          lifetimeExperience[mod.experienceType] = new Experience(
            mod.experienceType,
            mod.amount
          );
        }
      });

      // Convert lifetimeExperience object to array
      const experienceArray = Object.values(lifetimeExperience);

      // Calculate production amount or quality
      let productionValue: number;
      if (graphType === 'productionAmount') {
        // Use updated experience curves
        const tempCapacity = capacity.clone();
        tempCapacity.productionAmountExperienceCurves = experienceCurvesValues;

        productionValue = tempCapacity.productionAmountCalculation.evaluate(
          tempCapacity.production.goodType,
          tempCapacity.productionAmountExperienceCurves,
          experienceArray,
          tempCapacity.production.improvementAffinity
        );
      } else {
        // Use updated experience curves
        const tempCapacity = capacity.clone();
        tempCapacity.productionQualityExperienceCurves = experienceCurvesValues;

        productionValue = tempCapacity.productionQualityCalculation.evaluate(
          tempCapacity.production.goodType,
          tempCapacity.productionQualityExperienceCurves,
          experienceArray,
          tempCapacity.production.improvementAffinity
        );
      }

      productionData.push(parseFloat(productionValue.toFixed(2)));

      experienceCurvesValues.forEach((curve, index) => {
        const curveValue = curve.evaluate(experienceArray);
        perCurveContributions[index].push(parseFloat(curveValue.toFixed(2)));
      });

      const yearLabel = (week / (TimeEnum.Year / TimeEnum.Week)).toFixed(1);
      labels.push(`Year ${yearLabel}`);
    }

    return { labels, productionData, perCurveContributions };
  }, [
    graphType,
    simulationYears,
    startingExperienceValues,
    experienceCurvesValues,
    capacityModifyProducerValues,
    goodModifyProducerValues,
  ]);

  const productionDatasets = [
    {
      label:
        graphType === 'productionAmount'
          ? 'Production Amount'
          : 'Production Quality',
      data: simulateProduction.productionData,
      borderColor: 'white',
      backgroundColor: 'white',
    },
  ];

  // Update function for starting experience
  const updateStartingExperience = (expIndex: number, value: number) => {
    setStartingExperienceValues((prev) => {
      const newStartingExperience = [...prev];
      newStartingExperience[expIndex] = {
        ...newStartingExperience[expIndex],
        amount: value,
      };
      return newStartingExperience;
    });
  };

  // Update functions for experience curves
  const updateCurveVars = (
    curveIndex: number,
    varName: string,
    value: number
  ) => {
    setExperienceCurvesValues((prev) => {
      const newCurves = [...prev];
      newCurves[curveIndex] = newCurves[curveIndex].clone();
      newCurves[curveIndex].functionInfo.vars[varName] = value;
      return newCurves;
    });
  };

  const updateCurveExperienceInfluence = (
    curveIndex: number,
    expIndex: number,
    value: number
  ) => {
    setExperienceCurvesValues((prev) => {
      const newCurves = [...prev];
      newCurves[curveIndex] = newCurves[curveIndex].clone();
      newCurves[curveIndex].experienceInfluence[expIndex] = {
        ...newCurves[curveIndex].experienceInfluence[expIndex],
        amount: value,
      };
      return newCurves;
    });
  };

  // Update functions for modifyProducerValues
  const updateCapacityModifyProducerValue = (index: number, value: number) => {
    setCapacityModifyProducerValues((prev) => {
      const newValues = [...prev];
      newValues[index] = { ...newValues[index], amount: value };
      return newValues;
    });
  };

  const updateGoodModifyProducerValue = (index: number, value: number) => {
    setGoodModifyProducerValues((prev) => {
      const newValues = [...prev];
      newValues[index] = { ...newValues[index], amount: value };
      return newValues;
    });
  };

  // Render sliders for starting experience
  const startingExperienceSliders = startingExperienceValues.map(
    (exp, index) => (
      <div key={index}>
        <RangeSlider
          title={exp.experienceType}
          min={0}
          max={100}
          startingValue={exp.amount}
          onValueChange={(value) => updateStartingExperience(index, value)}
        />
      </div>
    )
  );

  // Render sliders for capacityModifyProducerValues
  const capacityModifyProducerSliders = capacityModifyProducerValues.map(
    (mod, index) => (
      <div key={index}>
        <RangeSlider
          title={mod.experienceType}
          min={-10}
          max={10}
          step={0.1}
          startingValue={mod.amount}
          onValueChange={(value) =>
            updateCapacityModifyProducerValue(index, value)
          }
        />
      </div>
    )
  );

  // Render sliders for goodModifyProducerValues
  const goodModifyProducerSliders = goodModifyProducerValues.map(
    (mod, index) => (
      <div key={index}>
        <RangeSlider
          title={mod.experienceType}
          min={-10}
          max={10}
          step={0.1}
          startingValue={mod.amount}
          onValueChange={(value) => updateGoodModifyProducerValue(index, value)}
        />
      </div>
    )
  );

  // Render curves grouped by experience influence and variables
  const curvesLineItems = experienceCurvesValues.map((curve, curveIndex) => {
    const functionType = curve.functionInfo.type;
    const vars = curve.functionInfo.vars;

    let functionVariableSliders: React.ReactNode[] = [];

    if (functionType === 'exponential decay') {
      const { initSlope, finSlope, xAdj, yAdj } = vars as {
        initSlope: number;
        finSlope: number;
        xAdj: number;
        yAdj: number;
      };
      functionVariableSliders = [
        <RangeSlider
          key='initSlope'
          title='Initial Slope'
          min={initSlope - Math.abs(initSlope) * 2}
          max={initSlope + Math.abs(initSlope) * 2}
          step={0.0001}
          startingValue={initSlope}
          onValueChange={(value) =>
            updateCurveVars(curveIndex, 'initSlope', value)
          }
        />,
        <RangeSlider
          key='finSlope'
          title='Final Slope'
          min={finSlope - Math.abs(finSlope) * 2}
          max={finSlope + Math.abs(finSlope) * 2}
          step={0.0001}
          startingValue={finSlope}
          onValueChange={(value) =>
            updateCurveVars(curveIndex, 'finSlope', value)
          }
        />,
        <RangeSlider
          key='xAdj'
          title='X Adjustment'
          min={xAdj - Math.abs(xAdj) * 2}
          max={xAdj + Math.abs(xAdj) * 2}
          step={0.1}
          startingValue={xAdj}
          onValueChange={(value) => updateCurveVars(curveIndex, 'xAdj', value)}
        />,
        <RangeSlider
          key='yAdj'
          title='Y Adjustment'
          min={yAdj - Math.abs(yAdj) * 2}
          max={yAdj + Math.abs(yAdj) * 2}
          step={0.1}
          startingValue={yAdj}
          onValueChange={(value) => updateCurveVars(curveIndex, 'yAdj', value)}
        />,
      ];
    } else if (functionType === 'sigmoid') {
      const { linearSlope, sigmoidSlope, height, xAdj, yAdj } = vars as {
        linearSlope: number;
        sigmoidSlope: number;
        height: number;
        xAdj: number;
        yAdj: number;
      };
      functionVariableSliders = [
        <RangeSlider
          key='linearSlope'
          title='Linear Slope'
          min={linearSlope - Math.abs(linearSlope) * 2}
          max={linearSlope + Math.abs(linearSlope) * 2}
          step={0.0001}
          startingValue={linearSlope}
          onValueChange={(value) =>
            updateCurveVars(curveIndex, 'linearSlope', value)
          }
        />,
        <RangeSlider
          key='sigmoidSlope'
          title='Sigmoid Slope'
          min={sigmoidSlope - Math.abs(sigmoidSlope) * 2}
          max={sigmoidSlope + Math.abs(sigmoidSlope) * 2}
          step={0.0001}
          startingValue={sigmoidSlope}
          onValueChange={(value) =>
            updateCurveVars(curveIndex, 'sigmoidSlope', value)
          }
        />,
        <RangeSlider
          key='height'
          title='Height'
          min={0}
          max={height + Math.abs(height) * 2}
          step={0.1}
          startingValue={height}
          onValueChange={(value) =>
            updateCurveVars(curveIndex, 'height', value)
          }
        />,
        <RangeSlider
          key='xAdj'
          title='X Adjustment'
          min={xAdj - Math.abs(xAdj) * 2}
          max={xAdj + Math.abs(xAdj) * 2}
          step={0.1}
          startingValue={xAdj}
          onValueChange={(value) => updateCurveVars(curveIndex, 'xAdj', value)}
        />,
        <RangeSlider
          key='yAdj'
          title='Y Adjustment'
          min={yAdj - Math.abs(yAdj) * 2}
          max={yAdj + Math.abs(yAdj) * 2}
          step={0.1}
          startingValue={yAdj}
          onValueChange={(value) => updateCurveVars(curveIndex, 'yAdj', value)}
        />,
      ];
    }

    const curveDataset = [
      {
        label: `${curve.name || `Curve ${curveIndex + 1}`} Contribution`,
        data: simulateProduction.perCurveContributions[curveIndex],
        borderColor: 'white',
        backgroundColor: 'white',
      },
    ];

    return (
      <LineItemStandard
        key={curveIndex}
        title={`${curve.name} (${functionType})`}
        dropdown={
          <VerticalList
            data={[
              // Experience Influence Sliders
              <LineItemStandard
                key='experienceInfluence'
                title='Experience Influence'
                dropdown={
                  <VerticalList
                    data={curve.experienceInfluence.map((exp, expIndex) => (
                      <RangeSlider
                        key={expIndex}
                        title={exp.experienceType}
                        min={0}
                        max={3}
                        step={0.01}
                        startingValue={exp.amount}
                        onValueChange={(value) =>
                          updateCurveExperienceInfluence(
                            curveIndex,
                            expIndex,
                            value
                          )
                        }
                      />
                    ))}
                  />
                }
              />,
              // Function Variables Sliders
              <LineItemStandard
                key='vars'
                title='Function Variables'
                dropdown={<VerticalList data={functionVariableSliders} />}
              />,
              // Curve Graph
              <Graph
                key='perCurveContributions'
                labels={simulateProduction.labels}
                datasets={curveDataset}
              />,
            ]}
          />
        }
      />
    );
  });

  return (
    <div>
      <LineItemStandard
        title='Starting Experience'
        dropdown={<VerticalList data={startingExperienceSliders} />}
      />

      <LineItemStandard
        title='Modify Producer'
        dropdown={
          <VerticalList
            data={[
              <LineItemStandard
                key='capacityModifyProducer'
                title='Capacity Modify Producer'
                dropdown={<VerticalList data={capacityModifyProducerSliders} />}
              />,
              <LineItemStandard
                key='goodModifyProducer'
                title='Good Modify Producer'
                dropdown={<VerticalList data={goodModifyProducerSliders} />}
              />,
            ]}
          />
        }
      />

      <VerticalList data={curvesLineItems} />

      <Graph labels={simulateProduction.labels} datasets={productionDatasets} />
      <RangeSlider
        title='Simulation Years'
        min={0}
        max={50}
        startingValue={simulationYears}
        onValueChange={setSimulationYears}
      />
    </div>
  );
};

export default ImprovementGraph;
