import React, { useState, useEffect } from 'react';

// Import your styled component
import { StartingStateTileStyled } from './styles/StartingStateTile.styled.js';
import GameState from '../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { StartingState } from '../../contexts/GameData/StartingStatesContextProvider.js';

interface StartingStateTileProps {
  startingState: StartingState;
  isSelected: boolean;
  onStartingStateClicked: (ss: StartingState) => void;
  deleteStartingState: (id: string) => void;
}

function StartingStateTile({
  startingState,
  isSelected,
  onStartingStateClicked,
  deleteStartingState,
}: StartingStateTileProps) {
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    if (!startingState) return;
    // Assuming you have a method to get land tiles from gameState.realm
    setTiles(startingState.gameState.realm.getLandTiles());
  }, [startingState]);

  return (
    <StartingStateTileStyled
      onClick={() => {
        onStartingStateClicked(startingState);
      }}
      selected={isSelected}
    >
      <div className='title-area'>
        <div className='title'>{startingState.gameState.realm.name}</div>
      </div>
      <div className='delete-button-holder'>
        <div
          className='delete-button'
          onClick={(event) => {
            event.stopPropagation(); // Prevent click from selecting the tile
            if (startingState._id) {
              deleteStartingState(startingState._id);
            }
          }}
        >
          X
        </div>
      </div>
      <div className='background'>
        <div className='background-holder'>
          {/* 
            If you had a preview map before (like RealmMap), you could implement a similar 
            preview here using tiles. For now, it's just a placeholder area.
          */}
        </div>
      </div>
    </StartingStateTileStyled>
  );
}

export default StartingStateTile;
