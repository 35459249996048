import styled from 'styled-components';
import '../../../../styles/constants.css';

export const ImageBoxStyled = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: ${({ height }) => (height ? height : 'auto')};

  img {
    width: 100%;
    height: ${({ height }) => (height ? '100%' : 'auto')};
    object-fit: ${({ height }) => (height ? 'cover' : 'initial')};
    margin: 0;
    padding: 0;
    display: block;
  }

  ${({ fadeTop, fadeBottom }) => {
    if (fadeTop || fadeBottom) {
      return `
        -webkit-mask: linear-gradient(
          ${fadeTop ? '180deg' : '0deg'},
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 1) 80%,
          rgba(0, 0, 0, 0) 100%
        );
        mask: linear-gradient(
          ${fadeTop ? '180deg' : '0deg'},
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 1) 80%,
          rgba(0, 0, 0, 0) 100%
        );
      `;
    }
    return '';
  }}
`;
