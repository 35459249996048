// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

// internal context
import { SelectedLandtileContext } from '../../../../../contexts/GameData/SelectedLandtileContextProvider.tsx';
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { TileInfoStyled } from './styles/TileInfo.styled.js';

// gl
import {
  BiomeType,
  BiomeTypeMethods,
} from '../../../../../../../../GildedLands/lib/Enums/Biome.js';
import { GoodType } from '../../../../../../../../GildedLands/lib/Enums/Good.js';
import CenterTitle from '../../../../UI/GenericComponents/CenterTitle.tsx';
import { UIPadding, UIColor, UIOpacity } from '../../../../UI/Enums/UIEnums.ts';
import Background from '../../../../UI/GenericComponents/Background.tsx';
import RuntimeLandTile from '../../../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';

const TileInfo = () => {
  const selectedTileIndex = useContext(SelectedLandtileContext);
  const { gameState } = useContext(GameStateDataContext);

  const [tile, setTile] = useState<RuntimeLandTile | null>(null);

  useEffect(() => {
    if (!selectedTileIndex) {
      setTile(null);
      return;
    }
    const newTile = gameState?.getLandTileByTileId(selectedTileIndex);
    if (newTile) {
      setTile(newTile);
    }
  }, [selectedTileIndex]);

  if (!tile) {
    return null;
  }

  if (!tile.mainBiomeType) {
    return (
      <TileInfoStyled className='tile-info'>
        <div className='biome-type-title'>Uncharted Territory</div>
        <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
      </TileInfoStyled>
    );
  }

  return (
    <TileInfoStyled className='tile-info'>
      <div className='biome-type-title'>
        <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
        {BiomeTypeMethods.displayName(tile?.mainBiomeType)}
      </div>
      {tile.resources.length > 0 && (
        <div className='resources'>
          <CenterTitle
            title='Resources'
            padding={UIPadding.Short}
            onClick={null}
            backgroundData={{
              color: UIColor.HeaderBlue,
              opacity: UIOpacity.Header,
            }}
          />
          <div className='tile-info-list resource-list'>
            <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
            {tile.resources.map((resource, index) => (
              <div key={index} className='tile-info-list-item'>
                <div className='tile-info-list-item-title'>
                  {GoodType[resource.goodType]}:&nbsp;
                </div>
                <div className='tile-info-list-item-value'>
                  {resource.amount}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {tile.improvementInstanceIds.length > 0 && (
        <div className='improvements'>
          <CenterTitle
            title='Improvements'
            padding={UIPadding.Short}
            onClick={null}
            backgroundData={{
              color: UIColor.HeaderBlue,
              opacity: UIOpacity.Header,
            }}
          />
          <div className='tile-info-list improvement-list'>
            <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
            {tile.improvementInstanceIds.map((improvementInstanceId) => (
              <div key={improvementInstanceId} className='tile-info-list-item'>
                <div className='tile-info-list-item-title'>
                  {
                    gameState?.getImprovementByInstanceId(improvementInstanceId)
                      ?.name
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {tile.tradeRouteInstanceIds.length > 0 && (
        <div className='trade-routes'>
          <CenterTitle
            title='Trade Routes'
            padding={UIPadding.Short}
            onClick={null}
            backgroundData={{
              color: UIColor.HeaderBlue,
              opacity: UIOpacity.Header,
            }}
          />
          <div className='tile-info-list trade-route-list'>
            <Background color={UIColor.ListBlue} opacity={UIOpacity.List} />
            {tile.tradeRouteInstanceIds.join(', ')}
          </div>
        </div>
      )}
    </TileInfoStyled>
  );
};

export default TileInfo;
