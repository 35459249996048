import React, { useState, useContext } from 'react';
import { TaxItemStyled } from './styles/TaxItem.styled.js';
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';
import ExportTariff from '../../../../../../../../../GildedLands/lib/Classes/Tax/ExportTariff.js';
import {
  GoodCategory,
  GoodSubCategory,
  GoodType,
} from '../../../../../../../../../GildedLands/lib/Enums/Good.js';
import EnumList from '../Utilities/EnumList.tsx';
import NationList from '../Utilities/NationList.tsx';

interface ExportTariffItemProps {
  tariff: ExportTariff;
}

const ExportTariffItem = ({ tariff }: ExportTariffItemProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const { editTax, deleteTax } = useContext(GameStateLogicContext);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const saveNewTariff = (newTariff: ExportTariff) => {
    editTax(newTariff);
  };

  return (
    <TaxItemStyled>
      <div className='tax-main-info' onClick={toggleDetails}>
        <span className='tax-title'>
          {showDetails ? '^ ' : '> '}
          Export Tariff
        </span>
        <span className='tax-info'>Tax Percentage: {tariff.taxPercentage}</span>
      </div>
      {showDetails && (
        <div className='tax-item-details'>
          <div className='tax-details-category-content'>
            <label>
              Tax Percentage:
              <input
                type='number'
                name='taxPercentage'
                defaultValue={tariff.taxPercentage * 100}
                onBlur={(e) => {
                  const newTariff = tariff.clone();
                  newTariff.taxPercentage = parseFloat(e.target.value) / 100;
                  saveNewTariff(newTariff);
                }}
              />
              <span>%</span>
            </label>
            <div className='tax-goods'>
              <label>
                Is On All Goods:
                <input
                  type='checkbox'
                  name='isOnAllGoods'
                  defaultChecked={tariff.isOnAllGoods}
                  onChange={(e) => {
                    const newTariff = tariff.clone();
                    newTariff.isOnAllGoods = e.target.checked;
                    saveNewTariff(newTariff);
                  }}
                />
              </label>
              <EnumList
                title='Excluded Categories'
                enumObject={GoodCategory}
                values={tariff.excludedCategories}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.excludedCategories = values;
                  saveNewTariff(newTariff);
                }}
              />
              <EnumList
                title='Excluded SubCategories'
                enumObject={GoodSubCategory}
                values={tariff.excludedSubCategories}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.excludedSubCategories = values;
                  saveNewTariff(newTariff);
                }}
              />
              <EnumList
                title='Excluded Goods'
                enumObject={GoodType}
                values={tariff.excludedGoods}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.excludedGoods = values;
                  saveNewTariff(newTariff);
                }}
              />
              <EnumList
                title='Included Categories'
                enumObject={GoodCategory}
                values={tariff.includedCategories}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.includedCategories = values;
                  saveNewTariff(newTariff);
                }}
              />
              <EnumList
                title='Included SubCategories'
                enumObject={GoodSubCategory}
                values={tariff.includedSubCategories}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.includedSubCategories = values;
                  saveNewTariff(newTariff);
                }}
              />
              <EnumList
                title='Included Goods'
                enumObject={GoodType}
                values={tariff.includedGoods}
                save={(values) => {
                  const newTariff = tariff.clone();
                  newTariff.includedGoods = values;
                  saveNewTariff(newTariff);
                }}
              />
            </div>
            <div className='tax-nations'>
              <NationList
                nationData={{
                  isOnAllNations: tariff.isOnAllNations,
                  excludedNationIds: tariff.excludedNationIds,
                  includedNationIds: tariff.includedNationIds,
                }}
                save={(nationData: {
                  isOnAllNations: boolean;
                  excludedNationIds: number[];
                  includedNationIds: number[];
                }) => {
                  const newTariff = tariff.clone();
                  newTariff.isOnAllNations = nationData.isOnAllNations;
                  newTariff.excludedNationIds = nationData.excludedNationIds;
                  newTariff.includedNationIds = nationData.includedNationIds;
                  saveNewTariff(newTariff);
                }}
              />
            </div>
          </div>
          <button
            className='delete-tax-button'
            onClick={() => deleteTax(tariff.instanceId)}
          >
            Delete
          </button>
        </div>
      )}
    </TaxItemStyled>
  );
};

export default ExportTariffItem;
