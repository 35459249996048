// external
import React from 'react';

// comps
import Background, { IGenericBackground } from './Background.tsx';

// contexts
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

// css
import { FlagshipSelectionListStyled } from './styles/FlagshipSelectionList.styled.js';
import FlagshipSelectionBox, {
  IFlagshipSelectionBox,
} from './FlagshipSelectionBox.tsx';
import GenericBackground from './Background.tsx';
import { UIColor } from '../Enums/UIEnums.ts';

interface IFlagshipSelectionList {
  selectionBoxes: IFlagshipSelectionBox[];
}

const FlagshipSelectionList: React.FC<IFlagshipSelectionList> = ({
  selectionBoxes,
}) => {
  const { getIKUrl } = React.useContext(ImageContext);

  return (
    <FlagshipSelectionListStyled className='flagship-selection-list'>
      <div className='flagship-box-container-background'>
        <GenericBackground color={UIColor.None} opacity={0.1} />
      </div>
      <div className='flagship-box-container'>
        {selectionBoxes.map((box, index) => (
          <FlagshipSelectionBox key={index} {...box} />
        ))}
      </div>
    </FlagshipSelectionListStyled>
  );
};

export default FlagshipSelectionList;
export { IFlagshipSelectionList };
