import { BiomeTypeMethods } from './Biome.js';
import { GoodCategory, GoodTypeMethods } from './Good.js';
import { ImprovementTypeMethods } from './ImprovementTypeMethods.js';
// #region Enums
var RequirementTypeEnum;
(function (RequirementTypeEnum) {
    RequirementTypeEnum["Box"] = "Box";
    RequirementTypeEnum["NoRequirements"] = "NoRequirements";
    RequirementTypeEnum["NotPlayerCreated"] = "NotPlayerCreated";
    RequirementTypeEnum["InCity"] = "InCity";
    RequirementTypeEnum["TileHasResource"] = "TileHasResource";
    RequirementTypeEnum["CityHasImprovement"] = "CityHasImprovement";
    RequirementTypeEnum["CityHasExperience"] = "CityHasExperience";
    RequirementTypeEnum["CityHasGenius"] = "CityHasGenius";
    RequirementTypeEnum["TileIsCity"] = "TileIsCity";
    RequirementTypeEnum["TileIsNotCity"] = "TileIsNotCity";
    RequirementTypeEnum["TileIsCoast"] = "TileIsCoast";
    RequirementTypeEnum["TileHasNoAgriculture"] = "TileHasNoAgriculture";
    RequirementTypeEnum["TileHasBiome"] = "TileHasBiome";
    RequirementTypeEnum["TileHasBiomeInList"] = "TileHasBiomeInList";
    RequirementTypeEnum["ProductionGoodAvailable"] = "ProductionGoodAvailable";
})(RequirementTypeEnum || (RequirementTypeEnum = {}));
var RequirementBoxTypeEnum;
(function (RequirementBoxTypeEnum) {
    RequirementBoxTypeEnum["Any"] = "Any";
    RequirementBoxTypeEnum["All"] = "All";
})(RequirementBoxTypeEnum || (RequirementBoxTypeEnum = {}));
// #endregion
// #region Return Classes
class RequirementReturn {
    constructor(isMet, requirementType) {
        this.isMet = isMet;
        this.requirementType = requirementType;
    }
}
class RequirementBoxReturn extends RequirementReturn {
    constructor(isMet, boxType, returnList) {
        super(isMet, RequirementTypeEnum.Box);
        this.boxType = boxType;
        this.returnList = returnList;
    }
}
class SingleRequirementReturn extends RequirementReturn {
    constructor(isMet, requirementType, reason) {
        super(isMet, requirementType);
        this.reason = reason;
    }
}
// #endregion
// #region Requirement Types
class Requirement {
    constructor(type) {
        this.type = type;
    }
}
class RequirementBox extends Requirement {
    constructor(boxType, requirements) {
        super(RequirementTypeEnum.Box);
        this.boxType = boxType;
        this.requirements = requirements;
    }
    hasRequirementType(type) {
        if (this.type === type)
            return true;
        for (let requirement of this.requirements) {
            if (requirement.hasRequirementType(type))
                return true;
        }
        return false;
    }
}
class SingleRequirement extends Requirement {
    constructor(type) {
        super(type);
    }
    hasRequirementType(type) {
        if (this.type === type)
            return true;
    }
}
class CityExperienceRequirement extends SingleRequirement {
    constructor(experienceType, experience) {
        super(RequirementTypeEnum.CityHasExperience);
        this.experienceType = experienceType;
        this.experience = experience;
    }
}
class CityHasGeniusRequirement extends SingleRequirement {
    constructor(experienceType, experience) {
        super(RequirementTypeEnum.CityHasGenius);
        this.experienceType = experienceType;
        this.experience = experience;
    }
}
class TileHasResourceRequirement extends SingleRequirement {
    constructor(resource) {
        super(RequirementTypeEnum.TileHasResource);
        this.resource = resource;
    }
}
class TileIsCityRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.TileIsCity);
    }
}
class TileIsNotCityRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.TileIsNotCity);
    }
}
class NotPlayerCreatedRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.NotPlayerCreated);
    }
}
class TileIsCoastRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.TileIsCoast);
    }
}
class TileHasNoOtherFarmRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.TileHasNoAgriculture);
    }
}
class TileHasBiomeRequirement extends SingleRequirement {
    constructor(biomeType) {
        super(RequirementTypeEnum.TileHasBiome);
        this.biomeType = biomeType;
    }
}
class TileHasBiomeInListRequirement extends SingleRequirement {
    constructor(biomeTypes) {
        super(RequirementTypeEnum.TileHasBiome);
        this.biomeTypes = biomeTypes;
    }
}
class ProductionGoodAvailableRequirement extends SingleRequirement {
    constructor() {
        super(RequirementTypeEnum.ProductionGoodAvailable);
    }
}
// #endregion
class RequirementTypeMethods {
    static checkRequirement(requirementData, improvementType, landTile, gameState) {
        if (!requirementData) {
            return new SingleRequirementReturn(true, RequirementTypeEnum.NoRequirements, 'No requirements needed to be met');
        }
        if (requirementData.type === undefined || requirementData.type === null) {
            throw new Error(`Requirement type not found for requirementData: ${requirementData}`);
        }
        if (requirementData.type === RequirementTypeEnum.Box) {
            let requirementBox = requirementData;
            if (!requirementBox)
                return new RequirementBoxReturn(false, requirementBox.boxType, []);
            let returnList = [];
            for (let requirement of requirementBox.requirements) {
                returnList.push(RequirementTypeMethods.checkRequirement(requirement, improvementType, landTile, gameState));
            }
            return new RequirementBoxReturn(requirementBox.boxType === RequirementBoxTypeEnum.All
                ? returnList.every((requirement) => requirement.isMet)
                : returnList.some((requirement) => requirement.isMet), requirementBox.boxType, returnList);
        }
        else {
            return RequirementTypeMethods.checkSingleRequirement(requirementData, improvementType, landTile, gameState);
        }
    }
    static checkSingleRequirement(requirementData, improvementType, landTile, gameState) {
        if (!requirementData) {
            return new SingleRequirementReturn(true, RequirementTypeEnum.NoRequirements, 'No requirements needed to be met');
        }
        const improvementInfo = ImprovementTypeMethods.getImprovementInfo(improvementType);
        if (!improvementInfo)
            throw new Error('Improvement type not found');
        switch (requirementData.type) {
            case RequirementTypeEnum.NotPlayerCreated: {
                return new SingleRequirementReturn(false, requirementData.type, 'This improvement cannot be created by the player.');
            }
            case RequirementTypeEnum.CityHasExperience: {
                let city = gameState.getCityByInstanceId(landTile.cityInstanceId);
                if (!city)
                    return new SingleRequirementReturn(false, requirementData.type, 'City not found for tile');
                let requirement = requirementData;
                if (!requirement)
                    return new SingleRequirementReturn(false, requirementData.type, 'Requirement not found');
                let experience = city.getExperienceAmount(requirement.experienceType);
                return new SingleRequirementReturn(experience >= requirement.experience, requirementData.type, `City has ${city
                    .getExperienceAmount(requirement.experienceType)
                    .toFixed(0)} ${requirement.experienceType} experience and needs ${requirement.experience}`);
            }
            case RequirementTypeEnum.CityHasGenius: {
                let city = gameState.getCityByInstanceId(landTile.cityInstanceId);
                if (!city) {
                    return new SingleRequirementReturn(false, requirementData.type, 'City not found for tile');
                }
                let requirement = requirementData;
                if (!requirement)
                    return new SingleRequirementReturn(false, requirementData.type, 'Requirement not found');
                const bestPerson = city.persons.reduce((best, current) => {
                    return current.getExperienceAmount(requirement.experienceType) >
                        best.getExperienceAmount(requirement.experienceType)
                        ? current
                        : best;
                });
                if (!bestPerson)
                    return new SingleRequirementReturn(false, requirementData.type, 'No person found with experience');
                return new SingleRequirementReturn(bestPerson.getExperienceAmount(requirement.experienceType) >=
                    requirement.experience, requirementData.type, `City has person with ${bestPerson.getExperienceAmount(requirement.experienceType)} ${requirement.experienceType} experience and needs ${requirement.experience}`);
            }
            case RequirementTypeEnum.TileIsCity: {
                let city = gameState.getCityByInstanceId(landTile.cityInstanceId);
                if (!city)
                    return new SingleRequirementReturn(false, requirementData.type, 'City not found for tile');
                if (city.centerTileLandtileId !== landTile.id)
                    return new SingleRequirementReturn(false, requirementData.type, 'Improvement must be built on the center tile of the city.');
                return new SingleRequirementReturn(true, requirementData.type, 'Tile is a city');
            }
            case RequirementTypeEnum.TileIsNotCity: {
                let city = gameState.getCityByInstanceId(landTile.cityInstanceId);
                if (!city)
                    return new SingleRequirementReturn(false, requirementData.type, 'City not found for tile');
                if (city.centerTileLandtileId === landTile.id)
                    return new SingleRequirementReturn(false, requirementData.type, 'Improvement cannot be built on the center tile of the city.');
                return new SingleRequirementReturn(true, requirementData.type, 'Tile is not a city');
            }
            case RequirementTypeEnum.TileHasResource: {
                let requirement = requirementData;
                return new SingleRequirementReturn(landTile.resources.some((resource) => resource.goodType === requirement.resource), requirementData.type, `Tile needs ${requirement.resource}`);
            }
            case RequirementTypeEnum.TileHasNoAgriculture: {
                for (let improvementInstanceId of landTile.improvementInstanceIds) {
                    let improvement = gameState.improvements.find((improvement) => improvement.instanceId === improvementInstanceId);
                    if (!!improvement.capacity &&
                        GoodTypeMethods.getGoodInfo(improvement.capacity.production.goodType).category === GoodCategory.Food) {
                        return new SingleRequirementReturn(false, requirementData.type, 'Tile must have no other agriculture.');
                    }
                }
                return new SingleRequirementReturn(true, requirementData.type, 'Tile has no agriculture');
            }
            case RequirementTypeEnum.TileIsCoast: {
                if (BiomeTypeMethods.isWater(landTile.mainBiomeType)) {
                    return new SingleRequirementReturn(false, requirementData.type, 'Tile is water, not coastal land.');
                }
                let neighbors = landTile.findNeighbors(gameState.realm);
                for (let neighbor of neighbors) {
                    if (BiomeTypeMethods.isWater(neighbor.mainBiomeType)) {
                        return new SingleRequirementReturn(true, requirementData.type, 'Tile is coastal');
                    }
                }
                return new SingleRequirementReturn(false, requirementData.type, 'Tile is not coastal');
            }
            case RequirementTypeEnum.TileHasBiome: {
                let requirement = requirementData;
                return new SingleRequirementReturn(landTile.mainBiomeType === requirement.biomeType ||
                    landTile.allBiomeTypes.includes(requirement.biomeType), requirementData.type, `Tile needs ${requirement.biomeType}`);
            }
            case RequirementTypeEnum.TileHasBiomeInList: {
                let requirement = requirementData;
                return new SingleRequirementReturn(requirement.biomeTypes.includes(landTile.mainBiomeType) ||
                    landTile.allBiomeTypes.some((biome) => requirement.biomeTypes.includes(biome)), requirementData.type, `Tile needs ${requirement.biomeTypes.join(', ')}`);
            }
            case RequirementTypeEnum.ProductionGoodAvailable: {
                for (let good of improvementInfo.capacity.possibleProduction) {
                    const reqMet = RequirementTypeMethods.checkRequirement(good.requirements, improvementType, landTile, gameState);
                    if (reqMet.isMet)
                        return new SingleRequirementReturn(true, requirementData.type, `Improvement has available production.`);
                }
                return new SingleRequirementReturn(false, requirementData.type, 'No production good is available');
            }
            default:
                return new SingleRequirementReturn(false, requirementData.type, 'Requirement type not implemented');
        }
    }
}
export { RequirementTypeEnum, RequirementBoxTypeEnum, RequirementTypeMethods, Requirement, RequirementBox, CityExperienceRequirement, RequirementReturn, RequirementBoxReturn, SingleRequirementReturn, TileHasResourceRequirement, TileIsCityRequirement, TileIsNotCityRequirement, NotPlayerCreatedRequirement, TileIsCoastRequirement, TileHasNoOtherFarmRequirement, CityHasGeniusRequirement, TileHasBiomeRequirement, TileHasBiomeInListRequirement, ProductionGoodAvailableRequirement, };
