import EquipmentCurveInfo from '../Classes/Army/EquipmentCurveInfo.js';
import { SoldierCalculation } from '../Classes/Army/SoldierCalculation.js';
import { ExperienceCurveType } from './Experience.js';
import { GoodType } from './Good.js';
var EquipmentType;
(function (EquipmentType) {
    EquipmentType[EquipmentType["Weapon"] = 0] = "Weapon";
    EquipmentType[EquipmentType["Armor"] = 1] = "Armor";
    EquipmentType[EquipmentType["HorseArmor"] = 2] = "HorseArmor";
    EquipmentType[EquipmentType["Equipment"] = 3] = "Equipment";
})(EquipmentType || (EquipmentType = {}));
function getEquipmentInfo(goodType) {
    switch (goodType) {
        case GoodType.Gladius:
            return {
                goodType: GoodType.Gladius,
                equipmentType: EquipmentType.Weapon,
                weight: 1,
                experienceCurves: [ExperienceCurveType.Swordsmanship],
                equipmentQualityCurves: [
                    EquipmentCurveInfo.fromJSON({
                        name: 'GladiusEffectivenessFromQuality',
                        goodType: GoodType.Gladius,
                        functionInfo: {
                            type: 'exponential decay',
                            vars: {
                                initSlope: 0.48,
                                finSlope: 1.64,
                                xAdj: 4,
                                yAdj: 50,
                            },
                        },
                    }),
                ],
                attackCalculation: SoldierCalculation.fromJSON({
                    name: 'Attack',
                    type: 'operation',
                    operation: 'multiply',
                    operands: [
                        {
                            name: 'Base Attack',
                            type: 'constant',
                            operation: null,
                            operands: [],
                            value: '100',
                        },
                        {
                            name: 'Base Weapon Curve to Fraction',
                            type: 'operation',
                            operation: 'divide',
                            operands: [
                                {
                                    name: 'Base Weapon Curve',
                                    type: 'experienceCurve',
                                    operation: null,
                                    operands: [],
                                    value: ExperienceCurveType.Swordsmanship,
                                },
                                {
                                    name: '100',
                                    type: 'constant',
                                    operation: null,
                                    operands: [],
                                    value: '100',
                                },
                            ],
                            value: null,
                        },
                        {
                            name: 'Equipment Curve to Fraction',
                            type: 'operation',
                            operation: 'divide',
                            operands: [
                                {
                                    name: 'Equipment Curve',
                                    type: 'equipmentCurve',
                                    operation: null,
                                    operands: [],
                                    value: 'GladiusEffectivenessFromQuality',
                                },
                                {
                                    name: '100',
                                    type: 'constant',
                                    operation: null,
                                    operands: [],
                                    value: '100',
                                },
                            ],
                            value: null,
                        },
                    ],
                    value: null,
                }),
                defenseCalculation: null,
                shockCalculation: null,
                secondaryShockCalculation: null,
                formationCalculation: null,
                secondaryFormationCalculation: null,
            };
    }
}
export default getEquipmentInfo;
