import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const TaxManagerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: auto;

  .tax-group {
    margin-top: 10px;
  }

  .tax-group-title {
    display: inline-block;
    margin-bottom: 10px;
  }

  .add-tax-button {
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 50px;
  }
`;
