import TileResource from '../Classes/Land/LandTile/TileResource.js';
import { GoodType } from './Good.js';
import { TimeEnum } from './Time.js';
var BiomeType;
(function (BiomeType) {
    BiomeType["Desert"] = "Desert";
    BiomeType["DesertCacti"] = "DesertCacti";
    BiomeType["DesertDeep"] = "DesertDeep";
    BiomeType["DesertMid"] = "DesertMid";
    BiomeType["DesertHills"] = "DesertHills";
    BiomeType["DesertShallow"] = "DesertShallow";
    BiomeType["DesertPillars"] = "DesertPillars";
    BiomeType["Farm"] = "Farm";
    BiomeType["Forest"] = "Forest";
    BiomeType["ForestBroadleaf"] = "ForestBroadleaf";
    BiomeType["ForestBroadleafThin"] = "ForestBroadleafThin";
    BiomeType["ForestPine"] = "ForestPine";
    BiomeType["ForestPineFullSnow"] = "ForestPineFullSnow";
    BiomeType["ForestPinePartSnow"] = "ForestPinePartSnow";
    BiomeType["Highlands"] = "Highlands";
    BiomeType["Hills"] = "Hills";
    BiomeType["HillsFullSnow"] = "HillsFullSnow";
    BiomeType["HillsPartSnow"] = "HillsPartSnow";
    BiomeType["HillsFarm"] = "HillsFarm";
    BiomeType["Marsh"] = "Marsh";
    BiomeType["Mountains"] = "Mountains";
    BiomeType["MountainsFullSnow"] = "MountainsFullSnow";
    BiomeType["MountainsPartSnow"] = "MountainsPartSnow";
    BiomeType["LonelyMountain"] = "LonelyMountain";
    BiomeType["MountainFarm"] = "MountainFarm";
    BiomeType["MountainsHighAlpine"] = "MountainsHighAlpine";
    BiomeType["Ocean"] = "Ocean";
    BiomeType["OceanCalm"] = "OceanCalm";
    BiomeType["OceanRough"] = "OceanRough";
    BiomeType["OceanIcebergs"] = "OceanIcebergs";
    BiomeType["Prairie"] = "Prairie";
    BiomeType["PrairieFullSnow"] = "PrairieFullSnow";
    BiomeType["PrairiePartSnow"] = "PrairiePartSnow";
    BiomeType["Tundra"] = "Tundra";
    BiomeType["TundraLargeDrifts"] = "TundraLargeDrifts";
    BiomeType["TundraSmallDrifts"] = "TundraSmallDrifts";
    BiomeType["NaturalWonder"] = "NaturalWonder";
    BiomeType["City"] = "City";
})(BiomeType || (BiomeType = {}));
class BiomeTypeMethods {
    static shippingCostPerTile(biomeType) {
        switch (biomeType) {
            case BiomeType.DesertShallow:
            case BiomeType.DesertMid:
            case BiomeType.DesertDeep:
            case BiomeType.DesertHills:
            case BiomeType.DesertCacti:
            case BiomeType.DesertPillars:
                return 2;
            case BiomeType.Farm:
                return 1;
            case BiomeType.ForestBroadleaf:
            case BiomeType.ForestBroadleafThin:
            case BiomeType.ForestPine:
            case BiomeType.ForestPineFullSnow:
            case BiomeType.ForestPinePartSnow:
                return 4;
            case BiomeType.Highlands:
            case BiomeType.Hills:
            case BiomeType.HillsFullSnow:
            case BiomeType.HillsPartSnow:
                return 3;
            case BiomeType.Marsh:
                return 6;
            case BiomeType.MountainsFullSnow:
            case BiomeType.MountainsPartSnow:
                return 10;
            case BiomeType.OceanCalm:
            case BiomeType.OceanRough:
            case BiomeType.OceanIcebergs:
                return 0.1;
            case BiomeType.Prairie:
            case BiomeType.PrairieFullSnow:
            case BiomeType.PrairiePartSnow:
                return 1;
            case BiomeType.Tundra:
            case BiomeType.TundraLargeDrifts:
            case BiomeType.TundraSmallDrifts:
                return 4;
            default:
                throw new Error(`Need to add this enum to this method: ${biomeType}`);
        }
    }
    static baseTravelTime(biomeType) {
        switch (biomeType) {
            case BiomeType.Prairie:
                return TimeEnum.Week;
            case BiomeType.PrairiePartSnow:
                return TimeEnum.Week + TimeEnum.Day * 3;
            case BiomeType.PrairieFullSnow:
                return TimeEnum.Week * 2;
            case BiomeType.DesertShallow:
                return TimeEnum.Week;
            case BiomeType.DesertMid:
                return TimeEnum.Week * 2;
            case BiomeType.DesertDeep:
                return TimeEnum.Week * 2;
            case BiomeType.DesertCacti:
                return TimeEnum.Week;
            case BiomeType.DesertPillars:
                return TimeEnum.Week * 2;
            case BiomeType.DesertHills:
                return TimeEnum.Week * 2;
            case BiomeType.ForestBroadleafThin:
                return TimeEnum.Week + TimeEnum.Day * 3;
            case BiomeType.ForestBroadleaf:
                return TimeEnum.Week * 2;
            case BiomeType.ForestPine:
                return TimeEnum.Week * 2;
            case BiomeType.ForestPinePartSnow:
                return TimeEnum.Week * 3;
            case BiomeType.ForestPineFullSnow:
                return TimeEnum.Month * 3;
            case BiomeType.Highlands:
            case BiomeType.Hills:
                return TimeEnum.Week * 2;
            case BiomeType.HillsPartSnow:
                return TimeEnum.Week * 3;
            case BiomeType.HillsFullSnow:
                return TimeEnum.Month;
            case BiomeType.Marsh:
                return TimeEnum.Month * 2;
            case BiomeType.MountainsPartSnow:
                return TimeEnum.Month * 6;
            case BiomeType.MountainsFullSnow:
                return TimeEnum.Year;
            case BiomeType.Tundra:
                return TimeEnum.Week * 2;
            case BiomeType.TundraSmallDrifts:
                return TimeEnum.Week * 3;
            case BiomeType.TundraLargeDrifts:
                return TimeEnum.Week * 4;
            case BiomeType.OceanCalm:
                return TimeEnum.Day;
            case BiomeType.OceanRough:
                return TimeEnum.Day * 2;
            case BiomeType.OceanIcebergs:
                return TimeEnum.Month;
            default:
                throw new Error(`Need to add this enum to this method: ${biomeType}`);
        }
    }
    static getAssignableBiomeTypesForTileWithType(landType) {
        switch (landType) {
            case BiomeType.Mountains:
                return [BiomeType.LonelyMountain];
            default:
                return [BiomeType.NaturalWonder];
        }
    }
    static getAllAutomaticBiomeTypesForBaseType(landType) {
        switch (landType) {
            case BiomeType.DesertCacti:
                return [BiomeType.Desert];
            case BiomeType.DesertDeep:
                return [BiomeType.Desert];
            case BiomeType.DesertMid:
                return [BiomeType.Desert];
            case BiomeType.DesertHills:
                return [BiomeType.Desert];
            case BiomeType.DesertShallow:
                return [BiomeType.Desert];
            case BiomeType.DesertPillars:
                return [BiomeType.Desert];
            case BiomeType.Farm:
                return [BiomeType.Farm];
            case BiomeType.ForestBroadleaf:
                return [BiomeType.Forest];
            case BiomeType.ForestBroadleafThin:
                return [BiomeType.Forest];
            case BiomeType.ForestPine:
                return [BiomeType.Forest];
            case BiomeType.ForestPineFullSnow:
                return [BiomeType.Forest];
            case BiomeType.ForestPinePartSnow:
                return [BiomeType.Forest];
            case BiomeType.Highlands:
                return [BiomeType.Highlands];
            case BiomeType.Hills:
                return [BiomeType.Hills];
            case BiomeType.HillsFullSnow:
                return [BiomeType.Hills];
            case BiomeType.HillsPartSnow:
                return [BiomeType.Hills];
            case BiomeType.HillsFarm:
                return [BiomeType.Hills];
            case BiomeType.Marsh:
                return [BiomeType.Marsh];
            case BiomeType.MountainsFullSnow:
                return [BiomeType.Mountains];
            case BiomeType.MountainsPartSnow:
                return [BiomeType.Mountains];
            case BiomeType.MountainFarm:
                return [BiomeType.Mountains];
            case BiomeType.MountainsHighAlpine:
                return [BiomeType.Mountains];
            case BiomeType.OceanCalm:
                return [BiomeType.Ocean];
            case BiomeType.OceanRough:
                return [BiomeType.Ocean];
            case BiomeType.OceanIcebergs:
                return [BiomeType.Ocean];
            case BiomeType.Prairie:
                return [BiomeType.Prairie];
            case BiomeType.PrairieFullSnow:
                return [BiomeType.Prairie];
            case BiomeType.PrairiePartSnow:
                return [BiomeType.Prairie];
            case BiomeType.Tundra:
                return [BiomeType.Tundra];
            case BiomeType.TundraLargeDrifts:
                return [BiomeType.Tundra];
            case BiomeType.TundraSmallDrifts:
                return [BiomeType.Tundra];
            case BiomeType.NaturalWonder:
                return [BiomeType.NaturalWonder];
            case BiomeType.City:
                return [BiomeType.City];
            default:
                throw new Error(`Need to add this enum to this method: ${landType}`);
        }
    }
    static getResourcesForBiomeType(mainBiomeType, otherTypes) {
        const resources = [];
        const bellCurveRandom = (avg, variance) => {
            const rand = (Array.from({ length: 6 }, () => Math.random()).reduce((sum, val) => sum + val, 0) -
                3) /
                3;
            return avg + Math.floor(rand * variance);
        };
        const addResource = (goodType, avg, variance) => {
            const amount = Math.max(0, bellCurveRandom(avg, variance));
            if (amount > 0) {
                resources.push(new TileResource(goodType, amount));
            }
        };
        switch (mainBiomeType) {
            case BiomeType.DesertShallow:
            case BiomeType.DesertMid:
            case BiomeType.DesertDeep:
            case BiomeType.DesertHills:
            case BiomeType.DesertCacti:
            case BiomeType.DesertPillars:
                addResource(GoodType.Wheat, 0, 10);
                addResource(GoodType.Oranges, 0, 10);
                addResource(GoodType.Grapes, 0, 10);
                break;
            case BiomeType.ForestBroadleaf:
            case BiomeType.ForestBroadleafThin:
            case BiomeType.ForestPine:
            case BiomeType.ForestPineFullSnow:
            case BiomeType.ForestPinePartSnow:
                addResource(GoodType.Sap, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                addResource(GoodType.Apples, 0, 10);
                addResource(GoodType.Oranges, 0, 10);
                break;
            case BiomeType.Highlands:
            case BiomeType.Hills:
            case BiomeType.HillsFullSnow:
            case BiomeType.HillsPartSnow:
                addResource(GoodType.Wheat, 0, 10);
                addResource(GoodType.Potato, 0, 10);
                addResource(GoodType.Beef, 0, 10);
                addResource(GoodType.Mutton, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Carrot, 0, 10);
                addResource(GoodType.Cabbage, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                addResource(GoodType.Peas, 0, 10);
                addResource(GoodType.Apples, 0, 10);
                addResource(GoodType.Oranges, 0, 10);
                addResource(GoodType.Grapes, 0, 10);
                break;
            case BiomeType.Marsh:
                addResource(GoodType.Rutabaga, 0, 10);
                addResource(GoodType.Rice, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Fish, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                break;
            case BiomeType.MountainsFullSnow:
            case BiomeType.MountainsPartSnow:
                addResource(GoodType.Potato, 0, 10);
                addResource(GoodType.Sap, 0, 10);
                addResource(GoodType.Mutton, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Carrot, 0, 10);
                addResource(GoodType.Cabbage, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                addResource(GoodType.Peas, 0, 10);
                addResource(GoodType.Apples, 0, 10);
                break;
            case BiomeType.OceanCalm:
            case BiomeType.OceanRough:
            case BiomeType.OceanIcebergs:
                addResource(GoodType.Fish, 2, 4);
                break;
            case BiomeType.Prairie:
            case BiomeType.PrairieFullSnow:
            case BiomeType.PrairiePartSnow:
                addResource(GoodType.Wheat, 0, 10);
                addResource(GoodType.Beef, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Carrot, 0, 10);
                addResource(GoodType.Cabbage, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                addResource(GoodType.Peas, 0, 10);
                addResource(GoodType.Apples, 0, 10);
                break;
            case BiomeType.Tundra:
            case BiomeType.TundraLargeDrifts:
            case BiomeType.TundraSmallDrifts:
                addResource(GoodType.Venison, 0, 10);
                break;
            default:
                throw new Error(`Need to add this enum to this method: ${mainBiomeType}`);
                addResource(GoodType.Wheat, 0, 10);
                addResource(GoodType.Rutabaga, 0, 10);
                addResource(GoodType.Potato, 0, 10);
                addResource(GoodType.Rice, 0, 10);
                addResource(GoodType.Sap, 0, 10);
                addResource(GoodType.Beef, 0, 10);
                addResource(GoodType.Mutton, 0, 10);
                addResource(GoodType.Venison, 0, 10);
                addResource(GoodType.Fish, 0, 10);
                addResource(GoodType.Carrot, 0, 10);
                addResource(GoodType.Cabbage, 0, 10);
                addResource(GoodType.Turnip, 0, 10);
                addResource(GoodType.Peas, 0, 10);
                addResource(GoodType.Apples, 0, 10);
                addResource(GoodType.Oranges, 0, 10);
                addResource(GoodType.Grapes, 0, 10);
                break;
        }
        return resources;
    }
    static isWater(biomeType) {
        return (biomeType === BiomeType.Ocean ||
            biomeType === BiomeType.OceanCalm ||
            biomeType === BiomeType.OceanRough ||
            biomeType === BiomeType.OceanIcebergs);
    }
    static displayName(biomeType) {
        switch (biomeType) {
            case BiomeType.DesertShallow:
                return 'Shallow Desert';
            case BiomeType.DesertMid:
                return 'Middle Desert';
            case BiomeType.DesertDeep:
                return 'Deep Desert';
            case BiomeType.DesertHills:
                return 'Desert Hills';
            case BiomeType.DesertCacti:
                return 'Cactus Forest';
            case BiomeType.DesertPillars:
                return 'Desert Pillars';
            case BiomeType.Farm:
                return 'Farm';
            case BiomeType.ForestBroadleaf:
                return 'Broadleaf Forest';
            case BiomeType.ForestBroadleafThin:
                return 'Thin Broadleaf Forest';
            case BiomeType.ForestPine:
                return 'Pine Forest';
            case BiomeType.ForestPineFullSnow:
                return 'Pine Forest with Snow';
            case BiomeType.ForestPinePartSnow:
                return 'Pine Forest with Light Snow';
            case BiomeType.Highlands:
                return 'Highlands';
            case BiomeType.Hills:
                return 'Hills';
            case BiomeType.HillsFullSnow:
                return 'Hills with Snow';
            case BiomeType.HillsPartSnow:
                return 'Hills with Light Snow';
            case BiomeType.Marsh:
                return 'Marsh';
            case BiomeType.Mountains:
                return 'Mountains';
            case BiomeType.MountainsFullSnow:
                return 'Mountains with Snow';
            case BiomeType.MountainsPartSnow:
                return 'Mountains with Light Snow';
            case BiomeType.Ocean:
                return 'Ocean';
            case BiomeType.OceanCalm:
                return 'Calm Ocean';
            case BiomeType.OceanRough:
                return 'Rough Ocean';
            case BiomeType.OceanIcebergs:
                return 'Icebergs';
            case BiomeType.Prairie:
                return 'Prairie';
            case BiomeType.PrairieFullSnow:
                return 'Prairie with Snow';
            case BiomeType.PrairiePartSnow:
                return 'Prairie with Light Snow';
            case BiomeType.Tundra:
                return 'Tundra';
            case BiomeType.TundraLargeDrifts:
                return 'Tundra with Large Drifts';
            case BiomeType.TundraSmallDrifts:
                return 'Tundra with Small Drifts';
            case BiomeType.NaturalWonder:
                return 'Natural Wonder';
            case BiomeType.City:
                return 'City';
            default:
                return biomeType;
        }
    }
}
BiomeTypeMethods.assignableBiomeTypes = [
    BiomeType.DesertShallow,
    BiomeType.DesertMid,
    BiomeType.DesertDeep,
    BiomeType.DesertHills,
    BiomeType.DesertCacti,
    BiomeType.DesertPillars,
    BiomeType.Farm,
    BiomeType.ForestBroadleaf,
    BiomeType.ForestBroadleafThin,
    BiomeType.ForestPine,
    BiomeType.ForestPineFullSnow,
    BiomeType.ForestPinePartSnow,
    BiomeType.Highlands,
    BiomeType.Hills,
    BiomeType.HillsFullSnow,
    BiomeType.HillsPartSnow,
    BiomeType.Marsh,
    BiomeType.MountainsFullSnow,
    BiomeType.MountainsPartSnow,
    BiomeType.OceanCalm,
    BiomeType.OceanRough,
    BiomeType.OceanIcebergs,
    BiomeType.Prairie,
    BiomeType.PrairieFullSnow,
    BiomeType.PrairiePartSnow,
    BiomeType.Tundra,
    BiomeType.TundraLargeDrifts,
    BiomeType.TundraSmallDrifts,
];
export { BiomeType, BiomeTypeMethods };
