export default class FunctionInfo {
    constructor(type, vars) {
        this.type = type;
        this.vars = vars;
    }
    evaluate(x) {
        if (this.type === 'exponential decay') {
            return FunctionInfo.evaluateExponentialDecay(x, this.vars);
        }
        else {
            return FunctionInfo.evaluateSigmoid(x, this.vars);
        }
    }
    static evaluateSigmoid(x, vars) {
        const { linearSlope, sigmoidSlope, height, xAdj, yAdj } = vars;
        if (linearSlope === undefined ||
            sigmoidSlope === undefined ||
            height === undefined ||
            xAdj === undefined ||
            yAdj === undefined) {
            throw new Error('Missing variables for sigmoid evaluation');
        }
        const sigmoidValue = yAdj - height / (1 + Math.exp(-sigmoidSlope * (x - xAdj))) + height;
        const linearAdjustment = x > xAdj ? (x - xAdj) * linearSlope : 0;
        return sigmoidValue + linearAdjustment;
    }
    static evaluateExponentialDecay(x, vars) {
        const { initSlope, finSlope, xAdj, yAdj } = vars;
        if (initSlope === undefined ||
            finSlope === undefined ||
            xAdj === undefined ||
            yAdj === undefined) {
            throw new Error('Missing variables for exponential decay evaluation');
        }
        return -1 * Math.exp(xAdj - initSlope * x) + finSlope * x + yAdj;
    }
    // #region Clone and JSON
    clone() {
        return new FunctionInfo(this.type, Object.assign({}, this.vars));
    }
    toJSON() {
        return {
            type: this.type,
            vars: Object.assign({}, this.vars),
        };
    }
    static fromJSON(json) {
        return new FunctionInfo(json.type, Object.assign({}, json.vars));
    }
}
