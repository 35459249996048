import styled from 'styled-components';
import '../../../../styles/constants.css';

export const TabSideContainerStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .tab-title-container {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 5px;
  }

  .tab-title {
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid white;
    cursor: pointer;

    font-family: var(--header-font);
    font-size: 12px;

    &:hover {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
    &.active {
      background-color: var(--lol-red);
      color: var(--lol-gold);
      border: 1px solid var(--lol-gold);
    }
  }

  .tab-content {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    padding: 5px;
    border-left: 1px solid var(--lol-gold);
  }
`;
