import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const DepartmentItemStyled = styled.div`
  border: 1px solid white;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;

  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }

  button.border {
    border: 1px solid white;

    :hover {
      color: var(--lol-gold);
      border-color: var(--lol-gold);
    }
  }

  button.red:hover {
    background-color: var(--lol-red);
  }

  button.green:hover {
    background-color: green;
  }

  button:hover {
    font-weight: bold;
  }

  .experience-header {
    margin: 10px 0;
  }

  .experience-list {
    margin: 10px 0 10px 10px;
    padding-left: 10px;
    border-left: 1px solid white;
  }

  .eunuch-list {
    margin: 10px 0 10px 10px;
    padding-left: 10px;
    border-left: 1px solid white;
  }

  .eunuch-experience {
    margin: 10px 0 10px 10px;
    padding-left: 10px;
  }

  .department-header {
    margin-bottom: 10px;
  }

  .department-name {
    font-size: 16px;
    color: white;
    font-weight: bold;
  }

  .list-header {
    margin: 10px 0;
    cursor: pointer;
  }

  .add-responsibility-button {
    display: inline-block;
    margin-left: 10px;
  }

  .add-item-button {
    display: inline-block;
    margin-left: 10px;
  }

  .delete-department-button {
    display: inline-block;
    margin-left: 10px;
  }

  .department-list {
  }

  .responsibility-list {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid white;
  }
`;
