// external
import React from 'react';
import { UIColor } from '../Enums/UIEnums.ts';

// css
import { CenterButtonStyled } from './styles/CenterButton.styled.js';

interface ICenterButton {
  label: string;
  onClick: () => void;
  color?: UIColor;
}

const CenterButton: React.FC<ICenterButton> = ({
  label,
  onClick,
  color = UIColor.Blue,
}) => {
  return (
    <CenterButtonStyled
      className='center-button'
      color={color}
      onClick={onClick}
    >
      {label}
    </CenterButtonStyled>
  );
};

export default CenterButton;
