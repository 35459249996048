// external
import React, { useState, useEffect, useRef } from 'react';

// css
import { HoverBoxStyled } from './styles/HoverBox.styled.js';

interface IHoverBox {
  content: React.ReactNode;
  width?: number;
  parentRef: React.RefObject<HTMLElement>;
}
const HoverBox: React.FC<IHoverBox> = ({ content, parentRef, width = 200 }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const hoverBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (parentRef.current && hoverBoxRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const hoverBoxRect = hoverBoxRef.current.getBoundingClientRect();

        // Position the hover box so its bottom-right corner is at the top-left of the parent
        let top = parentRect.top - hoverBoxRect.height - 5;
        let left = parentRect.left - hoverBoxRect.width - 5;

        // Adjust if it would go off the right side of the screen
        if (left + hoverBoxRect.width > window.innerWidth) {
          left = window.innerWidth - hoverBoxRect.width;
        }

        // Adjust if it would go off the bottom of the screen
        if (top + hoverBoxRect.height > window.innerHeight) {
          top = window.innerHeight - hoverBoxRect.height;
        }

        // Adjust if it would go off the top of the screen
        if (top < 0) {
          top = parentRect.bottom + 5;
        }

        setPosition({ top, left });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [parentRef]);

  return (
    <HoverBoxStyled
      ref={hoverBoxRef}
      style={{ top: position.top, left: position.left, width: width }}
      className='hover-box'
    >
      {content}
    </HoverBoxStyled>
  );
};

export default HoverBox;
export { IHoverBox };
