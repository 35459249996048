import { GoodCategory, GoodTypeMethods, } from '../../../Enums/Good.js';
import Person from '../../Person/Person.js';
import ConsumptionLineItem from '../Consumption/ConsumptionLineItem.js';
import RealizedTaxItem from '../TaxTracking/TaxItem.js';
class GoodsData {
    constructor(productionByPerson, consumptionByPerson, goods, goodCityQualityMap, goodsCityMap) {
        this.productionByPerson = productionByPerson;
        this.consumptionByPerson = consumptionByPerson;
        this.goods = goods;
        this.goodCityQualityMap = goodCityQualityMap;
        this.goodsCityMap = goodsCityMap;
    }
    // #region Start And End Cycle
    startNewCycle() {
        this.productionByPerson = [];
        this.consumptionByPerson = [];
        this.goods.forEach((good) => {
            good.productionAmount = 0;
            good.consumptionAmount = 0;
            good.productionPossible = false;
        });
    }
    endCycle() {
        this.normalizePrice();
        this.adjustPrice();
        // console.log(
        //   'goods: ',
        //   this.goods.map((good) => ({
        //     goodType: GoodType[good.goodType],
        //     city: good.cityInfo.cityName,
        //     quality: good.quality,
        //     productionValue: good.productionValue,
        //     productionAmount: good.productionAmount,
        //     consumptionAmount: good.consumptionAmount,
        //     productionPossible: good.productionPossible,
        //   }))
        // );
    }
    normalizePrice() {
        const producableFood = this.goods.filter((good) => GoodTypeMethods.getGoodInfo(good.goodType).category ===
            GoodCategory.Food && good.productionPossible);
        const cheapestFoodPrice = producableFood.length > 0
            ? producableFood.reduce((min, good) => {
                return Math.min(min, good.marketPrice);
            }, Number.MAX_VALUE)
            : 1;
        this.goods.forEach((good) => {
            if (good.productionPossible) {
                good.marketPrice = good.marketPrice / cheapestFoodPrice;
            }
        });
    }
    adjustPrice() {
        const maxIncrease = 1.06;
        const maxDecrease = 0.95;
        for (let good of this.goods) {
            if (good.productionAmount === 0 && good.consumptionAmount === 0) {
                // also catches !productionPossible
                // the price should not change - this is important
                // it means that it cannot be produced at a price the market will accept
                // we should not therefore mess with the price.
                // if the price is too low, people will buy it, if it is too high people will produce it
                continue;
            }
            if (good.productionAmount === 0) {
                good.marketPrice = good.marketPrice * maxIncrease;
                continue;
            }
            // we want to move half the difference (for smoothness) between the implied price
            // and the current price
            const demandSupplyRatio = good.consumptionAmount / good.productionAmount;
            const finalRatio = (demandSupplyRatio + 1) / 2;
            // keep it constrained
            const adjustmentFactor = Math.min(maxIncrease, Math.max(maxDecrease, finalRatio));
            good.marketPrice = good.marketPrice * adjustmentFactor;
        }
    }
    // #endregion
    // #region Add Production and Consumption
    getMarketPrice(goodType, city, quality) {
        // first check if we have the exact combo
        const cqmKey = `${goodType}-${city.instanceId}-${quality}`;
        if (this.goodCityQualityMap[cqmKey] !== undefined) {
            try {
                return this.goods[this.goodCityQualityMap[cqmKey]].marketPrice;
            }
            catch (error) {
                console.log('goodCityQualityMap: ', this.goodCityQualityMap);
                console.log('cqmKey: ', cqmKey);
                console.log('this.goods[this.goodCityQualityMap[cqmKey]]: ', this.goods[this.goodCityQualityMap[cqmKey]]);
                console.log('error: ', error);
                return 1;
            }
        }
        // if we don't, look for the price of the nearest quality good in the city
        const key = `${goodType}-${city.instanceId}`;
        const indices = this.goodsCityMap[key];
        if (!indices || indices.length === 0) {
            return GoodTypeMethods.getGoodInfo(goodType).startingPrice;
        }
        const qualities = indices.map((index) => this.goods[index].quality);
        const closestQuality = qualities.reduce((prev, curr) => {
            return Math.abs(curr - quality) < Math.abs(prev - quality) ? curr : prev;
        });
        const closestIndex = indices[qualities.indexOf(closestQuality)];
        return this.goods[closestIndex].marketPrice;
    }
    markProductionPossible(goodType, city, quality) {
        const cqmKey = `${goodType}-${city.instanceId}-${quality}`;
        if (this.goodCityQualityMap[cqmKey] !== undefined) {
            this.goods[this.goodCityQualityMap[cqmKey]].productionPossible = true;
        }
        else {
            // Create a new good entry since it's not already marked as possible
            const newGood = {
                goodType,
                cityId: city.instanceId,
                quality,
                marketPrice: this.getMarketPrice(goodType, city, quality),
                productionAmount: 0,
                consumptionAmount: 0,
                productionPossible: true,
            };
            this.goods.push(newGood);
            const newIndex = this.goods.length - 1;
            this.goodCityQualityMap[cqmKey] = newIndex;
            // Update goodsCityMap
            const cityKey = `${goodType}-${city.instanceId}`;
            if (this.goodsCityMap[cityKey]) {
                this.goodsCityMap[cityKey].push(newIndex);
            }
            else {
                this.goodsCityMap[cityKey] = [newIndex];
            }
        }
    }
    addProduction(person, city, improvementId, goodType, quality, productionAmount, marketValue, inputCost, valueToProducer, taxes) {
        // Add to personal production data
        this.productionByPerson.push({
            person,
            cityId: city.instanceId,
            improvementId,
            goodType,
            quality,
            productionAmount,
            marketValue,
            inputCost,
            valueToProducer,
            taxes: taxes.map((tax) => tax.clone()),
        });
        // Create a unique key for the goods tracker map
        const key = `${goodType}-${city.instanceId}-${quality}`;
        // if there is an existing entry
        if (this.goodCityQualityMap[key] !== undefined) {
            const index = this.goodCityQualityMap[key];
            this.goods[index].productionAmount += productionAmount;
        }
        // if there is not an existing entry
        else {
            const price = this.getMarketPrice(goodType, city, quality);
            this.goods.push({
                goodType,
                cityId: city.instanceId,
                quality,
                marketPrice: price,
                productionAmount,
                consumptionAmount: 0,
                productionPossible: true,
            });
            const newIndex = this.goods.length - 1;
            this.goodCityQualityMap[key] = newIndex;
            // update goodsCityMap
            const cityKey = `${goodType}-${city.instanceId}`;
            if (this.goodsCityMap[cityKey]) {
                this.goodsCityMap[cityKey].push(newIndex);
            }
            else {
                this.goodsCityMap[cityKey] = [newIndex];
            }
        }
    }
    addConsumption(consumptionLineItem) {
        const key = `${consumptionLineItem.goodType}-${consumptionLineItem.cityInfo.instanceId}-${consumptionLineItem.quality}`;
        if (this.goodCityQualityMap[key] !== undefined) {
            const index = this.goodCityQualityMap[key];
            if (index !== undefined) {
                this.goods[index].consumptionAmount += 1;
            }
        }
        else {
            throw new Error(`No production found for consumption with key: ${key}`);
        }
        this.consumptionByPerson.push(consumptionLineItem);
    }
    // #endregion
    // #region JSON and Clone
    clone() {
        const cloned = new GoodsData(this.productionByPerson.map((item) => ({
            person: item.person.clone(),
            improvementId: item.improvementId,
            cityId: item.cityId,
            goodType: item.goodType,
            quality: item.quality,
            productionAmount: item.productionAmount,
            marketValue: item.marketValue,
            inputCost: item.inputCost,
            valueToProducer: item.valueToProducer,
            taxes: item.taxes.map((tax) => tax.clone()),
        })), this.consumptionByPerson.map((item) => item.clone()), this.goods.map((good) => ({
            goodType: good.goodType,
            cityId: good.cityId,
            quality: good.quality,
            marketPrice: good.marketPrice,
            productionAmount: good.productionAmount,
            consumptionAmount: good.consumptionAmount,
            productionPossible: good.productionPossible,
        })), Object.fromEntries(Object.entries(this.goodCityQualityMap)), Object.fromEntries(Object.entries(this.goodsCityMap).map(([key, value]) => [
            key,
            [...value],
        ])));
        return cloned;
    }
    toJSON() {
        return {
            goods: this.goods.map((good) => ({
                goodType: good.goodType,
                cityId: good.cityId,
                quality: good.quality,
                productionValue: good.marketPrice,
                productionAmount: good.productionAmount,
                consumptionAmount: good.consumptionAmount,
                productionPossible: good.productionPossible,
            })),
            productionByPerson: this.productionByPerson.map((item) => ({
                person: item.person.toJSON(),
                cityId: item.cityId,
                improvementId: item.improvementId,
                goodType: item.goodType,
                quality: item.quality,
                productionAmount: item.productionAmount,
                productionValue: item.marketValue,
                valueToProducer: item.valueToProducer,
                taxes: item.taxes.map((tax) => tax.toJSON()),
            })),
            consumptionByPerson: this.consumptionByPerson.map((item) => item.toJSON()),
            goodCityQualityMap: Object.entries(this.goodCityQualityMap),
            goodsCityMap: Object.entries(this.goodsCityMap).map(([key, value]) => [
                key,
                [...value],
            ]),
        };
    }
    static fromJSON(json) {
        const goodsData = new GoodsData(json.productionByPerson.map((item) => ({
            person: Person.fromJSON(item.person),
            cityId: item.cityId,
            improvementId: item.improvementId,
            goodType: item.goodType,
            quality: item.quality,
            productionAmount: item.productionAmount,
            productionValue: item.productionValue,
            valueToProducer: item.valueToProducer,
            taxes: item.taxes.map((tax) => RealizedTaxItem.fromJSON(tax)),
        })), json.consumptionByPerson.map((item) => ConsumptionLineItem.fromJSON(item)), json.goods.map((good) => ({
            goodType: good.goodType,
            cityId: good.cityId,
            quality: good.quality,
            productionValue: good.productionValue,
            productionAmount: good.productionAmount,
            consumptionAmount: good.consumptionAmount,
            productionPossible: good.productionPossible,
        })), Object.fromEntries(Object.entries(json.goodCityQualityMap)), Object.fromEntries(Object.entries(json.goodsCityMap).map(([key, value]) => [
            key,
            [...value],
        ])));
        return goodsData;
    }
}
export default GoodsData;
