export class EvaluationResult {
    constructor(name, type, operation, value, result, inputs, extraData) {
        this.name = name;
        this.type = type;
        this.operation = operation;
        this.value = value;
        this.result = result;
        this.inputs = inputs;
        this.extraData = extraData;
    }
    /** Creates a deep clone of the EvaluationResult instance */
    clone() {
        var _a, _b, _c;
        return new EvaluationResult(this.name, this.type, this.operation, this.value, this.result, this.inputs ? this.inputs.map((input) => input.clone()) : [], {
            xValue: (_a = this.extraData) === null || _a === void 0 ? void 0 : _a.xValue,
            breakdown: (_c = (_b = this.extraData) === null || _b === void 0 ? void 0 : _b.breakdown) === null || _c === void 0 ? void 0 : _c.map((breakdown) => (Object.assign({}, breakdown))),
        });
    }
    /** Serializes the EvaluationResult to JSON */
    toJSON() {
        var _a, _b, _c;
        return {
            name: this.name,
            type: this.type,
            operation: this.operation,
            value: this.value,
            result: this.result,
            inputs: this.inputs ? this.inputs.map((input) => input.toJSON()) : [],
            extraData: {
                xValue: (_a = this.extraData) === null || _a === void 0 ? void 0 : _a.xValue,
                breakdown: (_c = (_b = this.extraData) === null || _b === void 0 ? void 0 : _b.breakdown) === null || _c === void 0 ? void 0 : _c.map((breakdown) => (Object.assign({}, breakdown))),
            },
        };
    }
    /** Deserializes the EvaluationResult from JSON */
    static fromJSON(json) {
        var _a, _b, _c;
        return new EvaluationResult(json.name, json.type, json.operation, json.value, json.result, json.inputs
            ? json.inputs.map((input) => EvaluationResult.fromJSON(input))
            : [], {
            xValue: (_a = json.extraData) === null || _a === void 0 ? void 0 : _a.xValue,
            breakdown: (_c = (_b = json.extraData) === null || _b === void 0 ? void 0 : _b.breakdown) === null || _c === void 0 ? void 0 : _c.map((breakdown) => (Object.assign({}, breakdown))),
        });
    }
}
