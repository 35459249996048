import RealizedTaxItem from '../EconomicData/TaxTracking/TaxItem.js';
import Administration from './Administration.js';
import Department from './Department/Department/Department.js';
import Load from './Load.js';
import Responsibility from './Responsibility.js';
class NationalAdministration extends Administration {
    constructor(balance, lastCycleRevenueAmount, lastCycleTaxes, lastCycleSpending, palace) {
        super(balance, lastCycleRevenueAmount, lastCycleTaxes, lastCycleSpending);
        this.palace = palace;
    }
    // #region load, efficacy, etc.
    clearLoad() {
        this.palace.clearLoad();
    }
    addLoad(role, load) {
        const dept = this.getDepartmentByRole(role);
        if (dept) {
            dept.addLoad(new Load(new Responsibility(role), load, 0));
        }
    }
    clearExperience() {
        this.palace.clearOrgExperience();
    }
    distributeExperience() {
        this.palace.distributeExperience([]);
    }
    calculateEffectiveness() {
        this.palace.calculateEffectiveness();
    }
    gainExperience() {
        this.palace.gainAndLoseExperience();
    }
    // #endregion
    // #region utilities
    getDepartmentById(id) {
        const found = this.palace.getDepartmentById(id);
        if (found)
            return found;
        return null;
    }
    getDepartmentByRole(role) {
        return this.palace.getDepartmentByRole(role);
    }
    updateFromClient(client, nation, gameState) {
        this.palace.updateFromClient(client.palace, nation, null, // city
        null, // managing office
        this, gameState);
    }
    updateStatsFromServer(client) {
        this.palace.updateStatsFromServer(client.palace);
    }
    isValid(gameState) {
        return this.palace.isDepartmentValid(gameState);
    }
    // #endregion
    // #region JSON and Clone
    clone() {
        return new NationalAdministration(this.balance, this.lastCycleRevenueAmount, this.lastCycleTaxes.map((tax) => tax.clone()), this.lastCycleSpending, this.palace.clone());
    }
    toJSON() {
        return {
            balance: this.balance,
            lastCycleRevenueAmount: this.lastCycleRevenueAmount,
            lastCycleTaxes: this.lastCycleTaxes.map((tax) => tax.toJSON()),
            lastCycleSpending: this.lastCycleSpending,
            palace: this.palace.toJSON(),
        };
    }
    static fromJSON(json) {
        return new NationalAdministration(json.balance, json.lastCycleRevenueAmount, json.lastCycleTaxes.map((tax) => RealizedTaxItem.fromJSON(tax)), json.lastCycleSpending, Department.fromJSON(json.palace));
    }
}
export default NationalAdministration;
