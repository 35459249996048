import RuntimeLandTile from '../LandTile/RuntimeLandtile.js';
import RuntimeBiome from '../Biome/RuntimeBiome.js';
import { BiomeType, BiomeTypeMethods } from '../../../Enums/Biome.js';
class RuntimeRealm {
    constructor(name, runtimeBiomes, runtimeLandTiles, realmLayout, startingLandTileIds = []) {
        this.name = 'New Biome';
        this.biomes = [];
        this.landTiles = [];
        this.startingLandTileSet = new Set();
        this.name = name;
        this.biomes = runtimeBiomes;
        this.landTiles = runtimeLandTiles;
        this.realmLayout = realmLayout;
        this.startingLandTileSet = new Set(startingLandTileIds);
        // Assign coordinates based on the current layout
        RuntimeRealm.assignCoords(this.landTiles, this.realmLayout);
    }
    // #region Checks
    isRealmValid() {
        const totalTiles = this.landTiles.length;
        const sumOfLayout = this.realmLayout.width * this.realmLayout.height;
        return totalTiles === sumOfLayout;
    }
    // #endregion Checks
    // #region Starting Land Tile Management
    isStartingLandTile(tileId) {
        return this.startingLandTileSet.has(tileId);
    }
    addStartingLandTile(tileId) {
        this.startingLandTileSet.add(tileId);
    }
    removeStartingLandTile(tileId) {
        this.startingLandTileSet.delete(tileId);
    }
    getStartingLandTileIds() {
        return Array.from(this.startingLandTileSet);
    }
    toggleRiver(tileId) {
        const tile = this.landTiles.find((t) => t.id === tileId);
        if (tile) {
            tile.river = !tile.river;
        }
        console.log('tile: ', tile);
    }
    toggleRoad(tileId) {
        const tile = this.landTiles.find((t) => t.id === tileId);
        if (tile) {
            if (!tile.road) {
                tile.road = { quality: 1 };
            }
            else {
                tile.road = null;
            }
        }
    }
    // #endregion
    // #region Landtiles
    getLandTiles() {
        return this.landTiles;
    }
    getTileIdFromXY(x, y) {
        return y * this.realmLayout.width + x;
    }
    editLayout(newLayout) {
        const newLandTiles = [];
        let currentIndex = 0;
        for (let rowIndex = 0; rowIndex < newLayout.height; rowIndex++) {
            if (rowIndex < this.realmLayout.height) {
                const currentRowTiles = this.landTiles.slice(currentIndex, currentIndex + this.realmLayout.width);
                for (let colIndex = 0; colIndex < newLayout.width; colIndex++) {
                    if (colIndex < currentRowTiles.length) {
                        const tile = currentRowTiles[colIndex].clone();
                        tile.id = rowIndex * newLayout.width + colIndex;
                        newLandTiles.push(tile);
                    }
                    else {
                        newLandTiles.push(this.createDefaultTile(rowIndex, colIndex, newLayout.width));
                    }
                }
            }
            else {
                for (let colIndex = 0; colIndex < newLayout.width; colIndex++) {
                    newLandTiles.push(this.createDefaultTile(rowIndex, colIndex, newLayout.width));
                }
            }
            currentIndex += this.realmLayout.width || 0;
        }
        this.landTiles = newLandTiles;
        this.realmLayout = { width: newLayout.width, height: newLayout.height };
        RuntimeRealm.assignCoords(this.landTiles, this.realmLayout);
        // Update startingLandTileSet to only include valid IDs
        this.startingLandTileSet = new Set([...this.startingLandTileSet].filter((id) => id < this.landTiles.length));
    }
    createDefaultTile(rowIndex, colIndex, width) {
        const id = rowIndex * width + colIndex;
        const mainBiome = BiomeType.OceanRough;
        const allBiomes = BiomeTypeMethods.getAllAutomaticBiomeTypesForBaseType(mainBiome);
        // Assuming RuntimeLandTile constructor something like: new RuntimeLandTile(id, x, y, mainBiomeType, allBiomeTypes, river)
        return new RuntimeLandTile(id, colIndex, rowIndex, mainBiome, allBiomes, false, null, 0, [], [], null, false, false, false);
    }
    changeMainLandTileType(tile_index, newType) {
        const tile = this.landTiles.find((t) => t.id === tile_index);
        if (tile) {
            tile.mainBiomeType = newType;
            tile.allBiomeTypes =
                BiomeTypeMethods.getAllAutomaticBiomeTypesForBaseType(newType);
        }
    }
    addLandTileType(tile_index, newType) {
        const tile = this.landTiles.find((t) => t.id === tile_index);
        if (tile) {
            if (!tile.allBiomeTypes.includes(newType)) {
                tile.allBiomeTypes.push(newType);
            }
        }
    }
    removeLandTileType(tile_index, typeToRemove) {
        const tile = this.landTiles.find((t) => t.id === tile_index);
        if (tile) {
            tile.allBiomeTypes = tile.allBiomeTypes.filter((type) => type !== typeToRemove);
        }
    }
    // #endregion Landtiles
    // #region Assign Coords
    static assignCoords(landTiles, layout) {
        for (let i = 0; i < landTiles.length; i++) {
            const tile = landTiles[i];
            const x = i % layout.width;
            const y = Math.floor(i / layout.width);
            tile.x = x;
            tile.y = y;
        }
    }
    // #endregion
    // #region JSON and CLONE
    toJSONForPlayer(discoveredTileIds, visibleTileIds) {
        const json = {};
        json.name = this.name;
        json.biomes = this.biomes.map((biome) => biome.toJSON());
        json.landTiles = this.landTiles.map((tile) => tile.toJSONForPlayer(discoveredTileIds.includes(tile.id), visibleTileIds.includes(tile.id)));
        json.realmLayout = {
            width: this.realmLayout.width,
            height: this.realmLayout.height,
        };
        json.startingLandTileIds = [];
        return json;
    }
    toPerfectJSON() {
        const json = {};
        json.name = this.name;
        json.biomes = this.biomes.map((biome) => biome.toJSON());
        json.landTiles = this.landTiles.map((tile) => tile.toPerfectJSON());
        json.realmLayout = {
            width: this.realmLayout.width,
            height: this.realmLayout.height,
        };
        json.startingLandTileIds = this.getStartingLandTileIds();
        return json;
    }
    static fromRuntimeJSON(json) {
        const realm = new RuntimeRealm(json.name, json.biomes.map((biome) => RuntimeBiome.fromRuntimeJSON(biome)), json.landTiles.map((tile) => RuntimeLandTile.fromRuntimeJSON(tile)), { width: json.realmLayout.width, height: json.realmLayout.height }, !!json.startingLandTileIds ? [...json.startingLandTileIds] : []);
        return realm;
    }
    // static fromLibraryJSON(json: any): RuntimeRealm {
    //   const realm = new RuntimeRealm(
    //     json.name,
    //     json.biomes.map((biome: any) => RuntimeBiome.fromLibraryJSON(biome)),
    //     json.landTiles.map((tile: any) => RuntimeLandTile.fromLibraryJSON(tile)),
    //     { width: json.realmLayout.width, height: json.realmLayout.height },
    //     !!json.startingLandTileIds ? [...json.startingLandTileIds] : []
    //   );
    //   return realm;
    // }
    clone() {
        const tempRealm = new RuntimeRealm(this.name, this.biomes.map((biome) => biome.clone()), this.landTiles.map((tile) => tile.clone()), { width: this.realmLayout.width, height: this.realmLayout.height }, [...this.getStartingLandTileIds()]);
        return tempRealm;
    }
}
export default RuntimeRealm;
