// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// internal context
import { ImageContext } from '../../../../contexts/Media/ImageContextProvider.tsx';
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';

// css
import { TopPanelStyled } from './styles/TopPanel.styled.js';

// lol

const TopPanel = () => {
  const { cityViewDefault } = useContext(ImageContext);
  const { selectedCity } = useContext(SelectedEntityContext);

  return (
    <TopPanelStyled className='city-view' background={cityViewDefault}>
      <div className='city-title'>
        <h1>{selectedCity.name}</h1>
      </div>
    </TopPanelStyled>
  );
};

export default TopPanel;
