import getEquipmentInfo from '../../Enums/WeaponInfo.js';
import Experience from '../Experience/Experience.js';
import { EvaluationResult } from './EvaluationResult.js';
import { SoldierCalculation } from './SoldierCalculation.js';
class Cohort {
    constructor(soldiers, morale, energy, weapons, armor, equipment, horse, experience, lastFightStats) {
        this.soldiers = soldiers;
        this.morale = morale;
        this.energy = energy;
        this.weapons = weapons;
        this.armor = armor;
        this.equipment = equipment;
        this.horse = horse;
        this.experience = experience;
        this.lastFightStats = lastFightStats;
    }
    updateStatsForBattle(battle) {
        if (!this.lastFightStats) {
            this.lastFightStats = {
                morale: { start: 0, end: 0 },
                energy: { start: 0, end: 0 },
                shock: null,
                formation: null,
                discipline: null,
                resilience: null,
                offensiveOutput: null,
            };
        }
        this.lastFightStats.morale.start = this.morale;
        this.lastFightStats.energy.start = this.energy;
        // Get primary weapon info if available
        const primaryWeapon = this.getPrimaryWeapon();
        let equipmentCurves = this.getEquipmentCurves();
        if (primaryWeapon) {
            const weaponInfo = getEquipmentInfo(primaryWeapon.goodType);
            // Add weapon-specific equipment curves
            equipmentCurves = equipmentCurves.concat(weaponInfo.equipmentQualityCurves);
        }
        this.lastFightStats.offensiveOutput =
            this.getOffensiveOutputCalculation().evaluate(equipmentCurves, this.getAllEquipment(), this.experience);
        this.lastFightStats.resilience = this.getResilienceCalculation().evaluate(this.getEquipmentCurves(), this.getAllEquipment(), this.experience);
        this.lastFightStats.formation = this.getFormationCalculation(battle.getTerrainFormationModifier()).evaluate(this.getEquipmentCurves(), this.getAllEquipment(), this.experience);
        this.lastFightStats.shock = this.getShockCalculation().evaluate(this.getEquipmentCurves(), this.getAllEquipment(), this.experience);
        this.lastFightStats.discipline = this.getDisciplineCalculation().evaluate(this.getEquipmentCurves(), this.getAllEquipment(), this.experience);
        this.morale -= 5;
        this.energy -= 5;
        this.morale = Math.max(0, Math.min(100, this.morale));
        this.energy = Math.max(0, Math.min(100, this.energy));
        this.lastFightStats.morale.end = this.morale;
        this.lastFightStats.energy.end = this.energy;
    }
    // #region Static SoldierCalculations
    /**
     * Returns the SoldierCalculation for Offensive Output.
     */
    getOffensiveOutputCalculation() {
        const primaryWeapon = this.getPrimaryWeapon();
        if (!primaryWeapon) {
            // If no primary weapon, return a trivial calculation that results in 0.
            return new SoldierCalculation('No Weapon', 'constant', null, [], 0);
        }
        // Get the weapon-specific info, which includes the attackCalculation
        const weaponInfo = getEquipmentInfo(primaryWeapon.goodType);
        // Create an experience curve calculation for AttackExperience
        const attackExperienceCalc = new SoldierCalculation('Experience Output', 'experienceCurve', null, [], 'AttackExperience');
        // For now, let's assume a simple constant horse output and effectiveness.
        // Adjust this logic as needed if you have dynamic biome logic.
        const horseOutput = new SoldierCalculation('Horse Output', 'operation', 'multiply', [
            new SoldierCalculation('Horse Attack', 'constant', null, [], 50),
            new SoldierCalculation('Horse Effectiveness', 'constant', null, [], 1),
        ], null);
        // Add together the weapon attack, the attack experience, and the horse output
        const totalOffensiveOutput = new SoldierCalculation('Total Offensive Output', 'operation', 'add', [
            weaponInfo.attackCalculation, // From the weapon's own data
            attackExperienceCalc, // Attack experience curve
            horseOutput, // Horse output calculation
        ], null);
        // Multiply by a soldier-based multiplier (adjust as needed)
        const scaledOffensiveOutput = new SoldierCalculation('Scaled Total Offensive Output', 'operation', 'multiply', [
            totalOffensiveOutput,
            new SoldierCalculation('Soldier Multiplier', 'constant', null, [], this.soldiers * 0.001),
        ], null);
        return scaledOffensiveOutput;
    }
    /**
     * Returns the SoldierCalculation for Resilience.
     */
    getResilienceCalculation() {
        const operands = [];
        // A helper to safely add a defense calculation if it's not null
        const addDefenseCalculation = (goodType, quality) => {
            if (goodType === null)
                return; // No item
            const info = getEquipmentInfo(goodType);
            if (info && info.defenseCalculation !== null) {
                // Clone to avoid any reference issues
                operands.push(info.defenseCalculation.clone());
            }
        };
        // Add armor resilience if available
        if (this.armor) {
            addDefenseCalculation(this.armor.goodType, this.armor.quality);
        }
        // Add horse armor resilience if available
        if (this.horse && this.horse.armor) {
            addDefenseCalculation(this.horse.armor.goodType, this.horse.armor.quality);
        }
        // Add resilience from each piece of equipment
        for (const eq of this.equipment) {
            addDefenseCalculation(eq.goodType, eq.quality);
        }
        // Add resilience from each weapon (including primary and secondary)
        for (const w of this.weapons) {
            addDefenseCalculation(w.goodType, w.quality);
        }
        // If no equipment-based defense calculations found, start with 0
        let totalEquipmentResilience;
        if (operands.length === 0) {
            totalEquipmentResilience = new SoldierCalculation('No Equipment Resilience', 'constant', null, [], 0);
        }
        else if (operands.length === 1) {
            totalEquipmentResilience = operands[0];
        }
        else {
            totalEquipmentResilience = new SoldierCalculation('Total Equipment Resilience', 'operation', 'add', operands, null);
        }
        // Incorporate formation into resilience
        const formationCalc = this.getFormationCalculation(1);
        // formationCalc might also be null if that's intended. Check and handle:
        let finalOperands = [totalEquipmentResilience];
        if (formationCalc !== null) {
            finalOperands.push(formationCalc);
        }
        // If formation is null and we have only the equipment resilience
        if (finalOperands.length === 1) {
            return finalOperands[0];
        }
        return new SoldierCalculation('Equipment Resilience + Formation', 'operation', 'add', finalOperands, null);
    }
    /**
     * Returns the SoldierCalculation for Formation.
     */
    getFormationCalculation(terrainModifier) {
        const operands = [];
        const addFormationCalc = (goodType) => {
            if (goodType === null)
                return;
            const info = getEquipmentInfo(goodType);
            if (info && info.formationCalculation !== null) {
                operands.push(info.formationCalculation.clone());
            }
        };
        // Add armor formation calculation
        if (this.armor) {
            addFormationCalc(this.armor.goodType);
        }
        // Add horse armor formation calculation
        if (this.horse && this.horse.armor) {
            addFormationCalc(this.horse.armor.goodType);
        }
        // Add equipment formation calculations
        for (const eq of this.equipment) {
            addFormationCalc(eq.goodType);
        }
        // Add weapons (primary and secondary)
        for (const w of this.weapons) {
            addFormationCalc(w.goodType);
        }
        // If no formation calculations found, start with 0
        let totalFormationBase;
        if (operands.length === 0) {
            totalFormationBase = new SoldierCalculation('No Formation', 'constant', null, [], 0);
        }
        else if (operands.length === 1) {
            totalFormationBase = operands[0];
        }
        else {
            totalFormationBase = new SoldierCalculation('Total Equipment Formation', 'operation', 'add', operands, null);
        }
        // Now multiply by the formation experience curve
        const formationExpCalc = new SoldierCalculation('Formation Experience', 'experienceCurve', null, [], 'FormationExperience');
        // Multiply total formation by formation experience
        const formationWithExp = new SoldierCalculation('Formation * FormationExperience', 'operation', 'multiply', [totalFormationBase, formationExpCalc], null);
        // Finally, incorporate terrain modifier if needed
        if (terrainModifier !== 1) {
            return new SoldierCalculation('Formation with Terrain Modifier', 'operation', 'multiply', [
                formationWithExp,
                new SoldierCalculation('Terrain Modifier', 'constant', null, [], terrainModifier),
            ], null);
        }
        else {
            return formationWithExp;
        }
    }
    /**
     * Returns the SoldierCalculation for Shock.
     */
    getShockCalculation() {
        const operands = [];
        const addShockCalc = (goodType) => {
            if (goodType === null)
                return;
            const info = getEquipmentInfo(goodType);
            if (info && info.shockCalculation !== null) {
                operands.push(info.shockCalculation.clone());
            }
        };
        // Add armor shock calculation
        if (this.armor) {
            addShockCalc(this.armor.goodType);
        }
        // Add horse armor shock calculation
        if (this.horse && this.horse.armor) {
            addShockCalc(this.horse.armor.goodType);
        }
        // Add equipment shock calculations
        for (const eq of this.equipment) {
            addShockCalc(eq.goodType);
        }
        // Add weapons (primary and secondary)
        for (const w of this.weapons) {
            addShockCalc(w.goodType);
        }
        // If no shock calculations found, start with 0
        let totalShockBase;
        if (operands.length === 0) {
            totalShockBase = new SoldierCalculation('No Shock', 'constant', null, [], 0);
        }
        else if (operands.length === 1) {
            totalShockBase = operands[0];
        }
        else {
            totalShockBase = new SoldierCalculation('Total Equipment Shock', 'operation', 'add', operands, null);
        }
        // Now multiply by the shock experience curve
        const shockExpCalc = new SoldierCalculation('Shock Experience', 'experienceCurve', null, [], 'ShockExperience');
        const shockWithExp = new SoldierCalculation('Shock * ShockExperience', 'operation', 'multiply', [totalShockBase, shockExpCalc], null);
        return shockWithExp;
    }
    /**
     * Returns the SoldierCalculation for Discipline.
     */
    getDisciplineCalculation() {
        return new SoldierCalculation('Discipline', 'experienceCurve', null, [], 'DisciplineExperience');
    }
    // #endregion
    /**
     * Helper method to get equipment curves needed for evaluation.
     */
    getEquipmentCurves() {
        // Collect all equipment curves used in calculations
        // This is a placeholder, you should implement the method to return actual curves
        return [
        // Example curves
        // new EquipmentCurveInfo('GladiusEffectivenessFromQuality', ...),
        // etc.
        ];
    }
    /**
     * Helper method to get all equipment items.
     */
    getAllEquipment() {
        const equipment = [];
        // Add weapons
        for (const weapon of this.weapons) {
            equipment.push({ goodType: weapon.goodType, quality: weapon.quality });
        }
        // Add armor
        if (this.armor) {
            equipment.push({
                goodType: this.armor.goodType,
                quality: this.armor.quality,
            });
        }
        // Add soldier equipment
        equipment.push(...this.equipment);
        // Add horse armor and equipment
        if (this.horse) {
            if (this.horse.armor) {
                equipment.push({
                    goodType: this.horse.armor.goodType,
                    quality: this.horse.armor.quality,
                });
            }
            equipment.push(...this.horse.equipment);
        }
        return equipment;
    }
    /**
     * Gets the primary weapon of the cohort.
     */
    getPrimaryWeapon() {
        return (this.weapons.find((weapon) => weapon.primary) || this.weapons[0] || null);
    }
    // #region Clone, toJSON, and fromJSON
    clone() {
        return new Cohort(this.soldiers, this.morale, this.energy, this.weapons.map((weapon) => ({
            goodType: weapon.goodType,
            primary: weapon.primary,
            quality: weapon.quality,
        })), this.armor
            ? {
                goodType: this.armor.goodType,
                quality: this.armor.quality,
            }
            : null, this.equipment.map((equipment) => ({
            goodType: equipment.goodType,
            quality: equipment.quality,
        })), this.horse
            ? {
                armor: this.horse.armor
                    ? {
                        goodType: this.horse.armor.goodType,
                        quality: this.horse.armor.quality,
                    }
                    : null,
                equipment: this.horse.equipment.map((equipment) => ({
                    goodType: equipment.goodType,
                    quality: equipment.quality,
                })),
            }
            : null, this.experience.map((experience) => experience.clone()), !!this.lastFightStats
            ? {
                morale: this.lastFightStats.morale,
                energy: this.lastFightStats.energy,
                shock: this.lastFightStats.shock.clone(),
                formation: this.lastFightStats.formation.clone(),
                discipline: this.lastFightStats.discipline.clone(),
                resilience: this.lastFightStats.resilience.clone(),
                offensiveOutput: this.lastFightStats.offensiveOutput.clone(),
            }
            : null);
    }
    toJSON() {
        return {
            soldiers: this.soldiers,
            morale: this.morale,
            energy: this.energy,
            weapons: this.weapons.map((weapon) => ({
                goodType: weapon.goodType,
                primary: weapon.primary,
                quality: weapon.quality,
            })),
            armor: this.armor
                ? {
                    goodType: this.armor.goodType,
                    quality: this.armor.quality,
                }
                : null,
            equipment: this.equipment.map((equipment) => ({
                goodType: equipment.goodType,
                quality: equipment.quality,
            })),
            horse: this.horse
                ? {
                    armor: this.horse.armor
                        ? {
                            goodType: this.horse.armor.goodType,
                            quality: this.horse.armor.quality,
                        }
                        : null,
                    equipment: this.horse.equipment.map((equipment) => ({
                        goodType: equipment.goodType,
                        quality: equipment.quality,
                    })),
                }
                : null,
            experience: this.experience.map((experience) => experience.toJSON()),
            lastFightStats: !!this.lastFightStats
                ? {
                    morale: this.lastFightStats.morale,
                    energy: this.lastFightStats.energy,
                    shock: this.lastFightStats.shock.toJSON(),
                    formation: this.lastFightStats.formation.toJSON(),
                    discipline: this.lastFightStats.discipline.toJSON(),
                    resilience: this.lastFightStats.resilience.toJSON(),
                    offensiveOutput: this.lastFightStats.offensiveOutput.toJSON(),
                }
                : null,
        };
    }
    static fromJSON(json) {
        return new Cohort(json.soldiers, json.morale, json.energy, json.weapons.map((weapon) => ({
            goodType: weapon.goodType,
            primary: weapon.primary,
            quality: weapon.quality,
        })), json.armor
            ? {
                goodType: json.armor.goodType,
                quality: json.armor.quality,
            }
            : null, json.equipment.map((equipment) => ({
            goodType: equipment.goodType,
            quality: equipment.quality,
        })), json.horse
            ? {
                armor: json.horse.armor
                    ? {
                        goodType: json.horse.armor.goodType,
                        quality: json.horse.armor.quality,
                    }
                    : null,
                equipment: json.horse.equipment.map((equipment) => ({
                    goodType: equipment.goodType,
                    quality: equipment.quality,
                })),
            }
            : null, json.experience.map((experience) => Experience.fromJSON(experience)), {
            morale: json.lastFightStats.morale,
            energy: json.lastFightStats.energy,
            shock: EvaluationResult.fromJSON(json.lastFightStats.shock),
            formation: EvaluationResult.fromJSON(json.lastFightStats.formation),
            discipline: EvaluationResult.fromJSON(json.lastFightStats.discipline),
            resilience: EvaluationResult.fromJSON(json.lastFightStats.resilience),
            offensiveOutput: EvaluationResult.fromJSON(json.lastFightStats.offensiveOutput),
        });
    }
}
export default Cohort;
