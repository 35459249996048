// External dependencies
import React, { useContext, useMemo } from 'react';

// Contexts
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
// Components
import CenterTitle from '../../../UI/GenericComponents/CenterTitle';
import GenericBackground from '../../../UI/GenericComponents/Background.tsx';
import CenterButton from '../../../UI/GenericComponents/CenterButton.tsx';

// Styles
import { BuildImprovementPanelStyled } from './styles/BuildImprovementPanel.styled.js';

// Enums
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';

// Utilities
import getImprovementTypeInfo from '../../../UI/MappingToData/Improvement/ImprovementTypeInfo.tsx';

// Types
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';

const BuildImprovementPanel = () => {
  // #region context
  const { gameState, userId } = useContext(GameStateDataContext);
  const {
    selectedCity,
    selectedImprovementTypeInfo,
    setSelectedImprovementTypeInfo,
  } = useContext(SelectedEntityContext);

  const { handleBuildImprovement } = useContext(InteractionContext);

  // #endregion

  const improvementMeetsRequirements = useMemo((): boolean => {
    if (!gameState) return false;
    if (!selectedImprovementTypeInfo) return false;
    return ImprovementTypeMethods.canBuildImprovementInCity(
      selectedImprovementTypeInfo,
      selectedCity,
      gameState
    ).canBuild;
  }, [gameState, selectedImprovementTypeInfo, selectedCity]);

  const haveMoneyForImprovement = useMemo((): boolean => {
    if (!gameState) return false;
    if (!selectedImprovementTypeInfo) return false;
    const landTile = gameState.getLandTileByTileId(
      selectedCity.centerTileLandtileId
    );
    return (
      gameState.getNationByControllerUserId(userId)?.administration?.balance >=
      ImprovementTypeMethods.getImprovementInfo(
        selectedImprovementTypeInfo
      ).cost.getCost(landTile, gameState)
    );
  }, [gameState, selectedImprovementTypeInfo, userId]);

  const buildImprovementButtonText = (reqs: boolean, money: boolean) => {
    if (!reqs) return 'Requirements Not Met';
    if (!money) return 'Not Enough Money';
    return 'Build';
  };

  return (
    <BuildImprovementPanelStyled className='improvement-type-info-panel ui-gold clickable-area'>
      <div
        className='close-button'
        onClick={() => setSelectedImprovementTypeInfo(null)}
      >
        X
      </div>
      <CenterTitle
        title={selectedImprovementTypeInfo}
        padding={UIPadding.Tall}
        backgroundData={{
          color: UIColor.HeaderBlue,
          opacity: UIOpacity.Full,
        }}
      />
      <div className='improvement-type-info-panel-content'>
        {getImprovementTypeInfo(
          selectedImprovementTypeInfo,
          selectedCity,
          gameState
        )}
      </div>
      <div className='build-button-container'>
        <GenericBackground color={UIColor.Blue6} opacity={UIOpacity.List} />
        <CenterButton
          label={buildImprovementButtonText(
            improvementMeetsRequirements,
            haveMoneyForImprovement
          )}
          onClick={() => {
            handleBuildImprovement();
          }}
          color={
            improvementMeetsRequirements && haveMoneyForImprovement
              ? UIColor.Blue
              : UIColor.EarthRed
          }
        />
      </div>
    </BuildImprovementPanelStyled>
  );
};

export default BuildImprovementPanel;
