// WeaponBuilderPage.tsx

import React, { useEffect, useContext } from 'react';

// internal imports
import { WeaponBuilderPageStyled } from './styles/WeaponBuilderPage.styled';
import QuickPage from '../../UtilityComponents/QuickPage';
import { ScreenModeContext } from '../../../contexts/ScreenAndMap/ScreenModeContextProvider';
import getWeaponInfoForBuilder from '../../UI/MappingToData/WeaponEditing/WeaponEditing';

function WeaponBuilderPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setScreenMode, ScreenMode } = useContext(ScreenModeContext);

  useEffect(() => {
    setScreenMode(ScreenMode.NotInGame);
  }, []);

  return (
    <WeaponBuilderPageStyled>
      <QuickPage />
      <div className='weapon-builder-content'>
        <div className='weapon-info-list ui-gold'>
          <div className='weapon-info-line-items'>
            {getWeaponInfoForBuilder()}
          </div>
        </div>
      </div>
    </WeaponBuilderPageStyled>
  );
}

export default WeaponBuilderPage;
