import styled from 'styled-components';
import '../../../../styles/constants.css';

export const SignInPageStyled = styled.div`
  position: relative;
  z-index: 1;
  height: ${({ height }) => height}px;
  overflow: hidden;

  .main-content {
    position: relative;
    width: calc(100vw - 2 * var(--screen-margin));
    height: calc(
      ${({ height }) => height}px - var(--navbar-height) - var(--screen-margin)
    );
    margin: 0 var(--screen-margin);
    border: 1px solid var(--lol-gold);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sign-in-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Adjust to center the component properly
    z-index: 3;
  }
`;
