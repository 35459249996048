import CharacterTrait from '../Character/CharacterTrait.js';
import Person from '../Person/Person.js';
import RuntimeEntity from '../Entity/RuntimeEntity.js';
import { ImprovementType } from '../../Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../Enums/ImprovementTypeMethods.js';
import { GoodCategory, GoodType, GoodTypeMethods } from '../../Enums/Good.js';
import RealizedTaxItem from '../EconomicData/TaxTracking/TaxItem.js';
import { TaxType } from '../../Enums/Tax.js';
import Experience from '../Experience/Experience.js';
import CityAdministration from '../Administration/CityAdministration.js';
import { BiomeTypeMethods } from '../../Enums/Biome.js';
class City extends RuntimeEntity {
    constructor(instanceId, name, ownerNationId, administeringNationId, persons, cityExperiences, cityCharacterTraits, governor, centerTileLandtileId, administeredTileLandtileIds) {
        super(instanceId);
        this.name = name;
        this.ownerNationId = ownerNationId;
        this.administeringNationId = administeringNationId;
        this.persons = persons;
        this.experiences = cityExperiences;
        this.cityCharacterTraits = cityCharacterTraits;
        this.governor = governor;
        this.centerTileLandtileId = centerTileLandtileId;
        this.administeredTileLandtileIds = administeredTileLandtileIds;
    }
    // #region City Utilities
    getImprovements(state) {
        const improvements = [];
        this.administeredTileLandtileIds.forEach((id) => {
            const tile = state.getLandTileByTileId(id);
            improvements.push(...tile.improvementInstanceIds.map((id) => state.getImprovementByInstanceId(id)));
        });
        return improvements;
    }
    getAdministeredLandTiles(state) {
        return this.administeredTileLandtileIds.map((id) => state.getLandTileByTileId(id));
    }
    static canFoundCityOnTile(tile, nation, state) {
        if (tile.amCityCenter)
            return false;
        if (BiomeTypeMethods.isWater(tile.mainBiomeType))
            return false;
        for (const city of state.cities) {
            if (city.administeredTileLandtileIds.includes(tile.id)) {
                return false;
            }
            if (city.administeredTileLandtileIds.includes(tile.id)) {
                return false;
            }
        }
        return true;
    }
    // #endregion
    // #region Economic Utilities
    getMedianIncome() {
        const sortedPersons = this.persons.sort((a, b) => a.consumptionChart.goods.reduce((acc, curr) => acc + curr.cost, 0) -
            b.consumptionChart.goods.reduce((acc, curr) => acc + curr.cost, 0));
        return sortedPersons[Math.floor(sortedPersons.length / 2)].consumptionChart.goods.reduce((acc, curr) => acc + curr.cost, 0);
    }
    getPriceOfFood(gameState) {
        const food = gameState.goodsData.goods.filter((good) => {
            return (GoodTypeMethods.getGoodInfo(good.goodType).category ===
                GoodCategory.Food && good.cityId === this.instanceId);
        });
        // get cheapest food
        return food.sort((a, b) => a.marketPrice - b.marketPrice)[0].marketPrice;
    }
    // #endregion
    // #region Production and Capacity
    runProduction(state) {
        // city capacity
        const capacity = this.getCapacity(state);
        // objects for production determination
        let everyPossibleProduction = [];
        // output
        const finalCityProduction = [];
        this.persons.forEach((person) => {
            capacity.forEach((capacitySlot, capacityIndex) => {
                const productionAmount = ImprovementTypeMethods.calculateProductionAmount(capacitySlot.improvement, person.experiences);
                const productionQuality = ImprovementTypeMethods.calculateProductionQuality(capacitySlot.improvement, person.experiences);
                const marketPrice = productionAmount *
                    state.goodsData.getMarketPrice(capacitySlot.improvement.capacity.production.goodType, this, productionQuality);
                // add production subsidy, production tax, and improvement subsidy
                const taxPolicies = state.taxes.filter((tax) => (tax.taxType === TaxType.ProductionSubsidy ||
                    tax.taxType === TaxType.ProductionTax) &&
                    tax.ownerNationId === this.ownerNationId);
                const tempTaxes = [];
                let taxImpact = 0;
                taxPolicies.forEach((tax) => {
                    switch (tax.taxType) {
                        case TaxType.ProductionSubsidy:
                            const productionSubsidy = tax;
                            const subsidyAmount = productionSubsidy.getCost(capacitySlot.improvement.capacity.production.goodType, this.instanceId, marketPrice, state);
                            if (subsidyAmount > 0) {
                                taxImpact -= subsidyAmount;
                                tempTaxes.push(new RealizedTaxItem(TaxType.ProductionSubsidy, capacitySlot.improvement.capacity.production.goodType, productionSubsidy.instanceId, this.administeringNationId, this.instanceId, subsidyAmount));
                            }
                        case TaxType.ProductionTax:
                            const productionTax = tax;
                            const taxAmount = productionTax.getCost(capacitySlot.improvement.capacity.production.goodType, this.instanceId, marketPrice, state);
                            if (taxAmount > 0) {
                                taxImpact += taxAmount;
                                tempTaxes.push(new RealizedTaxItem(TaxType.ProductionTax, capacitySlot.improvement.capacity.production.goodType, productionTax.instanceId, this.administeringNationId, this.instanceId, taxAmount));
                            }
                    }
                });
                let inputCost = 0;
                let inputsAvailable = true;
                for (const input of capacitySlot.improvement.capacity.production
                    .inputs) {
                    // Find the cheapest available good that satisfies input.goodOfType
                    const availableGoods = state.goodsData.goods.filter((good) => input.goodOfType.includes(good.goodType) &&
                        good.productionPossible &&
                        // Trade route exists
                        (good.cityId === this.instanceId ||
                            !!state.routeTracker.optimalCityToCityPaths[`${good.cityId}-${this.instanceId}`]));
                    if (availableGoods.length === 0) {
                        inputsAvailable = false;
                        break;
                    }
                    // Get the cheapest good
                    const cheapestGood = availableGoods.reduce((prev, curr) => prev.marketPrice < curr.marketPrice ? prev : curr);
                    // Calculate the cost
                    const amountNeeded = input.amount *
                        (input.inputType === 'perCapacity' ? 1 : productionAmount);
                    inputCost += cheapestGood.marketPrice * amountNeeded;
                }
                if (!inputsAvailable) {
                    // Skip this production since inputs are not available
                    return;
                }
                const valueToProducer = marketPrice - inputCost - taxImpact;
                everyPossibleProduction.push({
                    person: person,
                    capacityIndex: capacityIndex,
                    improvement: capacitySlot.improvement,
                    productionAmount: productionAmount,
                    productionQuality: productionQuality,
                    marketPrice: marketPrice,
                    inputCost: inputCost,
                    valueToProducer: valueToProducer,
                    activated: false,
                    taxes: tempTaxes,
                });
                // we also want to record in the main good data that production is possible,
                // so that even if it wasnt' produced it can be consumed
                state.goodsData.markProductionPossible(capacitySlot.improvement.capacity.production.goodType, this, productionQuality);
            });
        });
        // sort everyPossibleProduction by productionValue
        everyPossibleProduction.sort((a, b) => b.valueToProducer - a.valueToProducer);
        // must be positive production
        everyPossibleProduction = everyPossibleProduction.filter((production) => production.valueToProducer > 0);
        // now we iterate down everyPossibleProduction and add to finalProduction
        // if the capacity is not activated, we add the production to the capacity
        for (let i = 0; i < everyPossibleProduction.length; i++) {
            let production = everyPossibleProduction[i];
            if (!production.activated) {
                finalCityProduction.push({
                    person: production.person,
                    improvement: production.improvement,
                    productionQuality: production.productionQuality,
                    productionAmount: production.productionAmount,
                    marketPrice: production.marketPrice,
                    inputCost: production.inputCost,
                    valueToProducer: production.valueToProducer,
                    taxes: production.taxes,
                });
                production.improvement.capacity.currentNumberOfWorkers++;
                production.activated = true;
                // we also need to activate any production that shared a capacity or a person
                // Activate any production that shared a capacity or a person
                for (let j = i + 1; j < everyPossibleProduction.length; j++) {
                    let otherProduction = everyPossibleProduction[j];
                    if (otherProduction.person === production.person ||
                        otherProduction.capacityIndex === production.capacityIndex) {
                        otherProduction.activated = true;
                    }
                }
            }
        }
        // for people that didn't find work
        const sheepHerd = this.getImprovements(state).find((improvement) => improvement.improvementType === ImprovementType.NomadicHerd);
        if (!!sheepHerd) {
            const peopleWithoutWork = this.persons.filter((person) => !finalCityProduction.some((production) => production.person === person));
            const sheepAmount = 5;
            peopleWithoutWork.forEach((person) => {
                finalCityProduction.push({
                    person: person,
                    improvement: sheepHerd,
                    productionQuality: 1,
                    productionAmount: sheepAmount,
                    marketPrice: sheepAmount *
                        state.goodsData.getMarketPrice(GoodType.Mutton, this, 1),
                    inputCost: 0,
                    valueToProducer: sheepAmount *
                        state.goodsData.getMarketPrice(GoodType.Mutton, this, 1),
                    taxes: [],
                });
            });
        }
        // for everything that was produced, change each person's experiences and character
        for (const production of finalCityProduction) {
            const person = production.person;
            if (!person) {
                throw new Error('GameState.runEconomicCycle: Person not found');
            }
            const improvement = production.improvement;
            if (!improvement) {
                throw new Error('GameState.runEconomicCycle: Improvement not found');
            }
            ImprovementTypeMethods.modifyProducer(person, this, improvement, state);
        }
        return finalCityProduction.map((production) => {
            return {
                person: production.person,
                improvement: production.improvement,
                goodType: production.improvement.capacity.production.goodType,
                productionQuality: production.productionQuality,
                productionAmount: production.productionAmount,
                marketPrice: production.marketPrice,
                inputCost: production.inputCost,
                valueToProducer: production.valueToProducer,
                taxes: production.taxes,
            };
        });
    }
    getCapacity(state) {
        const capacitySlots = [];
        this.administeredTileLandtileIds.forEach((id) => {
            const tile = state.getLandTileByTileId(id);
            const improvements = tile.improvementInstanceIds.map((id) => state.getImprovementByInstanceId(id));
            for (const improvement of improvements) {
                if (!improvement.capacity)
                    continue;
                for (let i = 0; i < improvement.capacity.maxCapacity; i++) {
                    capacitySlots.push({
                        person: null,
                        improvement: improvement,
                    });
                }
            }
        });
        return capacitySlots;
    }
    // #endregion
    // #region Experience and Character
    getExperienceAmount(experienceType) {
        var _a;
        return (((_a = this.experiences.find((experience) => experience.experienceType === experienceType)) === null || _a === void 0 ? void 0 : _a.amount) || 0);
    }
    addExperience(experienceType, amount) {
        const experience = this.experiences.find((experience) => experience.experienceType === experienceType);
        if (experience) {
            experience.amount += amount;
        }
        else {
            this.experiences.push(new Experience(experienceType, amount));
        }
    }
    getCharacterAmount(characterType) {
        var _a;
        return (((_a = this.cityCharacterTraits.find((trait) => trait.characterType === characterType)) === null || _a === void 0 ? void 0 : _a.amount) || 0);
    }
    addCharacter(characterType, amount) {
        const character = this.cityCharacterTraits.find((trait) => trait.characterType === characterType);
        if (character) {
            character.amount += amount;
        }
        else {
            this.cityCharacterTraits.push(new CharacterTrait(characterType, amount));
        }
    }
    // #endregion
    // #region Clone and JSON
    clone() {
        return new City(this.instanceId, this.name, this.ownerNationId, this.administeringNationId, this.persons.map((person) => person.clone()), this.experiences.map((experience) => experience.clone()), this.cityCharacterTraits.map((trait) => trait.clone()), this.governor.clone(), this.centerTileLandtileId, [...this.administeredTileLandtileIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            name: this.name,
            ownerNationId: this.ownerNationId,
            administeringNationId: this.administeringNationId,
            persons: this.persons.map((person) => person.toJSON()),
            cityExperiences: this.experiences.map((experience) => experience.toJSON()),
            cityCharacterTraits: this.cityCharacterTraits.map((trait) => trait.toJSON()),
            governor: this.governor.toJSON(),
            centerTileLandtileId: this.centerTileLandtileId,
            administeredTileLandtileIds: this.administeredTileLandtileIds.map((id) => id),
        };
    }
    static fromJSON(json) {
        return new City(json.instanceId, json.name, json.ownerNationId, json.administeringNationId, json.persons.map((person) => Person.fromJSON(person)), json.cityExperiences.map((experience) => Experience.fromJSON(experience)), json.cityCharacterTraits.map((trait) => CharacterTrait.fromJSON(trait)), CityAdministration.fromJSON(json.governor), json.centerTileLandtileId, json.administeredTileLandtileIds.map((id) => id));
    }
}
export default City;
