import React, { useState, useEffect, useContext } from 'react';

// internal
// context
import { RoomsContext } from '../../../../contexts/AdminAndNetworking/RoomsContextProvider.tsx';

// css
import { RoomTileStyled } from './styles/RoomTile.styled.js';
import GenericBackground from '../../../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../../../UI/Enums/UIEnums.ts';

function RoomTile({ room, selected }) {
  // context
  const { joinRoom } = useContext(RoomsContext);

  const formatImageLink = (link) => {
    return `linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ), url('${link}')`;
  };

  return (
    <RoomTileStyled
      className='room-tile rounded-corners'
      onClick={() => {
        joinRoom(room.id);
      }}
    >
      <GenericBackground color={UIColor.ListBlue} opacity={0.3} />
      <div className='players-container'>
        <h3 className='players-title'>Players:</h3>
        {room.players.map((player, index) => {
          return <div key={player.name + index + ''}>{player.name}</div>;
        })}
      </div>
      <div className='room-info'>
        <div className='realm-name'>
          {'Realm: ' +
            (!!room.selectedRealmName
              ? room.selectedRealmName
              : 'None Selected')}
        </div>
        <div className='game-type'>
          {'Game Type: ' +
            (!!room.selectedGameType ? room.selectedGameType : 'None Selected')}
        </div>
      </div>
    </RoomTileStyled>
  );
}

export default RoomTile;
