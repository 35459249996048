import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const TopControlsStyled = styled.div`
  position: absolute;
  top: var(--game-ui-margin);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: auto;

  .left-buttons {
    display: flex;
  }

  .dev-buttons {
    display: flex;
  }

  .right-buttons {
    display: flex;
  }
`;
