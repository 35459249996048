import RealizedTaxItem from './TaxItem.js';
class TaxData {
    constructor(taxItems) {
        this.taxItems = taxItems;
    }
    clone() {
        return new TaxData(this.taxItems.map((taxItem) => taxItem.clone()));
    }
    toJSON() {
        return {
            taxItems: this.taxItems.map((taxItem) => taxItem.toJSON()),
        };
    }
    static fromJSON(json) {
        return new TaxData(json.taxItems.map((taxItem) => RealizedTaxItem.fromJSON(taxItem)));
    }
}
export default TaxData;
