// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { ListPopupStyled } from './styles/ListPopup.styled.js';

const ListPopup = ({
  list,
  onSelect,
  close,
}: {
  list: {
    name: string;
    value: any;
  }[];
  onSelect: (value: any) => void;
  close: () => void;
}) => {
  return (
    <ListPopupStyled className='list-popup'>
      <div className='list-popup-closer' onClick={close}></div>
      <div className='list-popup-area'>
        {list.map((item, index) => (
          <div
            className='list-popup-item'
            key={index}
            onClick={() => onSelect(item.value)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </ListPopupStyled>
  );
};

export default ListPopup;
