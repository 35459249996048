class RuntimeProduction {
    constructor(goodName, goodType, inputs, improvementAffinity) {
        this.goodName = goodName;
        this.goodType = goodType;
        this.inputs = inputs;
        this.improvementAffinity = improvementAffinity;
    }
    clone() {
        return new RuntimeProduction(this.goodName, this.goodType, this.inputs.map((input) => ({
            goodOfType: input.goodOfType.map((goodType) => goodType),
            amount: input.amount,
            inputType: input.inputType,
        })), {
            fixed: this.improvementAffinity.fixed,
            multiplier: this.improvementAffinity.multiplier,
        });
    }
    toJSON() {
        return {
            goodName: this.goodName,
            goodType: this.goodType,
            inputs: this.inputs.map((input) => ({
                goodOfType: input.goodOfType.map((goodType) => goodType),
                amount: input.amount,
                inputType: input.inputType,
            })),
            improvementAffinity: {
                fixed: this.improvementAffinity.fixed,
                multiplier: this.improvementAffinity.multiplier,
            },
        };
    }
    static fromJSON(json) {
        return new RuntimeProduction(json.goodName, json.goodType, json.inputs.map((input) => ({
            goodOfType: input.goodOfType.map((goodType) => goodType),
            amount: input.amount,
            inputType: input.inputType,
        })), {
            fixed: json.improvementAffinity.fixed,
            multiplier: json.improvementAffinity.multiplier,
        });
    }
}
export default RuntimeProduction;
