import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const CivilianUnitInfoStyled = styled.div`
  position: absolute;
  bottom: var(--game-ui-margin);
  right: var(--game-ui-margin);
  width: 250px;
  z-index: var(--overlay-z-index);

  border: 2px solid var(--lol-gold);
  border-radius: var(--ui-border-radius);

  pointer-events: auto;

  .unit-name {
    padding: 15px;
  }

  .unit-info-content {
    position: relative;
  }

  .unit-info-buttons {
    position: relative;
    padding: 5px 0;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }
`;
