import styled from 'styled-components';
import '../../../../styles/constants.css';

export const FlagshipSelectionListStyled = styled.div`
  position: relative;
  width: 100%;

  .flagship-box-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .flagship-box-container-background {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: calc(100% + 40px);

    border: 2px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(3px);

    z-index: -1;

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
