import React, { useState } from 'react';

// context
import { SocketContext } from './SocketContextProvider.tsx';

const RoomsContextProvider = ({ children }) => {
  // context
  const {
    socket,
    requestNewRoom: requestNewRoomSocket,
    joinRoom: joinRoomSocket,
    leaveRoom: leaveRoomSocket,
    onRoomsUpdate,
    onYouJoinedRoom,
    onYouLeftRoom,
    onRejoinedGame,
    changeRealm: changeRealmSocket,
    onRealmChange,
    changeGameType: changeGameTypeSocket,
    onGameTypeSelected,
    handleLoadSavedGame,
  } = React.useContext(SocketContext);

  // state
  const [rooms, setRooms] = useState([]);
  const [myRoomID, setMyRoomID] = useState(null);

  // socket emits

  const requestNewRoom = () => {
    requestNewRoomSocket();
  };

  const joinRoom = (roomId) => {
    joinRoomSocket(roomId);
  };

  const leaveRoom = () => {
    leaveRoomSocket();
  };

  const changeRealm = (realmId: string) => {
    changeRealmSocket(realmId);
  };

  const changeGameType = (gameType: string) => {
    if (gameType !== 'sandbox' && gameType !== 'competitive') {
      console.error('Invalid game type:', gameType);
      return;
    }
    changeGameTypeSocket(gameType);
  };

  // socket events
  React.useEffect(() => {
    onRoomsUpdate((data) => {
      setRooms(data);
    });

    onYouJoinedRoom((roomId) => {
      setMyRoomID(roomId);
    });

    onYouLeftRoom(() => {
      setMyRoomID(null);
    });

    onRejoinedGame((roomId) => {
      setMyRoomID(roomId);
    });

    onRealmChange((data) => {
      setRooms((prevRooms) =>
        prevRooms.map((room) =>
          room.id === data.roomId
            ? { ...room, selectedRealmName: data.realmName }
            : room
        )
      );
    });

    onGameTypeSelected((data) => {
      setRooms((prevRooms) =>
        prevRooms.map((room) =>
          room.id === data.roomId
            ? { ...room, selectedGameType: data.gameType }
            : room
        )
      );
    });

    handleLoadSavedGame((data) => {
      console.log('LOADED SAVED GAME: ', data);
    });

    return () => {
      onRejoinedGame(() => {});
      onRoomsUpdate(() => {});
      onYouJoinedRoom(() => {});
      onYouLeftRoom(() => {});
      onRealmChange(() => {});
      onGameTypeSelected(() => {});
      handleLoadSavedGame(() => {});
    };
  }, [
    socket,
    onRoomsUpdate,
    onYouJoinedRoom,
    onYouLeftRoom,
    onRealmChange,
    onGameTypeSelected,
    handleLoadSavedGame,
  ]);

  const value: RoomsContextType = {
    rooms,
    setRooms,
    myRoomID,
    setMyRoomID,
    requestNewRoom,
    joinRoom,
    leaveRoom,
    changeRealm,
    changeGameType,
  };
  return (
    <RoomsContext.Provider value={value}>{children}</RoomsContext.Provider>
  );
};

interface RoomsContextType {
  rooms: any[];
  setRooms: React.Dispatch<React.SetStateAction<any[]>>;
  myRoomID: string | null;
  setMyRoomID: React.Dispatch<React.SetStateAction<string | null>>;
  requestNewRoom: () => void;
  joinRoom: (roomId: string) => void;
  leaveRoom: () => void;
  changeRealm: (realmId: string) => void;
  changeGameType: (gameType: string) => void;
}

const RoomsContext = React.createContext<RoomsContextType | null>(null);

export { RoomsContextProvider, RoomsContext };
