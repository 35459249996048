// external
import React, { useState, useEffect, useContext, useMemo } from 'react';

// internal
// components

// internal context
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { ScreenModeContext } from '../../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';
import { GameStatusContext } from '../../../../contexts/GameData/GameStatusContextProvider.tsx';
import { SocketContext } from '../../../../contexts/AdminAndNetworking/SocketContextProvider.tsx';

// css
import { TopControlsStyled } from './styles/TopControls.styled.js';

// gl
import { GamePlayState } from '../../../../../../../GildedLands/lib/Enums/GamePlayState';
import {
  TimeEnum,
  TimeMethods,
} from '../../../../../../../GildedLands/lib/Enums/Time';

const TopControls = () => {
  const { gameState } = useContext(GameStateDataContext);
  const { ScreenMode, setScreenMode, screenMode } =
    useContext(ScreenModeContext);
  const { lastCycleRunTime } = useContext(GameStatusContext);
  const { socket } = useContext(SocketContext);

  // #region handle buttons

  const handleRunCycle = () => {
    socket.emit('dev-player-run-cycle');
  };

  const handleEndGame = () => {
    socket.emit('dev-player-end-game');
  };

  const handleTogglePlayPause = () => {
    if (gameState?.gamePlayState === GamePlayState.Paused) {
      socket.emit('dev-player-start-game');
    } else {
      socket.emit('dev-player-pause-game');
    }
  };

  const handleEconomyScreen = () => {
    setScreenMode(ScreenMode.EconomyScreen);
  };

  const handleTaxesScreen = () => {
    setScreenMode(ScreenMode.TaxesScreen);
  };

  const handleAdministrationScreen = () => {
    setScreenMode(ScreenMode.AdministrationScreen);
  };

  const handleResumeGame = () => {
    socket.emit('dev-player-start-game');
  };

  // #endregion

  return (
    <TopControlsStyled>
      <div className='left-buttons'>
        <button
          className={
            screenMode === ScreenMode.EconomyScreen
              ? 'gold-button selected'
              : 'gold-button'
          }
          onClick={() => setScreenMode(ScreenMode.EconomyScreen)}
        >
          Economy
        </button>
        <button
          className={
            screenMode === ScreenMode.TaxesScreen
              ? 'gold-button selected'
              : 'gold-button'
          }
          onClick={() => setScreenMode(ScreenMode.TaxesScreen)}
        >
          Taxes
        </button>
        <button
          className={
            screenMode === ScreenMode.AdministrationScreen
              ? 'gold-button selected'
              : 'gold-button'
          }
          onClick={() => setScreenMode(ScreenMode.AdministrationScreen)}
        >
          Admin
        </button>
      </div>
      {/* <div className='dev-buttons'>
        <button onClick={handleRunCycle}>Run Cycle</button>
        <p className='info-box'>Cycle Run: {lastCycleRunTime.toFixed(0)}ms</p>
        <button onClick={handleEndGame}>End Game</button>
      </div> */}
    </TopControlsStyled>
  );
};

export default TopControls;
