import styled from 'styled-components';
import '../../../styles/constants.css';

export const LobbyPageStyled = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 1;

  .lobby-main-content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .lobby-top-holder {
    position: absolute;
    top: var(--game-ui-margin);
    left: 50%;
    transform: translateX(-50%);

    height: 15%;
    width: 100%;

    background-image: ${({ background }) => `url(${background})`};
    background-size: cover;
    background-position: center;

    border: 2px solid rgba(255, 255, 255, 0.3);

    z-index: -1;

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0) 90%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0) 90%
    );

    .lobby-title {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-family: var(--header-font);
      font-size: 3rem;
      font-weight: bold;
      color: var(--lol-gold);
    }
  }

  .lobby-left-column {
    position: absolute;
    top: 10%;
    left: calc(var(--game-ui-margin) * 2);
    height: 80%;
    width: 250px;
  }

  .center-panel {
    position: absolute;
    top: calc(15% + 2 * var(--game-ui-margin));
    left: calc(var(--game-ui-margin) * 3 + 250px);
    width: 500px;
  }

  .center-panel-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .realms {
    height: 300px;
  }
`;
