// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

// context
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import { ProductionReportStyled } from './styles/ProductionReport.styled.js';

// lol
import { GoodType } from '../../../../../../../../GildedLands/lib/Enums/Good';
import City from '../../../../../../../../GildedLands/lib/Classes/City/City';
import GoodsData from '../../../../../../../../GildedLands/lib/Classes/EconomicData/PriceAndProduction/GoodsData';

interface ProductionReportProps {
  selectedCity: { name: string; instanceId: number };
}

const ProductionReport = ({ selectedCity }: ProductionReportProps) => {
  const { gameState, userId } = useContext(GameStateDataContext);

  return (
    <ProductionReportStyled className='production-report'>
      <div className='production-report-title'>
        <p>{`${selectedCity?.name} Production Report`}</p>
      </div>
    </ProductionReportStyled>
  );
};

export default ProductionReport;
