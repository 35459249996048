import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class AddTaxMessage extends ClientMessage {
    constructor(messageId, senderUserId, taxType) {
        super(NetworkProtocol.AddTax, messageId, senderUserId);
        this.taxType = taxType;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { taxType: this.taxType });
    }
    static fromJSON(json) {
        return new AddTaxMessage(json.messageId, json.senderUserId, json.taxType);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('add tax message validating: ', this.toJSON());
        return this.taxType !== undefined && super.validate();
    }
}
