// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { ReadyButtonStyled } from './styles/ReadyButton.styled';

// context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { RoomsContext } from '../../contexts/AdminAndNetworking/RoomsContextProvider.tsx';
import { SocketContext } from '../../contexts/AdminAndNetworking/SocketContextProvider.tsx';
import { UIColor } from '../UI/Enums/UIEnums.ts';
import CenterButton from '../UI/GenericComponents/CenterButton.tsx';

function ReadyButton({ selectedRealmID }) {
  // context
  const { myRoomID } = useContext(RoomsContext);
  const { socket, onSetReadyFromServer, requestSetReady } =
    useContext(SocketContext);

  // button ready
  const [amReady, setAmReady] = useState(false);
  useEffect(() => {
    onSetReadyFromServer((data) => {
      setAmReady(data);
    });
    return () => {
      onSetReadyFromServer(() => {});
    };
  }, [socket, onSetReadyFromServer]);

  const [readyButtonMessage, setReadyButtonMessage] = useState(
    'Select Realm and Room'
  );
  const [buttonActive, setButtonActive] = useState(false);

  useEffect(() => {
    if (!selectedRealmID && !myRoomID) {
      setReadyButtonMessage('Select Realm and Room');
      setButtonActive(false);
      return;
    }
    if (!selectedRealmID) {
      setReadyButtonMessage('Select Realm');
      setButtonActive(false);
      return;
    }
    if (!myRoomID) {
      setReadyButtonMessage('Select Room');
      setButtonActive(false);
      return;
    }
    if (!amReady) {
      setReadyButtonMessage('Set Ready');
      setButtonActive(true);
      return;
    }
    setReadyButtonMessage('Ready!');
    setButtonActive(true);
  }, [selectedRealmID, myRoomID, amReady]);

  // #endregion

  return (
    <ReadyButtonStyled>
      <CenterButton
        label={readyButtonMessage}
        onClick={() => {
          if (buttonActive) {
            requestSetReady();
          }
        }}
        color={buttonActive ? UIColor.Blue : UIColor.DarkGrey}
      />
    </ReadyButtonStyled>
  );
}

export default ReadyButton;
