import Experience from '../../../Experience/Experience.js';
import Responsibility from '../../Responsibility.js';
import Load from '../../Load.js';
import Eunuch from '../../Eunuch.js';
import { ResponsibilityType } from '../../../../Enums/Responsibility.js';
class Department {
    constructor(instanceIdentifier, name, nationId, cityId, managingDepartmentId, subDepartments, experience, eunuchs, responsibilities, loads, totalOrgExperience, cycleEffectivenessPercentage) {
        this.instanceIdentifier = instanceIdentifier;
        this.name = name;
        this.nationId = nationId;
        this.cityId = cityId;
        this.managingDepartmentId = managingDepartmentId;
        this.subDepartments = subDepartments;
        this.experience = experience;
        this.eunuchs = eunuchs;
        this.responsibilities = responsibilities;
        this.loads = loads;
        this.totalOrgExperience = totalOrgExperience;
        this.cycleEffectivenessPercentage = cycleEffectivenessPercentage;
    }
    // #region getters
    getDepartmentById(id) {
        if (this.instanceIdentifier === id)
            return this;
        for (const subDept of this.subDepartments) {
            const found = subDept.getDepartmentById(id);
            if (found)
                return found;
        }
        return null;
    }
    getLengthOfRoleMatch(role) {
        let out = -1;
        for (let responsibility of this.responsibilities) {
            for (let i = 0; i < responsibility.name.length; i++) {
                if (responsibility.name[i] === 'All') {
                    out = Math.max(out, i);
                    break;
                }
                if (responsibility.name[i] !== role[i]) {
                    break;
                }
                if (i === role.length - 1) {
                    out = Math.max(out, i);
                }
            }
        }
        return out;
    }
    getDepartmentByRole(role) {
        let bestMatch = -1;
        let bestDept = null;
        let thisMatch = Math.max(bestMatch, this.getLengthOfRoleMatch(role));
        if (thisMatch >= bestMatch) {
            bestMatch = thisMatch;
            bestDept = this;
        }
        for (const dept of this.subDepartments) {
            const matchLength = dept.getLengthOfRoleMatch(role);
            if (matchLength >= bestMatch) {
                bestMatch = matchLength;
                bestDept = dept;
            }
        }
        return bestDept;
    }
    // #endregion
    // #region load utils
    clearLoad() {
        this.loads = [];
        for (const dept of this.subDepartments) {
            dept.clearLoad();
        }
    }
    addLoad(load) {
        let currentLoad = this.loads.find((l) => l.responsibility.name.join('-') === load.responsibility.name.join('-'));
        if (currentLoad) {
            currentLoad.amount += load.amount;
        }
        else {
            this.loads.push(load);
        }
    }
    distributeExperience(parentExp) {
        for (const exp of this.experience) {
            this.addTotalOrgExperience(exp.clone());
        }
        for (const exp of parentExp) {
            let nexp = exp.clone();
            nexp.amount = nexp.amount * 0.5;
            this.addTotalOrgExperience(nexp);
        }
        for (const dept of this.subDepartments) {
            dept.distributeExperience(this.totalOrgExperience);
        }
    }
    calculateEffectiveness() {
        this.cycleEffectivenessPercentage = 0;
        // get all experience by eunuch
        // divide experience by matching load (evenly where applicable)
        for (const exp of this.totalOrgExperience) {
            let totalExp = exp.amount;
            while (totalExp > 0) {
                const matchingLoads = this.loads.filter((load) => load.responsibility.doesExperienceMatch(exp.experienceType) &&
                    load.coverage < load.amount);
                if (matchingLoads.length === 0)
                    break;
                let fairShare = totalExp / matchingLoads.length;
                for (const load of matchingLoads) {
                    const amountToAdd = Math.min(fairShare, load.amount - load.coverage);
                    load.coverage +=
                        amountToAdd * load.getMultForExperienceCoverage(exp.experienceType);
                    totalExp -= amountToAdd;
                }
            }
        }
        let totalLoad = 0;
        let totalCoverage = 0;
        for (const load of this.loads) {
            totalLoad += load.amount;
            totalCoverage += load.coverage;
        }
        this.cycleEffectivenessPercentage = Math.min(Math.pow(totalCoverage / totalLoad, 2), totalCoverage / totalLoad);
    }
    // #endregion
    // #region experience utils
    clearOrgExperience() {
        this.totalOrgExperience = [];
        for (const dept of this.subDepartments) {
            dept.clearOrgExperience();
        }
    }
    addExperience(experience) {
        if (experience.experienceType === 'All')
            return;
        let currentExp = this.experience.find((exp) => exp.experienceType === experience.experienceType);
        if (currentExp) {
            currentExp.amount += experience.amount;
        }
        else {
            this.experience.push(experience.clone());
        }
    }
    addTotalOrgExperience(experience) {
        let currentExp = this.totalOrgExperience.find((exp) => exp.experienceType === experience.experienceType);
        if (currentExp) {
            currentExp.amount += experience.amount;
        }
        else {
            this.totalOrgExperience.push(experience);
        }
    }
    getExperienceFromLoads() {
        const out = [];
        for (const load of this.loads) {
            const matchingResponsibilities = this.responsibilities.filter((resp) => resp.doesResponsibilityMatch(load.responsibility));
            if (matchingResponsibilities.length === 0)
                continue;
            const bestMatch = matchingResponsibilities.reduce((acc, resp) => (resp.name.length > acc.name.length ? resp : acc), matchingResponsibilities[0]);
            const respLength = bestMatch.name.length;
            for (let i = 0; i < respLength; i++) {
                const exp = new Experience(bestMatch.name[i], (load.amount * this.cycleEffectivenessPercentage) / respLength);
                out.push(exp);
            }
        }
        return out;
    }
    gainAndLoseExperience() {
        // gain
        const exp = this.getExperienceFromLoads();
        if (exp.length === 0) {
            for (let i = 0; i < this.eunuchs.length; i++) {
                exp.push(new Experience(ResponsibilityType.Admin, 1));
            }
        }
        for (const e of exp) {
            this.addExperience(e);
        }
        // reduce experience
        let totalExp = this.experience.reduce((acc, exp) => acc + exp.amount, 0);
        let amountToRemove = totalExp * 0.01;
        let amountToRemovePerType = amountToRemove / this.experience.length;
        for (const exp of this.experience) {
            exp.amount -= amountToRemovePerType;
        }
        for (let i = this.experience.length - 1; i >= 0; i--) {
            if (this.experience[i].amount <= 0) {
                this.experience.splice(i, 1);
            }
        }
        // same thing for eunuchs
        for (const eunuch of this.eunuchs) {
            const exp = this.getExperienceFromLoads();
            if (exp.length === 0) {
                exp.push(new Experience(ResponsibilityType.Admin, 1));
            }
            for (const e of exp) {
                eunuch.addExperience(e);
            }
            totalExp = eunuch.experience.reduce((acc, exp) => acc + exp.amount, 0);
            amountToRemove = totalExp * 0.01;
            amountToRemovePerType = amountToRemove / eunuch.experience.length;
            for (const exp of eunuch.experience) {
                exp.amount -= amountToRemovePerType;
            }
        }
    }
    // #endregion
    // #region creation utils
    updateFromClient(clientDept, nation, city, managingDept, admin, gameState) {
        if (this.eunuchs.length > clientDept.eunuchs.length) {
            // remove oldest
            this.eunuchs.splice(0, this.eunuchs.length - clientDept.eunuchs.length);
        }
        if (this.eunuchs.length < clientDept.eunuchs.length) {
            for (let i = this.eunuchs.length; i < clientDept.eunuchs.length; i++) {
                this.eunuchs.push(new Eunuch([]));
            }
        }
        // if it's the top level, it has all responsibilities
        if (!!managingDept) {
            this.responsibilities = clientDept.responsibilities.map((resp) => resp.clone());
        }
        let respString = this.responsibilities.length === 0 ? 'Nothing' : '';
        for (let i = 0; i < this.responsibilities.length; i++) {
            const resp = this.responsibilities[i];
            if (resp.name[resp.name.length - 1] === 'All') {
                respString = respString + resp.name[resp.name.length - 2];
            }
            else {
                respString = respString + resp.name[resp.name.length - 1];
            }
            if (i < this.responsibilities.length - 2) {
                respString = respString + ', ';
            }
            else if (i === this.responsibilities.length - 2) {
                respString = respString + ' and ';
            }
        }
        this.name = 'Department of ' + respString;
        if (!this.managingDepartmentId) {
            if (this.cityId !== null && this.cityId !== undefined) {
                this.name = 'City Governor';
            }
            else {
                this.name = 'Royal Palace';
            }
        }
        for (const subDept of clientDept.subDepartments) {
            const found = this.getDepartmentById(subDept.instanceIdentifier);
            if (found) {
                found.updateFromClient(subDept, nation, city, this, admin, gameState);
            }
            else {
                const newDept = new Department(subDept.instanceIdentifier, subDept.name, this.nationId, !!city ? city.instanceId : null, this.instanceIdentifier, [], [], [], [], [], [], 0);
                newDept.updateFromClient(subDept, nation, city, this, admin, gameState);
                this.subDepartments.push(newDept);
            }
        }
        for (let i = this.subDepartments.length - 1; i >= 0; i--) {
            if (!clientDept.subDepartments.find((d) => d.instanceIdentifier === this.subDepartments[i].instanceIdentifier)) {
                console.log('this.subDepartments: ', this.subDepartments);
                console.log('did not find :', this.subDepartments[i]);
                console.log('client subDepts: ', clientDept.subDepartments);
                this.subDepartments.splice(i, 1);
            }
        }
    }
    updateStatsFromServer(serverDept) {
        this.cycleEffectivenessPercentage = serverDept.cycleEffectivenessPercentage;
        for (let dept of serverDept.subDepartments) {
            const found = this.getDepartmentById(dept.instanceIdentifier);
            if (found) {
                found.updateStatsFromServer(dept);
            }
        }
    }
    isDepartmentValid(gameState) {
        const nation = gameState.getNationById(this.nationId);
        if (!nation)
            throw new Error('Nation not found');
        const parent = nation.getDepartmentByCityAndDepartmentId(this.cityId, this.managingDepartmentId, gameState);
        if (!!parent) {
            for (const resp of this.responsibilities) {
                // check if parent has an acceptable responsiblity
            }
        }
        for (const dept of this.subDepartments) {
            if (!dept.isDepartmentValid(gameState))
                return false;
        }
        return true;
    }
    isLevelCheck(topLevel) {
        if (!topLevel) {
            if (!this.managingDepartmentId) {
                console.log('failed level check' + JSON.stringify(this));
                return false;
            }
        }
        for (const dept of this.subDepartments) {
            if (!dept.isLevelCheck(false))
                return false;
        }
        return true;
    }
    // #endregion
    //#region JSON and Clone
    clone() {
        return new Department(this.instanceIdentifier, this.name, this.nationId, this.cityId, this.managingDepartmentId, this.subDepartments.map((dept) => dept.clone()), this.experience.map((exp) => exp.clone()), this.eunuchs, this.responsibilities.map((resp) => resp.clone()), this.loads.map((load) => load.clone()), this.totalOrgExperience.map((exp) => exp.clone()), this.cycleEffectivenessPercentage);
    }
    toJSON() {
        return {
            instanceIdentifier: this.instanceIdentifier,
            name: this.name,
            nationId: this.nationId,
            cityId: this.cityId,
            managingDepartmentId: this.managingDepartmentId,
            subDepartments: this.subDepartments.map((dept) => dept.toJSON()),
            experience: this.experience.map((exp) => exp.toJSON()),
            eunuchs: this.eunuchs,
            responsibilities: this.responsibilities.map((resp) => resp.toJSON()),
            loads: this.loads.map((load) => load.toJSON()),
            totalOrgExperience: this.totalOrgExperience.map((exp) => exp.toJSON()),
            cycleEffectivenessPercentage: this.cycleEffectivenessPercentage,
        };
    }
    static fromJSON(json) {
        return new Department(json.instanceIdentifier, json.name, json.nationId, !!json.cityId ? json.cityId : null, !!json.managingDepartmentId ? json.managingDepartmentId : null, json.subDepartments.map((dept) => Department.fromJSON(dept)), json.experience.map((exp) => Experience.fromJSON(exp)), json.eunuchs, json.responsibilities.map((resp) => Responsibility.fromJSON(resp)), json.loads.map((load) => Load.fromJSON(load)), json.totalOrgExperience.map((exp) => Experience.fromJSON(exp)), json.cycleEffectivenessPercentage);
    }
}
export default Department;
