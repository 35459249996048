enum UIPadding {
  Short = 5,
  Medium = 10,
  Tall = 15,
}

enum UIOpacity {
  None = 0,
  List = 0.8,
  Header = 0.9,
  Full = 1,
}

enum UIColor {
  None = 'none',
  LightBackground = 'var(--lol-grey)',
  DarkGrey = 'var(--lol-dark-grey)',
  Gold = 'var(--lol-gold)',
  Blue = 'var(--grey-blue)',
  Red = 'red',
  White = 'white',
  EarthRed = 'var(--earth-red)',

  // list colors
  HeaderBlue = 'var(--ui-blue-4)',
  ListBlue = 'var(--ui-blue-5)',

  // blues
  Blue1 = 'var(--ui-blue-1)',
  Blue2 = 'var(--ui-blue-2)',
  Blue3 = 'var(--ui-blue-3)',
  Blue4 = 'var(--ui-blue-4)',
  Blue5 = 'var(--ui-blue-5)',
  Blue6 = 'var(--ui-blue-6)',
}

enum UITextSize {
  Small = '14',
  Medium = '18',
  Large = '22',
}

export { UIPadding, UIColor, UIOpacity, UITextSize };
