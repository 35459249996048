import ExperienceCurveInfo from '../Classes/Improvement/ExperienceCurveInfo.js';
var ExperienceType;
(function (ExperienceType) {
    ExperienceType["None"] = "None";
    ExperienceType["GeneralEducation"] = "General Education";
    // food
    // food basic knowledge
    ExperienceType["WheatKnowledge"] = "Wheat Knowledge";
    ExperienceType["RutabagaKnowledge"] = "Rutabaga Knowledge";
    ExperienceType["PotatoKnowledge"] = "Potato Knowledge";
    ExperienceType["RiceKnowledge"] = "Rice Knowledge";
    ExperienceType["TreeKnowledge"] = "Tree Knowledge";
    ExperienceType["CarrotKnowledge"] = "Carrot Knowledge";
    ExperienceType["CabbageKnowledge"] = "Cabbage Knowledge";
    ExperienceType["TurnipKnowledge"] = "Turnip Knowledge";
    ExperienceType["PeaKnowledge"] = "Pea Knowledge";
    ExperienceType["CowBehavior"] = "Cow Behavior";
    ExperienceType["SheepBehavior"] = "Sheep Behavior";
    ExperienceType["DeerBehavior"] = "Deer Behavior";
    ExperienceType["FishBehavior"] = "Fish Behavior";
    // secondary
    ExperienceType["AnimalProcessing"] = "Animal Processing";
    ExperienceType["FiberProcessing"] = "Fiber Processing";
    ExperienceType["Earthworking"] = "Earthworking";
    ExperienceType["WeatherAttunement"] = "Weather Attunement";
    ExperienceType["WaterAttunement"] = "Water Attunement";
    ExperienceType["Tracking"] = "Tracking";
    // food improvements
    ExperienceType["Agriculture"] = "Simple Agriculture";
    ExperienceType["AnimalHusbandry"] = "Animal Husbandry";
    ExperienceType["Herding"] = "Herding";
    ExperienceType["NomadicLife"] = "Nomadic Life";
    // biome experience
    ExperienceType["MountainLife"] = "Mountain Life";
    ExperienceType["DesertLife"] = "Desert Life";
    ExperienceType["RuggedTravel"] = "Rugged Travel";
    ExperienceType["AlpineTravel"] = "Alpine Travel";
    ExperienceType["Seafaring"] = "Seafaring";
    // Sailing
    ExperienceType["Sails"] = "Sails";
    ExperienceType["Geometry"] = "Geometry";
    // Consumer Goods
    // Fabric and Clothing
    ExperienceType["Weaving"] = "Weaving";
    ExperienceType["ClothProduction"] = "Cloth Production";
    ExperienceType["LeatherProduction"] = "Leather Production";
    ExperienceType["ClothingProduction"] = "Clothing Production";
    ExperienceType["Dyeing"] = "Dyeing";
    // specific
    ExperienceType["TunicProduction"] = "Tunic Production";
    // Military
    ExperienceType["Military"] = "Military";
    ExperienceType["Combat"] = "Combat";
    ExperienceType["Swordsmanship"] = "Swordsmanship";
    ExperienceType["Spearmanship"] = "Spearmanship";
    ExperienceType["AxeFighting"] = "Axe Fighting";
    ExperienceType["Formation"] = "Formation";
    ExperienceType["MountedCombat"] = "Mounted Combat";
    ExperienceType["Horsemanship"] = "Horsemanship";
})(ExperienceType || (ExperienceType = {}));
var ExperienceCurveType;
(function (ExperienceCurveType) {
    ExperienceCurveType["Formation"] = "FormationExperience";
    ExperienceCurveType["Attack"] = "AttackExperience";
    ExperienceCurveType["Defense"] = "DefenseExperience";
    ExperienceCurveType["Discipline"] = "DisciplineExperience";
    ExperienceCurveType["Shock"] = "ShockExperience";
    ExperienceCurveType["Horsemanship"] = "HorsemanshipExperience";
    ExperienceCurveType["Swordsmanship"] = "SwordsmanshipExperience";
})(ExperienceCurveType || (ExperienceCurveType = {}));
class ExperienceMethods {
    static getExperienceCurve(experienceCurveType) {
        switch (experienceCurveType) {
            case ExperienceCurveType.Formation:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Formation,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Formation,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.12,
                            sigmoidSlope: -0.03,
                            height: 100,
                            xAdj: 77,
                            yAdj: 4.4,
                        },
                    },
                });
            case ExperienceCurveType.Attack:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Attack,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Combat,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.15,
                            sigmoidSlope: -0.02,
                            height: 80,
                            xAdj: 60,
                            yAdj: 5,
                        },
                    },
                });
            case ExperienceCurveType.Defense:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Defense,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Military,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.1,
                            sigmoidSlope: -0.025,
                            height: 90,
                            xAdj: 70,
                            yAdj: 4,
                        },
                    },
                });
            case ExperienceCurveType.Discipline:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Discipline,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Military,
                            amount: 3,
                        },
                        {
                            experienceType: ExperienceType.MountainLife,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.RuggedTravel,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AlpineTravel,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.DesertLife,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.18,
                            sigmoidSlope: -0.015,
                            height: 85,
                            xAdj: 65,
                            yAdj: 4.5,
                        },
                    },
                });
            case ExperienceCurveType.Shock:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Shock,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Military,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.18,
                            sigmoidSlope: -0.015,
                            height: 85,
                            xAdj: 65,
                            yAdj: 4.5,
                        },
                    },
                });
            case ExperienceCurveType.Horsemanship:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Horsemanship,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.MountedCombat,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.Horsemanship,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.15,
                            sigmoidSlope: -0.02,
                            height: 80,
                            xAdj: 60,
                            yAdj: 5,
                        },
                    },
                });
            case ExperienceCurveType.Swordsmanship:
                return ExperienceCurveInfo.fromJSON({
                    name: ExperienceCurveType.Swordsmanship,
                    experienceInfluence: [
                        {
                            experienceType: ExperienceType.Swordsmanship,
                            amount: 2,
                        },
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                    ],
                    functionInfo: {
                        type: 'sigmoid',
                        vars: {
                            linearSlope: 0.12,
                            sigmoidSlope: -0.03,
                            height: 100,
                            xAdj: 77,
                            yAdj: 4.4,
                        },
                    },
                });
            default:
                throw new Error(`Invalid experience curve type: ${experienceCurveType}`);
        }
    }
}
export { ExperienceType, ExperienceCurveType, ExperienceMethods };
