import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const CityScreenStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .clickable-area {
    pointer-events: auto;
  }

  .city-view-holder {
    position: absolute;
    top: var(--game-ui-margin);
    left: 50%;
    transform: translateX(-50%);

    height: 15%;
    width: 100%;

    z-index: -1;

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .center-buttons {
    position: absolute;
    top: calc(15% + 1.5 * var(--game-ui-margin));
    left: 50%;
    transform: translateX(-50%);
  }

  .right-buttons {
    position: absolute;
    top: calc(15% + 1.5 * var(--game-ui-margin));
    right: calc(1.5 * var(--game-ui-margin) + 300px);
  }

  .bottom-area {
    position: relative;
    width: 100%;
    height: 70%;
  }

  .city-side-bar {
    position: absolute;
    top: 10%;
    width: 300px;
    max-height: calc(80% - 2 * var(--game-ui-margin));
  }

  .left-bar {
    left: var(--game-ui-margin);
  }

  .right-bar {
    right: var(--game-ui-margin);
  }

  .bottom-panel {
    position: relative;
    top: 79%;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid var(--lol-gold);

    height: 20%;
    width: calc(80% - 3 * var(--game-ui-margin));

    z-index: -1;

    display: flex;
    flex-direction: column;

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bottom-panel-content {
    position: relative;
    height: calc(100% - 36px);
    width: 100%;
  }

  .experience-list-container {
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: calc(100vw - 800px);

    overflow-y: auto;
  }

  .experience-list {
    width: 100%;
  }
`;
