import React from 'react';
import { EvaluationResult } from '../../../../../../../GildedLands/lib/Classes/Army/EvaluationResult.js';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import { ExperienceCurveType } from '../../../../../../../GildedLands/lib/Enums/Experience.js';
import { ExperienceMethods } from '../../../../../../../GildedLands/lib/Enums/Experience.js';
import ExperienceCurveGraphComponent from './ExperienceCurveGraph.tsx';
interface IEvaluationResult {
  evaluationResult: EvaluationResult;
}

const EvaluationResultComponent: React.FC<IEvaluationResult> = ({
  evaluationResult,
}) => {
  const { name, type, operation, value, result, inputs, extraData } =
    evaluationResult;

  let additionalContent: React.ReactNode = null;

  if (type === 'experienceCurve' && value) {
    const curve = ExperienceMethods.getExperienceCurve(
      value as ExperienceCurveType
    );

    if (curve && extraData && typeof extraData.xValue === 'number') {
      // Display breakdown
      let breakdownContent: React.ReactNode = null;
      if (extraData.breakdown && Array.isArray(extraData.breakdown)) {
        // Create a simple vertical list of the breakdown
        const breakdownItems = extraData.breakdown.map(
          (item: any, index: number) => (
            <LineItemStandard
              key={index}
              title={item.experienceType}
              inlineDataFields={[
                {
                  fieldData: {
                    fieldName: 'Exp Amount',
                    value: item.experienceAmount,
                  },
                  dropdown: null,
                },
                {
                  fieldData: { fieldName: 'Influence', value: item.influence },
                  dropdown: null,
                },
                {
                  fieldData: {
                    fieldName: 'Contribution',
                    value: item.contribution,
                  },
                  dropdown: null,
                },
              ]}
            />
          )
        );

        breakdownContent = (
          <LineItemStandard
            title='Experience Breakdown'
            dropdown={<VerticalList data={breakdownItems} />}
          />
        );
      }

      additionalContent = (
        <div>
          {breakdownContent}
          <ExperienceCurveGraphComponent
            name={name}
            curve={curve}
            xValue={extraData.xValue}
            result={result}
          />
        </div>
      );
    }
  }

  return (
    <LineItemStandard
      title={name}
      inlineDataFields={[
        {
          fieldData: {
            fieldName: 'Type',
            value: type,
          },
          dropdown: null,
        },
        {
          fieldData: {
            fieldName: '',
            value: result,
          },
          dropdown: null,
        },
      ]}
      dropdown={
        !!inputs && inputs.length > 0 ? (
          <VerticalList
            data={[
              ...inputs.map((input, index) => (
                <div className='evaluation-result-input-item' key={index}>
                  <EvaluationResultComponent evaluationResult={input} />
                  {index < inputs.length - 1 && (
                    <div
                      className='evaluation-result-connector'
                      style={{ marginLeft: '20px' }}
                    >
                      {operation === 'add'
                        ? 'PLUS'
                        : operation === 'multiply'
                        ? 'TIMES'
                        : operation === 'subtract'
                        ? 'MINUS'
                        : operation === 'divide'
                        ? 'DIVIDED BY'
                        : 'ERROR IN OPERATION'}
                    </div>
                  )}
                </div>
              )),
              additionalContent ? (
                <div key='extra'>{additionalContent}</div>
              ) : null,
            ]}
          />
        ) : (
          <div>{additionalContent}</div>
        )
      }
    />
  );
};

export default EvaluationResultComponent;
