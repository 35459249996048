import { GoodCategory, GoodType, GoodTypeMethods, } from '../../Enums/Good.js';
import CharacterTrait from '../Character/CharacterTrait.js';
import Experience from '../Experience/Experience.js';
import ConsumptionLineItem from '../EconomicData/Consumption/ConsumptionLineItem.js';
import PersonalConsumptionChart from './PersonalConsumptionChart.js';
import { TimeEnum, TimeMethods } from '../../Enums/Time.js';
class Person {
    constructor(instanceId, name, characterTraits, experiences, consumptionChart, cash, dayBorn, nutrition, lifeExpectancy, lastChildCheck) {
        // just a check for array removal
        this.dead = false;
        this.instanceId = instanceId;
        this.name = name;
        this.characterTraits = characterTraits.map((characterTrait) => new CharacterTrait(characterTrait.characterType, characterTrait.amount));
        this.experiences = experiences.map((experience) => new Experience(experience.experienceType, experience.amount));
        this.consumptionChart = consumptionChart;
        this.cash = cash;
        this.dayBorn = dayBorn;
        this.nutrition = nutrition;
        this.lifeExpectancy = lifeExpectancy;
        this.canCheckForChildDate = lastChildCheck;
    }
    // #region Utilities
    static getNewPerson(gameState, name, age, parent = null) {
        let characterFromParent = [];
        if (parent) {
            characterFromParent = parent.characterTraits.map((trait) => {
                const newTrait = trait.clone();
                newTrait.amount = Math.floor(newTrait.amount / 2);
                return newTrait;
            });
        }
        let experiencesFromParent = [];
        if (parent) {
            experiencesFromParent = parent.experiences.map((experience) => {
                const newExperience = experience.clone();
                newExperience.amount = Math.floor(newExperience.amount / 3);
                return newExperience;
            });
        }
        let health = 50;
        if (parent) {
            health = parent.lifeExpectancy;
        }
        console.log('new person with health ', health);
        return new Person(gameState.currentEntityInstanceId++, name, characterFromParent, experiencesFromParent, PersonalConsumptionChart.getEmptyChart(), 0, // cash
        gameState.days -
            age * TimeEnum.Year +
            Math.floor(Math.random() * 12 * TimeEnum.Month), health, health, gameState.days);
    }
    // #endregion
    // #region Consumption
    consume(personCityInstanceId, personProductionValue, gameState) {
        const personCity = gameState.getCityByInstanceId(personCityInstanceId);
        if (!personCity) {
            throw new Error('Person city not found');
        }
        const availableGoods = gameState.goodsData.goods.filter((good) => good.productionPossible &&
            // trade route exists
            (good.cityId === personCity.instanceId ||
                !!gameState.routeTracker.optimalCityToCityPaths[`${good.cityId}-${personCity.instanceId}`]));
        // this is now done in the main loop so that we don't have old artifacts
        // this.consumptionChart = PersonalConsumptionChart.getEmptyChart();
        this.cash += personProductionValue;
        while (true) {
            let bestValue = 0;
            let bestGood = null;
            // iterate through all goods and calculate quality of life per unit price
            for (const good of availableGoods) {
                if (!good.productionPossible) {
                    throw new Error('Good is not production possible - this shouldnt happen, we should have filtered already');
                }
                const qol = GoodTypeMethods.calculateQualityOfLife(good.goodType, good.quality, this, this.consumptionChart);
                // here we need to find the consumer price based on policies and trade costs
                // add consumption subsidy, consumption tax, export tariff, import tariff and then
                // all trade route effects - cost, transit duty, export duty, import duty
                // consumption subsidy
                const goodCity = gameState.getCityByInstanceId(good.cityId);
                if (!goodCity) {
                    throw new Error('Good city not found');
                }
                let shippingAndTaxes = gameState.getShippingAndTaxesForGood(good.goodType, goodCity, good.quality, good.marketPrice, this, personCity);
                let finalCost = good.marketPrice +
                    shippingAndTaxes.totalTaxAmount +
                    shippingAndTaxes.totalShippingCost;
                const qolPerPrice = qol.qol / finalCost;
                // Check if this good has the best value so far
                if (qolPerPrice > bestValue && finalCost <= this.cash) {
                    bestValue = qolPerPrice;
                    bestGood = new ConsumptionLineItem(good.goodType, {
                        instanceId: goodCity.instanceId,
                        name: goodCity.name,
                    }, GoodTypeMethods.getGoodInfo(good.goodType).category, GoodTypeMethods.getGoodInfo(good.goodType).subCategory, good.quality, qol, finalCost, shippingAndTaxes);
                }
            }
            // also check potential upgrades for the best good
            const bestUpgrade = this.consumptionChart.getBestUpgrade();
            if (!!bestUpgrade &&
                bestUpgrade.qolPerUpgradePrice > bestValue &&
                bestUpgrade.good.cost <= this.cash) {
                console.log('----------------------------------------');
                console.log('----------------------------------------');
                console.log('----------------------------------------');
                console.log('----------------------------------------');
                console.log('WE ARE UPGRADING A GOOD');
                console.log('upgraded ', GoodType[bestGood.goodType], 'with quality ', bestGood.quality, 'from ', bestGood.cityInfo.name, 'to ', GoodType[bestUpgrade.goodType], 'with quality ', bestUpgrade.quality, 'from ', bestUpgrade.good.cityInfo.name);
                this.consumptionChart.upgradeGood(bestUpgrade, this, personCity, gameState);
                continue;
            }
            // Purchase the best good if found
            if (bestGood && bestGood.cost <= this.cash) {
                this.cash -= bestGood.cost;
                this.consumptionChart.addConsumption(bestGood.goodType, bestGood.cityInfo, GoodTypeMethods.getGoodInfo(bestGood.goodType).category, GoodTypeMethods.getGoodInfo(bestGood.goodType).subCategory, bestGood.quality, bestGood.qualityOfLife, bestGood.cost, this, personCity, bestGood.shippingAndTaxes, gameState);
            }
            else {
                break; // Exit if no affordable good is found
            }
        }
        // add final consumption
        for (const good of this.consumptionChart.goods) {
            gameState.goodsData.addConsumption(good.clone());
        }
    }
    // #endregion
    // #region Death
    // old death system
    checkForDeath(dateInDays) {
        const ageInYears = TimeMethods.getAgeInYearsFromBirthday(this.dayBorn, dateInDays);
        if (ageInYears > this.lifeExpectancy) {
            return true;
        }
        return false;
    }
    static calculateCycleNutrition(foodAmount) {
        return 100 * (1 - Math.exp(-0.03 * foodAmount));
    }
    updateHealthFromFood() {
        const foodQoL = this.consumptionChart.goods
            .filter((good) => good.category === GoodCategory.Food)
            .reduce((acc, good) => acc + good.qualityOfLife.qol, 0);
        const cycleNutitrion = Person.calculateCycleNutrition(foodQoL);
        this.nutrition = cycleNutitrion;
        const targetLifeExpectancy = 18 + (cycleNutitrion / 100) * 32;
        const diff = targetLifeExpectancy - this.lifeExpectancy;
        this.lifeExpectancy = this.lifeExpectancy + diff / 10;
    }
    // #region old death system
    oldCheckForDeath(dateInDays) {
        const random = Math.random();
        const ageInYears = TimeMethods.getAgeInYearsFromBirthday(this.dayBorn, dateInDays);
        const chanceOfDeath = Person.chanceOfDeath(this.lifeExpectancy, ageInYears);
        // console.log('BIG TEST');
        // for (let testHealth = 0; testHealth < 100; testHealth += 10) {
        //   for (let testAge = 0; testAge < 100; testAge += 10) {
        //     const chanceOfDeath = Person.chanceOfDeath(testHealth, testAge);
        //     console.log(
        //       `testHealth: ${testHealth}, testAge: ${testAge}, chanceOfDeath: ${chanceOfDeath}`
        //     );
        //   }
        // }
        if (random < chanceOfDeath) {
            console.log(`DEATH ALERT: Person with health ${this.lifeExpectancy} and age ${ageInYears} died`);
            return true;
        }
        return false;
    }
    static ageFactor(ageInYears) {
        const a = 0.1;
        const b = 0.75;
        const c = 0.197;
        const d = 50;
        return c - b / (1 + Math.exp(a * (ageInYears - d))) + b;
    }
    static healthFactor(health) {
        const l = 0.01;
        const r = 2;
        const q = 0;
        const w = 0.05;
        return l - r / (1 + Math.exp(w * (health - q)));
    }
    static chanceOfDeath(health, ageInYears) {
        const ageFactor = 0.046 - 0.91 / (1 + Math.exp(0.1 * (ageInYears - 50))) + 0.91;
        const healthFactor = 0.01 + 2 / (1 + Math.exp(0.05 * (health - 0)));
        const chance = ageFactor * healthFactor;
        return chance;
    }
    // #endregion
    // #endregion
    // #region Childbirth
    // THIS IS NOT CURRENTLY USED
    // checkForChildbirth(city: City, gameState: GameState) {
    //   const ageInDays = gameState.days - this.dayBorn;
    //   const ageInYears = ageInDays / TimeEnum.Year;
    //   if (ageInYears < 18) {
    //     return;
    //   }
    //   if (gameState.days < this.canCheckForChildDate) {
    //     return;
    //   }
    //   const targetPopulation = city.getTargetPopulation(gameState);
    //   const currentPopulation = city.persons.length;
    //   const random = Math.random();
    //   const check = Math.max(0, 1 - currentPopulation / targetPopulation);
    //   const havingChild = random < check;
    //   if (havingChild) {
    //     this.canCheckForChildDate = gameState.days + 12 * TimeEnum.Month;
    //     return true;
    //   }
    //   this.canCheckForChildDate = gameState.days + 3 * TimeEnum.Month;
    //   return false;
    // }
    // #endregion
    // #region Experience
    getExperienceAmount(experienceType) {
        var _a;
        return (((_a = this.experiences.find((experience) => experience.experienceType === experienceType)) === null || _a === void 0 ? void 0 : _a.amount) || 0);
    }
    addExperience(experienceType, amount) {
        const experience = this.experiences.find((experience) => experience.experienceType === experienceType);
        if (experience) {
            experience.amount += amount;
        }
        else {
            this.experiences.push(new Experience(experienceType, amount));
        }
    }
    // #endregion
    // #region Character
    getCharacterAmount(characterType) {
        var _a;
        return (((_a = this.characterTraits.find((characterTrait) => characterTrait.characterType === characterType)) === null || _a === void 0 ? void 0 : _a.amount) || 0);
    }
    addCharacter(characterType, amount) {
        const characterTrait = this.characterTraits.find((characterTrait) => characterTrait.characterType === characterType);
        if (characterTrait) {
            characterTrait.amount += amount;
        }
    }
    // #endregion
    // #region JSON and Clone
    clone() {
        return new Person(this.instanceId, this.name, this.characterTraits.map((characterTrait) => characterTrait.clone()), this.experiences.map((experience) => experience.clone()), this.consumptionChart.clone(), this.cash, this.dayBorn, this.nutrition, this.lifeExpectancy, this.canCheckForChildDate);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            name: this.name,
            characterTraits: this.characterTraits.map((characterTrait) => characterTrait.toJSON()),
            experiences: this.experiences.map((experience) => experience.toJSON()),
            consumptionChart: this.consumptionChart.toJSON(),
            cash: this.cash,
            dayBorn: this.dayBorn,
            targetHealth: this.nutrition,
            health: this.lifeExpectancy,
            lastChildCheck: this.canCheckForChildDate,
        };
    }
    static fromJSON(json) {
        return new Person(json.instanceId, json.name, json.characterTraits.map((characterTrait) => CharacterTrait.fromJSON(characterTrait)), json.experiences.map((experience) => Experience.fromJSON(experience)), 
        // PersonalConsumptionChart.getEmptyChart()
        PersonalConsumptionChart.fromJSON(json.consumptionChart), json.cash, json.dayBorn, json.targetHealth, json.health, json.lastChildCheck);
    }
}
export default Person;
