// external
import React, { useState, useEffect } from 'react';

enum ActionMode {
  Default,
  BuildCity,
  PickingTileForImprovement,
  PickingGoodTypeForImprovement,
  PickingTileForUnitMovement,
}

enum ScreenMode {
  NotInGame,
  Map,
  CityScreen,
  EconomyScreen,
  TaxesScreen,
  AdministrationScreen,
}

const ScreenModeContextProvider = ({ children }) => {
  // state
  const [screenMode, setScreenMode] = useState<ScreenMode>(ScreenMode.Map);
  const [actionMode, setActionMode] = useState<ActionMode>(ActionMode.Default);

  const value: ScreenModeContextType = {
    ScreenMode,
    ActionMode,
    screenMode,
    setScreenMode,
    actionMode,
    setActionMode,
  };

  return (
    <ScreenModeContext.Provider value={value}>
      {children}
    </ScreenModeContext.Provider>
  );
};

interface ScreenModeContextType {
  ScreenMode: typeof ScreenMode;
  ActionMode: typeof ActionMode;
  screenMode: ScreenMode;
  setScreenMode: (screenMode: ScreenMode) => void;
  actionMode: ActionMode;
  setActionMode: (actionMode: ActionMode) => void;
}

const ScreenModeContext = React.createContext<ScreenModeContextType | null>(
  null
);

export { ScreenModeContextProvider, ScreenModeContext };
