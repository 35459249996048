import styled, { keyframes, css } from 'styled-components';
import '../../../../../styles/constants.css';

const MapCityStyled = styled.div.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: none;
  z-index: 100;
  position: relative;
`;

const MapCityLabelStyled = styled.div.attrs(({ hexagonSize }) => ({
  style: {
    fontSize: `${hexagonSize * 0.3}px`,
  },
}))`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-green);
  color: white;
  font-family: var(--header-font);
  border: 1px solid var(--lol-gold);
  padding: 1px 3px;
  z-index: 11;
  white-space: nowrap;
  max-width: none;

  .city-name {
    font-size: 0.8em;
  }

  .city-population {
    font-size: 0.6em;
    margin-top: 2px;
  }
`;

const MapCityShapeStyled = styled.svg.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  position: absolute;
  fill: none;
  stroke: var(--lol-gold);
  stroke-width: 2;
  z-index: 10;
  left: 0;
  top: 0;
  cursor: pointer;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// const MapCityPulseDiamondStyled = styled.svg.attrs(({ hexagonSize }) => ({
//   style: {
//     width: `${5 * hexagonSize * Math.sqrt(3)}px`,
//     height: `${5 * hexagonSize * 2}px`,
//   },
// }))`
//   position: absolute;
//   z-index: -1;
//   left: 0;
//   top: 0;

//   polygon {
//     fill: url(#fade-gradient);
//     transform-origin: center;
//     animation: ${pulse} 3s infinite;
//   }
// `;

const MapCityImageStyled = styled.img.attrs(({ hexagonSize, points }) => ({
  style: {
    clipPath: `polygon(${points})`,
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  object-fit: cover;
  object-position: center;
  z-index: 9;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export {
  MapCityStyled,
  MapCityShapeStyled,
  MapCityImageStyled,
  MapCityLabelStyled,
  // MapCityPulseDiamondStyled,
};
