import React, { useState } from 'react';
import RealizedTaxItem from '../../../../../../../../GildedLands/lib/Classes/EconomicData/TaxTracking/TaxItem.js';
import { TaxType } from '../../../../../../../../GildedLands/lib/Enums/Tax.js';
import { TaxTypeSummaryStyled } from './styles/TaxTypeSummary.styled.js';

interface TaxTypeSummaryProps {
  taxType: string;
  taxItems: RealizedTaxItem[];
}

const TaxTypeSummary: React.FC<TaxTypeSummaryProps> = ({
  taxType,
  taxItems,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedPolicy, setExpandedPolicy] = useState<number | null>(null);

  const totalIncome = taxItems.reduce(
    (sum, tax) => sum + tax.incomeToGovernment,
    0
  );

  const groupedByPolicy = taxItems.reduce((acc, tax) => {
    if (!acc[tax.policyInstanceId]) {
      acc[tax.policyInstanceId] = [];
    }
    acc[tax.policyInstanceId].push(tax);
    return acc;
  }, {} as { [key: number]: RealizedTaxItem[] });

  return (
    <TaxTypeSummaryStyled>
      <div
        className='tax-type-title'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {TaxType[taxType]}: {totalIncome.toFixed(2)}
      </div>
      {isExpanded && (
        <div className='policy-summary'>
          {Object.entries(groupedByPolicy).map(([policyId, items]) => (
            <div key={policyId}>
              <div
                className='policy-details'
                onClick={() =>
                  setExpandedPolicy(
                    expandedPolicy === Number(policyId)
                      ? null
                      : Number(policyId)
                  )
                }
              >
                Policy Instance {policyId}:{' '}
                {items
                  .reduce((sum, item) => sum + item.incomeToGovernment, 0)
                  .toFixed(2)}
              </div>
              {expandedPolicy === Number(policyId) && (
                <div className='line-item'>
                  {items.map((item, index) => (
                    <div key={index}>Line Item: {item.incomeToGovernment}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </TaxTypeSummaryStyled>
  );
};

export default TaxTypeSummary;
