class Experience {
    constructor(experienceType, amount) {
        this.experienceType = experienceType;
        this.amount = amount;
    }
    clone() {
        const newEx = new Experience(this.experienceType, this.amount);
        return newEx;
    }
    toJSON() {
        return {
            experienceType: this.experienceType,
            amount: this.amount,
        };
    }
    static fromJSON(json) {
        return new Experience(json.experienceType, json.amount);
    }
}
export default Experience;
