// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

// internal context
import { SelectedEntityContext } from '../../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import { CivilianUnitInfoStyled } from './styles/CivilianUnitInfo.styled.js';

// gl
import {
  BiomeType,
  BiomeTypeMethods,
} from '../../../../../../../../GildedLands/lib/Enums/Biome.js';
import { GoodType } from '../../../../../../../../GildedLands/lib/Enums/Good.js';
import MilitaryUnit from '../../../../../../../../GildedLands/lib/Classes/Unit/MilitaryUnit';

const MilitaryUnitInfo = ({ selectedUnit }: { selectedUnit: MilitaryUnit }) => {
  if (!selectedUnit) {
    return null;
  }

  return (
    <CivilianUnitInfoStyled className='unit-info'>
      <div className='biome-type-title'>{selectedUnit.name}</div>
    </CivilianUnitInfoStyled>
  );
};

export default MilitaryUnitInfo;
