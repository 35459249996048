import { GoodTypeMethods, } from '../../Enums/Good.js';
import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class ConsumptionSubsidy extends Tax {
    constructor(instanceId, ownerNationId, subsidyAmountPerGood, isOnAllGoods, excludedCategories, excludedSubCategories, excludedGoods, includedCategories, includedSubCategories, includedGoods, isOnAllNations, excludedNationIds, includedNationIds) {
        super(instanceId, ownerNationId, TaxType.ConsumptionSubsidy);
        this.subsidyAmountPerGood = subsidyAmountPerGood;
        this.subsidyAmountPerGood = subsidyAmountPerGood;
        this.isOnAllGoods = isOnAllGoods;
        this.excludedCategories = excludedCategories;
        this.excludedSubCategories = excludedSubCategories;
        this.excludedGoods = excludedGoods;
        this.includedCategories = includedCategories;
        this.includedSubCategories = includedSubCategories;
        this.includedGoods = includedGoods;
        this.isOnAllNations = isOnAllNations;
        this.excludedNationIds = excludedNationIds;
        this.includedNationIds = includedNationIds;
    }
    getCost(goodType, originNationId, cost, gameState) {
        if (this.isOnAllNations) {
            if (this.excludedNationIds.includes(originNationId)) {
                return 0;
            }
        }
        else {
            if (!this.includedNationIds.includes(originNationId)) {
                return 0;
            }
        }
        if (this.includedGoods.includes(goodType)) {
            return this.subsidyAmountPerGood;
        }
        if (this.excludedGoods.includes(goodType)) {
            return 0;
        }
        if (this.includedSubCategories.includes(GoodTypeMethods.getGoodInfo(goodType).subCategory)) {
            return this.subsidyAmountPerGood;
        }
        if (this.excludedSubCategories.includes(GoodTypeMethods.getGoodInfo(goodType).subCategory)) {
            return 0;
        }
        if (this.includedCategories.includes(GoodTypeMethods.getGoodInfo(goodType).category)) {
            return this.subsidyAmountPerGood;
        }
        if (this.excludedCategories.includes(GoodTypeMethods.getGoodInfo(goodType).category)) {
            return 0;
        }
        if (this.isOnAllGoods) {
            return this.subsidyAmountPerGood;
        }
        return 0;
    }
    clone() {
        return new ConsumptionSubsidy(this.instanceId, this.ownerNationId, this.subsidyAmountPerGood, this.isOnAllGoods, [...this.excludedCategories], [...this.excludedSubCategories], [...this.excludedGoods], [...this.includedCategories], [...this.includedSubCategories], [...this.includedGoods], this.isOnAllNations, [...this.excludedNationIds], [...this.includedNationIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            subsidyAmountPerGood: this.subsidyAmountPerGood,
            isOnAllGoods: this.isOnAllGoods,
            excludedCategories: [...this.excludedCategories],
            excludedSubCategories: [...this.excludedSubCategories],
            excludedGoods: [...this.excludedGoods],
            includedCategories: [...this.includedCategories],
            includedSubCategories: [...this.includedSubCategories],
            includedGoods: [...this.includedGoods],
            isOnAllNations: this.isOnAllNations,
            excludedNationIds: [...this.excludedNationIds],
            includedNationIds: [...this.includedNationIds],
        };
    }
    static fromJSON(json) {
        return new ConsumptionSubsidy(json.instanceId, json.ownerNationId, json.subsidyAmountPerGood, json.isOnAllGoods, [...json.excludedCategories], [...json.excludedSubCategories], [...json.excludedGoods], [...json.includedCategories], [...json.includedSubCategories], [...json.includedGoods], json.isOnAllNations, [...json.excludedNationIds], [...json.includedNationIds]);
    }
}
export default ConsumptionSubsidy;
