import Experience from '../Experience/Experience.js';
class Eunuch {
    constructor(experience) {
        this.experience = experience;
    }
    addExperience(experience) {
        let currentExp = this.experience.find((exp) => exp.experienceType === experience.experienceType);
        if (currentExp) {
            currentExp.amount += experience.amount;
        }
        else {
            this.experience.push(experience);
        }
    }
    clone() {
        return new Eunuch(this.experience.map((exp) => exp.clone()));
    }
    toJSON() {
        return this.experience.map((exp) => exp.toJSON());
    }
    static fromJSON(json) {
        return new Eunuch(json.map((exp) => Experience.fromJSON(exp)));
    }
}
export default Eunuch;
