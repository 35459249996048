// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import getPersonData from '../../../../UI/MappingToData/Person/Person.tsx';

// internal context
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { PeopleReportStyled } from './styles/PeopleReport.styled.js';

// lol
import Person from '../../../../../../../../GildedLands/lib/Classes/Person/Person';
import City from '../../../../../../../../GildedLands/lib/Classes/City/City';
import { GoodType } from '../../../../../../../../GildedLands/lib/Enums/Good';
import GoodsData from '../../../../../../../../GildedLands/lib/Classes/EconomicData/PriceAndProduction/GoodsData';
import Background from '../../../../UI/GenericComponents/Background.tsx';
import { UIColor, UIOpacity } from '../../../../UI/Enums/UIEnums.ts';

interface PeopleReportProps {
  selectedCity: { name: string; instanceId: number };
}

const PeopleReport = ({ selectedCity }: PeopleReportProps) => {
  const { gameState } = useContext(GameStateDataContext);
  // #endregion

  if (!selectedCity) {
    return <div>No city selected</div>;
  }

  return (
    <PeopleReportStyled className='people-report'>
      <Background color={UIColor.LightBackground} opacity={0.9} />
      <div className='people-report-title'>
        <p>{`${selectedCity?.name} Population Report`}</p>
      </div>
      {!!selectedCity &&
        gameState.goodsData.productionByPerson
          .filter((item) => {
            return item.cityId === selectedCity.instanceId;
          })
          .map((production) => {
            return (
              <div key={production.person.instanceId}>
                {getPersonData(production, gameState)}
              </div>
            );
          })}
    </PeopleReportStyled>
  );
};

export default PeopleReport;
