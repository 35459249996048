import Experience from '../Classes/Experience/Experience.js';
import { ExperienceType } from './Experience.js';
import { CityExperienceRequirement, RequirementBox, RequirementBoxTypeEnum, RequirementTypeMethods, TileIsCoastRequirement, } from './Requirement.js';
import { TimeEnum } from './Time.js';
class MissionMethods {
    static getMissionLength(city, gameState) {
        let lengthInDays = TimeEnum.Year;
        const factors = [
            {
                title: 'Base Mission Length',
                description: 'The base length of a mission in days',
                daysAdded: TimeEnum.Year,
            },
        ];
        const weavingExperience = city.getExperienceAmount(ExperienceType.FiberProcessing);
        lengthInDays += Math.floor(weavingExperience / 100) * TimeEnum.Month * 2;
        if (weavingExperience > 100) {
            factors.push({
                title: 'Transport Baskets',
                description: 'Weaving experience extends the life of a mission by 2 months for each 100 weaving experience',
                daysAdded: TimeEnum.Month * 2,
            });
        }
        return {
            lengthInDays,
            factors,
        };
    }
    static getTotalCost(city, gameState, hasMountainTraining, hasShips, isCityFounder) {
        let cost = this.getBaseCost(city, gameState);
        if (hasMountainTraining) {
            cost += this.getMountainTrainingCost(city, gameState);
        }
        if (hasShips) {
            cost += this.getShipsCost(city, gameState);
        }
        if (isCityFounder) {
            cost += this.getFounderCost(city, gameState);
        }
        return cost;
    }
    static getBaseCost(city, gameState) {
        const cheapestFoodPrice = city.getPriceOfFood(gameState);
        const medianIncome = city.getMedianIncome();
        return ((TimeEnum.Month / gameState.economicCycleInterval) *
            (cheapestFoodPrice + medianIncome));
    }
    static getFounderCost(city, gameState) {
        const cheapestFoodPrice = city.getPriceOfFood(gameState);
        const medianIncome = city.getMedianIncome();
        return (3 * (TimeEnum.Year / gameState.economicCycleInterval) * cheapestFoodPrice);
    }
    static getMountainTrainingCost(city, gameState) {
        const cheapestFoodPrice = city.getPriceOfFood(gameState);
        const medianIncome = city.getMedianIncome();
        return (2 * (TimeEnum.Month / gameState.economicCycleInterval) * medianIncome);
    }
    static getMountainTrainingReturn(city, gameState) {
        return new Experience(ExperienceType.MountainLife, 100 + city.getExperienceAmount(ExperienceType.MountainLife));
    }
    static canBuildShips(city, gameState) {
        // requirements
        const isCoast = new TileIsCoastRequirement();
        const cityHasExperience = new CityExperienceRequirement(ExperienceType.Seafaring, 100);
        const requirementBox = new RequirementBox(RequirementBoxTypeEnum.All, [
            isCoast,
            cityHasExperience,
        ]);
        // check requirements
        const cityCenterTile = gameState.getLandTileByTileId(city.centerTileLandtileId);
        return RequirementTypeMethods.checkRequirement(requirementBox, null, cityCenterTile, gameState);
    }
    static getShipsCost(city, gameState) {
        const medianIncome = city.getMedianIncome();
        return medianIncome * 2 * (TimeEnum.Year / gameState.economicCycleInterval);
    }
}
export { MissionMethods };
