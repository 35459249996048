import NationalAdministration from '../Administration/NationalAdministration.js';
class Nation {
    constructor(id, name, controllerUserId, seaMerchantTaxRate, records, capitalCityId, administration, discoveredTileIds) {
        this.id = id;
        this.name = name;
        this.controllerUserId = controllerUserId;
        this.seaMerchantTaxRate = seaMerchantTaxRate;
        this.records = records;
        this.capitalCityId = capitalCityId;
        this.administration = administration;
        this.discoveredTileIds = discoveredTileIds;
    }
    // #region Utilities
    getDepartmentByCityAndDepartmentId(cityId, departmentId, gameState) {
        if (!!cityId) {
            const city = gameState.getCityByInstanceId(cityId);
            if (!city)
                throw new Error('City not found in department getter');
            return city.governor.governorsOffice.getDepartmentById(departmentId);
        }
        else {
            return this.administration.palace.getDepartmentById(departmentId);
        }
    }
    // #endregion Utilities
    // #region Clone and JSON
    clone() {
        return new Nation(this.id, this.name, this.controllerUserId, this.seaMerchantTaxRate, [...this.records], this.capitalCityId, this.administration.clone(), [...this.discoveredTileIds]);
    }
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            controllerUserId: this.controllerUserId,
            seaMerchantTaxRate: this.seaMerchantTaxRate,
            records: [...this.records],
            capitalCityId: this.capitalCityId,
            administration: this.administration.toJSON(),
            discoveredTileIds: [...this.discoveredTileIds],
        };
    }
    static fromJSON(json) {
        return new Nation(json.id, json.name, json.controllerUserId, json.seaMerchantTaxRate, [...json.records], json.capitalCityId ? json.capitalCityId : null, NationalAdministration.fromJSON(json.administration), [...json.discoveredTileIds]);
    }
}
export default Nation;
