import styled from 'styled-components';
import '../../../../styles/constants.css';

export const BattleSimulationPageStyled = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  .content-container {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 10;
    background-color: black;
  }

  .tile-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .top-panel-holder {
    position: absolute;
    top: var(--game-ui-margin);
    left: 50%;
    transform: translateX(-50%);

    height: 15%;
    width: 100%;

    z-index: -1;

    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .control-panel {
    position: absolute;
    bottom: 5px;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Optional: Add a background for better visibility */
  }

  .control-panel-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Important for allowing children to shrink */
  }

  .turn-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    padding: 10px 0;
  }

  .turn-label {
    margin: 0 15px;
    font-size: 1.2em;
    color: var(--lol-gold);
  }

  .history-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .history-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px; /* Optional: Space for scrollbar */

    /* Optional: Customize scrollbar */
    scrollbar-width: thin; /* For Firefox */
  }

  /* Webkit scrollbar styling */
  .history-list::-webkit-scrollbar {
    width: 8px;
  }

  .history-list::-webkit-scrollbar-thumb {
    background-color: var(--lol-gold);
    border-radius: 4px;
  }

  .army-panel {
    position: absolute;
    top: 10%;
    max-height: 80%;
  }

  .side-a {
    left: 5px;
  }

  .side-b {
    right: 5px;
  }
`;
