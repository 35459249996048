import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const NotificationFlagStyled = styled.div`
  cursor: pointer;
  pointer-events: auto;
  height: 100px;
  border-radius: var(--ui-border-radius);
  width: 100px;
  border: 1px solid white;
  overflow: hidden;

  // flex stuff
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  margin-left: 5px;
  flex-shrink: 0;

  .center-title.red {
    background-color: var(--lol-red);
  }

  .flag-description {
    padding: 5px;
    font-size: 12px;
    font-family: var(--body-font);
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
