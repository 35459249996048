// external
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { UIColor } from '../../components/UI/Enums/UIEnums';

const NotificationFlagsDataContextProvider = ({ children }) => {
  // notification id
  const currentNotificationIdRef = useRef(0);
  const getNewNotificationId = () => {
    currentNotificationIdRef.current += 1;
    return currentNotificationIdRef.current;
  };

  // notifications
  const [notifications, setNotifications] = useState<
    {
      id: number;
      title: string;
      color: UIColor;
      description: string;
      callback: () => void;
    }[]
  >([]);

  const addNotification = (notification: {
    title: string;
    color: UIColor;
    description: string;
    callback: () => void;
  }) => {
    setNotifications((prev) => {
      const newNotificationId = getNewNotificationId();
      if (clearedNotifications.includes(newNotificationId)) {
        return prev;
      }
      return [{ ...notification, id: newNotificationId }, ...prev];
    });
  };

  const removeNotification = (id: number) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
    setClearedNotifications((prev) => {
      const updatedCleared = [...prev, id];
      localStorage.setItem(
        'clearedNotifications',
        JSON.stringify(updatedCleared)
      );
      return updatedCleared;
    });
  };

  // cleared notifications
  const [clearedNotifications, setClearedNotifications] = useState<number[]>(
    () => {
      const savedCleared = localStorage.getItem('clearedNotifications');
      return savedCleared ? JSON.parse(savedCleared) : [];
    }
  );

  // event tracker
  const [eventTracker, setEventTrackerState] = useState(() => {
    const savedEventTracker = localStorage.getItem('eventTracker');
    return savedEventTracker
      ? JSON.parse(savedEventTracker)
      : { gameStarted: false, playerCreatedTax: false };
  });

  const setEventTracker = (update) => {
    setEventTrackerState((prev) => {
      const newEventTracker =
        typeof update === 'function' ? update(prev) : update;
      localStorage.setItem('eventTracker', JSON.stringify(newEventTracker));
      return newEventTracker;
    });
  };

  const resetEventsAndNotifications = useCallback(() => {
    setClearedNotifications([]);
    localStorage.removeItem('clearedNotifications');
    currentNotificationIdRef.current = 0;
    setEventTrackerState({ gameStarted: false, playerCreatedTax: false });
    setNotifications([]);
  }, [
    setClearedNotifications,
    setEventTrackerState,
    setNotifications,
    currentNotificationIdRef,
  ]);

  // end

  const value: NotificationFlagsDataContextType = {
    notifications,
    addNotification,
    removeNotification,
    eventTracker,
    setEventTracker,
    resetEventsAndNotifications,
  };

  return (
    <NotificationFlagsDataContext.Provider value={value}>
      {children}
    </NotificationFlagsDataContext.Provider>
  );
};

interface NotificationFlagsDataContextType {
  notifications: {
    id: number;
    title: string;
    color: UIColor;
    description: string;
    callback: () => void;
  }[];
  addNotification: (notification: {
    title: string;
    color: UIColor;
    description: string;
    callback: () => void;
  }) => void;
  removeNotification: (id: number) => void;
  eventTracker: {
    gameStarted: boolean;
    playerCreatedTax: boolean;
  };
  setEventTracker: (update: any) => void;
  resetEventsAndNotifications: () => void;
}

const NotificationFlagsDataContext =
  React.createContext<NotificationFlagsDataContextType | null>(null);

export { NotificationFlagsDataContextProvider, NotificationFlagsDataContext };
