import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const NationalAdministrationReportStyled = styled.div`
  height: 100%;
  box-sizing: border-box;

  .main-admin-content {
    height: 100px;
    box-sizing: border-box;
  }
  .departments {
    height: calc(100% - 100px);
    box-sizing: border-box;
  }
  .departments-header {
    height: 20px;
    box-sizing: border-box;
  }
  .palace {
    overflow-y: auto;
    height: calc(100% - 20px);
    box-sizing: border-box;
  }
`;
