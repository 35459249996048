import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const ResponsibilityItemStyled = styled.div`
  min-height: 20px;

  .responsibility-name {
    display: inline-block;
    font-size: 16px;
    color: white;
    cursor: pointer;
    :hover {
      font-weight: bold;
    }
  }

  .delete-responsibility-button {
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
  }
`;
