// external
import React, { useState } from 'react';

// lol
import { GamePlayState } from '../../../../../GildedLands/lib/Enums/GamePlayState';

const GameStatusContextProvider = ({ children }) => {
  const [lastCycleRunTime, setLastCycleRunTime] = useState<number>(0);

  const value = {
    lastCycleRunTime,
    setLastCycleRunTime,
  };

  return (
    <GameStatusContext.Provider value={value}>
      {children}
    </GameStatusContext.Provider>
  );
};

interface GameStatusContextType {
  lastCycleRunTime: number;
  setLastCycleRunTime: (time: number) => void;
}

const GameStatusContext = React.createContext<GameStatusContextType | null>(
  null
);

export { GameStatusContext, GameStatusContextProvider };
