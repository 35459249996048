import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const BuildImprovementListStyled = styled.div`
  position: relative;

  .filter-buttons {
    position: relative;
    padding: 5px;

    .selection-button {
      margin: 0 5px;
    }
  }
`;
