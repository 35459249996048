// react
import React, { useState, useEffect } from 'react';

// css
import { RangeSliderStyled } from './styles/RangeSlider.styled.js';

interface IRangeSlider {
  title?: string;
  titlePosition?: 'above' | 'left';
  min: number;
  max: number;
  step?: number;
  startingValue: number;
  onValueChange: (value: number) => void;
  onMinChange?: (value: number) => void;
  onMaxChange?: (value: number) => void;
}

const RangeSlider: React.FC<IRangeSlider> = ({
  title = 'test title',
  titlePosition = 'left',
  min,
  max,
  step = 0.1,
  startingValue,
  onValueChange,
  onMinChange,
  onMaxChange,
}) => {
  const [value, setValue] = useState(startingValue);
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [isEditingMin, setIsEditingMin] = useState(false);
  const [isEditingMax, setIsEditingMax] = useState(false);

  useEffect(() => {
    setValue((prevValue) => Math.min(Math.max(prevValue, minValue), maxValue)); // Clamp value
  }, [minValue, maxValue]);

  useEffect(() => {
    setValue(startingValue); // Update state if startingValue changes
  }, [startingValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
    onValueChange(newValue); // Call the callback with the new value
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMin = Number(e.target.value);
    setMinValue(newMin);
    if (value < newMin) {
      setValue(newMin); // Clamp value if it goes below new min
      onValueChange(newMin);
    }
    onMinChange?.(newMin);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMax = Number(e.target.value);
    setMaxValue(newMax);
    if (value > newMax) {
      setValue(newMax);
      onValueChange(newMax);
    }
    onMaxChange?.(newMax);
  };

  const currentValuePosition =
    ((value - minValue) / (maxValue - minValue)) * 100;

  const mid = (minValue + maxValue) / 2;
  const diff = value - mid;
  const adjustedPosition =
    currentValuePosition - (diff * 1.3) / (maxValue - minValue);

  return (
    <RangeSliderStyled className='range-slider' value={value}>
      {title && titlePosition === 'above' && (
        <div className='above-title'>{title}</div>
      )}
      <div className='slider-title-containter'>
        {title && titlePosition === 'left' && (
          <div className='left-title'>{title}</div>
        )}
        <div className='slider-container'>
          <input
            type='range'
            min={minValue}
            max={maxValue}
            value={value}
            onChange={handleChange}
            step={step}
          />
          <div
            className='current-value'
            style={{ left: `${adjustedPosition}%` }}
          >
            {value}
          </div>
          <div className='labels'>
            {isEditingMin ? (
              <input
                type='number'
                value={minValue}
                onChange={handleMinChange}
                onBlur={() => setIsEditingMin(false)}
                onKeyDown={(e) => e.key === 'Enter' && setIsEditingMin(false)}
              />
            ) : (
              <span
                className='slider-label'
                onClick={() => setIsEditingMin(true)}
              >
                {minValue}
              </span>
            )}
            {isEditingMax ? (
              <input
                type='number'
                value={maxValue}
                onChange={handleMaxChange}
                onBlur={() => setIsEditingMax(false)}
                onKeyDown={(e) => e.key === 'Enter' && setIsEditingMax(false)}
              />
            ) : (
              <span
                className='slider-label'
                onClick={() => setIsEditingMax(true)}
              >
                {maxValue}
              </span>
            )}
          </div>
        </div>
      </div>
    </RangeSliderStyled>
  );
};

export default RangeSlider;
export { IRangeSlider };
