// external
import React, { useState, useEffect, useContext } from 'react';

// comps

// css
import {
  RequirementReturnStyled,
  SingleRequirementReturnStyled,
  RequirementBoxReturnStyled,
} from './styles/RequirementReturn.styled.js';
import { UIColor } from '../../Enums/UIEnums.ts';
import {
  RequirementBoxReturn,
  RequirementBoxTypeEnum,
  RequirementReturn,
  RequirementTypeEnum,
  SingleRequirementReturn,
} from '../../../../../../../GildedLands/lib/Enums/Requirement.js';

interface IRequirementReturn {
  reqReturn: RequirementReturn;
}

const RequirementReturnComponent: React.FC<IRequirementReturn> = ({
  reqReturn,
}) => {
  console.log('reqReturn', reqReturn);
  return (
    <RequirementReturnStyled className='requirement-return'>
      {reqReturn.requirementType !== RequirementTypeEnum.Box ? (
        <SingleRequirementReturnComponent
          singleReqReturn={reqReturn as SingleRequirementReturn}
        />
      ) : (
        <RequirementBoxReturnComponent
          requirementBoxReturn={reqReturn as RequirementBoxReturn}
        />
      )}
    </RequirementReturnStyled>
  );
};

const SingleRequirementReturnComponent = ({
  singleReqReturn,
}: {
  singleReqReturn: SingleRequirementReturn;
}) => {
  return (
    <SingleRequirementReturnStyled
      className='single-requirement-return'
      isMet={singleReqReturn.isMet}
    >
      <div className='single-requirement-return-reason'>
        {singleReqReturn.reason}
      </div>
    </SingleRequirementReturnStyled>
  );
};

const RequirementBoxReturnComponent = ({
  requirementBoxReturn,
}: {
  requirementBoxReturn: RequirementBoxReturn;
}) => {
  return (
    <RequirementBoxReturnStyled
      className='requirement-box-return'
      color={requirementBoxReturn.isMet ? UIColor.White : UIColor.Red}
    >
      <div className='requirement-box-return-reason'>
        {requirementBoxReturn.returnList.map((reqReturn, index) => {
          return (
            <div
              className='requirement-box-return-reason-item'
              key={`${reqReturn.requirementType}-${index}-top`}
            >
              <RequirementReturnComponent reqReturn={reqReturn} />
              {index < requirementBoxReturn.returnList.length - 1 && (
                <div
                  className='requirement-box-return-reason-separator'
                  key={`${reqReturn.requirementType}-${index}-sep`}
                >
                  {requirementBoxReturn.boxType ===
                  RequirementBoxTypeEnum.All ? (
                    <span>AND</span>
                  ) : (
                    <span>OR</span>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </RequirementBoxReturnStyled>
  );
};

export default RequirementReturnComponent;
export { IRequirementReturn };
