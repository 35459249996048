import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const ProductionReportStyled = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;

  .production-report-title {
    height: 50px;
    color: var(--lol-white);
    display: flex;
    align-items: left;
    justify-content: left;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
  }
`;
