import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
import { ClientMessage } from '../MessageBase.js';
export default class MoveUnitMessage extends ClientMessage {
    constructor(messageId, senderUserId, unitInstanceId, destinationTileId) {
        super(NetworkProtocol.MoveUnit, messageId, senderUserId);
        this.unitInstanceId = unitInstanceId;
        this.destinationTileId = destinationTileId;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { unitInstanceId: this.unitInstanceId, destinationTileId: this.destinationTileId });
    }
    static fromJSON(json) {
        return new MoveUnitMessage(json.messageId, json.senderUserId, json.unitInstanceId, json.destinationTileId);
    }
    validate() {
        return (super.validate() &&
            typeof this.unitInstanceId === 'number' &&
            typeof this.destinationTileId === 'number');
    }
}
