class RuntimeKnowledge {
    constructor(type, experienceType, amount) {
        this.type = type;
        this.experienceType = experienceType;
        this.amount = amount;
    }
    clone() {
        return new RuntimeKnowledge(this.type, this.experienceType, this.amount);
    }
    toJSON() {
        return {
            type: this.type,
            experienceType: this.experienceType,
            amount: this.amount,
        };
    }
    static fromJSON(json) {
        return new RuntimeKnowledge(json.type, json.experienceType, json.amount);
    }
}
export default RuntimeKnowledge;
