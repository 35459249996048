import styled from 'styled-components';
import '../../../../styles/constants.css';

export const InlineDataFieldStyled = styled.div`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  overflow: hidden;
  padding: 2px 5px;

  .inline-data-field-dropdown-arrow {
    display: inline-block;
    vertical-align: middle;
  }

  .inline-data-field-field-name {
    font-family: var(--header-font);
    font-size: 0.8rem;
    display: inline-block;
    color: ${(props) => props.textColor};
  }

  .inline-data-field-icon {
    display: inline-block;
    height: 1em; // Match the height of the text
    width: 1em; // Match the width proportionally
    max-height: 1em; // Ensure the icon does not exceed the text height
    max-width: 1em; // Ensure the icon does not exceed the text width
  }

  .inline-data-field-icon img {
    height: 100%; // Ensure the image fits within the container
    width: 100%; // Ensure the image fits within the container
  }

  .inline-data-field-value {
    display: inline-block;
    font-family: var(--info-font);
    font-size: 1rem;
    color: ${(props) => props.textColor};
  }
`;
