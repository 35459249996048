import styled from 'styled-components';

export const WeaponItemStyled = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;

  .weapon-item-content {
    padding: 5px;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  select {
    font-family: var(--header-font);
  }
`;
