import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const TaxTypeSummaryStyled = styled.div`
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  margin: 10px 0;

  .tax-type-title {
    font-weight: bold;
  }

  .policy-summary {
    margin-left: 20px;
  }

  .policy-details {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .line-item {
    margin-left: 40px;
  }
`;
