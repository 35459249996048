import { GoodTypeMethods } from '../../Enums/Good.js';
export class ImprovementCalculation {
    constructor(name, type, operation, operands, value) {
        this.name = name;
        this.type = type;
        this.operation = operation;
        this.operands = operands.map((operand) => new ImprovementCalculation(operand.name, operand.type, operand.operation, operand.operands, operand.value));
        this.value = value;
    }
    evaluate(goodType, curves, experience, goodAffinity) {
        const goodInfo = GoodTypeMethods.getGoodInfo(goodType);
        switch (this.type) {
            case 'constant':
                return this.value;
            case 'operation':
                if (!this.operation || !this.operands || this.operands.length === 0) {
                    throw new Error(`Invalid operation in calculation ${this.name}`);
                }
                const operandValues = this.operands.map((op) => op.evaluate(goodType, curves, experience, goodAffinity));
                return ImprovementCalculation.performOperation(this.operation, operandValues);
            case 'curve':
                const curve = curves.find((c) => c.name === this.value);
                if (!curve) {
                    throw new Error(`Curve ${this.value} not found in ${this.name}`);
                }
                return curve.evaluate(experience);
            case 'goodDensity': {
                return goodInfo.baseProductionDensity;
            }
            case 'goodAffinityMultiplier': {
                return goodAffinity.multiplier;
            }
            case 'goodAffinityFixed': {
                return goodAffinity.fixed;
            }
            default:
                throw new Error(`Unsupported calculation type ${this.type}`);
        }
    }
    static performOperation(operation, operands) {
        switch (operation) {
            case 'add':
                return operands.reduce((a, b) => a + b, 0);
            case 'multiply':
                return operands.reduce((a, b) => a * b, 1);
            case 'subtract':
                if (operands.length !== 2) {
                    throw new Error('Subtract operation requires exactly two operands');
                }
                return operands[0] - operands[1];
            case 'divide':
                if (operands.length !== 2) {
                    throw new Error('Divide operation requires exactly two operands');
                }
                return operands[0] / operands[1];
            default:
                throw new Error(`Unsupported operation ${operation}`);
        }
    }
    // #region Clone and JSON
    clone() {
        return new ImprovementCalculation(this.name, this.type, this.operation, this.operands.map((operand) => operand.clone()), this.value);
    }
    toJSON() {
        return {
            name: this.name,
            type: this.type,
            operation: this.operation,
            operands: this.operands.map((operand) => operand.toJSON()),
            value: this.value,
        };
    }
    static fromJSON(json) {
        return new ImprovementCalculation(json.name, json.type, json.operation, json.operands.map((operand) => ImprovementCalculation.fromJSON(operand)), json.value);
    }
}
