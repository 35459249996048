// react
import React from 'react';

// enums
import { UIColor } from '../Enums/UIEnums.ts';

// css
import { TabSideContainerStyled } from './styles/TabSideContainer.styled.js';
import GenericBackground, { IGenericBackground } from './Background.tsx';
import LineItemStandard from './LineItemStandard.tsx';
import SelectionButton from './SelectionButton.tsx';

interface ITabSideContainer {
  data: {
    title: string;
    onTitleClick?: () => void;
    content: React.ReactNode;
  }[];
}

const TabSideContainer: React.FC<ITabSideContainer> = ({ data }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <TabSideContainerStyled className='tab-side-container'>
      <div className='tab-title-container'>
        <GenericBackground color={UIColor.Blue3} opacity={0.5} />
        {data.map((item, index) => (
          <div
            key={index}
            className={'tab-title' + (index === activeTab ? ' active' : '')}
            onClick={() => {
              setActiveTab(index);
              item.onTitleClick?.();
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={`tab-content`}>
        <GenericBackground color={UIColor.Blue3} opacity={0.5} />
        {data[activeTab].content}
      </div>
    </TabSideContainerStyled>
  );
};

export default TabSideContainer;
export { ITabSideContainer };
