// ImprovementGraphWithUpgrades.tsx

import React, { useState, useEffect, useMemo } from 'react';
import RuntimeImprovement from '../../../../../../../GildedLands/lib/Classes/Improvement/RuntimeImprovement';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement';
import ImprovementGraph from './ImprovementGraph';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';

interface ImprovementGraphWithUpgradesProps {
  improvementType: ImprovementType;
  graphType: 'productionAmount' | 'productionQuality';
}

const ImprovementGraphWithUpgrades: React.FC<
  ImprovementGraphWithUpgradesProps
> = ({ improvementType, graphType }) => {
  const [selectedGoodType, setSelectedGoodType] = useState<
    GoodType | undefined
  >(undefined);

  const [selectedUpgrades, setSelectedUpgrades] = useState<Set<number>>(
    new Set()
  );

  // Memoize improvementInfo
  const improvementInfo = useMemo(() => {
    return ImprovementTypeMethods.getImprovementInfo(improvementType);
  }, [improvementType]);

  const possibleProductions =
    improvementInfo.capacity?.possibleProduction || [];

  useEffect(() => {
    // Set default selected good type if not set
    if (!selectedGoodType && possibleProductions.length > 0) {
      setSelectedGoodType(possibleProductions[0].goodType);
    }
  }, [improvementType, selectedGoodType, possibleProductions]);

  const handleToggleUpgrade = (upgradeIndex: number) => {
    const newSelectedUpgrades = new Set(selectedUpgrades);
    if (newSelectedUpgrades.has(upgradeIndex)) {
      newSelectedUpgrades.delete(upgradeIndex);
    } else {
      newSelectedUpgrades.add(upgradeIndex);
    }
    setSelectedUpgrades(newSelectedUpgrades);
  };

  const handleGoodTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedGoodType(event.target.value as GoodType);
  };

  // Memoize runtimeImprovement
  const runtimeImprovement = useMemo(() => {
    if (!selectedGoodType) {
      return null;
    }

    let updatedImprovement = ImprovementTypeMethods.getNewRuntimeImprovement(
      improvementType,
      0,
      0,
      selectedGoodType,
      null
    );

    // Apply selected upgrades
    selectedUpgrades.forEach((upgradeIndex) => {
      const upgrade = improvementInfo.capacity?.upgrades[upgradeIndex];
      if (upgrade) {
        upgrade.upgradeFunction(updatedImprovement);
      }
    });

    return updatedImprovement.clone();
  }, [selectedUpgrades, improvementType, selectedGoodType, improvementInfo]);

  return (
    <div>
      {possibleProductions.length > 0 && (
        <div>
          <label>Select Good Type: </label>
          <select value={selectedGoodType} onChange={handleGoodTypeChange}>
            {possibleProductions.map((prod, index) => (
              <option key={index} value={prod.goodType}>
                {prod.goodName}
              </option>
            ))}
          </select>
        </div>
      )}

      {improvementInfo.capacity?.upgrades.map((upgrade, index) => {
        return (
          <div key={index}>
            <input
              type='checkbox'
              checked={selectedUpgrades.has(index)}
              onChange={() => handleToggleUpgrade(index)}
            />
            {upgrade.name}
          </div>
        );
      })}

      {runtimeImprovement && (
        <ImprovementGraph
          runtimeImprovement={runtimeImprovement}
          graphType={graphType}
        />
      )}
    </div>
  );
};

export default ImprovementGraphWithUpgrades;
