// external
import React, { useState, useEffect, useMemo, useContext, memo } from 'react';

// internal
// components
import TileImage from './TileImage.tsx';
import MapCity from './City/MapCity.tsx';

// hooks
import useDeepCompareMemoize from '../../../hooks/useDeepCompareMemoize.js';

// context
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';
import { SelectedEntityContext } from '../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { ScreenModeContext } from '../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';
import { HighlightedTileContext } from '../../../contexts/ScreenAndMap/HighlightedTileContextProvider.tsx';
import { InteractionContext } from '../../../contexts/GameData/InteractionContextProvider.tsx';
import { GameStateDataContext } from '../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import { HexTileStyled } from './styles/HexTile.styled.js';

// gilded lands
import RuntimeLandTile from '../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import MapImprovement from './Improvement/MapImprovement.tsx';

type RoadProps = {
  tile: RuntimeLandTile;
  x: number;
  y: number;
  hexagonSize: number;
};

const Road = memo(({ tile, x, y, hexagonSize }: RoadProps) => {
  // CONTEXT
  const { runtimeLandTileToRoad } = useContext(ImageContext);

  const { gameState } = useContext(GameStateDataContext);
  const { highlightedTiles, setHighlightedTiles } = useContext(
    HighlightedTileContext
  );

  // #region HIGHLIGHTS AND BORDERS
  const [amDimmed, setAmDimmed] = useState(false);

  useEffect(() => {
    if (highlightedTiles && !highlightedTiles.includes(tile.id)) {
      setAmDimmed(true);
    } else {
      setAmDimmed(!tile.visible || !tile.discovered);
    }
  }, [highlightedTiles, tile]);
  // #endregion HIGHLIGHTS AND BORDERS

  // #region STATE STUFF

  const tileImageUrl = useMemo(() => {
    if (!gameState || !gameState.realm) return;
    return runtimeLandTileToRoad(tile, gameState.realm);
  }, [tile, gameState]);

  // #endregion STATE STUFF

  return (
    <HexTileStyled
      className='road'
      position={{ x, y }}
      hexagonSize={hexagonSize}
    >
      <TileImage
        hexagonSize={hexagonSize}
        url={tileImageUrl}
        amDimmed={amDimmed}
      />
    </HexTileStyled>
  );
});

export default Road;
