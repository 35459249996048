const DAY = 1;
const WEEK = DAY * 7;
const MONTH = WEEK * 4;
const YEAR = MONTH * 12;
var TimeEnum;
(function (TimeEnum) {
    TimeEnum[TimeEnum["Day"] = 1] = "Day";
    TimeEnum[TimeEnum["Week"] = 7] = "Week";
    TimeEnum[TimeEnum["Month"] = 28] = "Month";
    TimeEnum[TimeEnum["Year"] = 336] = "Year";
})(TimeEnum || (TimeEnum = {}));
class TimeMethods {
    static getDateStringFromDays(days) {
        const years = Math.floor(days / TimeEnum.Year);
        const months = Math.floor((days % TimeEnum.Year) / TimeEnum.Month);
        const monthString = this.getMonthString(months);
        const monthDay = (days % TimeEnum.Month) + 1;
        return `${monthString} ${monthDay}, ${years} A.D.`;
    }
    static getMonthString(monthNumber) {
        switch (monthNumber) {
            case 0:
                return 'January';
            case 1:
                return 'February';
            case 2:
                return 'March';
            case 3:
                return 'April';
            case 4:
                return 'May';
            case 5:
                return 'June';
            case 6:
                return 'July';
            case 7:
                return 'August';
            case 8:
                return 'September';
            case 9:
                return 'October';
            case 10:
                return 'November';
            case 11:
                return 'December';
            default:
                throw new Error(`Invalid month: ${monthNumber}`);
        }
    }
    static getAgeInYearsFromBirthday(dayBorn, currentDay) {
        return (currentDay - dayBorn) / TimeEnum.Year;
    }
    static timeLengthString(timeInDays) {
        const years = Math.floor(timeInDays / TimeEnum.Year);
        timeInDays = Math.floor(timeInDays % TimeEnum.Year);
        const months = Math.floor(timeInDays / TimeEnum.Month);
        timeInDays = Math.floor(timeInDays % TimeEnum.Month);
        const weeks = Math.floor(timeInDays / TimeEnum.Week);
        timeInDays = Math.floor(timeInDays % TimeEnum.Week);
        const days = Math.floor(timeInDays); // Remaining days
        const parts = [];
        if (years > 0)
            parts.push(`${years} ${years === 1 ? 'year' : 'years'}`);
        if (months > 0)
            parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
        if (weeks > 0)
            parts.push(`${weeks} ${weeks === 1 ? 'week' : 'weeks'}`);
        if (days > 0)
            parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
        return parts.length > 0 ? parts.join(', ') : '0 days';
    }
    static timeBetweenDaysString(day1, day2) {
        let totalDays = Math.abs(day2 - day1);
        return this.timeLengthString(totalDays);
    }
}
export { TimeEnum, TimeMethods };
