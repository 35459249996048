// external
import React, { useContext, memo, useState } from 'react';

// context
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

// css
import {
  StartingTileMarkerStyled,
  StartingTileMarkerImageStyled,
  StartingTileMarkerShapeStyled,
  StartingTileMarkerLabelStyled,
} from './styles/StartingTileMarker.styled.js';

const StartingTileMarker = memo(({ hexagonSize }: { hexagonSize: number }) => {
  const { defaultCityIcon } = useContext(ImageContext);

  const [isHovered, setIsHovered] = useState(false);

  const squareSize = hexagonSize * 0.5;
  const hexagonCenter = {
    x: (hexagonSize * Math.sqrt(3)) / 2,
    y: hexagonSize,
  };
  const diamondPoints = [
    { x: hexagonCenter.x, y: hexagonCenter.y - squareSize / 2 }, // Top
    { x: hexagonCenter.x + squareSize / 2, y: hexagonCenter.y }, // Right
    { x: hexagonCenter.x, y: hexagonCenter.y + squareSize / 2 }, // Bottom
    { x: hexagonCenter.x - squareSize / 2, y: hexagonCenter.y }, // Left
  ];
  const pointsForSVG = diamondPoints.map((p) => `${p.x},${p.y}`).join(' ');
  const pointsForImage = diamondPoints
    .map(
      (p) =>
        `${(p.x / (hexagonSize * Math.sqrt(3))) * 100}% ${
          (p.y / (hexagonSize * 2)) * 100
        }%`
    )
    .join(', ');

  return (
    <StartingTileMarkerStyled
      className='starting-tile-marker'
      hexagonSize={hexagonSize}
    >
      {isHovered && (
        <StartingTileMarkerLabelStyled
          className='starting-tile-marker-label'
          hexagonSize={hexagonSize}
        >
          <div className='improvement-name'>Starting Tile</div>
        </StartingTileMarkerLabelStyled>
      )}
      <StartingTileMarkerImageStyled
        className='starting-tile-marker-image'
        src={defaultCityIcon}
        hexagonSize={hexagonSize}
        points={pointsForImage}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <StartingTileMarkerShapeStyled
        className='tile-diamond'
        hexagonSize={hexagonSize}
      >
        <polygon className='diamond' points={pointsForSVG} />
      </StartingTileMarkerShapeStyled>
    </StartingTileMarkerStyled>
  );
});

export default StartingTileMarker;
