// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components

import ProductionScreen from './Screens/ProductionScreen/ProductionScreen.tsx';
import PeopleScreen from './Screens/PeopleScreen/PeopleScreen.tsx';
import OverviewScreen from './OverviewScreen/OverviewScreen.tsx';

// internal context
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { EconomyStyled } from './styles/Economy.styled.js';
import GoodsData from '../../../../../../../GildedLands/lib/Classes/EconomicData/PriceAndProduction/GoodsData.js';

// lol

const Economy = () => {
  // #region context
  const { gameState } = useContext(GameStateDataContext);
  // #endregion

  // set goods data to the data on load - don't update it
  const [goodsData, setGoodsData] = useState<GoodsData>();
  useEffect(() => {
    if (gameState) setGoodsData(gameState.goodsData);
  }, []);

  const [activeScreen, setActiveScreen] = useState('overview');

  const toggleScreen = (screenName) => {
    setActiveScreen(screenName);
  };

  return (
    <EconomyStyled className='economy'>
      <div className='economy-buttons'>
        <button
          className={
            activeScreen === 'overview'
              ? 'active economy-button'
              : 'economy-button'
          }
          onClick={() => toggleScreen('overview')}
        >
          Overview
        </button>
        <button
          className={
            activeScreen === 'production'
              ? 'active economy-button'
              : 'economy-button'
          }
          onClick={() => toggleScreen('production')}
        >
          Production
        </button>
        <button
          className={
            activeScreen === 'people'
              ? 'active economy-button'
              : 'economy-button'
          }
          onClick={() => toggleScreen('people')}
        >
          People
        </button>
      </div>
      {activeScreen === 'overview' && <OverviewScreen />}
      {activeScreen === 'production' && <ProductionScreen />}
      {activeScreen === 'people' && <PeopleScreen />}
    </EconomyStyled>
  );
};

export default Economy;
