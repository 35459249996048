class TileResource {
    constructor(goodType, amount) {
        this.goodType = goodType;
        this.amount = amount;
    }
    clone() {
        return new TileResource(this.goodType, this.amount);
    }
    toJSON() {
        return {
            goodType: this.goodType,
            amount: this.amount,
        };
    }
    static fromJSON(json) {
        return new TileResource(json.goodType, json.amount);
    }
}
export default TileResource;
