// Start of Selection
var ImprovementType;
(function (ImprovementType) {
    // Specialty
    ImprovementType["NomadicHerd"] = "NomadicHerd";
    // Science
    ImprovementType["Schoolhouse"] = "Schoolhouse";
    ImprovementType["Library"] = "Library";
    ImprovementType["ResearchCouncil"] = "ResearchCouncil";
    ImprovementType["Apprecticehouse"] = "Apprecticehouse";
    // Outposts
    ImprovementType["Outpost"] = "Outpost";
    // Projects
    ImprovementType["CathedralProject"] = "CathedralProject";
    ImprovementType["Cathedral"] = "Cathedral";
    ImprovementType["UniversityProject"] = "UniversityProject";
    ImprovementType["University"] = "University";
    // Food
    ImprovementType["WildCropGatheringSite"] = "WildCropGatheringSite";
    ImprovementType["HuntingGrounds"] = "HuntingGrounds";
    ImprovementType["FishingSpot"] = "FishingSpot";
    ImprovementType["FlatlandFarm"] = "FlatlandFarm";
    ImprovementType["SapTapping"] = "SapTapping";
    ImprovementType["HillFarm"] = "HillFarm";
    ImprovementType["MountainFarm"] = "MountainFarm";
    ImprovementType["FlatlandPasture"] = "FlatlandPasture";
    ImprovementType["HillPasture"] = "HillPasture";
    ImprovementType["MountainPasture"] = "MountainPasture";
    // Secondary
    ImprovementType["TerraceFarm"] = "TerraceFarm";
    ImprovementType["RockBearHuntingGrounds"] = "RockBearHuntingGrounds";
    // Consumer Goods
    // fabrics
    ImprovementType["Linenary"] = "Linenary";
    ImprovementType["Woolery"] = "Woolery";
    ImprovementType["Leathercrofter"] = "Leathercrofter";
    ImprovementType["Silker"] = "Silker";
    // clothes
    ImprovementType["Tunicary"] = "Tunicary";
    ImprovementType["Peplosary"] = "Peplosary";
    // paper
    ImprovementType["Parchmentary"] = "Parchmentary";
})(ImprovementType || (ImprovementType = {}));
var ImprovementCategory;
(function (ImprovementCategory) {
    ImprovementCategory["Agriculture"] = "Agriculture";
    ImprovementCategory["Production"] = "Production";
    ImprovementCategory["Learning"] = "Learning";
    ImprovementCategory["Admin"] = "Admin";
    ImprovementCategory["Military"] = "Military";
    ImprovementCategory["Miscellaneous"] = "Miscellaneous";
})(ImprovementCategory || (ImprovementCategory = {}));
export { ImprovementType, ImprovementCategory };
