import React, { useEffect } from 'react';

// components
import SignInComponent from './SignInComponent';

// css
import { SignInPageStyled } from './styles/SignInPage.styled';

function SignInPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SignInPageStyled className='sign-in-page' height={window.innerHeight}>
      <div className='main-content'>
        <SignInComponent />
      </div>
    </SignInPageStyled>
  );
}

export default SignInPage;
