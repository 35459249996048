import styled from 'styled-components';
import '../../../styles/constants.css';

export const StartingStateTileStyled = styled.div`
  --padding: 10px;

  display: block;
  position: relative;

  box-sizing: border-box;
  height: 100px;
  width: 100%;
  margin: 10px 0;
  padding: var(--padding);

  overflow: visible;
  z-index: 2;
  border: 1px solid
    ${({ selected }) =>
      selected ? 'var(--border-color-bright)' : 'var(--border-color-dim)'};
  :hover {
    border-color: var(--border-color-bright);
  }
  transition: 0.5s;

  .title-area {
    position: absolute;
    top: var(--padding);
    left: var(--padding);
    width: 75%;
    height: 100%;
    pointer-events: none;
    padding: 0;
  }

  .title {
    text-align: left;
    font-size: 1rem;
    font-family: var(--bold-medieval-font);
    font-weight: 900;
  }

  .delete-button-holder {
    position: absolute;
    top: var(--padding);
    right: var(--padding);
  }
  .delete-button {
    transition: 0.2s ease;
    font-size: 1.5rem;
  }
  .delete-button:hover {
    color: var(--lol-red);
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
  }

  .background-holder {
    position: absolute;
    top: -100px;
    left: -100px;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.5s ease;
  }

  .background-holder:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  .realm-map {
    height: 250%;
    width: 250%;
  }
`;
