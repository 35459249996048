import styled from 'styled-components';
import '../../../styles/constants.css';

export const QuickPageStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  .main-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .map-container {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;

    .map-background {
      background-image: url('https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/background.png');
      background-size: 200%;
      background-position: center;
      filter: brightness(0.6);
      width: 100%;
      height: 100%;
    }
  }

  .fog-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
`;
