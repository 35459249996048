import { TaxType } from '../../Enums/Tax.js';
import Tax from './Tax.js';
class ExportDuty extends Tax {
    constructor(instanceId, ownerNationId, dutyAmountPerGood, isOnAllNations, excludedNationIds, includedNationIds) {
        super(instanceId, ownerNationId, TaxType.ExportDuty);
        this.dutyAmountPerGood = dutyAmountPerGood;
        this.isOnAllNations = isOnAllNations;
        this.excludedNationIds = excludedNationIds;
        this.includedNationIds = includedNationIds;
    }
    getCost(destinationCityInstanceId, gameState) {
        const destinationNationId = gameState.getCityByInstanceId(destinationCityInstanceId).administeringNationId;
        if (this.isOnAllNations) {
            if (this.excludedNationIds.includes(destinationNationId)) {
                return 0;
            }
        }
        else {
            if (!this.includedNationIds.includes(destinationNationId)) {
                return 0;
            }
        }
        return this.dutyAmountPerGood;
    }
    clone() {
        return new ExportDuty(this.instanceId, this.ownerNationId, this.dutyAmountPerGood, this.isOnAllNations, [...this.excludedNationIds], [...this.includedNationIds]);
    }
    toJSON() {
        return {
            instanceId: this.instanceId,
            ownerNationId: this.ownerNationId,
            taxType: this.taxType,
            dutyAmountPerGood: this.dutyAmountPerGood,
            isOnAllNations: this.isOnAllNations,
            excludedNationIds: [...this.excludedNationIds],
            includedNationIds: [...this.includedNationIds],
        };
    }
    static fromJSON(json) {
        return new ExportDuty(json.instanceId, json.ownerNationId, json.dutyAmountPerGood, json.isOnAllNations, [...json.excludedNationIds], [...json.includedNationIds]);
    }
}
export default ExportDuty;
