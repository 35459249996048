// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import CitySidebar from '../../../../../UI/GenericComponents/CitySidebar.tsx';
import ProductionReport from '../../Reports/ProductionReport.tsx';

// context
import { GameStateDataContext } from '../../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// css
import { ProductionScreenStyled } from './styles/ProductionScreen.styled.js';

// lol
import { GoodType } from '../../../../../../../../../GildedLands/lib/Enums/Good.js';

const ProductionScreen = () => {
  const { gameState, userId } = useContext(GameStateDataContext);
  const [selectedCity, setSelectedCity] = useState<{
    name: string;
    instanceId: number;
  } | null>(null);

  const [cityList, setCityList] = useState<
    { name: string; instanceId: number }[]
  >([]);

  useEffect(() => {
    if (gameState) {
      setCityList(
        gameState.cities.map((city) => ({
          name: city.name,
          instanceId: city.instanceId,
        }))
      );
      if (selectedCity === null) {
        setSelectedCity({
          name: gameState.cities[0].name,
          instanceId: gameState.cities[0].instanceId,
        });
      }
    }
  }, [gameState]);

  const handleCitySelect = (city: { name: string; instanceId: number }) => {
    setSelectedCity(city);
  };
  // #endregion

  return (
    <ProductionScreenStyled className='production-screen'>
      <div className='content'>
        <div className='sidebar'>
          <CitySidebar
            cityList={cityList}
            selectedCity={selectedCity}
            setSelectedCity={handleCitySelect}
          />
        </div>
        <div className='production-report-holder'>
          <ProductionReport selectedCity={selectedCity} />
        </div>
      </div>
    </ProductionScreenStyled>
  );
};

export default ProductionScreen;
