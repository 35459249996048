import styled from 'styled-components';
import '../../../../../../../styles/constants.css';

export const TaxItemStyled = styled.div`
  font-family: 'Courier New', Courier, monospace;

  .tax-main-info {
    cursor: pointer;
  }

  .tax-title {
    font-weight: bold;
  }

  .tax-info {
    margin-left: 20px;
  }

  .tax-info-headline {
    font-weight: bold;
  }

  .tax-item-details {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }

  .tax-goods {
    border: 1px solid white;
    margin-bottom: 10px;
    padding: 5px;
  }

  .tax-details-category-content {
    margin-top: 10px;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input {
    margin-left: 10px;
  }

  .delete-tax-button {
    width: 50px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
