// external
import React, { useState, useEffect } from 'react';

const PopupDataContextProvider = ({ children }) => {
  // Normal Middle Popups
  const [popups, setPopups] = useState<
    {
      title: string | null;
      description: string | null;
      options: {
        name: string;
        color: string;
        onSelect: () => void;
      }[];
    }[]
  >([]);

  const addPopup = (popup: {
    title: string | null;
    description: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }) => {
    setPopups([popup, ...popups]);
  };

  const removePopup = (index: number) => {
    setPopups(popups.filter((_, i) => i !== index));
  };

  // Large Event Popups
  const [largeEventPopups, setLargeEventPopups] = useState<
    {
      title: string | null;
      description: string | null;
      image: string | null;
      options: {
        name: string;
        color: string;
        onSelect: () => void;
      }[];
    }[]
  >([]);

  const addLargeEventPopup = (popup: {
    title: string | null;
    description: string | null;
    image: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }) => {
    setLargeEventPopups([...largeEventPopups, popup]);
  };

  const removeLargeEventPopup = () => {
    setLargeEventPopups(largeEventPopups.slice(1));
  };

  // Bottom Info
  const [bottomInfo, setBottomInfo] = useState<{
    visible: boolean;
    title: string | null;
    description: string | null;
  } | null>(null);

  const value: PopupDataContextType = {
    popups,
    addPopup,
    removePopup,
    bottomInfo,
    setBottomInfo,
    largeEventPopups,
    addLargeEventPopup,
    removeLargeEventPopup,
  };

  return (
    <PopupDataContext.Provider value={value}>
      {children}
    </PopupDataContext.Provider>
  );
};

interface PopupDataContextType {
  popups: {
    title: string | null;
    description: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }[];
  addPopup: (popup: {
    title: string | null;
    description: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }) => void;
  removePopup: (index: number) => void;
  bottomInfo: {
    visible: boolean;
    title: string | null;
    description: string | null;
  } | null;
  setBottomInfo: (info: {
    visible: boolean;
    title: string | null;
    description: string | null;
  }) => void;
  largeEventPopups: {
    title: string | null;
    description: string | null;
    image: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }[];
  addLargeEventPopup: (popup: {
    title: string | null;
    description: string | null;
    image: string | null;
    options: {
      name: string;
      color: string;
      onSelect: () => void;
    }[];
  }) => void;
  removeLargeEventPopup: () => void;
}

const PopupDataContext = React.createContext<PopupDataContextType | null>(null);

export { PopupDataContextProvider, PopupDataContext };
