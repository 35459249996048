// external
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// internal
// components
import Navbar from '../Navbar/Navbar';
import Fog from './Fog';
// context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';
import { ScreenSizeContext } from '../../contexts/ScreenAndMap/ScreenSizeContextProvider.tsx';

// css
import { TidingsPageStyled } from './styles/TidingsPage.styled.js';
import QuickPage from '../UtilityComponents/QuickPage.tsx';
import GenericBackground from '../UI/GenericComponents/Background.tsx';
import { UIColor } from '../UI/Enums/UIEnums.ts';
import FlagshipSelectionBox from '../UI/GenericComponents/FlagshipSelectionBox.tsx';
import FlagshipSelectionList from '../UI/GenericComponents/FlagshipSelectionList.tsx';

function TidingsPage() {
  const navigate = useNavigate();

  // context
  const { justWater } = useContext(ImageContext);

  return (
    <TidingsPageStyled className='tidings-page' background={justWater}>
      <QuickPage />
      <div className='screen'>
        <div className='tidings-content'>
          <h1 className='tidings-title'>Play</h1>
          <div className='selection-boxes'>
            <FlagshipSelectionList
              selectionBoxes={[
                {
                  title: 'Single Player',
                  text: 'Build your own city and explore a world of possibilities.',
                  image:
                    'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/settler.png',
                  onClick: () => navigate('/lobby'),
                },
                {
                  title: 'Multiplayer',
                  text: 'Build your own city and explore a world of possibilities.',
                  image:
                    'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/group.png',
                  onClick: () => navigate('/lobby'),
                },
              ]}
            />
          </div>
          <div className='tidings-button-container'>
            <button
              className='gold-button'
              onClick={() => navigate('/waitlist')}
            >
              Waitlist
            </button>
            <a className='gold-button' href='https://discord.gg/GPSZwSsV4r'>
              Discord
            </a>
          </div>
        </div>
      </div>
    </TidingsPageStyled>
  );
}

export default TidingsPage;
