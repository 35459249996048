import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const CreateMissionPopupStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .create-mission-popup-closer {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  .create-mission-popup-area {
    position: absolute;
    top: 45%;
    left: 50%;
    max-width: 600px;
    min-width: 400px;
    width: calc(100vw - 3 * var(--game-ui-margin) - 600px);
    height: 65%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border: 2px solid var(--lol-gold);
    border-radius: 10px;
    overflow-y: auto;
    pointer-events: auto;
    box-shadow: 0 0 20px white;
  }

  .create-mission-popup-button-wrapper {
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
`;
