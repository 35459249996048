// external
import React, { useState, useEffect, useContext } from 'react';

// internal

// components
import ConsumptionTaxItem from './TaxItems/ConsumptionTaxItem.tsx';
import ExportDutyItem from './TaxItems/ExportDutyItem.tsx';
import ImportDutyItem from './TaxItems/ImportDutyItem.tsx';
import TransitDutyItem from './TaxItems/TransitDutyItem.tsx';
import ProductionTaxItem from './TaxItems/ProductionTaxItem.tsx';
import ProductionSubsidyItem from './TaxItems/ProductionSubsidyItem.tsx';
import ImprovementSubsidyItem from './TaxItems/ImprovementSubsidyItem.tsx';
import ImportTariffItem from './TaxItems/ImportTariffItem.tsx';
import ExportTariffItem from './TaxItems/ExportTariffItem.tsx';
import ConsumptionSubsidyItem from './TaxItems/ConsumptionSubsidyItem.tsx';

// internal context
import { GameStateDataContext } from '../../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { GameStateLogicContext } from '../../../../../contexts/GameData/GameStateLogicContextProvider.tsx';

// css
import { TaxManagerStyled } from './styles/TaxManager.styled.js';

// lol
import ConsumptionSubsidy from '../../../../../../../../GildedLands/lib/Classes/Tax/ConsumptionSubsidy.js';
import ConsumptionTax from '../../../../../../../../GildedLands/lib/Classes/Tax/ConsumptionTax.js';
import ExportDuty from '../../../../../../../../GildedLands/lib/Classes/Tax/ExportDuty.js';
import ImportDuty from '../../../../../../../../GildedLands/lib/Classes/Tax/ImportDuty.js';
import TransitDuty from '../../../../../../../../GildedLands/lib/Classes/Tax/TransitDuty.js';
import ProductionTax from '../../../../../../../../GildedLands/lib/Classes/Tax/ProductionTax.js';
import ProductionSubsidy from '../../../../../../../../GildedLands/lib/Classes/Tax/ProductionSubsidy.js';
import ImprovementSubsidy from '../../../../../../../../GildedLands/lib/Classes/Tax/ImprovementSubsidy.js';
import ImportTariff from '../../../../../../../../GildedLands/lib/Classes/Tax/ImportTariff.js';
import ExportTariff from '../../../../../../../../GildedLands/lib/Classes/Tax/ExportTariff.js';
import { TaxType } from '../../../../../../../../GildedLands/lib/Enums/Tax';

const TaxManager = () => {
  // #region context
  const { gameState, userId } = useContext(GameStateDataContext);
  const { addTax } = useContext(GameStateLogicContext);
  // #endregion

  // set goods data to the data on load - don't update it
  const [consumptionSubsidies, setConsumptionSubsidies] =
    useState<ConsumptionSubsidy[]>();
  const [consumptionTaxes, setConsumptionTaxes] = useState<ConsumptionTax[]>();
  const [exportDuties, setExportDuties] = useState<ExportDuty[]>();
  const [exportTariffs, setExportTariffs] = useState<ExportTariff[]>();
  const [importDuties, setImportDuties] = useState<ImportDuty[]>();
  const [importTariffs, setImportTariffs] = useState<ImportTariff[]>();
  const [improvementSubsidies, setImprovementSubsidies] =
    useState<ImprovementSubsidy[]>();
  const [productionSubsidies, setProductionSubsidies] =
    useState<ProductionSubsidy[]>();
  const [productionTaxes, setProductionTaxes] = useState<ProductionTax[]>();
  const [transitDuties, setTransitDuties] = useState<TransitDuty[]>();

  useEffect(() => {
    if (!gameState) return;

    const playerNation = gameState.getNationByControllerUserId(userId);

    setConsumptionSubsidies(
      gameState.taxes
        .filter(
          (subsidy) =>
            subsidy.ownerNationId === playerNation.id &&
            subsidy.taxType === TaxType.ConsumptionSubsidy
        )
        .map((subsidy) => subsidy as ConsumptionSubsidy)
    );
    setConsumptionTaxes(
      gameState.taxes
        .filter(
          (tax) =>
            tax.ownerNationId === playerNation.id &&
            tax.taxType === TaxType.ConsumptionTax
        )
        .map((tax) => tax as ConsumptionTax)
    );
    setExportDuties(
      gameState.taxes
        .filter(
          (duty) =>
            duty.ownerNationId === playerNation.id &&
            duty.taxType === TaxType.ExportDuty
        )
        .map((duty) => duty as ExportDuty)
    );
    setExportTariffs(
      gameState.taxes
        .filter(
          (tariff) =>
            tariff.ownerNationId === playerNation.id &&
            tariff.taxType === TaxType.ExportTariff
        )
        .map((tariff) => tariff as ExportTariff)
    );
    setImportDuties(
      gameState.taxes
        .filter(
          (duty) =>
            duty.ownerNationId === playerNation.id &&
            duty.taxType === TaxType.ImportDuty
        )
        .map((duty) => duty as ImportDuty)
    );
    setImportTariffs(
      gameState.taxes
        .filter(
          (tariff) =>
            tariff.ownerNationId === playerNation.id &&
            tariff.taxType === TaxType.ImportTariff
        )
        .map((tariff) => tariff as ImportTariff)
    );
    setImprovementSubsidies(
      gameState.taxes
        .filter(
          (subsidy) =>
            subsidy.ownerNationId === playerNation.id &&
            subsidy.taxType === TaxType.ImprovementSubsidy
        )
        .map((subsidy) => subsidy as ImprovementSubsidy)
    );
    setProductionSubsidies(
      gameState.taxes
        .filter(
          (subsidy) =>
            subsidy.ownerNationId === playerNation.id &&
            subsidy.taxType === TaxType.ProductionSubsidy
        )
        .map((subsidy) => subsidy as ProductionSubsidy)
    );
    setProductionTaxes(
      gameState.taxes
        .filter(
          (tax) =>
            tax.ownerNationId === playerNation.id &&
            tax.taxType === TaxType.ProductionTax
        )
        .map((tax) => tax as ProductionTax)
    );
    setTransitDuties(
      gameState.taxes
        .filter(
          (duty) =>
            duty.ownerNationId === playerNation.id &&
            duty.taxType === TaxType.TransitDuty
        )
        .map((duty) => duty as TransitDuty)
    );
  }, [gameState, userId]);

  return (
    <TaxManagerStyled className='tax-manager'>
      <div className='consumption-taxes tax-group'>
        <h3 className='tax-group-title'>Consumption Taxes</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ConsumptionTax)}
        >
          {' Add New Consumption Tax '}
        </button>
        <div className='tax-list'>
          {consumptionTaxes?.map((tax) => (
            <ConsumptionTaxItem key={tax.instanceId} tax={tax} />
          ))}
        </div>
      </div>
      <div className='consumption-subsidies tax-group'>
        <h3 className='tax-group-title'>Consumption Subsidies</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ConsumptionSubsidy)}
        >
          {' Add New Consumption Subsidy '}
        </button>
        <div className='tax-list'>
          {consumptionSubsidies?.map((subsidy) => (
            <ConsumptionSubsidyItem
              key={subsidy.instanceId}
              subsidy={subsidy}
            />
          ))}
        </div>
      </div>
      <div className='export-duties tax-group'>
        <h3 className='tax-group-title'>Export Duties</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ExportDuty)}
        >
          {' Add New Export Duty '}
        </button>
        <div className='tax-list'>
          {exportDuties?.map((duty) => (
            <ExportDutyItem key={duty.instanceId} duty={duty} />
          ))}
        </div>
      </div>
      <div className='export-tariffs tax-group'>
        <h3 className='tax-group-title'>Export Tariffs</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ExportTariff)}
        >
          {' Add New Export Tariff '}
        </button>
        <div className='tax-list'>
          {exportTariffs?.map((tariff) => (
            <ExportTariffItem key={tariff.instanceId} tariff={tariff} />
          ))}
        </div>
      </div>
      <div className='import-duties tax-group'>
        <h3 className='tax-group-title'>Import Duties</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ImportDuty)}
        >
          {' Add New Import Duty '}
        </button>
        <div className='tax-list'>
          {importDuties?.map((duty) => (
            <ImportDutyItem key={duty.instanceId} duty={duty} />
          ))}
        </div>
      </div>
      <div className='import-tariffs tax-group'>
        <h3 className='tax-group-title'>Import Tariffs</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ImportTariff)}
        >
          {' Add New Import Tariff '}
        </button>
        <div className='tax-list'>
          {importTariffs?.map((tariff) => (
            <ImportTariffItem key={tariff.instanceId} tariff={tariff} />
          ))}
        </div>
      </div>
      <div className='improvement-subsidies tax-group'>
        <h3 className='tax-group-title'>Improvement Subsidies</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ImprovementSubsidy)}
        >
          {' Add New Improvement Subsidy '}
        </button>
        <div className='tax-list'>
          {improvementSubsidies?.map((subsidy) => (
            <ImprovementSubsidyItem
              key={subsidy.instanceId}
              subsidy={subsidy}
            />
          ))}
        </div>
      </div>
      <div className='production-subsidies tax-group'>
        <h3 className='tax-group-title'>Production Subsidies</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ProductionSubsidy)}
        >
          {' Add New Production Subsidy '}
        </button>
        <div className='tax-list'>
          {productionSubsidies?.map((subsidy) => (
            <ProductionSubsidyItem key={subsidy.instanceId} subsidy={subsidy} />
          ))}
        </div>
      </div>
      <div className='production-taxes tax-group'>
        <h3 className='tax-group-title'>Production Taxes</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.ProductionTax)}
        >
          {' Add New Production Tax '}
        </button>
        <div className='tax-list'>
          {productionTaxes?.map((tax) => (
            <ProductionTaxItem key={tax.instanceId} tax={tax} />
          ))}
        </div>
      </div>
      <div className='transit-duties tax-group'>
        <h3 className='tax-group-title'>Transit Duties</h3>
        <button
          className='add-tax-button'
          onClick={() => addTax(TaxType.TransitDuty)}
        >
          {' Add New Transit Duty '}
        </button>
        <div className='tax-list'>
          {transitDuties?.map((duty) => (
            <TransitDutyItem key={duty.instanceId} duty={duty} />
          ))}
        </div>
      </div>
    </TaxManagerStyled>
  );
};

export default TaxManager;
