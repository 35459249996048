// external
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

//context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';

//css
import { QuickPageStyled } from './styles/QuickPage.styled.js';
import ImageBox from '../UI/GenericComponents/ImageBox.tsx';
import Fog from '../Tidings/Fog.jsx';

function QuickPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { topo, justWater } = useContext(ImageContext);

  return (
    <QuickPageStyled className='quick-page'>
      <div className='screen-background'>
        <img
          className='screen-background-image'
          src={justWater}
          alt='background'
        />
      </div>

      <div className='main-content'>
        <div className='map-container'>
          <div className='map-background'></div>
        </div>
      </div>

      <Fog />
    </QuickPageStyled>
  );
}

export default QuickPage;
