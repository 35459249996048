// external
import React, { useContext, memo, useState } from 'react';

// context
import { ImageContext } from '../../../../contexts/Media/ImageContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
import { InteractionContext } from '../../../../contexts/GameData/InteractionContextProvider.tsx';
import { MapViewContext } from '../../../../contexts/ScreenAndMap/MapViewContextProvider.tsx';
// css
import {
  MapCityStyled,
  MapCityImageStyled,
  MapCityShapeStyled,
  MapCityLabelStyled,
  // MapCityPulseDiamondStyled,
} from './styles/MapCity.styled.js';

const MapCity = memo(
  ({
    cityInstanceId,
    hexagonSize,
  }: {
    cityInstanceId: number;
    hexagonSize: number;
  }) => {
    const { defaultCityIcon } = useContext(ImageContext);
    const { gameState } = useContext(GameStateDataContext);
    const { openCityScreen } = useContext(InteractionContext);
    const { setCenterOnTileId } = useContext(MapViewContext);
    const squareSize = hexagonSize * 1.2;
    const hexagonCenter = {
      x: (hexagonSize * Math.sqrt(3)) / 2,
      y: hexagonSize,
    };
    const diamondPoints = [
      { x: hexagonCenter.x, y: hexagonCenter.y - squareSize / 2 }, // Top
      { x: hexagonCenter.x + squareSize / 2, y: hexagonCenter.y }, // Right
      { x: hexagonCenter.x, y: hexagonCenter.y + squareSize / 2 }, // Bottom
      { x: hexagonCenter.x - squareSize / 2, y: hexagonCenter.y }, // Left
    ];
    const pointsForSVG = diamondPoints.map((p) => `${p.x},${p.y}`).join(' ');
    const pointsForImage = diamondPoints
      .map(
        (p) =>
          `${(p.x / (hexagonSize * Math.sqrt(3))) * 100}% ${
            (p.y / (hexagonSize * 2)) * 100
          }%`
      )
      .join(', ');

    const city = gameState.cities.find(
      (city) => city.instanceId === cityInstanceId
    );

    const handleClick = () => {
      openCityScreen(city.centerTileLandtileId);
      setCenterOnTileId(city.centerTileLandtileId);
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
      <MapCityStyled
        className='map-city'
        hexagonSize={hexagonSize}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <MapCityLabelStyled
          className='map-city-label'
          hexagonSize={hexagonSize}
        >
          <div className='city-name'>
            {city?.name + ' - Pop.' + city?.persons.length + 'k'}
          </div>
          {/* <div className='city-population'>Pop. {city?.persons.length}k</div> */}
        </MapCityLabelStyled>
        <MapCityImageStyled
          className='map-city-image'
          src={defaultCityIcon}
          hexagonSize={hexagonSize}
          points={pointsForImage}
          onClick={handleClick}
        />
        <MapCityShapeStyled className='tile-diamond' hexagonSize={hexagonSize}>
          <polygon className='diamond' points={pointsForSVG} />
        </MapCityShapeStyled>
      </MapCityStyled>
    );
  }
);

export default MapCity;
