import React from 'react';

import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';
import { UIPadding, UITextSize } from '../../Enums/UIEnums';
import TextBox from '../../GenericComponents/TextBox';

const getImprovementDescription = (
  improvementType: ImprovementType
): React.ReactNode[] => {
  return ImprovementTypeMethods.getImprovementInfo(
    improvementType
  ).description.map((line, index) => (
    <TextBox key={index} title={line.title} content={line.content} />
  ));
};

export default getImprovementDescription;
