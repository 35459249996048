// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import ListPopup from '../../../UI/GenericComponents/ListPopup.tsx';

// context

// css
import { ResponsibilityItemStyled } from './styles/ResponsibilityItem.styled.js';

// gl
import Responsibility from '../../../../../../../GildedLands/lib/Classes/Administration/Responsibility';
import { ResponsibilityMethods } from '../../../../../../../GildedLands/lib/Enums/Responsibility.js';

// lol

interface ResponsibilityItemProps {
  responsibility: Responsibility;
  saveResponsibility: (responsibility: Responsibility) => void;
  deleteResponsibility: () => void;
}

const ResponsibilityItem = ({
  responsibility,
  saveResponsibility,
  deleteResponsibility,
}: ResponsibilityItemProps) => {
  // context

  // state
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupList, setPopupList] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleStringClick = (index) => {
    const availableStrings =
      index === 0
        ? ResponsibilityMethods.subcategoriesForResponsibility('')
        : ResponsibilityMethods.subcategoriesForResponsibility(
            responsibility.name[index - 1]
          );
    if (availableStrings) {
      setPopupList(availableStrings);
      setSelectedIndex(index);
      setPopupVisible(true);
    }
  };

  const handleListSelect = (value: string) => {
    setPopupVisible((prev) => false);
    const clone = responsibility.clone();
    clone.name[selectedIndex] = value;
    clone.name = clone.name.slice(0, selectedIndex + 1);
    const possibleChildren =
      ResponsibilityMethods.subcategoriesForResponsibility(value);
    if (possibleChildren.length > 0) {
      clone.name.push(possibleChildren[0]);
      setPopupList(possibleChildren);
      setSelectedIndex((prev) => prev + 1);
      setPopupVisible((prev) => true);
    }
    saveResponsibility(clone);
  };

  return (
    <ResponsibilityItemStyled className='responsibility-item'>
      {responsibility.name.map((name, index) => (
        <RIString
          key={index}
          string={name}
          onClick={() => handleStringClick(index)}
          end={index === responsibility.name.length - 1}
        />
      ))}
      <button
        className='delete-responsibility-button border red'
        onClick={deleteResponsibility}
      >
        {'\u00A0\u00D7\u00A0'}
      </button>
      {popupVisible && (
        <ListPopup
          list={popupList.map((name) => ({ name, value: name }))}
          onSelect={handleListSelect}
          close={() => setPopupVisible(false)}
        />
      )}
    </ResponsibilityItemStyled>
  );
};

const RIString = ({
  string,
  onClick,
  end,
}: {
  string: string;
  onClick: () => void;
  end: boolean;
}) => {
  return (
    <div className='responsibility-name' onClick={onClick}>
      {string}
      {!end && <span>{'\u00A0\u00A0' + '>' + '\u00A0\u00A0'}</span>}
    </div>
  );
};

export default ResponsibilityItem;
