// import LibraryImprovement from '../Improvement/LibraryImprovement.js';
class GameManager {
    // properties are in their own static class - way easier to ref
    // library objects
    // improvementLibrary: LibraryImprovement[];
    // constructor(improvementLibrary: LibraryImprovement[]) {
    //   this.improvementLibrary = improvementLibrary;
    // }
    constructor() { }
    static initializeClassesWithRegistration() {
        // improvement requirements
        // improvement effects
        // improvement target conditions
    }
    // getImprovementFromLibraryId(libraryId: number): LibraryImprovement {
    //   const libraryImprovement = this.improvementLibrary.find(
    //     (improvement) => improvement.libraryId === libraryId
    //   );
    //   if (!libraryImprovement) {
    //     throw new Error(`No improvement found with id ${libraryId}`);
    //   }
    //   return libraryImprovement;
    // }
    // getImprovementFromEnum(improvementType: ImprovementType): LibraryImprovement {
    //   const libraryImprovement = this.improvementLibrary.find(
    //     (improvement) => improvement.improvementType === improvementType
    //   );
    //   return libraryImprovement;
    // }
    toJSON() {
        return {
        // improvementLibrary: this.improvementLibrary.map((improvement) =>
        //   improvement.toJSON()
        // ),
        };
    }
    clone() {
        return new GameManager();
        // this.improvementLibrary.map((improvement) => improvement.clone())
    }
    static fromJSON(json) {
        return new GameManager();
        // json.improvementLibrary.map((improvement: any) =>
        //   LibraryImprovement.fromJSON(improvement)
        // )
    }
    // utility methods
    static generateUniqueId(length = 20) {
        // need to make sure length is at least 20
        if (length < 20)
            length = 20;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        const timePart = Date.now().toString(36);
        let randomPartLength = length - timePart.length;
        for (let i = 0; i < randomPartLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return timePart + result;
    }
}
export default GameManager;
