import styled from 'styled-components';
import '../../../../styles/constants.css';

export const TextBoxStyled = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  padding: ${(props) => props.padding}px;
  border-radius: ${(props) =>
    props.roundedCorners ? 'var(--ui-border-radius)' : '0px'};

  .text-box-title {
    display: inline-block;
    font-family: var(--info-font);
    font-size: ${(props) => props.titleTextSize};
    font-weight: bold;
    text-align: left;
  }

  .text-box-content {
    display: inline-block;
    font-family: var(--body-font);
    font-size: ${(props) => props.contentTextSize};
    text-align: left;
  }
`;
