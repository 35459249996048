import CharacterTrait from '../Classes/Character/CharacterTrait.js';
import QualityOfLifeItem from '../Classes/EconomicData/Consumption/QualityOfLifeItem.js';
import ExperienceCurveInfo from '../Classes/Improvement/ExperienceCurveInfo.js';
import FunctionInfo from '../Classes/Improvement/FunctionInfo.js';
import { CharacterType } from './Character.js';
import { ExperienceType } from './Experience.js';
import { ImprovementCurveEnum } from './ImprovementCurve.js';
var GoodType;
(function (GoodType) {
    GoodType["ProjectOutput"] = "ProjectOutput";
    // Raw Goods
    // Wood
    GoodType["Oak"] = "Oak";
    GoodType["Ash"] = "Ash";
    GoodType["Elm"] = "Elm";
    // Stone
    GoodType["Marble"] = "Marble";
    GoodType["Granite"] = "Granite";
    GoodType["Quartz"] = "Quartz";
    // Metal
    GoodType["Iron"] = "Iron";
    GoodType["Copper"] = "Copper";
    GoodType["Silver"] = "Silver";
    GoodType["Gold"] = "Gold";
    // Food
    // Staple
    GoodType["Wheat"] = "Wheat";
    GoodType["Rutabaga"] = "Rutabaga";
    GoodType["Potato"] = "Potato";
    GoodType["Rice"] = "Rice";
    GoodType["Sap"] = "Sap";
    // Meat
    GoodType["Beef"] = "Beef";
    GoodType["Mutton"] = "Mutton";
    GoodType["Venison"] = "Venison";
    GoodType["Fish"] = "Fish";
    // Vegetables
    GoodType["Carrot"] = "Carrot";
    GoodType["Cabbage"] = "Cabbage";
    GoodType["Turnip"] = "Turnip";
    GoodType["Peas"] = "Peas";
    // Fruits
    GoodType["Apples"] = "Apples";
    GoodType["Oranges"] = "Oranges";
    GoodType["Grapes"] = "Grapes";
    // Alcohol
    // Wine
    GoodType["RedWine"] = "RedWine";
    GoodType["WhiteWine"] = "WhiteWine";
    GoodType["RoseWine"] = "RoseWine";
    // Beer
    GoodType["Ale"] = "Ale";
    GoodType["Lager"] = "Lager";
    GoodType["Stout"] = "Stout";
    // Spirits
    GoodType["Whiskey"] = "Whiskey";
    GoodType["Rum"] = "Rum";
    GoodType["Vodka"] = "Vodka";
    // Clothing
    // Cloth
    GoodType["Linen"] = "Linen";
    GoodType["Wool"] = "Wool";
    GoodType["Leather"] = "Leather";
    GoodType["Silk"] = "Silk";
    // Dyes
    GoodType["RedDye"] = "RedDye";
    GoodType["BlueDye"] = "BlueDye";
    GoodType["YellowDye"] = "YellowDye";
    GoodType["GreenDye"] = "GreenDye";
    // Footwear
    GoodType["Boots"] = "Boots";
    GoodType["Sandals"] = "Sandals";
    GoodType["Shoes"] = "Shoes";
    // Apparel
    GoodType["Tunic"] = "Tunic";
    GoodType["Cloak"] = "Cloak";
    GoodType["Breeches"] = "Breeches";
    GoodType["Robe"] = "Robe";
    // Accessories
    GoodType["Belt"] = "Belt";
    GoodType["Hat"] = "Hat";
    GoodType["Gloves"] = "Gloves";
    // Equipment
    // Farming Tools
    GoodType["Scythe"] = "Scythe";
    GoodType["Hoe"] = "Hoe";
    GoodType["Rake"] = "Rake";
    GoodType["Shovel"] = "Shovel";
    // Household Tools
    GoodType["Knife"] = "Knife";
    GoodType["Broom"] = "Broom";
    // Building Tools
    GoodType["Hammer"] = "Hammer";
    GoodType["Saw"] = "Saw";
    GoodType["Pickaxe"] = "Pickaxe";
    // Knowledge Tools
    GoodType["Book"] = "Book";
    GoodType["Scroll"] = "Scroll";
    GoodType["Quill"] = "Quill";
    // Household
    // Tableware
    GoodType["Plate"] = "Plate";
    GoodType["Cup"] = "Cup";
    GoodType["Bowl"] = "Bowl";
    // Storage
    GoodType["Chest"] = "Chest";
    GoodType["Barrel"] = "Barrel";
    GoodType["Basket"] = "Basket";
    // Cooking
    GoodType["Pot"] = "Pot";
    GoodType["Pan"] = "Pan";
    // Lighting
    GoodType["Candle"] = "Candle";
    GoodType["Lantern"] = "Lantern";
    GoodType["Torch"] = "Torch";
    // Textiles
    GoodType["Blanket"] = "Blanket";
    GoodType["Cushions"] = "Cushions";
    GoodType["Rug"] = "Rug";
    // Health
    // Herbs
    GoodType["Basil"] = "Basil";
    GoodType["Mint"] = "Mint";
    GoodType["Lavender"] = "Lavender";
    // Tinctures
    GoodType["Echinacea"] = "Echinacea";
    GoodType["Ginseng"] = "Ginseng";
    GoodType["Goldenseal"] = "Goldenseal";
    // Salve
    GoodType["Eucalyptus"] = "Eucalyptus";
    GoodType["Aloe"] = "Aloe";
    GoodType["Comfrey"] = "Comfrey";
    // Military
    GoodType["LightAxe"] = "LightAxe";
    GoodType["HeavyAxe"] = "HeavyAxe";
    GoodType["Gladius"] = "Gladius";
    GoodType["Broadsword"] = "Broadsword";
    GoodType["LightLance"] = "LightLance";
    GoodType["HeavyLance"] = "HeavyLance";
    GoodType["Pike"] = "Pike";
    GoodType["LeatherJerkin"] = "LeatherJerkin";
    GoodType["ChainMail"] = "ChainMail";
    GoodType["PlateMail"] = "PlateMail";
    GoodType["HeavyPlate"] = "HeavyPlate";
    // Luxury
    // Jewelry
    GoodType["Necklace"] = "Necklace";
    GoodType["Ring"] = "Ring";
    GoodType["Bracelet"] = "Bracelet";
    // Spice
    GoodType["Pepper"] = "Pepper";
    GoodType["Cinnamon"] = "Cinnamon";
    GoodType["Saffron"] = "Saffron";
    // Incense
    GoodType["Frankincense"] = "Frankincense";
    GoodType["Myrrh"] = "Myrrh";
    GoodType["Sandalwood"] = "Sandalwood";
    // Perfume
})(GoodType || (GoodType = {}));
var GoodSubCategory;
(function (GoodSubCategory) {
    GoodSubCategory["None"] = "None";
    // Raw Goods
    GoodSubCategory["Wood"] = "Wood";
    GoodSubCategory["Stone"] = "Stone";
    GoodSubCategory["Metal"] = "Metal";
    // Food
    GoodSubCategory["Grain"] = "Grain";
    GoodSubCategory["Meat"] = "Meat";
    GoodSubCategory["Vegetable"] = "Vegetable";
    GoodSubCategory["Fruits"] = "Fruits";
    // Alcohol
    GoodSubCategory["Wine"] = "Wine";
    GoodSubCategory["Beer"] = "Beer";
    GoodSubCategory["Spirits"] = "Spirits";
    // Clothing
    GoodSubCategory["Fabric"] = "Fabric";
    GoodSubCategory["Footwear"] = "Footwear";
    GoodSubCategory["Apparel"] = "Apparel";
    GoodSubCategory["Accessories"] = "Accessories";
    // Equipment
    GoodSubCategory["FarmingTools"] = "FarmingTools";
    GoodSubCategory["HouseholdTools"] = "HouseholdTools";
    GoodSubCategory["BuildingTools"] = "BuildingTools";
    GoodSubCategory["KnowledgeTools"] = "KnowledgeTools";
    // Household
    GoodSubCategory["Tableware"] = "Tableware";
    GoodSubCategory["Storage"] = "Storage";
    GoodSubCategory["Cooking"] = "Cooking";
    GoodSubCategory["Lighting"] = "Lighting";
    GoodSubCategory["Textiles"] = "Textiles";
    // Health
    GoodSubCategory["Herbs"] = "Herbs";
    GoodSubCategory["Tinctures"] = "Tinctures";
    GoodSubCategory["Salve"] = "Salve";
    // Luxury
    GoodSubCategory["Jewelry"] = "Jewelry";
    GoodSubCategory["Spice"] = "Spice";
    GoodSubCategory["Incense"] = "Incense";
    // Military
    GoodSubCategory["Sword"] = "Sword";
    GoodSubCategory["Axe"] = "Axe";
    GoodSubCategory["Spear"] = "Spear";
    GoodSubCategory["Armor"] = "Armor";
})(GoodSubCategory || (GoodSubCategory = {}));
var GoodCategory;
(function (GoodCategory) {
    GoodCategory["Raw"] = "Raw Goods";
    GoodCategory["Food"] = "Food";
    GoodCategory["Alcohol"] = "Alcohol";
    GoodCategory["Clothing"] = "Clothing";
    GoodCategory["Equipment"] = "Equipment";
    GoodCategory["Household"] = "Household";
    GoodCategory["Health"] = "Health";
    GoodCategory["Luxury"] = "Luxury";
    GoodCategory["Military"] = "Military";
})(GoodCategory || (GoodCategory = {}));
class GoodTypeMethods {
    static getCurveValue(curve, curveDefault, curveMultiplier, sharedGoods) {
        let curveValue = curve[sharedGoods];
        if (curveValue === undefined || curveValue === null)
            curveValue = curveDefault;
        return curveValue * curveMultiplier;
    }
    static getAffinity(person, goodCharacterTraits) {
        if (person.characterTraits.length === 0 ||
            goodCharacterTraits.length === 0) {
            return 0;
        }
        const totalTraitValue = person.characterTraits.reduce((sum, trait) => sum + trait.amount, 0);
        if (totalTraitValue === 0) {
            return 0;
        }
        const sharedTraitScore = person.characterTraits.reduce((sum, trait) => {
            const matchingTrait = goodCharacterTraits.find((t) => t.characterType === trait.characterType);
            return matchingTrait ? sum + matchingTrait.amount * trait.amount : sum;
        }, 0);
        return sharedTraitScore / totalTraitValue;
    }
    static calculateQualityOfLife(goodType, quality, person, currentConsumptionChart) {
        const goodInfo = this.getGoodInfo(goodType);
        const categoryNeed = this.getCurveValue(this.getCategoryInfo(goodInfo.category).baseCurve, this.getCategoryInfo(goodInfo.category).curveDefault, this.getCategoryInfo(goodInfo.category).curveMultiplier, currentConsumptionChart.categoryAmountTracker[goodInfo.category] || 0);
        const subCategoryNeed = this.getCurveValue(this.getSubCategoryInfo(goodInfo.subCategory).baseCurve, this.getSubCategoryInfo(goodInfo.subCategory).curveDefault, this.getSubCategoryInfo(goodInfo.subCategory).curveMultiplier, currentConsumptionChart.subCategoryAmountTracker[goodInfo.subCategory] ||
            0);
        const goodNeed = this.getCurveValue(goodInfo.baseCurve, goodInfo.curveDefault, goodInfo.curveMultiplier, currentConsumptionChart.goodAmountTracker[goodType] || 0);
        // lack of food
        const lackOfFoodDetractorCurve = [0, 0, 0, 0, 0, 0.1, 0.2, 0.5, 0.8, 0.9];
        const foodAmount = currentConsumptionChart.categoryAmountTracker[GoodCategory.Food] || 0;
        const lackOfFoodDetractor = this.getCurveValue(lackOfFoodDetractorCurve, 1, 1, foodAmount);
        const affinity = this.getAffinity(person, goodInfo.characterTraits);
        switch (goodInfo.category) {
            case GoodCategory.Military:
                return new QualityOfLifeItem(0, 0, 0, 0, 0, 0, 0, 0, 0);
            case GoodCategory.Food: {
                const qol = categoryNeed *
                    subCategoryNeed *
                    goodNeed *
                    (goodInfo.qualityOfLife +
                        lackOfFoodDetractor * (affinity + quality / 10));
                return new QualityOfLifeItem(qol, categoryNeed, subCategoryNeed, goodNeed, goodInfo.qualityOfLife, affinity, quality, lackOfFoodDetractor, 1);
            }
            case GoodCategory.Alcohol:
            case GoodCategory.Clothing:
            case GoodCategory.Equipment:
            case GoodCategory.Household:
            case GoodCategory.Health:
                // final answer
                const qol = categoryNeed *
                    subCategoryNeed *
                    goodNeed *
                    (goodInfo.qualityOfLife + affinity + quality / 10);
                return new QualityOfLifeItem(lackOfFoodDetractor * qol, categoryNeed, subCategoryNeed, goodNeed, goodInfo.qualityOfLife, affinity, quality, lackOfFoodDetractor, 1);
            case GoodCategory.Luxury:
                // lack of other consumer goods
                const lackOfOtherGoodsCurve = [0, 0, 0, 0, 0, 0.1, 0.2, 0.5, 0.8, 0.9];
                const lackOfOtherGoodsDetractor = this.getCurveValue(lackOfOtherGoodsCurve, 1, 1, currentConsumptionChart.goods.filter((good) => good.category !== GoodCategory.Food &&
                    good.category !== GoodCategory.Luxury).length);
                // final answer
                return new QualityOfLifeItem(lackOfFoodDetractor * lackOfOtherGoodsDetractor * qol, categoryNeed, subCategoryNeed, goodNeed, goodInfo.qualityOfLife, affinity, quality, lackOfFoodDetractor, lackOfOtherGoodsDetractor);
            default:
                throw new Error(`Unknown good category: ${goodInfo.category}`);
        }
    }
    static getCategoryInfo(category) {
        switch (category) {
            case GoodCategory.Food:
                return {
                    subCategories: [
                        GoodSubCategory.Grain,
                        GoodSubCategory.Meat,
                        GoodSubCategory.Vegetable,
                    ],
                    baseCurve: [
                        1, 1, 1, 1, 1, 1, 1, 1, 1, 0.95, 0.92, 0.89, 0.84, 0.75, 0.64, 0.55,
                        0.44, 0.34, 0.26, 0.2, 0.17, 0.14, 0.1,
                    ],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                };
            case GoodCategory.Alcohol:
                return {
                    subCategories: [
                        GoodSubCategory.Wine,
                        GoodSubCategory.Beer,
                        GoodSubCategory.Spirits,
                    ],
                    baseCurve: [1, 0.95, 0.89, 0.75, 0.55, 0.34, 0.2, 0.17, 0.1],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                };
            case GoodCategory.Clothing:
                return {
                    subCategories: [
                        GoodSubCategory.Fabric,
                        GoodSubCategory.Footwear,
                        GoodSubCategory.Apparel,
                        GoodSubCategory.Accessories,
                    ],
                    baseCurve: [
                        1, 1, 1, 1, 1, 0.95, 0.92, 0.89, 0.84, 0.75, 0.64, 0.55, 0.44, 0.34,
                        0.26, 0.2, 0.17, 0.14, 0.1,
                    ],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                };
            case GoodCategory.Equipment:
                return {
                    subCategories: [
                        GoodSubCategory.FarmingTools,
                        GoodSubCategory.HouseholdTools,
                        GoodSubCategory.BuildingTools,
                        GoodSubCategory.KnowledgeTools,
                    ],
                    baseCurve: [1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            case GoodCategory.Household:
                return {
                    subCategories: [
                        GoodSubCategory.Tableware,
                        GoodSubCategory.Storage,
                        GoodSubCategory.Cooking,
                        GoodSubCategory.Lighting,
                        GoodSubCategory.Textiles,
                    ],
                    baseCurve: [1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            case GoodCategory.Health:
                return {
                    subCategories: [
                        GoodSubCategory.Herbs,
                        GoodSubCategory.Tinctures,
                        GoodSubCategory.Salve,
                    ],
                    baseCurve: [1, 1, 1, 1, 1, 0.95, 0.92, 0.89, 0.84, 0.75, 0.64],
                    curveDefault: 0.64,
                    curveMultiplier: 1,
                };
            case GoodCategory.Military:
                return {
                    subCategories: [
                        GoodSubCategory.Sword,
                        GoodSubCategory.Axe,
                        GoodSubCategory.Spear,
                        GoodSubCategory.Armor,
                    ],
                    baseCurve: [0],
                    curveDefault: 0,
                    curveMultiplier: 0,
                };
            case GoodCategory.Luxury:
                return {
                    subCategories: [
                        GoodSubCategory.Jewelry,
                        GoodSubCategory.Spice,
                        GoodSubCategory.Incense,
                    ],
                    baseCurve: [1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            default:
                throw new Error(`Unknown good category: ${category}`);
        }
    }
    static getSubCategoryInfo(subCategory) {
        switch (subCategory) {
            case GoodSubCategory.Grain:
                return {
                    subCategories: [
                        GoodType.Wheat,
                        GoodType.Rutabaga,
                        GoodType.Potato,
                        GoodType.Rice,
                        GoodType.Sap,
                    ],
                    baseCurve: [1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            case GoodSubCategory.Meat:
                return {
                    subCategories: [
                        GoodType.Beef,
                        GoodType.Mutton,
                        GoodType.Venison,
                        GoodType.Fish,
                    ],
                    baseCurve: [1.5, 1.4, 1.3, 1.2, 1.1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            case GoodSubCategory.Vegetable:
                return {
                    subCategories: [
                        GoodType.Carrot,
                        GoodType.Cabbage,
                        GoodType.Turnip,
                        GoodType.Peas,
                    ],
                    baseCurve: [1.5, 1.4, 1.3, 1.2, 1.1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            // Add new cases for each subcategory
            case GoodSubCategory.Wine:
            case GoodSubCategory.Beer:
            case GoodSubCategory.Spirits:
            case GoodSubCategory.Footwear:
            case GoodSubCategory.Apparel:
            case GoodSubCategory.Accessories:
            case GoodSubCategory.FarmingTools:
            case GoodSubCategory.HouseholdTools:
            case GoodSubCategory.BuildingTools:
            case GoodSubCategory.KnowledgeTools:
            case GoodSubCategory.Tableware:
            case GoodSubCategory.Storage:
            case GoodSubCategory.Cooking:
            case GoodSubCategory.Lighting:
            case GoodSubCategory.Textiles:
            case GoodSubCategory.Herbs:
            case GoodSubCategory.Tinctures:
            case GoodSubCategory.Salve:
            case GoodSubCategory.Jewelry:
            case GoodSubCategory.Spice:
            case GoodSubCategory.Incense:
                return {
                    subCategories: [],
                    baseCurve: [1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                };
            default:
                throw new Error(`Unknown good sub category: ${subCategory}`);
        }
    }
    static getGoodInfo(goodType) {
        switch (goodType) {
            // Food
            case GoodType.Wheat:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Grain,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [new CharacterTrait('Agrarian', 1)],
                    // curve
                    baseCurve: [1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 15,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.FiberProcessing,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Rutabaga:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Grain,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.RutabagaKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.RutabagaKnowledge,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.WeatherAttunement,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Potato:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Grain,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.PotatoKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.PotatoKnowledge,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.Earthworking,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Rice:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Grain,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 12,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.RiceKnowledge,
                                amount: 0.65,
                            },
                            {
                                experienceType: ExperienceType.WaterAttunement,
                                amount: 0.35,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.RiceKnowledge,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.FiberProcessing,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.WaterAttunement,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Beef:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Meat,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.3, 1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 25,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.CowBehavior,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.01,
                            finSlope: 0,
                            xAdj: 3,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.CowBehavior,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Mutton:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Meat,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.3, 1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 25,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.SheepBehavior,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.01,
                            finSlope: 0,
                            xAdj: 3,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.SheepBehavior,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Venison:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Meat,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.3, 1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 35,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.DeerBehavior,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.DeerBehavior,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.AnimalProcessing,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.Tracking,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.WeatherAttunement,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Fish:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Meat,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.5, 1.1, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 50,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.FishBehavior,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.001,
                            finSlope: 0,
                            xAdj: 4.1,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.FishBehavior,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Carrot:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Vegetable,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.5, 1.2, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 5,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.CarrotKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.004,
                            finSlope: 0,
                            xAdj: 3.4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.CarrotKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Cabbage:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Vegetable,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.5, 1.2, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 5,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.CabbageKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.CabbageKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Turnip:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Vegetable,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.5, 1.2, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 5,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.TurnipKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.004,
                            finSlope: 0,
                            xAdj: 3.4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.TurnipKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Peas:
                return {
                    category: GoodCategory.Food,
                    subCategory: GoodSubCategory.Vegetable,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1.5, 1.2, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 5,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.PeaKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.PeaKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Ale:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Beer,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 5,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Lager:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Beer,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Stout:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Beer,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Whiskey:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Spirits,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Rum:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Spirits,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Vodka:
                return {
                    category: GoodCategory.Alcohol,
                    subCategory: GoodSubCategory.Spirits,
                    startingPrice: 1,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [2, 1.3, 1],
                    curveDefault: 1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 10,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            // Clothing
            // Fabrics
            case GoodType.Linen:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Fabric,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.3, 0.1],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.Weaving,
                                amount: 1,
                            },
                            {
                                experienceType: ExperienceType.ClothProduction,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0,
                            finSlope: 0,
                            xAdj: 0,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodQualityCurve, [
                            {
                                experienceType: ExperienceType.Weaving,
                                amount: 1,
                            },
                            {
                                experienceType: ExperienceType.ClothProduction,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0,
                            finSlope: 0,
                            xAdj: 0,
                            yAdj: 1,
                        })),
                    ],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.Weaving,
                            amount: 1,
                        },
                        {
                            experienceType: ExperienceType.ClothProduction,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Boots:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Footwear,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.3, 0.1],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Sandals:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Footwear,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.3, 0.1],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Tunic:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Apparel,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.3, 0.1],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.TunicProduction,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0,
                            finSlope: 0,
                            xAdj: 0,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.TunicProduction,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Cloak:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Apparel,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.6, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Breeches:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Apparel,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.6, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Robe:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Apparel,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.6, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Belt:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Accessories,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Hat:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Accessories,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.6, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Gloves:
                return {
                    category: GoodCategory.Clothing,
                    subCategory: GoodSubCategory.Accessories,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.8, 0.6, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            // Equipment
            case GoodType.Hammer:
                return {
                    category: GoodCategory.Equipment,
                    subCategory: GoodSubCategory.BuildingTools,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Saw:
                return {
                    category: GoodCategory.Equipment,
                    subCategory: GoodSubCategory.BuildingTools,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Pickaxe:
                return {
                    category: GoodCategory.Equipment,
                    subCategory: GoodSubCategory.BuildingTools,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.1,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            // Household
            case GoodType.Plate:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Tableware,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Cup:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Tableware,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Bowl:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Tableware,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Chest:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Storage,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Barrel:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Storage,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Basket:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Storage,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Pot:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Cooking,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Pan:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Cooking,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Knife:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Cooking,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Candle:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Lighting,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Lantern:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Lighting,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Torch:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Lighting,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Blanket:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Textiles,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Cushions:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Textiles,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Rug:
                return {
                    category: GoodCategory.Household,
                    subCategory: GoodSubCategory.Textiles,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 1, 1, 1, 0.8, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            // Health
            case GoodType.Basil:
                return {
                    category: GoodCategory.Health,
                    subCategory: GoodSubCategory.Herbs,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Mint:
                return {
                    category: GoodCategory.Health,
                    subCategory: GoodSubCategory.Herbs,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Lavender:
                return {
                    category: GoodCategory.Health,
                    subCategory: GoodSubCategory.Herbs,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            // Luxury
            case GoodType.Necklace:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Jewelry,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Ring:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Jewelry,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Bracelet:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Jewelry,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Pepper:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Spice,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Cinnamon:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Spice,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Saffron:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Spice,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Frankincense:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Incense,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Myrrh:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Incense,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            case GoodType.Sandalwood:
                return {
                    category: GoodCategory.Luxury,
                    subCategory: GoodSubCategory.Incense,
                    startingPrice: 10,
                    qualityOfLife: 1,
                    characterTraits: [
                        new CharacterTrait(CharacterType[CharacterType.Agrarian], 1),
                    ],
                    // curve
                    baseCurve: [1, 0.5],
                    curveDefault: 0.5,
                    curveMultiplier: 1,
                    // base production density
                    baseProductionDensity: 1,
                    productionAmountExperienceCurves: [
                        new ExperienceCurveInfo(ImprovementCurveEnum.MainGoodAmountCurve, [
                            {
                                experienceType: ExperienceType.WheatKnowledge,
                                amount: 1,
                            },
                        ], new FunctionInfo('exponential decay', {
                            initSlope: 0.029,
                            finSlope: 0,
                            xAdj: 4,
                            yAdj: 100,
                        })),
                    ],
                    productionQualityExperienceCurves: [],
                    // gained experience
                    modifyProducer: [
                        {
                            experienceType: ExperienceType.WheatKnowledge,
                            amount: 1,
                        },
                    ],
                };
            default:
                throw new Error('GoodTypeMethods.getGoodInfo: Good type not found');
        }
    }
}
export { GoodType, GoodTypeMethods, GoodSubCategory, GoodCategory };
