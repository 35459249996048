import RealizedTaxItem from '../EconomicData/TaxTracking/TaxItem.js';
import Administration from './Administration.js';
import Department from './Department/Department/Department.js';
import Load from './Load.js';
import Responsibility from './Responsibility.js';
class CityAdministration extends Administration {
    constructor(balance, lastCycleRevenueAmount, lastCycleTaxes, lastCycleSpending, governor) {
        super(balance, lastCycleRevenueAmount, lastCycleTaxes, lastCycleSpending);
        this.governorsOffice = governor;
    }
    // #region fetch
    getDepartmentByRole(role) {
        return this.governorsOffice.getDepartmentByRole(role);
    }
    getDepartmentById(id) {
        const found = this.governorsOffice.getDepartmentById(id);
        if (found)
            return found;
        return null;
    }
    // #endregion
    // #region utils
    isValid(gameState) {
        return this.governorsOffice.isDepartmentValid(gameState);
    }
    updateStatsFromServer(client) {
        this.governorsOffice.updateStatsFromServer(client.governorsOffice);
    }
    updateFromClient(client, nation, city, gameState) {
        this.governorsOffice.updateFromClient(client.governorsOffice, nation, city, null, // managing office
        this, gameState);
    }
    // #endregion
    // #region load, etc.
    clearLoad() {
        this.governorsOffice.clearLoad();
    }
    addLoad(role, load) {
        const department = this.getDepartmentByRole(role);
        if (department) {
            department.addLoad(new Load(new Responsibility(role), load, 0));
        }
    }
    clearExperience() {
        this.governorsOffice.clearOrgExperience();
    }
    distributeExperience() {
        this.governorsOffice.distributeExperience([]);
    }
    calculateEffectiveness() {
        this.governorsOffice.calculateEffectiveness();
    }
    gainExperience() {
        this.governorsOffice.gainAndLoseExperience();
    }
    // #endregion
    // #region JSON and Clone
    clone() {
        return new CityAdministration(this.balance, this.lastCycleRevenueAmount, this.lastCycleTaxes.map((tax) => tax.clone()), this.lastCycleSpending, this.governorsOffice.clone());
    }
    toJSON() {
        return {
            balance: this.balance,
            lastCycleRevenueAmount: this.lastCycleRevenueAmount,
            lastCycleTaxes: this.lastCycleTaxes.map((tax) => tax.toJSON()),
            lastCycleSpending: this.lastCycleSpending,
            governor: this.governorsOffice.toJSON(),
        };
    }
    static fromJSON(json) {
        return new CityAdministration(json.balance, json.lastCycleRevenueAmount, json.lastCycleTaxes.map((tax) => RealizedTaxItem.fromJSON(tax)), json.lastCycleSpending, Department.fromJSON(json.governor));
    }
}
export default CityAdministration;
