import React, { useState, useEffect, useContext, Component } from 'react';

// components
import Cycler from './Cycler.tsx';
// css
import { EnumListStyled } from './styles/EnumList.styled.js';

function EnumList({ title, enumObject, values, save }) {
  const handleNewValue = () => {
    const newValue = createNewValue();
    values.push(newValue);
    save(values);
  };

  const createNewValue = () => {
    const newValue = Object.keys(enumObject)[0];
    return newValue;
  };

  const deleteValue = (index: number) => {
    values.splice(index, 1);
    save(values);
  };

  // get string values from numeric enum
  const stringValues = Object.keys(enumObject)
    .map((item) => enumObject[item])
    .filter((value) => isNaN(value));

  return (
    <EnumListStyled>
      <p className='enum-list-title'>{title}</p>
      <button className='add-enum-button' onClick={() => handleNewValue()}>
        Add
      </button>
      <div className='enum-items'>
        {values?.map((v, index) => (
          <div className='enum-item' key={index}>
            <Cycler
              key={index}
              items={stringValues}
              onItemChange={(stringValue) => {
                values[index] = enumObject[stringValue];
                save(values);
              }}
              currentItem={enumObject[v]}
            />
            <button onClick={() => deleteValue(index)}>Delete</button>
          </div>
        ))}
      </div>
    </EnumListStyled>
  );
}

export default EnumList;
