var TaxType;
(function (TaxType) {
    TaxType["ConsumptionSubsidy"] = "ConsumptionSubsidy";
    TaxType["ConsumptionTax"] = "ConsumptionTax";
    TaxType["ExportDuty"] = "ExportDuty";
    TaxType["ExportTariff"] = "ExportTariff";
    TaxType["ImportDuty"] = "ImportDuty";
    TaxType["ImportTariff"] = "ImportTariff";
    TaxType["ImprovementSubsidy"] = "ImprovementSubsidy";
    TaxType["ProductionSubsidy"] = "ProductionSubsidy";
    TaxType["ProductionTax"] = "ProductionTax";
    TaxType["TransitDuty"] = "TransitDuty";
})(TaxType || (TaxType = {}));
export { TaxType };
