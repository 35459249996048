import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';

// GildedLands imports
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import { TimeMethods } from '../../../../../../../GildedLands/lib/Enums/Time';

const getInfoSidebarData = (
  gameState: GameState,
  centerByTile: (tileId: number) => void,
  openCityScreen: (tileId: number) => void,
  setSelectedUnit: (unit: any) => void
): React.ReactNode => {
  const data: React.ReactNode[] = [];

  const citiesSection = renderCitiesSection(
    gameState,
    centerByTile,
    openCityScreen
  );
  const civilianUnitsSection = renderCivilianUnitsSection(
    gameState,
    centerByTile,
    setSelectedUnit
  );
  const militaryUnitsSection = renderMilitaryUnitsSection(
    gameState,
    centerByTile,
    setSelectedUnit
  );

  data.push(citiesSection, civilianUnitsSection, militaryUnitsSection);

  return <VerticalList data={data} />;
};

const renderCitiesSection = (
  gameState: GameState,
  centerByTile: (tileId: number) => void,
  openCityScreen: (tileId: number) => void
): React.ReactNode => {
  const citiesLineItems = gameState.cities.map((city) =>
    renderCityLineItem(city, centerByTile, openCityScreen)
  );

  return (
    <CenterTitle
      key='cities-section'
      title='Cities'
      padding={UIPadding.Short}
      backgroundData={{
        color: UIColor.HeaderBlue,
        opacity: UIOpacity.Header,
      }}
      dropdown={
        <VerticalList
          data={citiesLineItems}
          backgroundData={{
            color: UIColor.Blue6,
            opacity: UIOpacity.List,
          }}
        />
      }
    />
  );
};

const renderCityLineItem = (
  city: City,
  centerByTile: (tileId: number) => void,
  openCityScreen: (tileId: number) => void
): React.ReactNode => (
  <LineItemStandard
    key={`city-${city.instanceId}`}
    title={city.name}
    titleColor={UIColor.White}
    inlineDataFields={[
      {
        fieldData: {
          fieldName: 'Pop.',
          textColor: UIColor.White,
          iconUrl:
            'https://ik.imagekit.io/hfywj4j0a/GildedLands/Images/wheat_icon.png',
          value: `${city.persons.length}k`,
        },
        dropdown: null,
      },
    ]}
    onMainClick={() => centerByTile(city.centerTileLandtileId)}
    onDoubleClick={() => openCityScreen(city.centerTileLandtileId)}
  />
);

const renderCivilianUnitsSection = (
  gameState: GameState,
  centerByTile: (tileId: number) => void,
  setSelectedUnit: (unit: any) => void
): React.ReactNode => {
  const civilianUnitsLineItems = gameState.civilianUnits.map((unit) =>
    renderUnitLineItem(unit, centerByTile, setSelectedUnit)
  );

  return (
    <CenterTitle
      key='civilian-units-section'
      title='Civilian Units'
      padding={UIPadding.Short}
      backgroundData={{
        color: UIColor.HeaderBlue,
        opacity: UIOpacity.Header,
      }}
      dropdown={
        <VerticalList
          data={civilianUnitsLineItems}
          backgroundData={{
            color: UIColor.Blue6,
            opacity: UIOpacity.List,
          }}
        />
      }
    />
  );
};

const renderMilitaryUnitsSection = (
  gameState: GameState,
  centerByTile: (tileId: number) => void,
  setSelectedUnit: (unit: any) => void
): React.ReactNode => {
  const militaryUnitsLineItems = gameState.militaryUnits.map((unit) =>
    renderUnitLineItem(unit, centerByTile, setSelectedUnit)
  );

  return (
    <CenterTitle
      key='military-units-section'
      title='Military Units'
      padding={UIPadding.Short}
      backgroundData={{
        color: UIColor.HeaderBlue,
        opacity: UIOpacity.Header,
      }}
      dropdown={
        <VerticalList
          data={militaryUnitsLineItems}
          backgroundData={{
            color: UIColor.ListBlue,
            opacity: UIOpacity.List,
          }}
        />
      }
    />
  );
};

const renderUnitLineItem = (
  unit: any,
  centerByTile: (tileId: number) => void,
  setSelectedUnit: (unit: any) => void
): React.ReactNode => {
  const remainingTime = calculateRemainingTime(unit);

  return (
    <LineItemStandard
      key={`unit-${unit.instanceId}`}
      title={unit.name}
      titleColor={UIColor.White}
      inlineDataFields={[
        {
          fieldData: {
            fieldName: null,
            textColor: UIColor.White,
            value: remainingTime,
          },
          dropdown: null,
        },
      ]}
      onMainClick={() => {
        centerByTile(unit.tileId);
        setSelectedUnit(unit);
      }}
      onDoubleClick={() => setSelectedUnit(unit)}
    />
  );
};

const calculateRemainingTime = (unit: any): string => {
  const timeInDays = unit.timeToFinalDestination();
  if (timeInDays === 0) {
    return 'Idle';
  }
  return Math.floor(timeInDays) + ' days';
};

export default getInfoSidebarData;
