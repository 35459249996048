// external
import React, { useState, useEffect, useContext } from 'react';

// css
import { WholeButtonStyled } from './styles/WholeButton.styled.js';
import { UIColor } from '../Enums/UIEnums.js';

const WholeButton = ({
  title,
  onClick,
  color,
}: {
  title: string | null;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  color: UIColor;
}) => {
  return (
    <WholeButtonStyled
      className='whole-button'
      onClick={(e) => onClick(e)}
      color={color}
    >
      {title}
    </WholeButtonStyled>
  );
};

export default WholeButton;
