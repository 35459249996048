import styled from 'styled-components';
import '../../../../styles/constants.css';

export const SoldierExperiencePageStyled = styled.div`
  .soldier-experience-content {
    position: absolute;
    top: var(--game-ui-margin);
    left: var(--game-ui-margin);
    width: calc(100% - var(--game-ui-margin) * 2);
    height: calc(100% - var(--game-ui-margin) * 2);
    pointer-events: auto;
    overflow-y: hidden;
  }

  .soldier-experience-info-list {
    max-height: 100%;
    overflow-y: auto;
  }
`;
