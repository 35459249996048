import Cohort from './Cohort.js';
class ActiveArmy {
    constructor(cohorts, supplies, dugIn) {
        this.cohorts = cohorts;
        this.supplies = supplies;
        this.dugIn = dugIn;
    }
    runStatsForBattle(battle) {
        this.cohorts.forEach((cohort) => cohort.updateStatsForBattle(battle));
    }
    getAverageOffense(battle) {
        return (this.cohorts.reduce((acc, cohort) => acc + cohort.lastFightStats.offensiveOutput.value, 0) / this.cohorts.length);
    }
    getAverageResilience(battle) {
        return (this.cohorts.reduce((acc, cohort) => acc + cohort.lastFightStats.resilience.value, 0) / this.cohorts.length);
    }
    // #region Clone, toJSON, and fromJSON
    clone() {
        return new ActiveArmy(this.cohorts.map((cohort) => cohort.clone()), this.supplies.map((supply) => ({
            type: supply.type,
            amount: supply.amount,
        })), this.dugIn);
    }
    toJSON() {
        return {
            cohorts: this.cohorts.map((cohort) => cohort.toJSON()),
            supplies: this.supplies.map((supply) => ({
                type: supply.type,
                amount: supply.amount,
            })),
            dugIn: this.dugIn,
        };
    }
    static fromJSON(json) {
        return new ActiveArmy(json.cohorts.map((cohort) => Cohort.fromJSON(cohort)), json.supplies.map((supply) => ({
            type: supply.type,
            amount: supply.amount,
        })), json.dugIn);
    }
}
export default ActiveArmy;
