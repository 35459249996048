import React from 'react';

// Components
import { UIPadding, UIColor, UIOpacity } from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';

// GildedLands imports
import City from '../../../../../../../GildedLands/lib/Classes/City/City';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement.js';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods.js';

// Other imports
import getImprovementDescription from './ImprovementDescription.tsx';
import getRequirementInfo from './RequirementsInfo.tsx';
import ImageBox from '../../GenericComponents/ImageBox.tsx';

const getImprovementTypeInfo = (
  improvementType: ImprovementType,
  city: City,
  gameState: GameState
): React.ReactNode => {
  const improvementInfo =
    ImprovementTypeMethods.getImprovementInfo(improvementType);

  if (!improvementInfo) {
    return null;
  }

  const data: React.ReactNode[] = [];

  if (improvementInfo.image && improvementInfo.image !== '') {
    data.push(<ImageBox url={improvementInfo.image} height='200px' />);
  }

  data.push(renderImprovementDescription(improvementType));

  data.push(renderHeader('Requirements'));

  data.push(renderRequirementsInfo(improvementType, city, gameState));

  return <VerticalList data={data} />;
};

const renderHeader = (title: string): React.ReactNode => (
  <CenterTitle
    title={title}
    padding={UIPadding.Short}
    backgroundData={{
      color: UIColor.HeaderBlue,
      opacity: UIOpacity.Header,
    }}
  />
);

const renderImprovementDescription = (
  improvementType: ImprovementType
): React.ReactNode => {
  const description = getImprovementDescription(improvementType);
  const descriptionContent = Array.isArray(description)
    ? description
    : [description];

  return (
    <VerticalList
      data={descriptionContent}
      backgroundData={{
        color: UIColor.Blue6,
        opacity: UIOpacity.List,
      }}
    />
  );
};

const renderRequirementsInfo = (
  improvementType: ImprovementType,
  city: City,
  gameState: GameState
): React.ReactNode => {
  const requirements = getRequirementInfo(improvementType, city, gameState);
  const requirementsContent = Array.isArray(requirements)
    ? requirements
    : [requirements];

  return (
    <VerticalList
      data={requirementsContent}
      backgroundData={{
        color: UIColor.Blue6,
        opacity: UIOpacity.List,
      }}
    />
  );
};

export default getImprovementTypeInfo;
