import QualityOfLifeItem from './QualityOfLifeItem.js';
import AllShippingAndTaxesItem from '../TaxTracking/AllShippingAndTaxesItem.js';
class ConsumptionLineItem {
    constructor(goodType, cityInfo, category, subCategory, quality, qualityOfLife, cost, shippingAndTaxes) {
        this.goodType = goodType;
        this.cityInfo = cityInfo;
        this.category = category;
        this.subCategory = subCategory;
        this.quality = quality;
        this.qualityOfLife = qualityOfLife;
        this.cost = cost;
        this.shippingAndTaxes = shippingAndTaxes;
    }
    clone() {
        return new ConsumptionLineItem(this.goodType, Object.assign({}, this.cityInfo), this.category, this.subCategory, this.quality, this.qualityOfLife.clone(), this.cost, this.shippingAndTaxes.clone());
    }
    toJSON() {
        return {
            goodType: this.goodType,
            cityInfo: Object.assign({}, this.cityInfo),
            category: this.category,
            subCategory: this.subCategory,
            quality: this.quality,
            qualityOfLife: this.qualityOfLife.toJSON(),
            cost: this.cost,
            shippingAndTaxes: this.shippingAndTaxes.toJSON(),
        };
    }
    static fromJSON(json) {
        return new ConsumptionLineItem(json.goodType, Object.assign({}, json.cityInfo), json.category, json.subCategory, json.quality, QualityOfLifeItem.fromJSON(json.qualityOfLife), json.cost, AllShippingAndTaxesItem.fromJSON(json.shippingAndTaxes));
    }
}
export default ConsumptionLineItem;
