import FunctionInfo from '../Improvement/FunctionInfo.js';
export default class EquipmentCurveInfo {
    constructor(name, goodType, functionInfo) {
        this.name = name;
        this.goodType = goodType;
        this.functionInfo = functionInfo;
    }
    evaluate(goodQuality) {
        return this.functionInfo.evaluate(goodQuality);
    }
    // #region Clone and JSON
    clone() {
        return new EquipmentCurveInfo(this.name, this.goodType, this.functionInfo.clone());
    }
    toJSON() {
        return {
            name: this.name,
            goodType: this.goodType,
            functionInfo: this.functionInfo.toJSON(),
        };
    }
    static fromJSON(json) {
        return new EquipmentCurveInfo(json.name, json.goodType, FunctionInfo.fromJSON(json.functionInfo));
    }
}
