// MapViewContext.tsx
import React, { createContext, useState } from 'react';

export const MapViewContext = createContext(null);

export const MapViewContextProvider = ({ children }) => {
  const [centerOnTileId, setCenterOnTileId] = useState<number | null>(null);

  return (
    <MapViewContext.Provider value={{ centerOnTileId, setCenterOnTileId }}>
      {children}
    </MapViewContext.Provider>
  );
};
