import React from 'react';
import City from '../../../../../../../GildedLands/lib/Classes/City/City.js';
import GameState from '../../../../../../../GildedLands/lib/Classes/Game/GameState.js';
import { MissionMethods } from '../../../../../../../GildedLands/lib/Enums/Mission.js';
import {
  UIPadding,
  UIColor,
  UIOpacity,
  UITextSize,
} from '../../Enums/UIEnums.ts';
import CenterTitle from '../../GenericComponents/CenterTitle.tsx';
import LineItemStandard from '../../GenericComponents/LineItemStandard.tsx';
import TextBox from '../../GenericComponents/TextBox.tsx';
import VerticalList from '../../GenericComponents/VerticalList.tsx';
import RequirementReturnComponent from '../Requirements/RequirementReturn.tsx';
import { GoodType } from '../../../../../../../GildedLands/lib/Enums/Good';
import {
  Requirement,
  RequirementTypeMethods,
} from '../../../../../../../GildedLands/lib/Enums/Requirement';
import { ExperienceType } from '../../../../../../../GildedLands/lib/Enums/Experience';
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement';
import RuntimeLandTile from '../../../../../../../GildedLands/lib/Classes/Land/LandTile/RuntimeLandtile';
import { ImprovementTypeMethods } from '../../../../../../../GildedLands/lib/Enums/ImprovementTypeMethods';
import { IImprovementProduction } from '../../../../../../../GildedLands/lib/Enums/ImprovementInfo';

const getSelectProductionData = (
  selectedGoodType: GoodType,
  setSelectedGoodType: (goodType: GoodType) => void,
  improvementType: ImprovementType,
  tileIndex: number,
  gameState: GameState
): React.ReactNode => {
  console.log('getSelectProductionData for tile', tileIndex);

  const landTile = gameState.getLandTileByTileId(tileIndex);
  if (!landTile) throw new Error('Land tile not found');

  const info = ImprovementTypeMethods.getImprovementInfo(improvementType);

  const data: React.ReactNode[] = [];

  for (let possibleProduction of info.capacity.possibleProduction) {
    data.push(
      getProductionLineItem(
        possibleProduction,
        improvementType,
        landTile,
        gameState,
        selectedGoodType,
        setSelectedGoodType
      )
    );
  }

  return <VerticalList data={data} />;
};

const getProductionLineItem = (
  possibleProductionItem: IImprovementProduction,
  improvementType: ImprovementType,
  landTile: RuntimeLandTile,
  gameState: GameState,
  selectedGoodType: GoodType,
  setSelectedGoodType: (goodType: GoodType) => void
): React.ReactNode => {
  const requirementReturn = RequirementTypeMethods.checkRequirement(
    possibleProductionItem.requirements,
    improvementType,
    landTile,
    gameState
  );

  let dropdown = null;
  if (!requirementReturn.isMet) {
    dropdown = (
      <VerticalList
        data={[<RequirementReturnComponent reqReturn={requirementReturn} />]}
      />
    );
  }

  const titleColor = requirementReturn.isMet ? UIColor.White : UIColor.Red;

  return (
    <LineItemStandard
      title={possibleProductionItem.goodName}
      titleColor={titleColor}
      dropdown={dropdown}
      onMainClick={
        !dropdown
          ? () => {
              setSelectedGoodType(possibleProductionItem.goodType);
            }
          : null
      }
      inlineFieldsAlignment='even'
      selectorData={
        !dropdown
          ? {
              amSelected: possibleProductionItem.goodType === selectedGoodType,
            }
          : null
      }
    />
  );
};

export default getSelectProductionData;
