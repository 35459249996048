import styled from 'styled-components';
import '../../../../../../styles/constants.css';

export const OverviewScreenStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .tax-revenue-summary-title {
    margin-top: 10px;
  }
`;
