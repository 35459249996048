// external
import React, { useState, useEffect, useContext } from 'react';

// internal
// components
import Economy from './Economy/Economy.tsx';
import CityScreen from './CityScreen/CityScreen.tsx';
import Taxes from './Taxes/Taxes.tsx';
import TileInfo from './MapOverlay/TileInfo/TileInfo.tsx';
import UnitInfo from './MapOverlay/UnitInfo/UnitInfo.tsx';
import MapOverlay from './MapOverlay/MapOverlay.tsx';
import AdministrationScreen from './Administration/AdministrationScreen.tsx';
import SelectProductionPopup from './CityScreen/SelectProductionPopup.tsx';
// internal context
import { ScreenModeContext } from '../../../contexts/ScreenAndMap/ScreenModeContextProvider.tsx';
import { SelectedEntityContext } from '../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { ProductionPopupDataContext } from '../../../contexts/PopupsAndNotifications/SelectProductionPopupContextProvider.tsx';
// css
import { OverlayStyled } from './styles/Overlay.styled.js';
import InfoSidebar from './MapOverlay/InfoSidebar.tsx';
import Popups from './MapOverlay/Popups.tsx';

const Overlay = () => {
  const { screenMode, setScreenMode, ScreenMode } =
    useContext(ScreenModeContext);
  const { selectedUnit } = useContext(SelectedEntityContext);
  return (
    <OverlayStyled className='overlay'>
      {screenMode !== ScreenMode.CityScreen && <MapOverlay />}
      {screenMode === ScreenMode.EconomyScreen && <Economy />}
      {screenMode === ScreenMode.TaxesScreen && <Taxes />}
      {screenMode === ScreenMode.CityScreen && <CityScreen />}
      {screenMode === ScreenMode.AdministrationScreen && (
        <AdministrationScreen />
      )}
      {screenMode !== ScreenMode.Map &&
        screenMode !== ScreenMode.CityScreen && (
          <div
            className='fader'
            onClick={() => setScreenMode(ScreenMode.Map)}
          ></div>
        )}
      <Popups />
      {!selectedUnit && <TileInfo />}
    </OverlayStyled>
  );
};

export default Overlay;
