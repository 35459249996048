// tiles
import { BiomeTypeMethods } from '../../Enums/Biome.js';
class TradeRoute {
    constructor(tradeRouteType, originCityInstanceId, destinationCityInstanceId, landTileIds, controllingFortInstanceIds, controllingCityInstanceIds, shippingCost, exportDutyIds, importDutyIds, transitDutyIds) {
        this.tradeRouteType = tradeRouteType;
        this.originCityInstanceId = originCityInstanceId;
        this.destinationCityInstanceId = destinationCityInstanceId;
        this.landTileIds = landTileIds;
        this.controllingFortInstanceIds = controllingFortInstanceIds;
        this.controllingCityInstanceIds = controllingCityInstanceIds;
        this.shippingCost = shippingCost;
        this.exportDutyIds = exportDutyIds;
        this.importDutyIds = importDutyIds;
        this.transitDutyIds = transitDutyIds;
    }
    updateCosts(gameState) {
        this.shippingCost = 0;
        for (const tileId of this.landTileIds) {
            const tile = gameState.getLandTileByTileId(tileId);
            // costs
            this.shippingCost += BiomeTypeMethods.shippingCostPerTile(tile.mainBiomeType);
            // taxes
            const city = gameState.getCityByInstanceId(tile.cityInstanceId);
            if (city) {
                const duties = gameState.getDutiesForCity(city.instanceId);
                this.exportDutyIds.push(...duties.exportDuties.map((duty) => duty.instanceId));
                this.importDutyIds.push(...duties.importDuties.map((duty) => duty.instanceId));
                this.transitDutyIds.push(...duties.transitDuties.map((duty) => duty.instanceId));
            }
        }
        // filter out duplicates
        this.exportDutyIds = [...new Set(this.exportDutyIds)];
        this.importDutyIds = [...new Set(this.importDutyIds)];
        this.transitDutyIds = [...new Set(this.transitDutyIds)];
    }
    clone() {
        return new TradeRoute(this.tradeRouteType, this.originCityInstanceId, this.destinationCityInstanceId, [...this.landTileIds], [...this.controllingFortInstanceIds], [...this.controllingCityInstanceIds], this.shippingCost, [...this.exportDutyIds], [...this.importDutyIds], [...this.transitDutyIds]);
    }
    toJSON() {
        return {
            tradeRouteType: this.tradeRouteType,
            originCityInstanceId: this.originCityInstanceId,
            destinationCityInstanceId: this.destinationCityInstanceId,
            landTileIds: [...this.landTileIds],
            controllingFortInstanceIds: [...this.controllingFortInstanceIds],
            controllingCityInstanceIds: [...this.controllingCityInstanceIds],
            shippingCost: this.shippingCost,
            exportDutyIds: [...this.exportDutyIds],
            importDutyIds: [...this.importDutyIds],
            transitDutyIds: [...this.transitDutyIds],
        };
    }
    static fromJSON(json) {
        return new TradeRoute(json.tradeRouteType, json.originCityInstanceId, json.destinationCityInstanceId, [...json.landTileIds], [...json.controllingFortInstanceIds], [...json.controllingCityInstanceIds], json.shippingCost, [...json.exportDutyIds], [...json.importDutyIds], [...json.transitDutyIds]);
    }
}
export default TradeRoute;
