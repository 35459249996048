import styled from 'styled-components';
import '../../../../../styles/constants.css';

export const BuildImprovementPanelStyled = styled.div`
  position: absolute;
  top: 10%;
  left: var(--game-ui-margin);
  width: 500px;

  z-index: 100;

  .improvement-type-info-panel-content {
    position: relative;
    max-height: calc(70vh - 2 * var(--game-ui-margin));
    overflow-y: auto;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: var(--dark-grey);
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    z-index: 101;
  }

  .build-button-container {
    position: relative;
    padding: 10px 0;
    width: 100%;
  }
`;
