import React from 'react';

import { WeaponItemStyled } from './styles/WeaponItem.styled.js';
import RangeSlider from '../../UI/GenericComponents/RangeSlider.tsx';
import { GoodType } from '../../../../../../GildedLands/lib/Enums/Good.js';
import { UIColor, UIOpacity, UIPadding } from '../../UI/Enums/UIEnums.ts';
import SelectionButton from '../../UI/GenericComponents/SelectionButton.tsx';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';

function WeaponItem({ weapon, handleWeaponChange, removeWeapon, index }) {
  return (
    <WeaponItemStyled className='weapon-item rounded'>
      <GenericBackground color={UIColor.ListBlue} opacity={UIOpacity.Header} />
      <div className='weapon-item-content'>
        <div className='top-row'>
          <select
            value={weapon.goodType}
            onChange={(e) =>
              handleWeaponChange(index, 'goodType', e.target.value)
            }
          >
            {Object.values(GoodType).map((good) => (
              <option key={good} value={good}>
                {good}
              </option>
            ))}
          </select>
          <SelectionButton
            title='Primary'
            amSelected={weapon.primary}
            selectable={true}
            onClick={() =>
              handleWeaponChange(index, 'primary', !weapon.primary)
            }
          />

          <SelectionButton
            title='Remove Weapon'
            amSelected={false}
            selectable={true}
            onClick={() => removeWeapon(index)}
          />
        </div>

        <RangeSlider
          title='Quality'
          min={0}
          max={100}
          step={1}
          startingValue={weapon.quality}
          onValueChange={(value) => handleWeaponChange(index, 'quality', value)}
        />
      </div>
    </WeaponItemStyled>
  );
}

export default WeaponItem;
