import styled from 'styled-components';

export const TileImageStyled = styled.img`
  position: absolute;
  z-index: 1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  filter: ${({ amDimmed }) => (amDimmed ? 'brightness(0.6)' : 'none')};
`;
