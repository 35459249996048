// external
import React, { useContext, memo, useState } from 'react';

// context
import { ImageContext } from '../../../../contexts/Media/ImageContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';

// css
import {
  MapImprovementStyled,
  MapImprovementImageStyled,
  MapImprovementShapeStyled,
  MapImprovementLabelStyled,
} from './styles/MapImprovement.styled.js';

const MapImprovement = memo(
  ({
    improvementInstanceId,
    hexagonSize,
  }: {
    improvementInstanceId: number;
    hexagonSize: number;
  }) => {
    const { defaultCityIcon } = useContext(ImageContext);
    const { gameState } = useContext(GameStateDataContext);

    const [isHovered, setIsHovered] = useState(false);

    const squareSize = hexagonSize * 0.5;
    const hexagonCenter = {
      x: (hexagonSize * Math.sqrt(3)) / 2,
      y: hexagonSize,
    };
    const diamondPoints = [
      { x: hexagonCenter.x, y: hexagonCenter.y - squareSize / 2 }, // Top
      { x: hexagonCenter.x + squareSize / 2, y: hexagonCenter.y }, // Right
      { x: hexagonCenter.x, y: hexagonCenter.y + squareSize / 2 }, // Bottom
      { x: hexagonCenter.x - squareSize / 2, y: hexagonCenter.y }, // Left
    ];
    const pointsForSVG = diamondPoints.map((p) => `${p.x},${p.y}`).join(' ');
    const pointsForImage = diamondPoints
      .map(
        (p) =>
          `${(p.x / (hexagonSize * Math.sqrt(3))) * 100}% ${
            (p.y / (hexagonSize * 2)) * 100
          }%`
      )
      .join(', ');

    const improvement = gameState.improvements.find(
      (improvement) => improvement.instanceId === improvementInstanceId
    );

    return (
      <MapImprovementStyled
        className='map-improvement'
        hexagonSize={hexagonSize}
      >
        {isHovered && (
          <MapImprovementLabelStyled
            className='map-improvement-label'
            hexagonSize={hexagonSize}
          >
            <div className='improvement-name'>{improvement?.name}</div>
            {/* <div className='city-population'>Pop. {city?.persons.length}k</div> */}
          </MapImprovementLabelStyled>
        )}
        <MapImprovementImageStyled
          className='map-improvement-image'
          src={defaultCityIcon}
          hexagonSize={hexagonSize}
          points={pointsForImage}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        <MapImprovementShapeStyled
          className='tile-diamond'
          hexagonSize={hexagonSize}
        >
          <polygon className='diamond' points={pointsForSVG} />
        </MapImprovementShapeStyled>
      </MapImprovementStyled>
    );
  }
);

export default MapImprovement;
