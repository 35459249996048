// External dependencies
import React, { useContext, useMemo, useState } from 'react';

// Contexts
import { SelectedEntityContext } from '../../../../contexts/GameData/SelectedEntityContextProvider.tsx';
import { GameStateDataContext } from '../../../../contexts/GameData/GameStateDataContextProvider.tsx';
// Components
import CenterTitle from '../../../UI/GenericComponents/CenterTitle';
import CenterButton from '../../../UI/GenericComponents/CenterButton.tsx';

// Styles
import { BuildImprovementListStyled } from './styles/BuildImprovementList.styled.js';

// Enums
import { UIColor, UIOpacity, UIPadding } from '../../../UI/Enums/UIEnums.ts';

// Utilities
import getBuildingsData from '../../../UI/MappingToData/CityScreen/BuildingsToBuild.tsx';

// Types
import { ImprovementType } from '../../../../../../../GildedLands/lib/Enums/Improvement';
import SelectionButton from '../../../UI/GenericComponents/SelectionButton.tsx';
import GenericBackground from '../../../UI/GenericComponents/Background.tsx';

const BuildImprovementList = () => {
  // #region context
  const { gameState } = useContext(GameStateDataContext);
  const {
    selectedRuntimeImprovement,
    setSelectedRuntimeImprovement,
    selectedCity,
    selectedImprovementTypeInfo,
    setSelectedImprovementTypeInfo,
  } = useContext(SelectedEntityContext);
  // #endregion

  const [filter, setFilter] = useState<string>('buildable');

  const handleSelectImprovementType = (improvementType: ImprovementType) => {
    if (improvementType === selectedImprovementTypeInfo) {
      setSelectedImprovementTypeInfo(null);
    } else {
      setSelectedImprovementTypeInfo(improvementType);
    }
    if (!!selectedRuntimeImprovement) {
      setSelectedRuntimeImprovement(null);
    }
  };

  const buildingsToBuildData = useMemo(() => {
    if (!gameState) return;
    return getBuildingsData(
      gameState,
      selectedCity,
      filter,
      handleSelectImprovementType
    );
  }, [gameState, selectedCity, filter, handleSelectImprovementType]);

  return (
    <BuildImprovementListStyled className='build-improvements-list'>
      <CenterTitle
        title='To Build'
        padding={UIPadding.Tall}
        backgroundData={{
          color: UIColor.Blue,
          opacity: UIOpacity.Full,
        }}
      />
      <div className='filter-buttons'>
        <SelectionButton
          title='All'
          amSelected={filter === 'all'}
          selectable={true}
          onClick={() => setFilter('all')}
        />
        <SelectionButton
          title='Buildable'
          amSelected={filter === 'buildable'}
          selectable={true}
          onClick={() => setFilter('buildable')}
        />
        <GenericBackground color={UIColor.White} opacity={0.5} />
      </div>
      <div className='buildings-list'>{buildingsToBuildData}</div>
    </BuildImprovementListStyled>
  );
};

export default BuildImprovementList;
