// external
import React from 'react';

// comps
import Background, { IGenericBackground } from './Background.tsx';

// contexts
import { ImageContext } from '../../../contexts/Media/ImageContextProvider.tsx';

// css
import { ImageBoxStyled } from './styles/ImageBox.styled.js';
import { UIPadding } from '../Enums/UIEnums.ts';

interface IImageBox {
  url: string;
  height?: string;
  fadeTop?: boolean;
  fadeBottom?: boolean;
}

const ImageBox: React.FC<IImageBox> = ({
  url,
  height,
  fadeTop = false,
  fadeBottom = false,
}) => {
  const { getIKUrl } = React.useContext(ImageContext);

  return (
    <ImageBoxStyled
      className='image-box'
      height={height}
      fadeTop={fadeTop}
      fadeBottom={fadeBottom}
    >
      <img className='image-box-content' src={getIKUrl(url, 1000)} alt='' />
    </ImageBoxStyled>
  );
};

export default ImageBox;
export { IImageBox };
