import React, { useState } from 'react';

import { BiomePopupStyled } from './styles/BiomePopup.styled.js';
import { BiomeType } from '../../../../../../GildedLands/lib/Enums/Biome.js';

// generic
import CenterTitle from '../../UI/GenericComponents/CenterTitle.tsx';
import { UIColor, UIOpacity, UIPadding } from '../../UI/Enums/UIEnums.ts';
import GenericBackground from '../../UI/GenericComponents/Background.tsx';

function BiomePopup({
  mainBiomeType,
  otherBiomeTypes,
  onBiomeChange,
  onClose,
}) {
  const [selectedMainBiome, setSelectedMainBiome] = useState(mainBiomeType);
  const [selectedOtherBiomes, setSelectedOtherBiomes] =
    useState(otherBiomeTypes);

  const biomeOptions = Object.values(BiomeType);

  const handleMainBiomeChange = (e) => {
    setSelectedMainBiome(e.target.value);
  };

  const handleOtherBiomesChange = (e) => {
    const value = e.target.value;
    setSelectedOtherBiomes((prev) =>
      prev.includes(value)
        ? prev.filter((biome) => biome !== value)
        : [...prev, value]
    );
  };

  const handleSubmit = () => {
    onBiomeChange(selectedMainBiome, selectedOtherBiomes);
  };

  return (
    <BiomePopupStyled className='ui-gold'>
      <GenericBackground color={UIColor.HeaderBlue} opacity={UIOpacity.Full} />
      <CenterTitle
        title='Select Biome Types'
        padding={UIPadding.Tall}
        backgroundData={{
          color: UIColor.DarkGrey,
          opacity: UIOpacity.Full,
        }}
      />
      <CenterTitle
        title='Select Main Biome Types'
        padding={UIPadding.Short}
        backgroundData={{
          color: UIColor.Blue6,
          opacity: UIOpacity.Header,
        }}
      />
      <select value={selectedMainBiome} onChange={handleMainBiomeChange}>
        {biomeOptions.map((biome) => (
          <option key={biome} value={biome}>
            {biome}
          </option>
        ))}
      </select>
      <CenterTitle
        title='Select Other Biome Types'
        padding={UIPadding.Short}
        backgroundData={{
          color: UIColor.Blue6,
          opacity: UIOpacity.Header,
        }}
      />
      <div className='other-biomes'>
        {biomeOptions.map((biome) => (
          <div key={biome}>
            <input
              type='checkbox'
              id={biome}
              value={biome}
              checked={selectedOtherBiomes.includes(biome)}
              onChange={handleOtherBiomesChange}
            />
            <label htmlFor={biome}>{biome}</label>
          </div>
        ))}
      </div>
      <div className='button-container'>
        <button onClick={handleSubmit}>Apply</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </BiomePopupStyled>
  );
}

export default BiomePopup;
