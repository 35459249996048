import { BiomeType, BiomeTypeMethods } from '../../Enums/Biome.js';
import { TradeRouteType } from '../../Enums/TradeRoute.js';
import TradeRoute from './TradeRoute.js';
class RouteTracker {
    constructor(tradeRoutes, optimalCityToCityPaths) {
        this.tradeRoutes = tradeRoutes;
        this.optimalCityToCityPaths = optimalCityToCityPaths;
    }
    // #region paths
    calculateOptimalCityToCityPaths(gameState) {
        const cities = gameState.cities;
        for (const startCity of cities) {
            for (const endCity of cities) {
                if (startCity.instanceId !== endCity.instanceId) {
                    this.calculateOptimalCityToCityPath(startCity.instanceId, endCity.instanceId, gameState);
                }
            }
        }
    }
    calculateOptimalCityToCityPath(startCityId, endCityId, gameState) {
        const cities = gameState.cities;
        const unvisitedCities = new Set(cities.map((city) => city.instanceId));
        const distances = {};
        const previous = {};
        distances[startCityId] = 0;
        for (const city of cities) {
            if (city.instanceId !== startCityId) {
                distances[city.instanceId] = Infinity;
            }
            previous[city.instanceId] = null;
        }
        while (unvisitedCities.size > 0) {
            const currentCityId = Array.from(unvisitedCities).reduce((minCityId, cityId) => distances[cityId] < distances[minCityId] ? cityId : minCityId);
            unvisitedCities.delete(currentCityId);
            if (currentCityId === endCityId)
                break;
            const currentCity = gameState.getCityByInstanceId(currentCityId);
            if (!currentCity)
                continue;
            for (const route of this.tradeRoutes) {
                if (route.originCityInstanceId === currentCityId &&
                    unvisitedCities.has(route.destinationCityInstanceId)) {
                    const neighborCityId = route.destinationCityInstanceId;
                    const exportDutyCost = this.calculateExportDutyCost(route, gameState);
                    const importDutyCost = this.calculateImportDutyCost(route, gameState);
                    const transitDutyCost = this.calculateTransitDutyCost(route, gameState);
                    const alt = distances[currentCityId] +
                        route.shippingCost +
                        exportDutyCost +
                        importDutyCost +
                        transitDutyCost;
                    if (alt < distances[neighborCityId]) {
                        distances[neighborCityId] = alt;
                        previous[neighborCityId] = currentCityId;
                    }
                }
            }
        }
        const path = [];
        let currentId = endCityId;
        while (previous[currentId] !== null) {
            path.unshift(currentId);
            currentId = previous[currentId];
        }
        if (path.length > 0) {
            path.unshift(startCityId);
        }
        const completePath = path.reduce((acc, cityId, idx) => {
            if (idx === 0)
                return acc;
            const prevCityId = path[idx - 1];
            const route = this.tradeRoutes.find((r) => r.originCityInstanceId === prevCityId &&
                r.destinationCityInstanceId === cityId);
            if (route)
                acc.push(route);
            return acc;
        }, []);
        if (completePath.length > 0) {
            const totalShippingCost = completePath.reduce((sum, route) => sum + route.shippingCost, 0);
            const totalExportDutyCost = completePath.reduce((sum, route) => sum + this.calculateExportDutyCost(route, gameState), 0);
            const totalImportDutyCost = completePath.reduce((sum, route) => sum + this.calculateImportDutyCost(route, gameState), 0);
            const totalTransitDutyCost = completePath.reduce((sum, route) => sum + this.calculateTransitDutyCost(route, gameState), 0);
            const allExportDutyIds = [
                ...new Set(completePath.flatMap((route) => route.exportDutyIds)),
            ];
            const allImportDutyIds = [
                ...new Set(completePath.flatMap((route) => route.importDutyIds)),
            ];
            const allTransitDutyIds = [
                ...new Set(completePath.flatMap((route) => route.transitDutyIds)),
            ];
            this.optimalCityToCityPaths[`${startCityId}-${endCityId}`] = {
                route: completePath,
                shippingCost: totalShippingCost +
                    totalExportDutyCost +
                    totalImportDutyCost +
                    totalTransitDutyCost,
                exportDutyIds: allExportDutyIds,
                importDutyIds: allImportDutyIds,
                transitDutyIds: allTransitDutyIds,
            };
        }
    }
    calculateExportDutyCost(route, gameState) {
        let totalExportDutyCost = 0;
        for (const dutyId of route.exportDutyIds) {
            const duty = gameState.getTaxByInstanceId(dutyId);
            totalExportDutyCost += duty.getCost(route.destinationCityInstanceId, gameState);
        }
        return totalExportDutyCost;
    }
    calculateImportDutyCost(route, gameState) {
        let totalImportDutyCost = 0;
        for (const dutyId of route.importDutyIds) {
            const duty = gameState.getTaxByInstanceId(dutyId);
            totalImportDutyCost += duty.getCost(route.originCityInstanceId, gameState);
        }
        return totalImportDutyCost;
    }
    calculateTransitDutyCost(route, gameState) {
        let totalTransitDutyCost = 0;
        for (const dutyId of route.transitDutyIds) {
            const duty = gameState.getTaxByInstanceId(dutyId);
            totalTransitDutyCost += duty.getCost(route.originCityInstanceId, route.destinationCityInstanceId, gameState);
        }
        return totalTransitDutyCost;
    }
    // #endregion paths
    addNewCity(cityInstanceId, gameState) {
        this.addRoutesForNewCity(cityInstanceId, gameState);
        this.calculateOptimalCityToCityPaths(gameState);
    }
    addRoutesForNewCity(cityInstanceId, gameState) {
        const newCity = gameState.getCityByInstanceId(cityInstanceId);
        if (!newCity)
            return;
        for (const existingCity of gameState.cities) {
            if (existingCity.instanceId === cityInstanceId)
                continue;
            const startingTile = newCity.centerTileLandtileId;
            const endingTile = existingCity.centerTileLandtileId;
            const bestRoute = this.calculateBestRoute(cityInstanceId, existingCity.instanceId, gameState.getLandTileByTileId(startingTile), gameState.getLandTileByTileId(endingTile), gameState);
            if (bestRoute) {
                this.tradeRoutes.push(bestRoute);
                this.optimalCityToCityPaths[`${cityInstanceId}-${existingCity.instanceId}`] = {
                    route: [bestRoute],
                    shippingCost: bestRoute.shippingCost,
                    exportDutyIds: [...bestRoute.exportDutyIds],
                    importDutyIds: [...bestRoute.importDutyIds],
                    transitDutyIds: [...bestRoute.transitDutyIds],
                };
                // add reverse
                const reverseBestRoute = new TradeRoute(bestRoute.tradeRouteType, existingCity.instanceId, cityInstanceId, bestRoute.landTileIds.reverse(), [...bestRoute.controllingCityInstanceIds], [...bestRoute.controllingFortInstanceIds], bestRoute.shippingCost, bestRoute.exportDutyIds, bestRoute.importDutyIds, bestRoute.transitDutyIds);
                this.tradeRoutes.push(reverseBestRoute);
                this.optimalCityToCityPaths[`${existingCity.instanceId}-${cityInstanceId}`] = {
                    route: [reverseBestRoute],
                    shippingCost: reverseBestRoute.shippingCost,
                    exportDutyIds: [...reverseBestRoute.exportDutyIds],
                    importDutyIds: [...reverseBestRoute.importDutyIds],
                    transitDutyIds: [...reverseBestRoute.transitDutyIds],
                };
            }
        }
    }
    // #region pathfinding for routes
    calculateBestRoute(originCityInstanceId, destinationCityInstanceId, startingTile, endingTile, gameState) {
        var _a;
        let openSet = [startingTile];
        const cameFrom = new Map();
        const gScore = new Map();
        const fScore = new Map();
        const requiresOcean = startingTile.allBiomeTypes.includes(BiomeType.Ocean);
        gScore.set(startingTile, 0);
        fScore.set(startingTile, this.heuristicCostEstimate(startingTile, endingTile));
        while (openSet.length > 0) {
            let current = openSet.reduce((lowest, tile) => fScore.get(tile) < fScore.get(lowest) ? tile : lowest, openSet[0]);
            if (current === endingTile) {
                const totalPath = [current];
                while (cameFrom.has(current)) {
                    current = cameFrom.get(current);
                    totalPath.unshift(current);
                }
                const newRoute = new TradeRoute(requiresOcean ? TradeRouteType.Sea : TradeRouteType.Land, originCityInstanceId, destinationCityInstanceId, totalPath.map((tile) => tile.id), [], [], 0, [], [], []);
                newRoute.updateCosts(gameState);
                return newRoute;
            }
            openSet = openSet.filter((tile) => tile !== current);
            const neighbors = current.findNeighbors(gameState.realm);
            for (const neighbor of neighbors) {
                if (requiresOcean &&
                    !neighbor.allBiomeTypes.includes(BiomeType.Ocean)) {
                    continue;
                }
                if (!requiresOcean &&
                    neighbor.allBiomeTypes.includes(BiomeType.Ocean)) {
                    continue;
                }
                const tentativeGScore = gScore.get(current) + this.distance(neighbor);
                if (tentativeGScore < ((_a = gScore.get(neighbor)) !== null && _a !== void 0 ? _a : Infinity)) {
                    cameFrom.set(neighbor, current);
                    gScore.set(neighbor, tentativeGScore);
                    fScore.set(neighbor, tentativeGScore + this.heuristicCostEstimate(neighbor, endingTile));
                    if (!openSet.includes(neighbor)) {
                        openSet.push(neighbor);
                    }
                }
            }
        }
        return null;
    }
    heuristicCostEstimate(start, goal) {
        // Using Manhattan distance as the heuristic
        return Math.abs(start.x - goal.x) + Math.abs(start.y - goal.y);
    }
    distance(tile) {
        return BiomeTypeMethods.shippingCostPerTile(tile.mainBiomeType);
    }
    // #endregion pathfinding
    // #region json
    clone() {
        const newTradeRoutes = this.tradeRoutes.map((route) => route.clone());
        const newOptimalCityToCityPaths = Object.keys(this.optimalCityToCityPaths).reduce((acc, key) => {
            acc[key] = {
                route: this.optimalCityToCityPaths[key].route.map((route) => route.clone()),
                shippingCost: this.optimalCityToCityPaths[key].shippingCost,
                exportDutyIds: [...this.optimalCityToCityPaths[key].exportDutyIds],
                importDutyIds: [...this.optimalCityToCityPaths[key].importDutyIds],
                transitDutyIds: [...this.optimalCityToCityPaths[key].transitDutyIds],
            };
            return acc;
        }, {});
        return new RouteTracker(newTradeRoutes, newOptimalCityToCityPaths);
    }
    toJSON() {
        return {
            tradeRoutes: this.tradeRoutes.map((route) => route.toJSON()),
            optimalCityToCityPaths: Object.keys(this.optimalCityToCityPaths).length > 0
                ? Object.keys(this.optimalCityToCityPaths).reduce((acc, key) => {
                    acc[key] = {
                        route: this.optimalCityToCityPaths[key].route.map((route) => route.toJSON()),
                        shippingCost: this.optimalCityToCityPaths[key].shippingCost,
                        exportDutyIds: [
                            ...this.optimalCityToCityPaths[key].exportDutyIds,
                        ],
                        importDutyIds: [
                            ...this.optimalCityToCityPaths[key].importDutyIds,
                        ],
                        transitDutyIds: [
                            ...this.optimalCityToCityPaths[key].transitDutyIds,
                        ],
                    };
                    return acc;
                }, {})
                : {},
        };
    }
    static fromJSON(json) {
        const tradeRoutes = json.tradeRoutes.map((routeJson) => TradeRoute.fromJSON(routeJson));
        const optimalCityToCityPaths = json.optimalCityToCityPaths
            ? Object.keys(json.optimalCityToCityPaths).reduce((acc, key) => {
                acc[key] = {
                    route: json.optimalCityToCityPaths[key].route.map((routeJson) => TradeRoute.fromJSON(routeJson)),
                    shippingCost: json.optimalCityToCityPaths[key].shippingCost,
                    exportDutyIds: [
                        ...json.optimalCityToCityPaths[key].exportDutyIds,
                    ],
                    importDutyIds: [
                        ...json.optimalCityToCityPaths[key].importDutyIds,
                    ],
                    transitDutyIds: [
                        ...json.optimalCityToCityPaths[key].transitDutyIds,
                    ],
                };
                return acc;
            }, {})
            : {};
        return new RouteTracker(tradeRoutes, optimalCityToCityPaths);
    }
}
export default RouteTracker;
