import styled, { keyframes, css } from 'styled-components';
import '../../../../../styles/constants.css';

const MapUnitStyled = styled.div.attrs(({ hexagonSize, position }) => ({
  style: {
    top: `${position.y}px`,
    left: `${position.x}px`,
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: none;
  z-index: 100;
  position: absolute;
`;

const MapUnitLabelStyled = styled.div.attrs(({ hexagonSize }) => ({
  style: {
    fontSize: `${hexagonSize * 0.3}px`,
  },
}))`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-green);
  color: white;
  font-family: var(--logo-font);
  border: 1px solid var(--lol-gold);
  padding: 1px 3px;
  z-index: 11;
  white-space: nowrap;
  max-width: none;

  .unit-name {
    font-size: 1em;
  }

  .unit-population {
    font-size: 0.8em;
    margin-top: 2px;
  }
`;

const MapUnitShapeStyled = styled.svg.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
    top: 0,
    left: 0,
  },
}))`
  position: absolute;
  fill: none;
  stroke: var(--lol-gold);
  stroke-width: 2;
  z-index: 10;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const MapUnitPulseCircleStyled = styled.svg.attrs(({ hexagonSize }) => ({
  style: {
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;

  circle {
    fill: url(#fade-gradient);
    transform-origin: center;
    animation: ${pulse} 3s infinite;
  }
`;

const MapUnitImageStyled = styled.img.attrs(({ hexagonSize, points }) => ({
  style: {
    clipPath: `circle(${hexagonSize * 0.4}px at ${
      (hexagonSize * Math.sqrt(3)) / 2
    }px ${hexagonSize}px)`,
    width: `${hexagonSize * Math.sqrt(3)}px`,
    height: `${hexagonSize * 2}px`,
  },
}))`
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export {
  MapUnitStyled,
  MapUnitShapeStyled,
  MapUnitImageStyled,
  MapUnitLabelStyled,
  MapUnitPulseCircleStyled,
};
