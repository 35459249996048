import React, { useContext, useState } from 'react';

// contexts
import { HighlightedTileContext } from '../../../contexts/ScreenAndMap/HighlightedTileContextProvider.tsx';

// internal
// css
import { TileHexagonStyled } from './styles/TileHexagon.styled.js';

const Hexagon = ({ x, y, size }) => {
  const hexPoints = [
    { x: x + (size * Math.sqrt(3)) / 2, y: y },
    { x: x + size * Math.sqrt(3), y: y + size / 2 },
    { x: x + size * Math.sqrt(3), y: y + (size * 3) / 2 },
    { x: x + (size * Math.sqrt(3)) / 2, y: y + 2 * size },
    { x: x, y: y + (size * 3) / 2 },
    { x: x, y: y + size / 2 },
  ];
  const points = hexPoints.map((p) => `${p.x},${p.y}`).join(' ');
  return <polygon className='hexagon' points={points} />;
};

const TileHexagon = ({ tileId, hexagonSize }) => {
  const { borderedTiles, conditionBorderedTiles, conditionalDefault } =
    useContext(HighlightedTileContext);
  const [isHovered, setIsHovered] = useState(false);

  const getBorderColor = () => {
    if (isHovered) {
      return (
        conditionBorderedTiles[tileId]?.color ||
        conditionalDefault?.color ||
        'transparent'
      );
    }
    return borderedTiles[tileId]?.color || 'transparent';
  };

  const border = { stroke: getBorderColor(), strokeWidth: 4 };

  return (
    <TileHexagonStyled
      className='tile-hexagon'
      width={hexagonSize * Math.sqrt(3)}
      height={2 * hexagonSize}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        ...border,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Hexagon x={0} y={0} size={hexagonSize} />
    </TileHexagonStyled>
  );
};

export default TileHexagon;
