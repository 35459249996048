// external
import React, { useState, useEffect, useContext, useRef } from 'react';

// internal context
import { GameElementSizeContext } from '../../contexts/ScreenAndMap/GameElementSizeContextProvider.tsx';
import { InteractionContext } from '../../contexts/GameData/InteractionContextProvider.tsx';

// internal components
import EndGamePopup from './Popups/EndGame/EndGamePopup.tsx';
import HexMapContainer from './Map/HexMapContainer.tsx';
import Overlay from './Overlay/Overlay.tsx';

// css
import { GamePageStyled } from './styles/GamePage.styled.js';
import Popups from './Overlay/MapOverlay/Popups.tsx';
import { ScreenSizeContext } from '../../contexts/ScreenAndMap/ScreenSizeContextProvider.tsx';
import { SelectedLandtileSetContext } from '../../contexts/GameData/SelectedLandtileContextProvider.tsx';
import { GameStateDataContext } from '../../contexts/GameData/GameStateDataContextProvider.tsx';
// lol imports

function GamePage() {
  // #region contexts and refs and vars
  // if I'm not in play, leave this screen and go to the lobby

  const gameContainerRef = useRef(null);

  const { displayHeight, displayWidth } = useContext(ScreenSizeContext);
  const { onClickTile, onRightClickTile } = useContext(InteractionContext);
  const setSelectedTileIndex = useContext(SelectedLandtileSetContext);
  const { gameState } = useContext(GameStateDataContext);
  // #endregion

  // #region controls (limited to dev and end game screen)

  // #endregion

  return (
    <GamePageStyled className='game-page'>
      {/* <div className='screen-background water'>
        <img
          className='screen-background-image'
          src={justWater}
          alt='background'
        />
      </div> */}
      <div className='game-container' ref={gameContainerRef}>
        {/* Map */}
        <div className='map-container'>
          <HexMapContainer
            tiles={gameState ? gameState.realm.getLandTiles() : []}
            mapTileSize={100}
            onMouseEnterTile={(id) => setSelectedTileIndex(id)}
            onMouseLeaveTile={() => setSelectedTileIndex(null)}
            onRightClickTile={onRightClickTile}
            onMouseUpOnTile={onClickTile}
            width={displayWidth}
            height={displayHeight}
          />
        </div>
        {/* Fog */}
        {/* <Fog /> */}

        {/* City Screen and Reporting */}
        <Overlay />
      </div>
    </GamePageStyled>
  );
}

export default GamePage;
