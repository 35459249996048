import React, { useContext, useRef, useState } from 'react';
import { lolGold } from '../../styles/constants.ts';

const HighlightedTileContextProvider = ({ children }) => {
  const [highlightedTiles, setHighlightedTiles] = useState<number[]>(null);

  const [borderedTiles, setBorderedTiles] = useState<{
    [tileId: number]: {
      color: string;
    };
  }>({});

  const [conditionBorderedTiles, setConditionBorderedTiles] = useState<{
    [tileId: number]: {
      color: string;
    };
  }>({});

  const [conditionalDefault, setConditionalDefault] = useState<{
    color: string;
  } | null>({
    color: lolGold,
  });

  const resetConditionalDefault = () => {
    setConditionalDefault({
      color: lolGold,
    });
  };

  const value: HighlightedTileContextType = {
    highlightedTiles,
    setHighlightedTiles,
    borderedTiles,
    setBorderedTiles,
    conditionBorderedTiles,
    setConditionBorderedTiles,
    conditionalDefault,
    setConditionalDefault,
    resetConditionalDefault,
  };

  return (
    <HighlightedTileContext.Provider value={value}>
      {children}
    </HighlightedTileContext.Provider>
  );
};

interface HighlightedTileContextType {
  highlightedTiles: number[];
  setHighlightedTiles: (tiles: number[]) => void;
  borderedTiles: {
    [tileId: number]: {
      color: string;
    };
  };
  setBorderedTiles: (tiles: {
    [tileId: number]: {
      color: string;
    };
  }) => void;
  conditionBorderedTiles: {
    [tileId: number]: {
      color: string;
    };
  };
  setConditionBorderedTiles: (tiles: {
    [tileId: number]: {
      color: string;
    };
  }) => void;
  conditionalDefault: {
    color: string;
  } | null;
  setConditionalDefault: ({ color }: { color: string }) => void;
  resetConditionalDefault: () => void;
}

const HighlightedTileContext =
  React.createContext<HighlightedTileContextType | null>(null);

export { HighlightedTileContextProvider, HighlightedTileContext };
