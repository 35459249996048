import { ClientMessage } from '../MessageBase.js';
import { NetworkProtocol } from '../../../Enums/NetworkProtocol.js';
export default class ChangeKnowledgeMessage extends ClientMessage {
    constructor(messageId, senderUserId, improvementId, knowledgeIndex, experienceType) {
        super(NetworkProtocol.ChangeImprovementProduction, messageId, senderUserId);
        this.improvementId = improvementId;
        this.knowledgeIndex = knowledgeIndex;
        this.experienceType = experienceType;
    }
    toJSON() {
        return Object.assign(Object.assign({}, super.toJSON()), { improvementId: this.improvementId, knowledgeIndex: this.knowledgeIndex, experienceType: this.experienceType });
    }
    static fromJSON(json) {
        return new ChangeKnowledgeMessage(json.messageId, json.senderUserId, json.improvementId, json.knowledgeIndex, json.experienceType);
    }
    // check that all fields in the message are valid
    validate() {
        console.log('create improvement message validating: ', this.toJSON());
        return (this.improvementId !== undefined &&
            this.knowledgeIndex !== undefined &&
            this.experienceType !== undefined &&
            super.validate());
    }
}
