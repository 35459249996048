// external
import React, { useContext } from 'react';

// internal
// context
import { ImageContext } from '../../contexts/Media/ImageContextProvider.tsx';

// css
import { FogStyled } from './styles/Fog.styled.js';

function Fog() {
  // context
  const { fog } = useContext(ImageContext);

  return (
    <FogStyled className='fog-component'>
      <div className='fog fog-one'>
        <img src={fog} />
      </div>
      <div className='fog fog-two'>
        <img src={fog} />
      </div>
      <div className='fog fog-three'>
        <img src={fog} />
      </div>
      <div className='fog fog-four'>
        <img src={fog} />
      </div>
    </FogStyled>
  );
}

export default Fog;
